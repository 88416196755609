import React, {useContext, useEffect, useState} from 'react';
import {priceToUserString} from "../../../http/basketApi";
import {useNavigate} from "react-router-dom";
import './calcBasket.css'
import {observer} from "mobx-react-lite";
import CheckBoxPolitic from "../../../components/UI/checkBoxPolitic/CheckBoxPolitic";
import { Context } from '../../../index';
import { ReactComponent as Loader } from '../../../components/Loader/loader.svg'
import '../../../components/Loader/loader.css'
import Checkout from '../../../components/checkout/Checkout';

const CalcBasket = observer(({user ,basketItems, nameButton, forwardedRef}) => {

    const {basket, delivery} = useContext(Context);
    const Navigate = useNavigate();
    const [stateCheсkBoxBasket, setStateCheсkBoxBasket] = useState(false)
    const [errorCheсkBoxBasket, setErrorCheсkBoxBasket] = useState('')
    const [activeOrderButton, setActiveOrderButton] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);

    const basketGoOrder = async () => {
        setShowSpinner(true);
        if(!activeOrderButton) {
            return false;
        }

        if (stateCheсkBoxBasket === false) {
            setErrorCheсkBoxBasket("Необходимо принять соглашение для оформления заказа");
            return false;
        }

        if (basket.selectPaymentTypeError !== '') {
            setErrorCheсkBoxBasket(basket.selectPaymentTypeError);
        }

        setActiveOrderButton(false);
        setErrorCheсkBoxBasket('');
        const result = await basket.upStepPage(basket.startBasketNum)
        
        if(!result) {
            if (basket.selectPaymentTypeError !== '') {
                executeScroll();
            } else {
                window.scrollTo(0, 0)
            }
            setShowSpinner(false);
            return false;
        }
        if(result.order) {
            basket.removeAllItems('active', false);
            Navigate("/order/id/"+result.order.custom_order_id);
        }
    }

    const executeScroll = () => {
        forwardedRef.current.scrollIntoView(
            {
                behavior: 'auto',
                block: 'center',
                inline: 'center'
            }
        )
    }

    useEffect(() => {
        if (basket.selectPaymentTypeError === '') {
            setActiveOrderButton(true);
        }
    }, [basket.selectPaymentTypeError])

    return (
        <>
        <div className={"basket_page_right_order"}>
            <div className={"basket_page_heading_right_container"}>
                <span>Ваш заказ</span>
                {
                    basket.startBasketNum > 1 ?
                        <span className={"change_basket_go_prev_step"} onClick={e => basket.setStartBasketNum(1)} >Изменить</span> : ""
                }
            </div>
            <div className={"basket_floating_step"}>
                <div className="basket_page_product_counter_line">
                    <span>Количество товаров: {basket.selectedItems.length} шт.</span>
                </div>

                <div className={"basket_page_all_order_price"}>
                    <span>Сумма заказа: </span>
                    <span className={"basket_page_price_string"}>{priceToUserString(basket.priceNoSell)} ₽</span>
                </div>
                <div className={"basket_page_all_order_delivery delivery"}>
                    <div className={"basket_page_all_order_delivery_top_line"}>
                        <span className={"basket_page_all_order_label_name"}>Доставка: </span>
                        <span className={"basket_page_price_string"}>{basket.delPrice ? priceToUserString(basket.delPrice)+ " ₽" : "бесплатно"}</span>
                    </div>

                    {
                        delivery.active?.negative_dop_price ?
                            <div className="basket_page_all_order_delivery_bottom_line">
                                <span className={"negative_price_row"}>{"*"+priceToUserString(delivery.active.negative_dop_price)+ " при невыкупе"}</span>
                            </div>
                        : ""
                    }

                </div>
                <div className={"basket_page_all_order_price sales"}>
                    <span>Скидка: </span>
                    <span className={"basket_page_price_string"}>{priceToUserString(basket.priceNoSell - basket.totalPrice - basket.bonusesConfirmed + basket.delPrice)} ₽</span>
                </div>
                {
                    basket.isLoyaltyActive ? 
                        <div className={"basket_page_all_order_price sales"}>
                            <span>Оплачено бонусами: </span>
                            <span className={"basket_page_price_string"}>{priceToUserString(basket.bonusesConfirmed)} ₽</span>
                        </div>
                    : ""
                }
                <div className={"basket_page_result_price"}>
                    <span>Итого:</span>
                    <span className={"basket_page_result_price_grey"}>{ priceToUserString(basket.totalPrice)+" ₽" } </span>
                </div>
                {
                    basket.totalBonusesAvailable > 0 ? 
                    <>
                        <div className={"basket_page_all_order_price sales"}>
                            <span>Доступно бонусов (всего): </span>
                            <span className={"basket_page_price_string"}>{basket.totalBonusesAvailable}</span>
                        </div>
                        <div className={"basket_page_all_order_price"}>
                            <span>Доступно бонусов (для списания):</span>
                            <span className={"basket_page_price_string"}>{basket.bonusesAvailableForBasket} </span>
                        </div>
                        <div className={"bonus_check"}>
                            <div
                                onClick={e=> basket.setSpendBonuses(!basket.spendBonuses)}
                                className={ basket.spendBonuses === true ? "checkbox_selector active" : "checkbox_selector"}>
                                <div className="child_checkbox_selector">
                                </div>
                            </div>
                            <span> Списать бонусы </span>
                        </div>
                    </>
                    : ""
                }
                
            </div>
            {
                basket.startBasketNum == 4 ?
                    <div className={"basket_floating_next_step"}>
                        <span
                            onClick={e => basketGoOrder()}
                            className={activeOrderButton ? "basket_page_go_order_page" : "basket_page_go_order_page disable"}>
                            {showSpinner ? <Loader className={'spinner'}/> : nameButton}
                        </span>
                            <CheckBoxPolitic
                                setStateCheсkBox = {e=> setStateCheсkBoxBasket(e)}
                                stateCheсkBox ={stateCheсkBoxBasket}
                            />
                            {
                                errorCheсkBoxBasket !== '' ?
                                <div className={"select_politic_type_error"}>{errorCheсkBoxBasket}</div>
                                : ''
                            }
                    </div>
                : ""
            }
        </div>
        </>
    );
});

export default CalcBasket;
