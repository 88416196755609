import React from 'react';
import './OneCardsContact.css'

const OneCardsContact = (props) => {


    const fieldsHanlerCards = (e,fieldName) => {
        props.setNoJsonContent({...props.noJson}, [props.noJson.cardsInfo[props.index][fieldName] = e.target.value])
        props.setUpdateSocialSaveTriger(true)
    }


    return (
            <div className={"one_cards_contacts_adm"}>
                <div className={"delete_cars_contacts_relative"}>
                    <div
                        onClick={e => props.deleteOneCards(props.index)}
                        className={"delete_cars_contacts"}></div>
                </div>
                <div className={"left_fields_container"}>
                    <span className="contacts_field_label_name">Заголовок карточки</span>
                    <input
                        value={props.noJson.cardsInfo[props.index].title ? props.noJson.cardsInfo[props.index].title : ""}
                        onChange={e => fieldsHanlerCards(e, "title")}
                        className="contacts_field" type="text" />
                </div>
                <div className={"left_fields_container"}>
                    <span className="contacts_field_label_name">Текст карточки</span>
                    <textarea
                        value={props.noJson.cardsInfo[props.index].text_cards ? props.noJson.cardsInfo[props.index].text_cards : ""}
                        onChange={e => fieldsHanlerCards(e, "text_cards")}
                        className="contacts_field textarea" type="text" />
                </div>
                <div className={"left_fields_container"}>
                    <span className="contacts_field_label_name">Текст в кнопке</span>
                    <input
                        value={props.noJson.cardsInfo[props.index].name_buttom ? props.noJson.cardsInfo[props.index].name_buttom : ""}
                        onChange={e => fieldsHanlerCards(e, "name_buttom")}
                        className="contacts_field" type="text"/>
                </div>
                <div className={"left_fields_container"}>
                    <span className="contacts_field_label_name">Ссылка для кнопки (пример: /content/contacts/ или https://you-wanna.ru/)</span>
                    <input
                        value={props.noJson.cardsInfo[props.index].link ? props.noJson.cardsInfo[props.index].link : ""}
                        onChange={e => fieldsHanlerCards(e, "link")}
                        className="contacts_field" type="text"/>
                </div>
                <div className={"fields_container_link"}>
                    <span className="contacts_field_label_name">Активировать если ссылка на сторонний ресурс/ телефон/ почта</span>
                    <div
                        onClick={e => props.setNoJsonContent({...props.noJson},[props.noJson.cardsInfo[props.index].active_target_blank = !props.noJson.cardsInfo[props.index].active_target_blank])}
                        className={props.noJson.cardsInfo[props.index].active_target_blank ? "link_contacts_check_box active" : "link_contacts_check_box"}></div>
                </div>
            </div>
    );
};

export default OneCardsContact;