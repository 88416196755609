import React from 'react';
import {Link} from "react-router-dom";

const BreadcrumbsItem = (props) => {
    return (
        <div className={"breadcrumbs_item_content"}>
            <Link to={props.item.link} itemScope itemProp="itemListElement" itemType="http://schema.org/ListItem">
                {props.item.name}
                <meta itemProp="position" content={props.item.position} />
            </Link>
        </div>
    );
};

export default BreadcrumbsItem;
