import React, {useState} from 'react';
import "./OneSizeAdmin.css";
import {deleteSize, updateSize} from "../../../../http/catalogApi";
import {Link} from "react-router-dom";


const OneSizeAdmin = (props) => {


    const remSize = async (id) => {
        const {data} = await deleteSize(id)
        if(data.message){
            props.setSizeTriger(!props.sizeTriger)
            return false;
        }
        if(data.length){
            setErrMessage(data);
        }

    }

    const updateSizeHandler = async (item) => {
         updateSize(item).then(response => {
            if(response.error) {
                setUpdateStatus(response.error);
                return false;
            }
            if(response.message) {
                setUpdateStatus(response.message);
                return false;
            }
            return false;
         })
    }
    const [updateStatus,setUpdateStatus] = useState("");
    const [errMessage, setErrMessage] = useState([]);

    return (
        <div className={"one_size_wrapper"}>
            <div className={"one_size_admin"}>
                {props.item.size_name}
            </div>
            <div className={"one_size_position_field_container"}>
                <span className={"label_position_field"}>Позиция (кратно 100) </span>
                <input
                    className={"size_field_pos"}
                    value={props.item.position ? props.item.position : "" }
                    onChange={ e => props.setSize([...props.size],[props.size[props.index].position = e.target.value])}
                    type="text"/>
            </div>
            <div className={"update_one_size_button"} onClick={e => updateSizeHandler(props.item)}> Сохранить </div>


            <div onClick={e => remSize(props.item.id)} className={"delete_size_button"}> удалить </div>
            <div className={"update_information_this_size"}> {updateStatus ? updateStatus : ""} </div>
            <div className={errMessage.length ? "size_delete_response_server active" : "size_delete_response_server"}>
                <div className={"err_text_message"}><span className={"error_sup_message"}>Удаление недоступно, товары использущие этот размер:</span>
                   <div className={"products_size_go"}>
                       {   errMessage ?
                           errMessage.map((item,index) => {
                               return(<a className={"link_size_go_product"} href={"../products/oneitem/"+item} key={index}>{item}</a>)
                           }) : ""}
                   </div>



                </div>

            </div>
        </div>

    );
};

export default OneSizeAdmin;