import React from 'react';
import './offerSliderProduct.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {priceToUserString} from "../../../http/basketApi";
import {Link, useNavigate} from "react-router-dom";
import Like from "../../../components/UI/Like/Like";
import {login} from "../../../http/userAPI";

const OfferSliderProduct = (props) => {


    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                // style={{ ...style, display: "block", top: -30,  right: 50, height: 30, width: 30,  }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                // style={{ ...style, display: "block", top: -30, right: 100, left: "auto", height: 30, width: 30,}}
                onClick={onClick}
            />
        );
    }


    let settings = {
        dots: false,
        infinite: chekRecomendationsLength(),
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        draggable: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    // dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true
                }
            }
        ]
    };

    function chekRecomendationsLength() {
        if (props.productMokTest.length < 4) {
            props.productMokTest[0] = {...props.productMokTest[0], is_first: true};

        }
        return props.productMokTest.length > 3
    }



    function urlOneProductSlider(item) {
        if(!item.product) {
            return false;
        }

        if(item.product.product_url) {
            return item.product.product_url+item.code+"/";
        }

        var url = "/catalog/";
        if(props.parrent){
            url += props.parrent+"/";
        }
        if(props.child){
            url += props.child+"/";
        }
        url += "product/"+item.code+"/";
        return url;

    }
    const Navigate = useNavigate();
    const switchingToProduct = () =>  {
        Navigate(urlOneProductSlider());
    }

    return (
            <div className={"slick_wrapper"} >
                <div className={"wrapper_arrows_slider"}>

                </div>
                <Slider {...settings}>
                    {
                        props.productMokTest ? props.productMokTest.map((item, index)=>
                                <div className={"one_cards_slider"} key={item.id}>
                                    <div className={"add_product_favorite"}>
                                        <Like
                                            itemProduct={item}
                                        />
                                    </div>
                                    <Link className={"bottom_one_product_wrapper"} to={urlOneProductSlider(item)}
                                          onClick={() => switchingToProduct()}
                                    >



                                        <div
                                            key={index}
                                            className={"one_cards_product"} style={{ borderLeft: item.is_first ? "1px solid black" : ""}}>

                                            <div className={"slider_img"}>
                                                <img className={"standart_photo_product"} src={
                                                    process.env.REACT_APP_API_URL+"uploads/images/"+item.productId+"/"+item.id+"/" + "950_"+item.img_preview}
                                                     alt={ (item.name ? item.name : '')+'; цвет: '+(item.color.color_name ? item.color.color_name : '')}
                                                />
                                            </div>


                                            <div className={"price_and_name_slider"}>

                                                <div className={"name_product_slider"}>{item.name ? `${item.name.charAt(0).toUpperCase()}${item.name.slice(1).toLowerCase()}` : ''}</div>
                                                {/*<div className={"name_product_slider"}>{item.name ? item.name : ''}</div>*/}
                                                {!item.price_hidden ?
                                                    <div className={"price_and_oldprice"}>
                                                        <div className={"sell_price_prod_slider"}>{item.sell_price != 0 ? priceToUserString(item.sell_price)+" ₽" : ''}</div>
                                                        <div className={
                                                            item.sell_price != 0 ? "price_product_slider old_price" : "price_product_slider"
                                                        }>{item.parrent_price ? priceToUserString(item.parrent_price)+" ₽" : ''}</div>
                                                    </div> : ''
                                                }
                                            </div>
                                        </div>
                                    </Link>

                                </div>




                            )
                            : ""
                    }

                </Slider>
            </div>
        );
};


export default OfferSliderProduct;