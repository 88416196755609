import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';

import './css/ReturnsList.css'

import ReturnsApi from "../../../../http/returnsApi";
import ReturnsListItem from "./ReturnsListItem";
import returnsContext from "../lib/returnsContext";
import Paginstion from "../../../../components/Paginstion";
import {login} from "../../../../http/userAPI";

const limit = 25

const ReturnsList = () => {

    const [searchParam, setSearchParam] = useState('orderNumber');
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(1)
    const [returnsList, setReturnsList] = useState([])
    const [allCount, setAllCount] = useState(0)
    //чекаем удаление возвратов, обновляем
    const [check, setCheck] = useState(false)
    //id сортировки
    const [selected, setSelected] = useState(0);
    const [statusSort, setStatusSort] = useState("%");

    const linkElement = useRef(null)

    const {setError} = useContext(returnsContext)

    const searchHandler = (e) => {
        e.preventDefault()
        setSearchText(e.target.value)
    }

    const getReturnsList = async () => {

        const response = await ReturnsApi.getAll(limit, page, selected == 1 ? 'ASC' : 'DESC', statusSort, searchParam, searchText)

        if(!response.success)
            return setError(response.message)

        setAllCount(response.data.count)

        setReturnsList(response.data.rows)
    }

    useEffect(() => {
        getReturnsList()
    }, [page, check, selected])

    useMemo(() => {
        setPage(1)
        getReturnsList()
    }, [statusSort, searchParam, searchText])


    const generateExcelExportHandler = () => {
        let options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        };
        ReturnsApi.generateExcelExport(options).then(response => {
            if(response) {
                const link = document.createElement('a');
                link.href = `${process.env.REACT_APP_API_URL}/uploads/${response}`;
                link.setAttribute(
                'download',
                response
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }
        })
    }

    const sortRefund = [
        {id: 0, value: 'Сначала новые'},
        {id: 1, value: 'Сначала старые'},
    ]

    const sortRefund2 = [
        {id: 0, key: "Все", value: "%"},
        {id: 1, key: "Новые",  value: 'Новый'},
        {id: 2, key: "В обработке",  value: 'В обработке'},
        {id: 3, key: "Выполнены",  value: 'Выполнен'},
    ]

    function List() {

        return <div className={'sort_returns returns-list-item__field'}>
            <select
                className={'status_refund'}
                onChange={(e) => setSelected(e.target.value)}
                value={selected ? selected : 'Сортировка'}>
                {sortRefund.map((m, ix) => {
                    return <option
                        key={m.id}
                        value={ix}
                    >
                        {m.value}
                    </option>
                })};
            </select>
        </div>;
    }

    function ListStatus() {

        return <div className={'sort_returns returns-list-item__field'}>
            <select
                className={'status_refund'}
                onChange={(e) => setStatusSort(e.target.value)}
                value={statusSort ? statusSort : 'Сортировка'}>
                {sortRefund2.map((m, ix) => {
                    return <option
                        key={m.id}
                        value={m.value}
                    >
                        {m.key}
                    </option>
                })};
            </select>
        </div>;
    }


    return (
        <>
            <div className={"adm_refund_page"}>
                <div className={"title_and_button"}>
                    <div className={"title_refund_page"}>Возвраты интернет магазин</div>
                    <button
                        style={{marginBottom: '20px'}}
                        onClick={generateExcelExportHandler}
                        className="download_list_refund">
                        СГЕНЕРИРОВАТЬ ТАБЛИЦУ ТОВАРОВ
                    </button>
                </div>
                <div className={"list_refund_wrapper"}>
                    <div className={"search_filter_container"}>
                        <div className={"controls_button_search"}>
                            <div
                                onClick={e => setSearchParam("orderNumber")}
                                className={searchParam === "orderNumber" ? "search_btn_param active" : "search_btn_param"}>Номер заказа</div>
                            <div
                                onClick={e => setSearchParam("article")}
                                className={searchParam === "article" ? "search_btn_param active" : "search_btn_param"}>Артикул</div>
                            <div
                                onClick={e => setSearchParam("fullName")}
                                className={searchParam === "fullName" ? "search_btn_param active" : "search_btn_param"}>ФИО</div>
                            <div
                                onClick={e => setSearchParam("phone")}
                                className={searchParam === "phone" ? "search_btn_param active" : "search_btn_param"}>Телефон</div>
                            </div>


                            <div className={"container_for_search_field"}>
                                <input className={"search_input_products_page"} type="text"
                                    value={searchText}
                                    onChange={e => searchHandler(e)}
                                    placeholder={"Поиск..."}
                                />
                                {
                                    ListStatus()
                                }

                            </div>
                        </div>

                    <div className="returns-list">
                        <a ref={linkElement} style={{display: 'none'}}></a>
                        <div className="returns-list_child">
                            <div className="returns-list-item__field">
                                ID
                            </div>
                            <div className="returns-list-item__field">
                                Тип возврата
                            </div>
                            <div className="returns-list-item__field">
                                Дата заявки
                            </div>
                            <div className="returns-list-item__field">
                                Ф.И.О
                            </div>
                            <div className="returns-list-item__field">
                                НОМЕР ТЕЛЕФОНА
                            </div>
                            <div className="returns-list-item__field">
                                АРТИКУЛ
                            </div>
                            <div className="returns-list-item__field">
                                НОМЕР ЗАКАЗА
                            </div>
                            <div className="returns-list-item__field">
                                РАЗМЕР
                            </div>
                            <div className="returns-list-item__field">
                                ЦВЕТ
                            </div>
                            <div className="returns-list-item__field">
                                СТАТУС
                            </div>

                        </div>
                        {
                            List()
                        }
                    </div>

                    <div>
                        {
                            returnsList.map((returnItem) => {
                                return <ReturnsListItem
                                    key={returnItem.id}
                                    item={returnItem}
                                    setCheck={e=>setCheck(e)}
                                    check={check}
                                />
                            })
                            //
                        }
                    </div>
                </div>

                <Paginstion
                    page={page}
                    count={allCount}
                    limit={limit}
                    onChangePage={page => page && setPage(page)} />
            </div>

        </>
    );
};

export default ReturnsList;
