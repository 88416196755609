import React from 'react';
import InputMask from "react-input-mask";
import "./emailinput.css";


const EmailInput = (props) => {


    const {errMessageEmail, setErrMessageEmail} = props;

//первичнеый валидатор, ограничивающий поле по количеству символов и  вызывающий вторичный валидатор, если подтвержден
    // статус ошибки в родителе
    const emailInputValidator = (email) => {
        if(email.length*1  > 40) {
            return false;
        }

        props.setNewValue({...props.userFields,  [props.fieldName]: email});
        if(props.isErrEmail) {
            onBlurFalse();
        }
    }
// вызывается после потери фокуса или во время живого редактирования инпута, в случае, если статус ошибки подтвержден
    const onBlurFalse = () => {
        let pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let resultValid = pattern.test(props.userFields[props.fieldName]);

        if(resultValid) {
            props.errController(false);
            setErrMessageEmail('')
        } else {
            props.errController(true);
            setErrMessageEmail('Пожалуйста, введите корректный E-mail')
        }

    }


    return (
        <div ref={props.errRef} className={"email_input_wrapper "+ props.errClass }>
            <div className={"title_email_lk"}>E-mail
                {props.requiredField ? <span className={"required_field"}>{props.requiredField}</span> : ''}
            </div>
            <InputMask
                className={"user_email_component"}
                // placeholder={"E-mail"}
                value={props.value}
                onChange={e => emailInputValidator(e.target.value)}
                onBlur={e => onBlurFalse()}
                alwaysShowMask={true}
                maskChar={""}
            />
            <div className={"email_input_err_message"}>
                {errMessageEmail}
            </div>
        </div>
    );
};

export default EmailInput;