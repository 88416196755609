import React from 'react';
import './BreadcrumbsAndFilterLineLanding.css';
import BreadcrumbsCatalogLanding from "./breadcrumbsCatalogLanding/BreadcrumbsCatalogLanding";
import CatalogViewLanding from "./FilterAndCatalogViewLanding/CatalogViewLanding/CatalogViewLanding";

const BreadcrmbsAndFilterLineLanding = () => {
    return (
        <div className={"filter_and_breadcrumbs"}>
            <BreadcrumbsCatalogLanding />

            <div className={"wrapper_control_catalog_bar"}>
             <CatalogViewLanding/>
            </div>
        </div>
    );
};

export default BreadcrmbsAndFilterLineLanding;