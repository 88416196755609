import React, {useState} from 'react';
import './BlockQuestion.css';

const ButtonHide = (props) => {


    return (
        <div
            // onClick={props.OnclikHihe}
            onClick={e => props.setOpenTextFqa(!props.openTextFqa)}
               className={props.openTextFqa === true ? "q_button hide" : "q_button"}>
            <button className={"q_button_hide"}>
            </button>
        </div>
    );
};

export default ButtonHide;