import React, {useContext} from 'react';
import './BlockQuestion.css';
import {Context} from "../../index";

const TextBlock = (props) => {

    const {settings} = useContext(Context)

    return (
        <div className={"q_dropdown"}>
            {/*<div className={"q_line"}>*/}
            {/*    <div className={"q_border one"}></div>*/}
            {/*    <div className={"q_border_text"}>Ответ YOUWANNA</div>*/}
            {/*    <div className={"q_border two"}></div>*/}
            {/*</div>*/}
            <div className={"q_text"}>
                <div className={"fqa_one_paragrah"}>
                    {props.answer ? <span>{props.answer}</span> : "" }
                </div>

            </div>




            {
                settings.projectWidth && settings.projectWidth > 768 ?
                    <div className={"line_text_fqa_parents"}>
                        <div className={"line_text_fqa"}></div>
                    </div>
                    :
                    ""
            }

        </div>
    );
};

export default TextBlock;