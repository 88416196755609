import React, {useContext} from 'react';
import "./ShadowOverlay.css";
import {Context} from "../../../index";

const ShadowOverlay = (props) => {

    const {user} = useContext(Context)

    const shadowOverlayPath =  process.env.REACT_APP_API_URL+"uploads/images/ui/shadowOverlay.png";

    return (
        <div style={{background:"url("+shadowOverlayPath+")"}}  onClick={props.closeClick} className={user.stateOpenModalWindow === true ? "ShadowOverlay " + props.className + " nonclick " : "ShadowOverlay " + props.className}>

        </div>
    );
};

export default ShadowOverlay;