import React, {useState} from 'react';
import './oneimagefield.css';
import CompliteRemove from "../compliteremove/CompliteRemove";
const OneImageField = (props) => {


    const [delState,setDelState] = useState(false)

    const removeImage = (e) => {
        props.deleteImage(e)
        setDelState(false)
    }

    return (


            <div className={"preview_container_img"}>
                <CompliteRemove open={delState} canselRemove={e => setDelState(false)} goRemove={e => removeImage(e) } />
                <span className={"info_field_for_manager"}> {props.text} </span>

                <div className={"wrapper_image_container_t"}>
                    { props.image ?
                        <div className={"img_wrapper_one_d"}>
                            <div onClick={e => setDelState(true)} className={"remove_image_img_field"}> удалить </div>
                            {
                                props.video ?
                                <video src={props.image} alt=""/>
                                : 
                                <img src={props.image} alt=""/>

                            }
                        </div>
                        : ""
                    }
                    <input onChange={e => props.onChange(e)} className={"one_img_field_input"} type="file" />
                    {
                        !props.image ?
                            <span className={"system_info"}>Перетащи или кликни, чтобы загрузить</span>
                            : ""
                    }
                </div>
            </div>
    );
};

export default OneImageField;