import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Fashion.scss";
import { observer } from "mobx-react-lite";
import Modal from "../../../components/Modal/Modal";
import OneProductCart from "../../catalog/OneProductCart";
import NotFound from "../notFound/NotFound";
import Spiner from "../../administrator/spiner/Spiner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  fetchImageById,
  fetchProductsByImageId,
} from "../../../http/contentApi";

const Fashion = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  let { id } = useParams();

  const listRef = useRef();

  const [fashion, setFashion] = useState(null);
  const [isScrollingList, setIsScrollingList] = useState(false);
  const [productsArray, setProductsArray] = useState([]);
  const [spiner, setSpiner] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if (!id) return;

    fetchImageById(id).then((res) => {
      if (res) {
        setFashion(res);
      }
    });
  }, [id]);

  useEffect(() => {
    if (!fashion) return;

    const set = JSON.parse(fashion.set);

    if (!set || !set.length) {
      setProductsArray([]);
      setSpiner(false);
      return;
    }

    fetchProductsByImageId(set).then((response) => {
      setSpiner(false);

      if (response && response.status == 404) {
        setProductsArray([]);
        return;
      }

      if (!response.data.error) {
        setProductsArray(response.data);
      }
    });
  }, [fashion]);

  useEffect(() => {
    if (!listRef.current) return;

    setIsScrollingList(
      listRef.current.clientHeight < listRef.current.scrollHeight
    );
  }, [listRef.current]);

  const handleFashionClose = useCallback(() => {
    if (!location.state) {
      navigate("/fashions/");
    } else {
      navigate(-1);
    }
  }, [location, navigate]);

  if (notFound) {
    return <NotFound />;
  }

  return (
    <Modal extraClass="fashion__modal-wrapper" onClose={handleFashionClose}>
      <div className="fashion">
        <button class="fashion__modal-close" onClick={handleFashionClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M1 1L10.0455 10.0454M10.0455 10.0454L1 19M10.0455 10.0454L19 1M10.0455 10.0454L19 19"
              stroke="#1A1A1A"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </button>
        <div className="fashion__wrapper">
          {spiner ? (
            <Spiner />
          ) : (
            <>
              <div className="fashion__img">
                <img
                  className="fashion__img"
                  src={
                    process.env.REACT_APP_API_URL +
                    "uploads/images/image/" +
                    (fashion.detailImg
                      ? fashion.detailImg
                      : fashion.previewImg)
                  }
                  alt=""
                  width={781}
                  height={781}
                />
                {
                  fashion.tag ?
                      fashion.href ? 
                        <a href={fashion.href} className="fashion__name">
                          @{fashion.tag}
                        </a>
                      : 
                      <span className="fashion__name">
                          @{fashion.tag}
                      </span>
                  : ""
                }
              </div>

              <div className="fashion__aside">
                <p className="fashion__aside-count">
                  Товаров в образе: {productsArray.length}
                </p>
                <ul
                  className={
                    "fashion__list" + isScrollingList
                      ? " fashion__list--scroll"
                      : ""
                  }
                  ref={listRef}
                >
                  {productsArray[0] ? (
                    productsArray.map((item, index) => (
                      <OneProductCart
                        item={item}
                        index={index}
                        key={item.id}
                        className="one_product_fashion"
                      />
                    ))
                  ) : (
                    <div className={"no_find_product"}>Нет товаров</div>
                  )}
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
});

export default Fashion;
