import React from 'react';
import './confirmSelector.css'

const ConfirmSelector = (props) => {


    return (


        <div className={"confirm_wrapper_and_err"}>
            <div onClick={props.onClick} className={"confirm_use_info " + props.isActive}>
                <div className={"confirm_selector"}>
                    <div className="child_confirm_selector">

                    </div>
                </div>
                <span className={"user_confirm_text"}>Я даю согласие на обработку моих персональных данных и ознакомлен(а) с <a rel="noreferrer"  target={"_blank"} href="/personal/privacy-policy/">политикой конфиденциальности</a></span>
            </div>
            <div className="confirm_err_text">
                {props.confirmTextErr}
            </div>
        </div>

    );
};

export default ConfirmSelector;