import React from 'react';
import './product.css'


const Product = (props) => {
    return (
            <div className="favorites_one_product_wrapper">
                <div className="favorites_one_product">
                    <div className="favorites_image_container">
                        <div className={"favorites_dop_controll_bar_product"}>
                            <div className="favorites_add_favorite_product active">
                                <svg width="30" height="27" viewBox="0 0 30 27"  xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.0764 5.14371L14.7905 5.87065L15.5039 5.14291L16.6725 3.95064C16.6726 3.95059 16.6726 3.95054 16.6727 3.95048C19.2773 1.29933 23.486 1.29938 26.0905 3.95063C28.6981 6.60517 28.6966 10.905 26.0916 13.5726C26.0912 13.573 26.0909 13.5733 26.0905 13.5737L14.8338 25.0259L14.8336 25.0261C14.8247 25.0351 14.8174 25.0392 14.8118 25.0415C14.8056 25.044 14.7986 25.0455 14.7912 25.0455C14.7838 25.0455 14.7768 25.044 14.7706 25.0415C14.7651 25.0392 14.7577 25.0351 14.7489 25.0261L3.49863 13.5739C3.49863 13.5739 3.49862 13.5739 3.49862 13.5739C0.889777 10.9182 0.889789 6.60522 3.49862 3.94956L3.49862 3.94956C6.09772 1.3038 10.3042 1.30381 12.9032 3.94955L12.9033 3.94958L14.0764 5.14371Z" stroke="#1A1A1A" strokeWidth="2"/>
                                </svg>
                            </div>
                        </div>
                        <img className={"favorites_standart_photo_product"} src={"/files/images/standartphoto.png"} alt=""/>
                        <img className={"favorites_hover_photo_product"} src={"/files/images/hoverPhoto.png"} alt=""/>
                        <div className={"favorites_controll_panel_offers_and_colors"}>
                            <div className="favorites_color_picker_product">
                                COLOR PRODUCTS
                            </div>
                            <div className="favorites_size_picker_product">
                                SIZE PRODUCTS
                            </div>
                            <div className="favorites_add_product_to_cart">
                                ДОБАВИТЬ в корзину
                            </div>
                        </div>
                    </div>
                    <div className={"favorites_name_wrapper_product"}>
                        <span className={"favorites_name_product_text"}>{props.item.name}</span>
                    </div>
                    <div className={"favorites_price_product_wrapper"}>
                        <span className={"favorites_price_product"}>{props.item.price}</span> <span className={"favorites_valute_sublol"}>₽</span>
                    </div>
                </div>
            </div>
    );
};

export default Product;