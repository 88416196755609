import React, {useContext, useEffect, useState} from 'react';
import './onePageLookBook.css'
import {useParams} from "react-router";
import {fetchOneLookBook} from "../../../http/contentApi";
import {Link} from "react-router-dom";
import ContentBreadcrumbs from "../../../components/Breadcrumbs/contentBreadcrumbs/ContentBreadcrumbs";
import {Context} from "../../../index";
import Helmet from "react-helmet";

const OnePageLookBook = () => {

    const {settings} = useContext(Context)

    let {id} = useParams();

    const [imageElements, setImageElements] = useState('')
    const [oneThing, setOneThing] = useState('')


    useEffect(()=>{
        window.scrollTo(0, 0)
        fetchOneLookBook(id).then(data => {
            if (data) {
                console.log(data)
                setImageElements(data)
            }
            if (data.content) {
                setOneThing(data.content)
            }
        }  );
    },[])


    return (
        <div className={"page_look_detail"}>
            {
                imageElements ?
                    <div
                        style={{marginTop:settings.headerHeight}}
                        className={"wrap_details_content"}>
                        <Helmet>
                            <title>Lookbook {imageElements.name ? imageElements.name : ''} интернет-магазина YOU WANNA</title>
                            <meta name="description" content={"Lookbook, " + (imageElements.name ? imageElements.name : '') + "для женщин в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07."}/>
                        </Helmet>
                        <ContentBreadcrumbs
                            margTop={settings.headerHeight}
                            items={[
                                {name:"Главная", link:"/"},
                                {name:"Lookbook", link:"/lookbook"},
                                {name: imageElements.name, link:imageElements.id}
                            ]}
                        />
                        <div className={"one_look_details"}>
                            <h1 className={"name_look"}>{imageElements.name ? imageElements.name : ''}</h1>
                            <div className={"description_look"}>{imageElements.text ? imageElements.text : ''}</div>
                            <div className={"things_image"}>
                                {
                                    oneThing ? oneThing.map((item, index) =>  {

                                        if(item.type === "horizontal") {
                                            return(
                                                <div className={"thing_image_horizontal"}>
                                                    {
                                                        item.linkLook ?
                                                            <Link to={item.linkLook} className={"to_product_page"}>
                                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="20" cy="20" r="20" fill="white"/>
                                                                    <path d="M30.9995 32.0001H9V12.4473C9 11.3738 9.87341 10.5002 10.9472 10.5002H13.4999V16.8432L20.2445 14.9799L26.4998 16.8305V10.5H29.0528C30.1262 10.5 30.9997 11.3736 30.9997 12.4471L30.9995 32.0001ZM10.0001 31H29.9999L29.9998 12.4473C29.9998 11.9252 29.575 11.5003 29.0529 11.5003H27.4999V18.1697L20.2353 16.0202L12.4999 18.1572V11.5003H10.9472C10.4249 11.5003 10 11.9252 10 12.4473L10.0001 31Z" fill="black"/>
                                                                    <path d="M14.3609 17.4485C13.9786 17.4485 13.6068 17.33 13.2847 17.1006C12.7858 16.7452 12.5 16.1902 12.5 15.5781V9.86929C12.5 8.83863 13.3386 8 14.3697 8H25.6304C26.6612 8 27.4998 8.83859 27.4998 9.86929V15.5331C27.4998 16.1444 27.2006 16.7179 26.6988 17.0672C26.1973 17.4165 25.5553 17.4987 24.982 17.2866L20.0016 15.4535L14.9925 17.3404C14.783 17.4129 14.5701 17.4485 14.3605 17.4485L14.3609 17.4485ZM14.3696 9.0004C13.8901 9.0004 13.5 9.39032 13.5 9.86967V15.5785C13.5 15.8632 13.6332 16.1211 13.8651 16.2865C14.0971 16.4521 14.384 16.4938 14.6537 16.4002L19.998 14.3871L25.328 16.3491C25.6 16.4494 25.8909 16.4124 26.1272 16.2474C26.3643 16.0823 26.4999 15.8223 26.4999 15.5337V9.86994C26.4999 9.39062 26.11 9.00067 25.6305 9.00067L14.3696 9.0004Z" fill="black"/>
                                                                    <path d="M20.0051 15.7261L12.6895 9.88872L13.313 9.10687L19.9954 14.4391L26.6509 8.96387L27.286 9.73648L20.0051 15.7261Z" fill="black"/>
                                                                    <path d="M19.501 15.8408H20.501V31.6552H19.501V15.8408Z" fill="black"/>
                                                                    <path d="M23.5 21.498H27.9999V22.4981H23.5V21.498Z" fill="black"/>
                                                                </svg>
                                                            </Link>
                                                            : ''
                                                    }

                                                    {
                                                        item.photoLook ?
                                                            <img src={process.env.REACT_APP_API_URL+"/uploads/images/lookbook/"+id+"/"+"1500_"+item.photoLook } alt={'YouWanna - Lookbook '+(imageElements.name ? imageElements.name : '')}/>
                                                            : ''
                                                    }
                                                </div>
                                            ) }
                                        if(item.type === "double") {
                                        return(
                                            <div className={"thing_image_double"}>
                                                <div className={"one_block_photo"}>
                                                    {
                                                        item.linkLookleft ?
                                                            <Link to={item.linkLookleft} className={"to_product_page"}>
                                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="20" cy="20" r="20" fill="white"/>
                                                                    <path d="M30.9995 32.0001H9V12.4473C9 11.3738 9.87341 10.5002 10.9472 10.5002H13.4999V16.8432L20.2445 14.9799L26.4998 16.8305V10.5H29.0528C30.1262 10.5 30.9997 11.3736 30.9997 12.4471L30.9995 32.0001ZM10.0001 31H29.9999L29.9998 12.4473C29.9998 11.9252 29.575 11.5003 29.0529 11.5003H27.4999V18.1697L20.2353 16.0202L12.4999 18.1572V11.5003H10.9472C10.4249 11.5003 10 11.9252 10 12.4473L10.0001 31Z" fill="black"/>
                                                                    <path d="M14.3609 17.4485C13.9786 17.4485 13.6068 17.33 13.2847 17.1006C12.7858 16.7452 12.5 16.1902 12.5 15.5781V9.86929C12.5 8.83863 13.3386 8 14.3697 8H25.6304C26.6612 8 27.4998 8.83859 27.4998 9.86929V15.5331C27.4998 16.1444 27.2006 16.7179 26.6988 17.0672C26.1973 17.4165 25.5553 17.4987 24.982 17.2866L20.0016 15.4535L14.9925 17.3404C14.783 17.4129 14.5701 17.4485 14.3605 17.4485L14.3609 17.4485ZM14.3696 9.0004C13.8901 9.0004 13.5 9.39032 13.5 9.86967V15.5785C13.5 15.8632 13.6332 16.1211 13.8651 16.2865C14.0971 16.4521 14.384 16.4938 14.6537 16.4002L19.998 14.3871L25.328 16.3491C25.6 16.4494 25.8909 16.4124 26.1272 16.2474C26.3643 16.0823 26.4999 15.8223 26.4999 15.5337V9.86994C26.4999 9.39062 26.11 9.00067 25.6305 9.00067L14.3696 9.0004Z" fill="black"/>
                                                                    <path d="M20.0051 15.7261L12.6895 9.88872L13.313 9.10687L19.9954 14.4391L26.6509 8.96387L27.286 9.73648L20.0051 15.7261Z" fill="black"/>
                                                                    <path d="M19.501 15.8408H20.501V31.6552H19.501V15.8408Z" fill="black"/>
                                                                    <path d="M23.5 21.498H27.9999V22.4981H23.5V21.498Z" fill="black"/>
                                                                </svg>
                                                            </Link>
                                                            : ''
                                                    }

                                                    {
                                                        item.one_photoLook ?
                                                            <img src={process.env.REACT_APP_API_URL+"/uploads/images/lookbook/"+id+"/"+"750_"+item.one_photoLook } alt={'YouWanna - Lookbook '+(imageElements.name ? imageElements.name : '')}/>
                                                            : ''
                                                    }
                                                </div>
                                                <div className={"one_block_photo"}>
                                                    {
                                                        item.linkLookRight ?
                                                            <Link to={item.linkLookRight} className={"to_product_page"}>
                                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="20" cy="20" r="20" fill="white"/>
                                                                    <path d="M30.9995 32.0001H9V12.4473C9 11.3738 9.87341 10.5002 10.9472 10.5002H13.4999V16.8432L20.2445 14.9799L26.4998 16.8305V10.5H29.0528C30.1262 10.5 30.9997 11.3736 30.9997 12.4471L30.9995 32.0001ZM10.0001 31H29.9999L29.9998 12.4473C29.9998 11.9252 29.575 11.5003 29.0529 11.5003H27.4999V18.1697L20.2353 16.0202L12.4999 18.1572V11.5003H10.9472C10.4249 11.5003 10 11.9252 10 12.4473L10.0001 31Z" fill="black"/>
                                                                    <path d="M14.3609 17.4485C13.9786 17.4485 13.6068 17.33 13.2847 17.1006C12.7858 16.7452 12.5 16.1902 12.5 15.5781V9.86929C12.5 8.83863 13.3386 8 14.3697 8H25.6304C26.6612 8 27.4998 8.83859 27.4998 9.86929V15.5331C27.4998 16.1444 27.2006 16.7179 26.6988 17.0672C26.1973 17.4165 25.5553 17.4987 24.982 17.2866L20.0016 15.4535L14.9925 17.3404C14.783 17.4129 14.5701 17.4485 14.3605 17.4485L14.3609 17.4485ZM14.3696 9.0004C13.8901 9.0004 13.5 9.39032 13.5 9.86967V15.5785C13.5 15.8632 13.6332 16.1211 13.8651 16.2865C14.0971 16.4521 14.384 16.4938 14.6537 16.4002L19.998 14.3871L25.328 16.3491C25.6 16.4494 25.8909 16.4124 26.1272 16.2474C26.3643 16.0823 26.4999 15.8223 26.4999 15.5337V9.86994C26.4999 9.39062 26.11 9.00067 25.6305 9.00067L14.3696 9.0004Z" fill="black"/>
                                                                    <path d="M20.0051 15.7261L12.6895 9.88872L13.313 9.10687L19.9954 14.4391L26.6509 8.96387L27.286 9.73648L20.0051 15.7261Z" fill="black"/>
                                                                    <path d="M19.501 15.8408H20.501V31.6552H19.501V15.8408Z" fill="black"/>
                                                                    <path d="M23.5 21.498H27.9999V22.4981H23.5V21.498Z" fill="black"/>
                                                                </svg>
                                                            </Link>
                                                            : ''
                                                    }

                                                    {
                                                        item.two_photoLook ?
                                                            <img src={process.env.REACT_APP_API_URL+"/uploads/images/lookbook/"+id+"/"+"750_"+item.two_photoLook } alt={'YouWanna - Lookbook '+(imageElements.name ? imageElements.name : '')}/>
                                                            : ''
                                                    }
                                                </div>

                                            </div>
                                        ) }
                                    }) : ''
                                }

                            </div>
                        </div>
                    </div>
                  : ""
            }

        </div>
    );
};

export default OnePageLookBook;