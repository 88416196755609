import React, {useContext} from 'react';
import UserInfo from "./userinfo/UserInfo";
import Favorites from "./Favorites/Favorites";
import MyOffers from "./MyOffers/MyOffers";
import ConsultantChat from "./ConsultantChat/ConsultantChat";
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";
import {UserBonuses} from "../UserBonuses/UserBonuses";


const ContentArea = observer((props) => {

    const {user} = useContext(Context)

    return (
        <div className={"user_personal_content"}>
            <UserInfo
                active={user.currentPage === "profile"  ? "active" : "hidden" }
            />
            {user.currentPage === "bonuses" && (
                <UserBonuses/>
            )}
            <Favorites
                // elementRef={props.elementRef}
                // elementRefOneCards={props.elementRefOneCards}
                active={user.currentPage === "favorites" ? "active" : "hidden" }
            />
            <MyOffers
                active={user.currentPage === "order" ? "active" : "hidden" }
            />
            {/*<ConsultantChat*/}
            {/*    active={user.currentPage === "callmanager" ? "active" : "hidden" }*/}
            {/*/>*/}

        </div>
    );
});

export default ContentArea;