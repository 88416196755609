import React from 'react';
import './isactiveindicator.css'

const IsActiveIndicator = (props) => {
    return (
            <div className={props.active ? "wrapper_indicator on" : "wrapper_indicator"}>
                <div
                    onClick={props.change}
                    className={"is_active_item"}>
                    <div className={"circle_active_item"}>

                    </div>
                </div>
            </div>
    );
};

export default IsActiveIndicator;