import React, {useContext, useEffect, useState} from 'react';
import "./Homepage.css";
import BigBlock from "../../components/homepage/BigBlock/BigBlock";
import DoubleBlock from "../../components/homepage/DoubleBlock/DoubleBlock";
import DevelopSection from "../../components/DevelopSection/DevelopSection";
import OneAndFour from "../../components/homepage/OneAndFour/OneAndFour";
import SubscribeForm from "../../components/SubscribeForm/SubscribeForm";
import AboutUsHome from "../../components/homepage/AboutUsHome/AboutUsHome";
import SupportLine from "../../components/SupportLine";
import {useLocation} from "react-router";
import Helmet from "react-helmet";
import {Context} from "../../index";
import {fetchAllImages, fetchLookBookHome, getActiveStockAndTrends, getOnePageContent} from "../../http/contentApi";
import RightBlockHome from "../../components/homepage/DoubleBlock/RightBlockHome";
import LeftBlockHome from "../../components/homepage/DoubleBlock/LeftBlockHome";
import {login} from "../../http/userAPI";
import SliderNewProduct from "../../components/homepage/sliderNewProduct/SliderNewProduct";
import {fetchNewProductHomePage, productSearcht} from "../../http/catalogApi";
import StockAndTrends from "../../components/homepage/homeBlockStockAndTrend/StockAndTrends";
import SliderLookBook from "../../components/homepage/sliderLookBook/SliderLookBook";
import SliderFashion from '../../components/homepage/sliderFashion/sliderFashion';

const Homepage = () => {

    const {settings} = useContext(Context);

    const [pageHomeContent, setPageHomeContent] = useState({})
    const [homeNoJson, setHomeNoJson] = useState({})
    const [pageProps, setPageProps] = useState(false)
    const [newProductHome, setNewProductHome] = useState('')
    const [lookBookHome, setLookBookHome] = useState('')
    const [fashionsHome, setFashionsHome] = useState(null)
    const [stockAndTrends, setStockAndTrends] = useState({})

    useEffect(() => {

        window.scrollTo(0, 0)

        getOnePageContent(8).then(response => {
            setPageHomeContent(response)

            if (response.content) {
                // setHomeNoJson(JSON.parse(response.content))
                setHomeNoJson({
                    ...JSON.parse(response.content),

                })
                getActiveStockAndTrends().then(data => {
                    setHomeNoJson({...JSON.parse(response.content), saleAndTrends: {
                        SaleHome: data.data.stocks,
                        commonName: "Актуальные события и тренды",
                        nameBlockSale: "Актуальные события",
                        nameBlockTrends: "Тренды",
                        trendsHome: data.data.trends
                    }})
                })
                const {title, description} = response
                if(title || description)
                    setPageProps({title, description})
                return false;
            }

            setHomeNoJson({
                oneBanner: {},
                twoBanner:{},
                threeBanner: {},
                fourthBanner: {}
            })

        })

        fetchNewProductHomePage(18).then(response => {
            if (response) {
                setNewProductHome(response.data)
            }
        })

        fetchLookBookHome(4).then(response => {
            if (response) {
                setLookBookHome(response)
            }

        })

        fetchAllImages(8, 0, undefined, true).then(response => {
            if (response) {
                setFashionsHome(response)
            }
        })
    }, [])

    const location = useLocation();


    return (
        <div className="main_wrapper">

            { location.pathname === "/" && <SupportLine /> }
            <Helmet>
                <title>{pageProps.title ? pageProps.title : ""}</title>
                <meta name="description" content={pageProps.description ? pageProps.description : ""}/>
            </Helmet>
            <h1 className={"home_page_title"}>{pageHomeContent.name ? pageHomeContent.name : ''}</h1>
            <div
                // style={{marginTop:settings.headerHeight-30}}
                className="hpage_wrapper">
                {homeNoJson.oneBanner ?
                    <BigBlock
                        item={homeNoJson.oneBanner}
                        sliderBannerName={'first'}
                        sliderImages={pageHomeContent.slider_images}
                        objectName={"oneBanner"}
                    />
                 : ""}

                {homeNoJson.twoBanner ?
                    <BigBlock
                        item={homeNoJson.twoBanner}
                        objectName={"twoBanner"}
                    />
                    : ""}

                <div className="reuse_double_block">
                    {homeNoJson.threeBanner ?
                    <LeftBlockHome
                        item={homeNoJson.threeBanner}
                        objectName={"threeBanner"}
                    /> : '' }
                    {homeNoJson.fourthBanner ?
                        <RightBlockHome
                            item={homeNoJson.fourthBanner}
                            objectName={"fourthBanner"}
                        /> : ''
                    }
                </div>


                {/*{BlocksObject.DoubleBlock ? BlocksObject.DoubleBlock.map((block, index) =>*/}
                {/*    <DoubleBlock key={index} block={block}/>*/}
                {/*): ""}*/}

                {/*<DevelopSection title="INSTASHOP"/>*/}
                {/*<OneAndFour/>*/}
                {
                    homeNoJson.switchProductNew && homeNoJson.switchProductNew === true && newProductHome ?
                        <SliderNewProduct
                        titleNewsSlider={homeNoJson.nameBlockNew}
                        newProductHome={newProductHome}
                    />
                        : ''
                }

                {
                    homeNoJson.switchLookBook && homeNoJson.switchLookBook === true && lookBookHome ?
                        <SliderLookBook
                            titleLookSlider={homeNoJson.nameBlockLookbook}
                            lookBookHome={lookBookHome}
                        />
                        : ''
                }

                {/*{
                    homeNoJson.saleAndTrends &&  homeNoJson.saleAndTrends.SaleHome != '' || homeNoJson.saleAndTrends &&  homeNoJson.saleAndTrends.trendsHome != ''?
                        <StockAndTrends
                            saleAndTrends={homeNoJson.saleAndTrends}
                        />
                        : ''
                }*/}

                {
                    homeNoJson.saleAndTrends && (
                        <StockAndTrends
                            {...homeNoJson.saleAndTrends}
                        />
                    )
                }

                {
                    !!fashionsHome?.rows?.length && (
                        <SliderFashion
                            titleSlider={"Образы"}
                            fashionsHome={fashionsHome.rows}
                        />
                    )
                }

                {/*<DevelopSection title="NEW PRODUCTS SLIDER"/>*/}
                {/*<DevelopSection title="STOCKS & TRENDS"/>*/}
                {
                    homeNoJson.switchSubscriptionForm && homeNoJson.switchSubscriptionForm === true ?
                        <SubscribeForm
                           titleSubscriptionForm={homeNoJson.titleFormHome}
                        />
                        : ''
                }
                {/* {
                    homeNoJson.leftBlockText ||  homeNoJson.rightBlockText ?
                        <AboutUsHome
                            leftBlock={homeNoJson.leftBlockText}
                            rightBlock={homeNoJson.rightBlockText}
                        />
                        : ''
                } */}

            </div>
        </div>
    );
};

export default Homepage;
