import React, {useState} from 'react'

const SitemapUrl = ({id, url, deleteUrl, updateUrl}) => {

    const [value, setValue] = useState(url)

    return (
        <div className="one_category_redaction_wrapper">
            <div className={"one_category_wrap_field"}>
                <span className={"one_category_heading_item"}>* URL</span>
                <input className={"one_category_list_input"} onChange={({target}) => setValue(target.value)} value={value}
                       type="text"/>
            </div>
            <div className={"save_category_button"} onClick={() => {updateUrl(id, value)}}>
                Сохранить
            </div>

            <div className={"delete_category_button"} onClick={() => deleteUrl(id)}> Удалить </div>
        </div>
    )
}

export default SitemapUrl