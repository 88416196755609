import {$authHost,$host} from "./index";





export const addProduct = async () => {
    const data = await $authHost.post('api/catalog/addproduct', )

    return data;
}

export const changeProductActive = async (id,status) => {
    const {data} = await $authHost.post('api/catalog/productchangeactive', {id,status});

    return data;
}

export const addChildProduct = async () => {
    const {data} = await $authHost.post('api/catalog/addchildproduct');

    return data;
}


export const getOneProduct = async (productid) => {
    const data = await $authHost.post('api/catalog/getoneproduct', {productid})

    return data;
}

export const getAllProducts = async (sortField, sortParam, search, searchParam, page, limit) => {
     const data = await $authHost.post('api/catalog/getallproducts', {sortField, sortParam, search, searchParam, page, limit})

     return data;
}

export const removeOneProduct = async (productid) => {
    const data = await $authHost.post('api/catalog/removeoneproduct', {productid})

    return data;
}

export const addNewColor = async (color) => {
    const data = await $authHost.post("api/catalog/addcolor", {color} )
    return data;
}
export const removeOneColor = async (id) => {
    const data = await $authHost.post('api/catalog/removecolor', {id})
    return data;
}


export const fetchColors = async () => {
    const {data} = await $authHost.post("api/catalog/getcolors", {} )
    return data;
}

export const updateOneProduct = async(fields,redactor) => {
    const data = await $authHost.post("api/catalog/updateproduct", fields)
    return data;
}


export const removeChildProduct = async(id)=> {
    const data = await $authHost.post('api/catalog/deletechildproduct', {id})
    return data;
}


export const updateOneColor = async (object) => {
    const data = await $authHost.post('api/catalog/updatecolor', {object})
    return data;
}

export const fetchSizes = async () => {
    const {data} = await $authHost.post('/api/catalog/fetchsizes');
    return data;
}

export const fetchTrends = async () => {
    const {data} = await $authHost.post('/api/catalog/fetchtrends');
    return data;
}

export const addNewTrend = async (name) => {
    const data = await $authHost.post('/api/catalog/addnewtrend',{name});

    return data;
}

export const deleteTrend = async (id) => {
    const data = await $authHost.post('/api/catalog/removetrend', {id})
    return data;
}

export const updateTrend = async (fields) => {
    const {data} = await $authHost.post('/api/catalog/updatetrend',{fields})

    return data;
}
export const findChildProductsAndFixJsonSize = async () => {
    const {data} = await $authHost.post('/api/catalog/fixjsonsizes');
    return data;
}

export const addNewSize = async (size_name) => {
    const data = await $authHost.post('/api/catalog/addnewsize',{size_name});

    return data;
}
export const deleteSize = async (id) => {
    const data = await $authHost.post('/api/catalog/removesize', {id})
    return data;
}

export const updateSize = async (fields) => {
    const {data} = await $authHost.post('/api/catalog/updatesize',{fields})

    return data;
}

export const addLandry = async() => {
    const {data} = await $authHost.post('/api/catalog/addlandry');

    return data;
}
export const fetchLandry = async () => {
    const {data} = await $authHost.post('/api/catalog/fetchlandry');

    return data;
}

export const removeLandry = async (id) => {
    const {data} = await $authHost.post('/api/catalog/deletelandry', {id})
    return data;
}

export const saveLandry = async (item) => {
    const {data} = await $authHost.post('/api/catalog/updatelandry', {item})
    return data;
}

export const createLandryLinks = async (product,landrys) =>  {
    const {data} = await $authHost.post('/api/catalog/createlendrylinks',{product,landrys})
    return data;
}

export const addNewCategory = async (params) => {

    const {data} = await $authHost.post('/api/catalog/addnewcategory',{params})

    return data;
}

export const fetchCategory = async () => {
    const {data} = await $authHost.post('/api/catalog/fetchallcategory')

    return data;
}
export const fetchClearCategory = async () => {
    const {data} = await $authHost.post('api/catalog/fetchclearallcategory');

    return data;
}

export const fetchChangeableCategories = async () => {
    const {data} = await $authHost.post('api/catalog/fetchchangeablecategories');

    return data;
}

export const saveOneCategory = async (category) => {
    const {data} = await $authHost.post('api/catalog/saveonecategory',{category});

    return data;
}

export const deleteOneCategory = async (category) => {
    const {data} = await $authHost.post('api/catalog/deleteonecategory', {category})

    return data;
}


export const fetchProducts = async (parrent, child, third, fourth, fakeCategory, limit, page, orderParam, filters, getCount = false) => {
    try {
        const response = await $host.post('api/catalog/fetchproducts',{parrent, child, third, fourth, fakeCategory, limit, page, orderParam, filters, getCount})
        return  response;

    } catch (e) {
        return e.response
    }
}

export const countFilterdProducts = async (parrent, child, third, fourth, fakeCategory, filters) => {
    try {
        const response = await $host.post('api/catalog/countfilterdproducts',{parrent, child, third, fourth, fakeCategory, filters})
        return  response;

    } catch (e) {
        return e.response
    }
}

export const fetchFilterContext = async (parrent, child, third, fourth, fakeCategory) => {
    try {
        const response = await $host.post('api/catalog/fetchfiletrcontext',{parrent, child, third, fourth, fakeCategory})
        return  response;

    } catch (e) {
        return e.response
    }
}

export const getProduct = async (parrent,child,code) => {
    try {
        const response = await $host.post('api/catalog/getoneproductpage', {parrent,child,code})
        return response
    } catch (e){
        return e.response
    } 
}

export const getSliderData = async(productId) => {
    try {
        const response = await $host.post('api/catalog/getsliderdata', {productId})
        return response
    } catch (e){
        return e.response
    }   
}

export const getLastViewed = async(items) => {
    try {
        const response = await $host.post('api/catalog/getlastviewed', {items})
        return response
    } catch (e){
        return e.response
    }   
}

export const getDataForBreadcrumbs = async  (parrent,child,third,fourth) => {
    const {data} = await $host.post('api/catalog/getdataforbreadcrumbs',{parrent, child, third, fourth})

    return data;
}
export const serchChildProductsForAdminCatalog = async (sortField, sortParam, searchValue, page, limit) => {
    const {data} = await $authHost.post('api/catalog/getchildproductsforadmin', {sortField, sortParam, searchValue, page, limit})
    return data;
}

export const updateSortOneChildProduct = async (id,value) => {
    const {data} = await $authHost.post('api/catalog/updatesortchildproduct',{id,value})
    return data;
}

export const getCategory = async (slug) => {
    try {
        const response = await $authHost.get('api/catalog/getcategory', {params: {slug}})

        if(response.data.error)
            return {success: false, message: response.data.error}

        return {success: true, data: response.data}
    } catch (error) {
        return {success: false, message: error.message}
    }
}

export const getParentCategories = async () => {
    try {
        const response = await $authHost.get('api/catalog/getparentcategories')

        if(response.data.error)
            return {success: false, message: response.data.error}

        return {success: true, data: response.data}
    } catch (error) {
        return {success: false, message: error.message}
    }
}

export const setFeedCategories = async (ids) => {
    try {
        const response = await $authHost.post('api/catalog/setfeedcategories', {ids})

        if(response.data.error)
            return {success: false, message: response.data.error}

        return {success: true, data: response.data}
    } catch (error) {
        return {success: false, message: error.message}
    }
}

export const productSearcht = async (q,limit,offset) => {
    const {data} = await $authHost.get('api/catalog/search-product',{params: {q,limit, offset}})
    return data;
}

export const searchTips = async (query) => {
    const {data} = await $authHost.post('api/catalog/search-tips',{query})
    return data;
}


export const getCategories = async (ids) => {
    try {
        const response = await $host.post('/api/catalog/categories', {ids})

        const data = {}

        response.data.forEach(({id, name}) => {
            data[id] = name
        })

        return {success: true, data}
    } catch (error) {
        return  {success: false, message: error.message}
    }
}

export const getSizeIdByName = async (name) => {
    try {
        const response = await $host.post('/api/catalog/getsizeid', {name})
        return {success: true, message: '', data: response.data};
    } catch (error) {
        return {success: false, message: error.message};
    }
}

export const getchildsforslider = async (arrayId) => {
    const {data} = await $host.post('/api/catalog/getitemsforslider', {arrayId})
    return data;
}

export const fetchproductsbyid = async (arrayId) => {
    const {data} = await $host.post('/api/catalog/fetchproductsbyid', {arrayId})
    return data;
}

export const fetchNewProductHomePage = async (limit) => {
    const data = await $host.post('api/catalog/getnewproductsforhomepage', {limit})
    return data;
}

export const mindboxViewProduct = async (deviceUUID, userAgent, retailId) => {
    try {
        const response = await $host.post('api/catalog/mindboxviewproduct', {deviceUUID, userAgent, retailId})
        return response
    } catch (e){
        return e.response
    } 
}

export const mindboxViewCategory = async (deviceUUID, userAgent, cat) => {
    try {
        const response = await $host.post('api/catalog/mindboxviewcategory', {deviceUUID, userAgent, cat})
        return response
    } catch (e){
        return e.response
    } 
}

export const mindboxAddProductToProsmotrennyeItemList  = async (deviceUUID, userAgent, retailId, price) => {
    try {
        const response = await $host.post('api/catalog/mindboxaddproducttoprosmotrennyeitemlist', {deviceUUID, userAgent, retailId, price})
        return response
    } catch (e){
        return e.response
    } 
}

export const mindboxAddToWishList  = async (deviceUUID, userAgent, retailId, price) => {
    try {
        const response = await $host.post('api/catalog/mindboxaddtowishlist', {deviceUUID, userAgent, retailId, price})
        return response
    } catch (e){
        return e.response
    } 
}

export const mindboxRemoveFromWishList  = async (deviceUUID, userAgent, retailId, price) => {
    try {
        const response = await $host.post('api/catalog/mindboxremovefromwishlist', {deviceUUID, userAgent, retailId, price})
        return response
    } catch (e){
        return e.response
    } 
}