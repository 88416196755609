import React, {useContext, useEffect, useRef, useState} from 'react';
import InputMask from "react-input-mask";
import GenderSelect from "./genderSelect/GenderSelect";
import {Context} from "../../../../index";
import {countryArr} from "../../../../utils/consts";
import ChangeNumberModal from "./modalChangeNumber/ChangeNumberModal";
import {observer} from "mobx-react-lite";
import ConfirmSelector from "../../../UI/confirmSelector/ConfirmSelector";
import BirthdayInput from "../../../UI/birthdayInput/BirthdayInput";
import EmailInput from "../../../UI/EmailInput/EmailInput";
import FioInput from "../../../UI/FioInput/FioInput";
import {saveUserInfo} from "../../../../http/userAPI";
import ExitButton from "../../exitButton/ExitButton";






const UserInfo = observer((props) => {

    const [deviceUUID, setDeviceUUID] = useState('')
    const [userAgent, setUserAgent] = useState('')
    const nameErrRef = useRef(null);
    const emailErrRef = useRef(null);

    useEffect(() => {
        setDeviceUUID(document.cookie.replace(/(?:(?:^|.*;\s*)mindboxDeviceUUID\s*\=\s*([^;]*).*$)|^.*$/, "$1"))
        setUserAgent(window.navigator.userAgent)
    })


    const {user,settings} = useContext(Context)
    const userInfo = user.user;
// текущая страна для подгрузки флага и маски.
    const countryUser = countryArr.find(city => city.name === userInfo.country)
// состояние пользовательского соглашения
    const [userConfirm, setUserConfirm] = useState(false);



    const refreshUserInformation = async (userFields, userStore) => {

        const data = await saveUserInfo(userFields, userStore, deviceUUID, userAgent);
        if(data.error) {
            user.setShadowOverlay(true);
            settings.clearResultModal();
            settings.showUserResultModal('error','Данные не сохранены','Причина:', data.error, 'Закрыть')

        }

        if(data.message ===  "success") {
            user.setUser(data.updateUser);
            user.setShadowOverlay(true);
            settings.showUserResultModal('success','Данные сохранены','', 'Вы можете закрыть это окно', 'Закрыть')

        } else {
            return false;
        }

    }



    const confirmController = (isConfirm) => {
            setUserConfirm(isConfirm);
            if(isConfirm) {
                setConfirmTextErr('');
            }
    }

    const [confirmTextErr, setConfirmTextErr] = useState("");

// Валидация полей с буквами (имя, фамилия, отчество)
    const textFieldsValid = (text,fieldName) => {

        let pattern = /^[a-zA-Zа-яА-ЯёЁ]*$/

        if(pattern.test(text) && text.length < 20){
            setUserFields({ ...userFields, [fieldName]: text})
        };
    }





// Вызывает функцию сохранения полей в базу.
    const saveUserInformation = (objectFields, user) => {
        let  isError = false;

        if(!userConfirm) {
            setConfirmTextErr('Не принято соглашение')
            isError = true;
        }


        if(userFields.name === "") {
            setNameErr(true);
            isError = true;
        } else {
            setNameErr(false);
        }


        if(  (userFields.birthdate.length*1 >= 1 && userFields.birthdate.length*1 < 10) || errDateBirthday === true ) {
            setErrorNumberText('Укажите дату корректно')
            setErrDateBirthday(true)
            isError = true;
        }

        if(userFields.email === "" || errEmailUser === true ) {

            setErrEmailUser(true)
            setErrMessageEmail('Заполните поле')
            isError = true;
        }

        if(isError) {
            return false;
        }

        refreshUserInformation(userFields,user)

    }



    const [userFields, setUserFields] = useState({
        surname: user.user.surname ? user.user.surname : "",
        name: user.user.name ? user.user.name : "",
        patronymic: user.user.patronymic ? user.user.patronymic : "",
        gender: user.user.gender ? user.user.gender : "noGender",
        birthdate: user.user.birthdate ? user.user.birthdate : "",
        email: user.user.email ? user.user.email : "",
        city: user.user.city ? user.user.city : "",
        street: user.user.street ? user.user.street : "",
        flat: user.user.flat ? user.user.flat : "",
        favorite_magazine: user.user.favorite_magazine ? user.user.favorite_magazine : "",
    })

    // const [surnameErr, setSurnameErr] = useState(false);
    const [nameErr, setNameErr] = useState(false);
    // const [patronymicErr, setPatronymicErr] = useState(false);
    const [errDateBirthday, setErrDateBirthday] = useState(false);
    // текст в дате рождения
    const [errorNumberText, setErrorNumberText] = useState('')
    const [errEmailUser, setErrEmailUser] = useState(false);
    // текст в эмейле
    const [errMessageEmail, setErrMessageEmail] = useState('');


    const goChangeNumber = () => {
        user.setStepModals("changeNumber");
        user.setShadowOverlay(true)
    }


    const closeModalChangeNumber = () => {
        user.setStepModals("unset");
        user.setShadowOverlay(false)

    }

    useEffect(() => {
        if (user.shadowOverlay === false) {
            user.setStepModals("unset");
        }
    }, [user.shadowOverlay])

    useEffect(( )=> {
        if (nameErr) {
            nameErrRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            })
        }
        if (errEmailUser) {
            emailErrRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            })
        }
    }, [nameErr, errEmailUser])




    return (
        <div className={"user_info_container " + props.active}>

            <div className={"logo_fio_bar"}>
                <img className={"user_logo_image"} src={"/files/images/logo_user_cabinet.png"} alt={"YouWanna - личный кабинет "+(user.user.name ? user.user.name : '')+' '+(user.user.surname ? user.user.surname : "")}/>
                <div className={"contain_fio_and_bonus"}>
                    <div className={"fio_container_controll_bar"}>
                        <span className={"user_fio_item"}>{user.user.name ? user.user.name : "Имя"}</span>
                        <span className={"user_fio_item"}>{user.user.surname ? user.user.surname : user.user.name ? "" : "Фамилия"}</span>
                    </div>
                    {/*<span className={"bon_cart_info"}>Бонусная карта №9999999</span>*/}
                </div>
            </div>

            {/*<h2 className={"user_heading"}>Личная информация</h2>*/}
            <div className="form_user_info_wrapper">

                <FioInput
                    fieldsName={"Фамилия"}
                    placeholder={"Фамилия"}
                    value={userFields.surname}
                    onChange={e => textFieldsValid(e.target.value, 'surname')}
                    alwaysShowMask={true}
                    maskChar={""}
                    // errorClass = {surnameErr ? "error" : ""}
                    // errorText={"Фамилия не может быть пустой"}

                />
                <FioInput
                    fieldsName={"Имя"}
                    requiredField={" "}
                    placeholder={"Имя"}
                    value={userFields.name}
                    onChange={e => textFieldsValid(e.target.value, 'name')}
                    alwaysShowMask={true}
                    maskChar={""}
                    errRef={nameErrRef}
                    errorClass = {nameErr ? "error" : ""}
                    errorText={"Имя не может быть пустым"}
                />
                <FioInput
                    fieldsName={"Отчество"}
                    placeholder={"Отчество"}
                    value={userFields.patronymic}
                    onChange={e => textFieldsValid(e.target.value, 'patronymic')}
                    alwaysShowMask={true}
                    maskChar={""}
                    // errorClass = {patronymicErr ? "error" : ""}
                />

                <BirthdayInput
                    value={userFields.birthdate}
                    setNewValue={setUserFields}
                    userFields={userFields}
                    fieldName={"birthdate"}
                    errClass={errDateBirthday ? "error" : ""}
                    errController={setErrDateBirthday}
                    errorNumberText={errorNumberText}
                    setErrorNumberText={setErrorNumberText}
                />

                <GenderSelect
                    setFemale={e => setUserFields({...userFields,gender: "female"})}
                    setMale={e => setUserFields({...userFields,gender: "male"})}
                    unsetGender={e => setUserFields({...userFields,gender: "noGender"})}
                    femaleClass={userFields.gender === "female" ? "active" : ""}
                    maleClass={userFields.gender === "male" ? "active" : ""}
                    unsetClass={userFields.gender === "noGender" ? "active" : "" }
                />




                <EmailInput
                    requiredField={" "}
                    value={userFields.email}
                    userFields={userFields}
                    setNewValue={setUserFields}
                    fieldName={"email"}
                    errRef={emailErrRef}
                    errClass={errEmailUser ? "error" : ""}
                    errController={setErrEmailUser}
                    isErrEmail={errEmailUser}
                    errMessageEmail={errMessageEmail}
                    setErrMessageEmail={setErrMessageEmail}
                />


                <div className={"user_phone_wrapper"}>
                    <div className={"title_name_fields non_active"}>
                        Телефон
                    </div>
                    <div className={"phone_mask_and_flag"}>
                        {/*<div className={"country_img_wrapper"}>*/}
                        {/*    <img src={countryUser.imgPath} alt=""/>*/}
                        {/*</div>*/}
                        <InputMask
                            className={"user_phonenumber user_field"}
                            placeholder={"Ваш номер"}
                            value={userInfo.telephone}
                            alwaysShowMask={true}
                            mask={countryUser && countryUser.mask ? countryUser.mask : ""}
                            maskChar={""}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className={"change_number_parents"}>
                    <span
                        className={"change_number"}
                        onClick={ () =>  goChangeNumber()}
                    >Привязать другой номер</span>
                </div>
                <ChangeNumberModal controllClass={ user.stepModals === "changeNumber" ? "active" : "hide"} closeModalChangeNumber={e=>closeModalChangeNumber()}/>
                <div className={"city_user_wrapper"}>
                    <span className={"title_name_fields"}>Город</span>
                    <InputMask
                        className={"user_city user_field"}
                        // placeholder={"Город"}
                        value={userFields.city}
                        onChange={e => setUserFields({ ...userFields, city: e.target.value})}
                        alwaysShowMask={true}
                        maskChar={""}
                    />
                </div>

                <div className={"city_user_wrapper"}>
                    <span className={"title_name_fields"}>Улица, дом</span>
                    <InputMask
                        className={"street user_field"}
                        // placeholder={"Улица, дом"}
                        value={userFields.street}
                        onChange={e => setUserFields({ ...userFields, street: e.target.value})}
                        alwaysShowMask={true}
                        maskChar={""}
                    />
                </div>

                <div className={"city_user_wrapper"}>
                    <span className={"title_name_fields"}>Квартира</span>
                    <InputMask
                        className={"house user_field"}
                        // placeholder={"Квартира"}
                        value={userFields.flat}
                        onChange={e => setUserFields({ ...userFields, flat: e.target.value})}
                        alwaysShowMask={true}
                        maskChar={""}
                    />
                </div>



                <div className={"your_market_wrapper"}>
                    <span className={"title_name_fields"}>Любимый магазин</span>
                    <InputMask
                        className={"user_market user_field"}
                        // placeholder={"Любимый магазин"}
                        value={userFields.favorite_magazine}
                        onChange={e => setUserFields({ ...userFields, favorite_magazine: e.target.value})}
                        alwaysShowMask={true}
                        maskChar={""}
                    />

                </div>
                <ConfirmSelector
                    isActive={userConfirm ? "active" : "unactive" }
                    onClick={e => confirmController(!userConfirm)}
                    confirmTextErr={confirmTextErr}


                />
                <div className={"save_changes"}
                onClick={e => saveUserInformation(userFields,user.user)}
                >Сохранить информацию</div>
                <div className={"button_exit_lk"}>
                    <ExitButton/>
                </div>

            </div>

        </div>
    );
});

export default UserInfo;
