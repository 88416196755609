import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {Link, Route, Routes} from "react-router-dom";
import "./Admin.css";
import ExitButton from "../../components/personal/exitButton/ExitButton";
import LeftControllMenu from "./LeftControllMenu/LeftControllMenu";
import Users from "./users/Users";
import Products from "./products/Products";
import StartPageHomepage from "./startPageHomepage/StartPageHomepage";
import ColorManager from "./colorsManager/ColorManager";
import SizeManager from "./sizeManager/SizeManager";
import Landry from "./landryManager/Landry";
import AdminReviewsPage from "./content/reviews/AdminReviewsPage";
import CityManager from "./settingsPages/cityManager/CityManager";
import Vacancy from "./vacancy/Vacancy";
import CategoryManager from "./categoryManager/CategoryManager";
import AdminContactsPage from "./content/contacts/AdminContactsPage";
import AdminShopPage from "./content/shop/AdminShopPage";
import StockAndTrends from "./content/stockAndTrends/StockAndTrends"
import SocialManager from "./settingsPages/socialManager/SocialManager";
import DeliveryManager from "./settingsPages/delivery/DeliveryManager";
import OrdersList from "./ordersList/OrdersList";
import OneOrderPage from "./ordersList/oneOrderPage/OneOrderPage";
import Promocode from "./settingsPages/promoPage/Promocode";
import OrderStatus from "./settingsPages/orderStatus/OrderStatus";
import ModalPage from "./settingsPages/modal/ModalPage";
import Sitemap from "./settingsPages/Sitemap";
import AdmFqa from "./content/adminFqa/admFqa";
import AdmHomePage from "./content/homePage/admHomePage";
import Redirects from "./settingsPages/Redirects";
import Limiter from "./settingsPages/limiter/Limiter";
import AdmPayment from "./content/admPayment/AdmPayment";
import AdmDelivery from "./content/admDelivery/AdmDelivery";
import AdmLookbook from "./content/admLookbook/AdmLookbook";
import OneLook from "./content/admLookbook/OneLook";
import DolyamiSettingsPage from "./settingsPages/dolyamiSettings/DolyamiSettingsPage";
import AdmAbout from "./content/admAbout/AdmAbout";
import AdmPageNotFound from "./content/pageNotFound/AdmPageNotFound";
import HeaderAndFooterSettings from "./settingsPages/headerAndFooter/HeaderAndFooterSettings";
import {ReturnItemPage, Returns} from "./Returns";
import TrendManager from './trendManager/TrendManager';
import CertList from './certList/CertList';
import OneImage from "./content/admImages/OneImage";
import AdmImages from "./content/admImages/AdmImages";
import OneVideo from "./content/admVideos/OneVideo";

const Admin = observer(() => {

    const {user} = useContext(Context);

    return (
        <div  className={"main admin_dashboard"}>
                <div className={"header_admin_top_area"}>
                    <span className={"go_site_back"}><Link to={"/"} >YOU WANNA</Link></span>
                    <div className={"user_container_admin"}>
                        <span className={"circle_sumb_user"}>{user.user.name[0]}{user.user.surname[0]}</span>
                        <div className={"adm_panel_user_info"} >
                            <span className={"name_user_admin"}>{user.user.name} {user.user.surname[0]+"."} </span>
                        </div>
                        <ExitButton/>
                        {/* <button className='reboot_btn'
                            onClick={fetchReboot}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.582 9H4V4l.582 5Zm0 0a8.001 8.001 0 0 1 15.356 2M4.582 9H9m10.419 6H20v5l-.581-5Zm0 0a8.003 8.003 0 0 1-15.357-2m15.357 2H15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                </path>
                            </svg>
                        </button> */}

                    </div>
                </div>
                <div className={"Work_Admin_Space"}>
                    <LeftControllMenu
                        // adminPages = {adminPages}
                    />

                    <div className="routeWorkSpace">
                        <Routes>
                            <Route path="/"  element={<StartPageHomepage/>} />
                            <Route path="/users/*" element={<Users/>} />
                            <Route path="/products/*" element={<Products/>} />
                            <Route path="/color-manager/" element={<ColorManager/>}/>
                            <Route path="/size-manager/" element={<SizeManager/>}/>
                            <Route path="/trend-manager/" element={<TrendManager/>}/>
                            <Route path="/landry-manager/" element={<Landry/>}/>
                            <Route path="/content/home/" element={<AdmHomePage/>}/>
                            <Route path="/content/lookbook/" element={<AdmLookbook/>}/>
                            <Route path="/content/images/:id" element={<OneImage/>}/>
                            <Route path="/content/video/" element={<OneVideo/>}/>
                            <Route path="/content/images/new" element={<OneImage/>}/>
                            <Route path="/content/images/" element={<AdmImages/>}/>
                            <Route path="/content/lookbook/:id" element={<OneLook/>}/>
                            <Route path="/content/reviews/" element={<AdminReviewsPage/>}/>
                            <Route path="/content/vacancy/" element={<Vacancy/>}/>
                            <Route path="/content/shop/" element={<AdminShopPage/>}/>
                            <Route path="/content/contacts/" element={<AdminContactsPage/>}/>
                            <Route path="/content/faq/" element={<AdmFqa/>}/>
                            <Route path="/content/payment/" element={<AdmPayment/>}/>
                            <Route path="/content/delivery/" element={<AdmDelivery/>}/>
                            <Route path="/content/about/" element={<AdmAbout/>}/>
                            <Route path="/content/404/" element={<AdmPageNotFound/>}/>
                            <Route path="/content/stock-trend/*" element={<StockAndTrends/>}/>
                            <Route path="/settings/city/" element={<CityManager/>}/>
                            <Route path="/settings/category/" element={<CategoryManager/>}/>
                            <Route path="/orderlist/:custom_order_id/" element={<OneOrderPage />} />
                            <Route path="/orderlist/" element={<OrdersList/>} />
                            <Route path="/certlist/" element={<CertList/>} />
                            <Route path="/settings/headerandfooter/" element={<HeaderAndFooterSettings/>}/>
                            <Route path="/settings/delivery/" element={<DeliveryManager/>}/>
                            <Route path="/settings/social/" element={<SocialManager/>}/>
                            <Route path="/settings/promo/" element={<Promocode/>}/>
                            <Route path="/settings/order_statuses/" element={<OrderStatus/>}/>
                            <Route path="/settings/modal_controll/" element={<ModalPage/>}/>
                            <Route path="/settings/sitemap/" element={<Sitemap/>}/>
                            <Route path="/settings/redirects/" element={<Redirects/>}/>
                            <Route path="/settings/limiter/" element={<Limiter/>}/>
                            <Route path="/settings/doly/" element={<DolyamiSettingsPage/>}/>
                            <Route path="/returns/" element={<Returns/>}/>
                            <Route path="/returns/:id" element={<ReturnItemPage />}/>
                        </Routes>
                    </div>
                </div>
        </div>
    );
});

export default Admin;
