import React from 'react';
import {Route, Routes} from "react-router-dom";
import AllStockAndTrends from "./allStockAndTrends/allStockAndTrends";
import AddOneStockOrTrend from "./addOneStockOrTrend/addOneStockOrTrend";
import './StockAndTrends.css';
const StockAndTrends = () => {
    return (
        <div className={"homy_stock_and_trends_page"}>
            <Routes>
                <Route path="/oneItem/:itemId/" element={<AddOneStockOrTrend/>} />
                <Route path="/*"  element={<AllStockAndTrends/>} />
            </Routes>
        </div>
    );
};

export default StockAndTrends;