import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import { Context } from "../../../../../index";
import { observer } from "mobx-react-lite";
import HeaderSearchResult from "../../../HeaderSearchResult/HeaderSearchResult";


const SearchIcon = observer((props) => {

    let navigate = useNavigate();
    const { settings } = useContext(Context)

    const searchManagement = () => {
        props.setStateSearcht(true)
    }
    const closeSearch = () => {
        props.setStateSearcht(false)
    }
    const goSearchPage = () => {
        closeSearch();
        navigate({pathname: '/search', search: `?query=${settings.searchQuery}`});
    }

    const searchHandlerProduct = (e) => {
        e.preventDefault()
        settings.setSearchQuery(e.target.value)
    }

    useEffect(() => {
        if (window.location.pathname != '/search') {
            props.setStateSearcht(false)
        }
    }, [window.location.pathname])

    useEffect(() => {

        if (props.stateSearcht === true) {
            document.onkeydown = function checkKeycode(event) {
                let keycode;
                if (!event) var event = window.event;
                if (event.keyCode) keycode = event.keyCode; // IE
                else if (event.which) keycode = event.which; // all browsers

                if (keycode === 13) {
                    goSearchPage()
                    closeSearch()
                }
            }
        }
    }, [props.stateSearcht])

    return (
        <div className="bar_icon_wrapper fill _search">
            {
                props.stateSearcht === false ?
                    <div onClick={e => searchManagement()}>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9.72974 0C15.1033 0 19.4595 4.35614 19.4595 9.72973C19.4595 11.9482 18.7169 13.9934 17.4668 15.6302L23.6201 21.7854C24.1266 22.292 24.1266 23.1134 23.6201 23.62C23.1524 24.0877 22.4165 24.1236 21.9076 23.728L21.7854 23.62L15.6302 17.4668C13.9934 18.7169 11.9483 19.4595 9.72974 19.4595C4.35615 19.4595 0 15.1033 0 9.72973C0 4.35614 4.35615 0 9.72974 0ZM9.72974 2.5946C5.78911 2.5946 2.5946 5.7891 2.5946 9.72973C2.5946 13.6704 5.78911 16.8649 9.72974 16.8649C13.6704 16.8649 16.8649 13.6704 16.8649 9.72973C16.8649 5.7891 13.6704 2.5946 9.72974 2.5946Z"
                                fill="white"
                            />
                        </svg>
                    </div>

                    : (
                        <>
                            <div className={"search_wrapper"}>
                                <div className={"search_input_and_close"}>
                                    <div onClick={e => goSearchPage()}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M9.72974 0C15.1033 0 19.4595 4.35614 19.4595 9.72973C19.4595 11.9482 18.7169 13.9934 17.4668 15.6302L23.6201 21.7854C24.1266 22.292 24.1266 23.1134 23.6201 23.62C23.1524 24.0877 22.4165 24.1236 21.9076 23.728L21.7854 23.62L15.6302 17.4668C13.9934 18.7169 11.9483 19.4595 9.72974 19.4595C4.35615 19.4595 0 15.1033 0 9.72973C0 4.35614 4.35615 0 9.72974 0ZM9.72974 2.5946C5.78911 2.5946 2.5946 5.7891 2.5946 9.72973C2.5946 13.6704 5.78911 16.8649 9.72974 16.8649C13.6704 16.8649 16.8649 13.6704 16.8649 9.72973C16.8649 5.7891 13.6704 2.5946 9.72974 2.5946Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>
                                    <input
                                        className={"input_search_product"}
                                        type="search"
                                        value={settings.searchQuery}
                                        onChange={e => searchHandlerProduct(e)}
                                        placeholder={"Поиск "}
                                    />
                                </div>
                                <div
                                    onClick={e => closeSearch()}
                                    className={"close_search"}
                                ></div>
                            </div>
                            <HeaderSearchResult setStateSearcht={props.setStateSearcht}/>
                        </>
                    )

            }

        </div>
    );
});

export default SearchIcon;


