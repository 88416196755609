import React, {useState} from 'react';
import RefundRequestPage from "../../../../components/refunds/RefundRequestPage";
import ResultRequest from "../../../../components/refunds/ResultRequest";

const RequestForRefund = () => {

    const [stateApplications, setStateApplications] = useState(false)

    return (
        <div className={"wrapper_request"}>
            {stateApplications  ?
                <ResultRequest/>
                :
                <RefundRequestPage
                    setStateApplications={e=>setStateApplications(e)}
                />
            }
        </div>
    );
};

export default RequestForRefund;