import React from 'react';

const GenderSelect = (props) => {
    return (
        <div className="gender_select">
            <div className={"title_gender_lk"}>Пол</div>
            <div className={"contain_variants_gender"}>
                <div  onClick={props.setFemale} className="gender_select_elements">
                        <span className={"circle_select "+ props.femaleClass}>
                            <span className={"circle_black"}> </span>
                        </span>
                    <span className={"gender_name"}>Женский</span>
                </div>
                <div onClick={props.setMale} className="gender_select_elements">
                        <span className={"circle_select "+ props.maleClass}>
                            <span className={"circle_black"}> </span>
                        </span>
                    <span className={"gender_name"}>Мужской</span>
                </div>
                <div onClick={props.unsetGender} className="gender_select_elements">
                <span className={"circle_select "+ props.unsetClass}>
                    <span className={"circle_black"}> </span>
                </span>
                    <span className={"gender_name"}>Не указан</span>
                </div>
            </div>
        </div>
    );
};

export default GenderSelect;