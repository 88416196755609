import React from 'react';
import './compliteRemove.css';
const CompliteRemove = (props) => {
    return (
        <div className={props.open ? "shadow_abs active" : "shadow_abs"}>
            <span className={"warning_abs_rm"}>Удалить этот элемент?</span>
            <div className={"abs_wrapper_btn"}>
                <span onClick={props.goRemove} className={"complite_remove anim_btn"}>Удалить</span>
                <span onClick={props.canselRemove} className={"back_prev_state anim_btn"}>Вернуться</span>
            </div>
        </div>
    );
};

export default CompliteRemove;