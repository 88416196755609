import React, {useContext, useEffect, useState} from 'react';
import ModalSubscriptions from "../../homepage/ModalSubscriptions/ModalSubscriptions";
import {Context} from "../../../index";
import {getOneModal, subscribeNewsletter} from "../../../http/settingsApi";
import {observer} from "mobx-react-lite";
import {getOrdersByUserId, getСheckingUserOrder} from "../../../http/userAPI";



const PopupThreeVariant = observer((props) => {

    const {user, settings} = useContext(Context)

    const [activeModalComponetns, setActiveModalComponetns ] = useState(false)
    const [modalPopupContent, setModalPopupContent] = useState('')
    const [activePhotoModal, setActivePhotoModal] = useState('')
    const [deviceUUID, setDeviceUUID] = useState('')
    const [userAgent, setUserAgent] = useState('')

    useEffect(() => {
        setDeviceUUID(document.cookie.replace(/(?:(?:^|.*;\s*)mindboxDeviceUUID\s*\=\s*([^;]*).*$)|^.*$/, "$1"))
        setUserAgent(window.navigator.userAgent)
    })

    useEffect(() => {
        window.scrollTo(0, 0)
        getOneModal(4).then(response => {
            // console.log("response")
            // console.log(response)
            if (response.isActive === true) {
                setActiveModalComponetns(true)
            }
            if (response) {
                setModalPopupContent(response)
                if (response.image_desctop && settings.projectWidth > 768) {
                    setActivePhotoModal(response.image_desctop)
                }
                if (response.image_mobile && settings.projectWidth < 769) {
                    setActivePhotoModal(response.image_mobile)
                }
            }
            return false;
        })


    }, [])


    const [сheckingUserOrder, setCheckingUserOrder] = useState(false)




    const [modalContentPopup, setModalContentPopup] = useState({
        email:"",
    })

    const [stateCheсkBox, setStateCheсkBox] = useState(false)
    const [errorSubscribe, setErrorSubscribe] = useState('')
    const [errorSoglasie, setErrorSoglasie] = useState('')
    const [openModalPopUp, setOpenModalPopUp] = useState(false)
    const [activeModalResult, setActiveModalResult] = useState(false)

    useEffect(() => {
        if(props.open === true) {
            setOpenModalPopUp(true)
            props.setBottomModalThreeImportant(false)
        }
    },[props])

    const goModalPopup = () => {
        user.setShadowOverlay(true);
        setOpenModalPopUp(true);
        user.setStateOpenModalWindow(true)
    }

    const closeModalPopup = () => {
        setOpenModalPopUp(false);
        user.setShadowOverlay(false);
        user.setStateOpenModalWindow(false)
    }

    const openResultModal = () => {
        setOpenModalPopUp(false);
        setActiveModalResult(true)
    }

    const closeModalResult = () => {
        setActiveModalResult(false);
        user.setShadowOverlay(false);
        user.setStateOpenModalWindow(false)
    }

    const subscribeModalPopUp = (onclick) => {
        if(stateCheсkBox != false) {
            subscribeNewsletter(modalContentPopup.email, 4, deviceUUID, userAgent, 'subscriptionInPopUp').then(res => {
                if(res.message) {
                    setModalContentPopup({...modalContentPopup, email:""})
                    openResultModal()
                    setErrorSubscribe('')
                    window.dataLayer.push({
                        event: 'subscription_success', // название события, не меняется
                    })
                    // console.log(res.message)
                    return false;
                }
                setErrorSubscribe(res.error)
                // console.log(res.error)

            } )

            return false

        }
        setErrorSoglasie('Примите соглашение')

    }

    useEffect(() => {
        if (user.shadowOverlay === false) {
            setOpenModalPopUp(false);
            setActiveModalResult(false)
        }
    }, [user.shadowOverlay])




    // const [stateCurrentTime, setStateCurrentTime] = useState(false)
    //
    // useEffect(() => {
    //     if (!sessionStorage.getItem('startTime')) {
    //         sessionStorage.setItem('startTime', Date.now());
    //     }
    //     const enterTime = sessionStorage.getItem('startTime')
    //     const showPopup = () => {
    //         let currentTime = Date.now()
    //         let spentTime = (currentTime - enterTime) / 1000
    //
    //         if (spentTime > 30) {
    //             setStateCurrentTime(true)
    //             clearInterval(timer)
    //         }
    //         setStateCurrentTime(false)
    //         // console.log(`Вы провели на сайте минут: ${Math.floor(spentTime / 60)}, секунд: ${Math.floor(spentTime % 60)}`)
    //     }
    //
    //     const timer = setInterval(showPopup, 10000)
    // },[])


    // console.log(settings.activeModalNonAuth)

    // useEffect(() => {
    //
    //     if (activeModalComponetns != true && settings.activeModalNonAuth != true) {
    //         return false;
    //     }
    //
    //     if (stateCurrentTime === true || window.sessionStorage.getItem("pageCount") == 3) {
    //         if (settings.activeModalNonAuth === true && activeModalComponetns === true) {
    //             let currentTime = Date.now();
    //             let currentTimeStorage = sessionStorage.getItem('popupTimeStorageNonPromo');
    //             let timeLocalStorage = ''
    //
    //             if (!currentTimeStorage) {
    //                 let jsonCurrentTimeStorageArray = JSON.stringify(currentTime)
    //                 sessionStorage.setItem("popupTimeStorageNonPromo", jsonCurrentTimeStorageArray)
    //                 goModalPopup()
    //             } else {
    //                 // return false;
    //                 timeLocalStorage = JSON.parse(currentTimeStorage);
    //                 let timeHasPassed = currentTime - timeLocalStorage;
    //                 let passedHours = Math.floor((timeHasPassed / (1000 * 60 * 60)) % 24);
    //
    //
    //                 if (passedHours >= 24) {
    //                     let jsonCurrentNewTime = JSON.stringify(currentTime)
    //                     sessionStorage.setItem("popupTimeStorageNonPromo", jsonCurrentNewTime)
    //                     goModalPopup()
    //                 }
    //                 return false;
    //             }
    //         }
    //     }
    //     return false;
    // },[settings.activeModalNonAuth, stateCurrentTime, settings.countingPages])



    useEffect(() => {


        // if (modalPopupContent.file_modal != null && activeModalResult === true) {
        //     const url = process.env.REACT_APP_API_URL+"uploads/images/modal/"+ modalPopupContent.file_modal;
        //     var userAgent = navigator.userAgent;
        //     if (userAgent.indexOf('iPhone') != -1 ||
        //         userAgent.indexOf('iPod') != -1 ||
        //         userAgent.indexOf('iPad') != -1) {
        //         window.open(url, '_blank');
        //         return false;
        //     }
        //     return true;
        // }

        if (modalPopupContent.file_modal != null && activeModalResult === true) {
            if (settings.projectWidth > 768) {
                const url = process.env.REACT_APP_API_URL+"uploads/images/modal/"+ modalPopupContent.file_modal;
                window.open(url, '_blank');
            }
            else {
                const url = process.env.REACT_APP_API_URL+"uploads/images/modal/"+ modalPopupContent.file_modal;
                if (      window.location.assign(url)) {
                    window.open(url, '_blank');
                }
            }
        }



    }, [activeModalResult])


    return (
        <div>
            { activeModalComponetns === true ?
                <div
                    className={openModalPopUp === true  ? "modal_popup_wrapper active" : "modal_popup_wrapper" }
                    // className={"modal_popup_wrapper active"}
                >
                    <div className={"popup_child_wrap new_variant"}>
                        <div
                            onClick={e => closeModalPopup()}
                            className={"close_popup"}></div>
                        <div className={"popup_content_wrap"}>
                            <div className={"container_banner_popup three_variant"}>
                                <img className={"banner_popup"} src={activePhotoModal ? process.env.REACT_APP_API_URL+"uploads/images/modal/"+ activePhotoModal : null}
                                     alt={'YouWanna - '+ (modalPopupContent.modal_fooText ? modalPopupContent.modal_fooText : 'интернет-магазин одежды и аксессуаров')}
                                />
                                <div className={"info_text_banner_popup"}>
                                    <div className={"text_banner_bold_line"}>{modalPopupContent.modal_oneText ? modalPopupContent.modal_oneText : ''}</div>
                                    <div className={"text_banner_one_line"}>{modalPopupContent.modal_twoText ? modalPopupContent.modal_twoText : ''}</div>
                                    <div className={"text_promo_and_copy"}>
                                        <div
                                            className={"popup_promocod"}>{modalPopupContent.modal_threeText ? modalPopupContent.modal_threeText : ''}</div>
                                        {modalPopupContent.modal_threeText ?
                                            <div
                                                onClick={e => navigator.clipboard.writeText(modalPopupContent.modal_threeText)}
                                                className={"button_copy_promocod"}>Копировать</div>
                                            :
                                            ''
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className={"container_fields_popup new_disain"}>
                                <div className={"child_fields_info"}>
                                    <div className={"title_popup_fields"}>{modalPopupContent.modal_fooText ? modalPopupContent.modal_fooText : ''}</div>
                                    <div className={"fields_and_sending"}>
                                        <div className={"container_email_fields"}>
                                            <span>E-mail</span>
                                            <input
                                                value={modalContentPopup.email}
                                                onChange={e =>  setModalContentPopup({...modalContentPopup,email:e.target.value})}
                                                className={"email_fields_input_popup"} type="text"/>
                                            <div className={"error_massage_popup"}>{errorSubscribe}</div>
                                        </div>
                                        <div className={"container_button_modal"}>

                                            <div
                                                onClick={e=> subscribeModalPopUp()}
                                                className={stateCheсkBox === true ? "button_sending_popup" : "button_sending_popup unactive"}>
                                                Подписаться
                                            </div>



                                            {
                                                settings.projectWidth > 768 ?
                                                    <div
                                                        onClick={e=> closeModalPopup()}
                                                        className={"button_close_popup"}><noindex>Закрыть</noindex></div>
                                                    :''
                                            }
                                        </div>
                                        <div className="politic_popup_wrapper">
                                            <div className={"link_politic_popup"}>
                                                <div
                                                    onClick={e=> setStateCheсkBox(!stateCheсkBox)}
                                                    className={stateCheсkBox === true ? "confirm_selector_popup active" : "confirm_selector_popup"}>
                                                    <div className="child_confirm_selector_popup">
                                                    </div>
                                                </div>
                                                <span><noindex> Нажимая кнопку “ПОДПИСАТЬСЯ”, вы соглашаетесь с нашей  <a rel="noreferrer"  target={"_blank"} href="/personal/privacy-policy/">политикой конфиденциальности</a> </noindex> </span>
                                            </div>
                                            <div className={"error_massage_popup"}>{errorSoglasie}</div>
                                        </div>

                                        {
                                            settings.projectWidth < 769 ?
                                                <div
                                                    onClick={e=> closeModalPopup()}
                                                    className={"button_close_popup mobile"}><noindex>Закрыть</noindex></div>
                                                :''
                                        }

                                    </div>



                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                :
                ""
            }

            <ModalSubscriptions
                className={activeModalResult === true ? "active" : "hide"}
                closeClick={() => closeModalResult()}
            />
        </div>
    );
});

export default PopupThreeVariant;
