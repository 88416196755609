import React, { useRef, useState } from 'react';
import './toolTipComponent.css'
const ToolTipComponent = ({ children, text, customClass }) => {
    const refSetTimeout = useRef();
    const [showToolTip, setShowToolTip] = useState(false);
    const toolTipClasses = customClass ? `base_tooltip ${customClass}` : `base_tooltip`;
    
    const onMouseEnterHandler = () => {
        refSetTimeout.current = setTimeout(() => {
            setShowToolTip(true);
        }, 200);
    };
    
    const onMouseLeaveHandler = () => {
        clearTimeout(refSetTimeout.current);
        setShowToolTip(false);
    };

    return (
        <div className={'tt_container'} onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}>
            {children}
            {showToolTip && <div className={toolTipClasses}>{text}</div>}
        </div>
    );
  };

export default ToolTipComponent;