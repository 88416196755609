import React, {useEffect, useState} from 'react';
import './adminShopPage.css';
import OneShop from "./oneShop/OneShop";
import {
    addNewMarket,
    fetchAllMarkets,
    getOnePageContent,
    updateContentPage
} from "../../../../http/contentApi";
import CityChanger from "../../UI/cityChanger/CityChanger";
import IsActiveIndicator from "../../UI/isActiveIndicator/IsActiveIndicator";
import OneImageField from "../../UI/oneImageField/OneImageField";
import {catalogProductSizePreset, contentShopImage} from "../../../../utils/consts";
import {addImages, deleteImage} from "../../../../http/imagesApi";

const AdminShopPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])


    const [contentPageShop, setContentPageShop] = useState({}); //контент страницы
    const [shopArray, setShopArray] = useState([]) //все мазазины
    const [errorShop, setErrorShop] = useState(""); //вывод ошибки
    const [trigerShopSeoHandler, setTrigerShopSeoHandler] = useState(false);
    const [oneShop, setOneShop] = useState(
        {img_shop:"", name_market:"", address: "", work_schedule:"", back_number: "", front_number: "",
            map_url: "", cityId: "", is_active: false, city: "" }
    )
    const [refreshDbShop,setRefreshDbShop] = useState(false) ;




    useEffect(() => {
        getOnePageContent(6).then(response => {
            setContentPageShop(response)
            if(response.error) {
                setErrorShop(response.error)
                return false;
            }
            if(response.message) {
                setErrorShop(response.message)
                //добавить тригер
            }
        })
        fetchAllMarkets(false).then(response => {
            setShopArray(response.data)
        })

    },[refreshDbShop])


    const updateShopHandlerSeo = (contentPageShop) => {
        updateContentPage(contentPageShop).then(response => {
            setTrigerShopSeoHandler(false)
        } )
    }





    const addNewShopHandler = (fields) => {
        if(oneShop.name_market !== '' && oneShop.address !== '' && oneShop.work_schedule !== ''  && oneShop.back_number !== '' && oneShop.front_number !== '' &&
            oneShop.map_url !== '') {
            addNewMarket(fields).then(res => {
                if(res.message) {
                    setErrorShop("Магазин добавлен")
                    setOneShop(
                        {img_shop:"", name_market:"", address: "", work_schedule:"", back_number: "", front_number: "", map_url: "", cityId: "", is_active: false }
                    )
                    setRefreshDbShop(!refreshDbShop)
                    return false;
                }
                setErrorShop('Непредвиденная ошибка, обратитесь к администрации')
            })
        } else {
            setErrorShop('Заполните все поля')
        }
    }

    const HandlerSeoShop = (e, fieldName) => {
        setContentPageShop({...contentPageShop, [fieldName]: e.target.value})
        setTrigerShopSeoHandler(true)
    }

    const cardsShopFields = (e, fieldName) => {
        setOneShop({...oneShop, [fieldName]: e.target.value})
        // setTrigerShopSeoHandler(true)
    }

    const setCity = (value) => {
        setOneShop({...oneShop, cityId: value.id, city: value})
    }
    const changeActiveShop = () => {
        setOneShop({...oneShop, is_active: !oneShop.is_active})
    }






    const uploadShoptImage = (e,id) => {


        let newFile = e.target.files[0];

        let formData = new FormData();
        formData.append("image", newFile);

        if (oneShop.img_shop){
            deleteImage(oneShop.img_shop, "shop", null).then(response  => {
                setOneShop({...oneShop,img_shop: ""})            })
        }

        addImages(formData,'shop',null, contentShopImage).then(response => {
            if(response.message) {
                setOneShop({...oneShop,img_shop:response.fileName})
            }
        })

    }

    const deleteImageShopOnClick = (filename) => {
        deleteImage(filename, "shop", null).then(response  => {
            setOneShop({...oneShop,img_shop: ""})             })
    }





    return (
        <div className={"wrapper_page_shop"}>
            <div className={"workspace_area_shop"}>
                <div className={"adm_shop_title"}>Менеджер магазинов</div>
                <div className={"content_manager_shop"}>
                    <div className={"left_wrapper_shop"}>
                        <div className="left_container_shop">
                            <span className="shop_field_label_name">title</span>
                            <input
                                value={contentPageShop.title ? contentPageShop.title : ""}
                                onChange={e => HandlerSeoShop(e,"title")}
                                className="shop_field" type="text"/>
                        </div>
                        <div className="left_container_shop">
                            <span className="shop_field_label_name">description</span>
                            <input
                                value={contentPageShop.description ? contentPageShop.description : ""}
                                onChange={e => HandlerSeoShop(e,"description")}
                                className="shop_field" type="text"/>
                        </div>
                        <div
                            onClick={e => updateShopHandlerSeo(contentPageShop)}
                            className=
                                {
                                    trigerShopSeoHandler === true ? "save_button_shop_content trig" : "save_button_shop_content"
                                } >Сохранить
                        </div>
                    </div>
                </div>

                   <div className={"container_cards_shop"}>
                       <div className={"shop_city_container"}>
                           <div className={"preview_img_shop"}>
                               <OneImageField text={"Превью магазина"}
                                              image={oneShop.img_shop ?
                                                  process.env.REACT_APP_API_URL+"uploads/images/shop/"+ oneShop.img_shop : null }
                                              onChange={ e => uploadShoptImage(e,'img_shop',catalogProductSizePreset)}
                                              deleteImage={e => deleteImageShopOnClick(oneShop.img)}
                               />
                           </div>
                           <div className={"wrap_create_shop"}>

                               <div className="left_container_shop">
                                   <span className="shop_field_label_name">Наименование магазина</span>
                                   <input
                                       value={oneShop.name_market ? oneShop.name_market : ""}
                                       onChange={e => cardsShopFields(e,"name_market") }
                                       className="shop_field" type="text"/>
                               </div>
                               <div className="left_container_shop">
                                   <span className="shop_field_label_name">Адрес</span>
                                   <textarea
                                       value={oneShop.address ? oneShop.address : ""}
                                       onChange={e => cardsShopFields(e,"address") }
                                       className="shop_field textarea" type="text"/>
                               </div>
                               <div className="left_container_shop">
                                   <span className="shop_field_label_name">Режим работы</span>
                                   <input
                                       value={oneShop.work_schedule ? oneShop.work_schedule : ""}
                                       onChange={e => cardsShopFields(e,"work_schedule") }
                                       className="shop_field" type="text"/>
                               </div>
                               <div className="left_container_shop">
                                   <span className="shop_field_label_name">Номер телефона (виден пользователям)</span>
                                   <input
                                       value={oneShop.front_number ? oneShop.front_number : ""}
                                       onChange={e => cardsShopFields(e,"front_number") }
                                       className="shop_field" type="text"/>
                               </div>
                               <div className="left_container_shop">
                                   <span className="shop_field_label_name">Номер телефона для кнопки (без пробелов, скобок и символов)</span>
                                   <input
                                       value={oneShop.back_number ? oneShop.back_number : ""}
                                       onChange={e => cardsShopFields(e,"back_number") }
                                       className="shop_field" type="text"/>
                               </div>
                               <div className="left_container_shop">
                                   <span className="shop_field_label_name">Ссылка на карту магазина</span>
                                   <textarea
                                       value={oneShop.map_url ? oneShop.map_url : ""}
                                       onChange={e => cardsShopFields(e,"map_url") }
                                       className="shop_field textarea" type="text"/>
                               </div>
                           </div>
                           <div className={"right_container_city"}>
                               <div
                                   onClick={e => changeActiveShop()}
                                   className={"is_active_shop"}>
                                   <IsActiveIndicator active={oneShop.is_active} />
                               </div>
                               <div className={"add_city_shop"}>
                                   <CityChanger
                                       targetCity={oneShop.city}
                                       setCity={e => setCity(e)}
                                   />
                               </div>
                           </div>
                       </div>
                       <div className={"shop_container_buttons"}>
                          <div className={"error_text"}>
                              {errorShop}
                          </div>
                            <span
                                onClick={e => addNewShopHandler(Object.assign({}, oneShop ))}
                                className="add_new_shop_btn">Добавить магазин</span>
                       </div>
                   </div>

                <div className={"shop_wrapper_content"}>
                    <div className={"container_cards_shop"}>

                        {
                            shopArray &&  shopArray.map((item, index) =>
                                    <OneShop
                                        item={item}
                                        index={index}
                                        key={index}
                                        shopArray={shopArray}
                                        setShopArray={setShopArray}
                                        setRefreshDbShop={e => setRefreshDbShop(e)}
                                        refreshDbShop={refreshDbShop}
                                    />
                                )
                        }

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminShopPage;