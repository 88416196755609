import React from 'react';
import './StocksTrends.css';
import {Link} from "react-router-dom";
import {ONE_STOCKS} from "../../../utils/consts";

const StocksTrends = (props) => {


    return (
        <div className={"stock_parent"}>
            <Link to={ONE_STOCKS}>
            <div className={"stock_child"}>
                <img className={"stock_img"} src={props.item.img} alt=""/>
                <div className={"stock_absolute"}>
                    <div className={"stock_black_back"}>
                        <span className={"stock_subline_sale"}>{props.item.name}</span>
                    </div>
                </div>
            </div>
            </Link>
        </div>
    );
};

export default StocksTrends;