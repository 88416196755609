import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import BreadCrumbsBlack from "../../../../components/Breadcrumbs/breadCrumbsBlack/BreadCrumbsBlack";
import './pageStockTrend.css';
import {Context} from "../../../../index";
import {Link} from "react-router-dom";
import {ONE_STOCKS, STOCKS_AND_TRENDS} from "../../../../utils/consts";



const PageStockTrend = observer((props) => {

    const {settings} = useContext(Context)
    const margTop = settings.headerHeight



    const text = [
        {text:"В коллекции представлены базовая линия из натуральных и дышащих тканей, изделия из самого трендового материала - экокожи, комфортные брючные комплекты из теплого трикотажа, одежда и аксессуары, идеально подходящие для работы в офисе и из дома, а также аксессуары, которые будут согревать вас в непредсказуемую погоду и стильно дополнять ваши наряды."},
        {text:"Хлопковые клетчатые рубашки - тренд, идеально вписывающийся в любой гардероб. В зависимости от повода и настроения вы можете подобрать рубашку в мелкую клетку, которая будет гармонично смотреться в офисе,  или отдать предпочтение  более крупному принту для повседневных casual-образов."},
    ]
    const [count,setCount] = useState(0)

    useEffect(() => {
        window.scrollTo(0, 0)
        let countSumbol = 0;
        text.forEach((item,index) => {
            countSumbol += Number(item.text.length)
        })
        setCount(countSumbol);
    },[])

    const switchButton = {switch: "a", link: "http://192.168.1.151:3000/catalog/"}

    return (
        <div className={"one_page_stocks"}>
            <div id={"ops_content_wrap"} style={{top:margTop, marginBottom:margTop}} className={"ops_content_wrap"}>
                {settings.projectWidth && settings.projectWidth > 1024 ?
                    <BreadCrumbsBlack
                        items={[
                            {name: "Youwanna", link: "/"},
                            {name: "ТЕПЛЫЕ КУРТКИ НА...", link: ONE_STOCKS}
                        ]}
                    />
                    :
                    ""
                }
            <div className={"ops_img_text"}>
                <div className={"ops_banner_wrap"}>
                    <img className={"ops_banner"} src={"/files/images/stocksTrends/trends1.png"} alt=""/>
                </div>
                <div  className={"ops_text_wrap"}>
                    <div className={"ops_text"}>
                        <div className={"ops_text_child"}>
                            <h1 className={"ops_title"}>Теплые куртки на осень и зиму: 5 главных трендов</h1>
                            <div className={count > 1900 ? "ops_check active" : "ops_check"}>
                                {
                                    text.map((item,index) => {
                                        return(<div className={"ops_style_text"} key={index} >{item.text}</div>)
                                    })
                                }
                            </div>
                        </div>
                        <div className={"ops_button"}>
                            <Link to={STOCKS_AND_TRENDS} className={"ops_style_button left"}>Назад</Link>
                            {
                                switchButton.switch === "a" ?
                                    <a href={switchButton.link} className={"ops_style_button"}>Перейти</a>
                                    :
                                    <Link to={switchButton.link} className={"ops_style_button"}>Перейти</Link>
                            }
                        </div>
                    </div>
                </div>
            </div>
                <div className={"ops_content_img"}>
                    <img className={"ops_dop_photo"} src={"/files/images/stocksTrends/trends2.png"} alt=""/>
                    <img className={"ops_dop_photo"} src={"/files/images/stocksTrends/trends3.png"} alt=""/>
                </div>
            </div>
        </div>
    );
});

export default PageStockTrend;