import React, {useContext, useEffect} from 'react';
import {Helmet} from "react-helmet";

import './PrivacyPages.css'
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";
import BreadCrumbsBlack from "../../../components/Breadcrumbs/breadCrumbsBlack/BreadCrumbsBlack";
import PolicyAndOffer from "../../../components/policyAndOffer/PolicyAndOffer";

const  PublicOffer = observer(() => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const {settings} = useContext(Context)
    const margTop = settings.headerHeight




    const publicArrayText = [
        {title:"", text:[
                {p: "Интернет-магазин «You Wanna», расположенный на доменном имени www.you-wanna.ru (ИП Кабулова Юлия Борисовна, юридический адрес: 141315, Сергиево-Посадский р-н, г.Сергиев Посад г, Дружбы ул., дом № 09 \"а\", кв.45), в лице руководителя, Кабуловой Юлии Борисовны, действующего (- ей) на основании Устава, именуемый в дальнейшем «Продавец», публикует Публичную оферту о продаже Товара дистанционным способом."}
            ]},
        {title:"1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ", text:[
                {p: '1.1. Публичная оферта (далее – «Оферта») - публичное предложение Продавца, адресованное неопределенному кругу лиц, заключить с Продавцом договор купли-продажи товара дистанционным способом (далее - «Договор») на условиях, содержащихся в настоящей Оферте, включая все Приложения.'},
                {p: '1.2. Заказ Товара на сайте Интернет-магазина – позиции указанные Покупателем из ассортимента Товара, предложенного к продаже, при оформлении заявки на приобретение Товара на сайте Интернет-магазина или через Оператора.'},
            ]},
        {title:"2. ОБЩИЕ ПОЛОЖЕНИЯ", text:[
                {p: '2.1. Заказ Покупателем Товара, размещенного на сайте Интернет-магазина означает, что Покупатель согласен со всеми условиями настоящей Оферты.'},
                {p: '2.2. Администрация сайта Интернет-магазина имеет право вносить изменения в Оферту без уведомления Покупателя.'},
                {p: '2.3. Срок действия Оферты не ограничен, если иное не указано на сайте Интернет-магазина.'},
                {p: '2.3. Срок действия Оферты не ограничен, если иное не указано на сайте Интернет-магазина.'},
            ]},
        {title:"3. ЦЕНА ТОВАРА", text:[
                {p: '3.1. Цена на каждую позицию Товара указана на сайте Интернет-магазина.'},
                {p: '3.2. Продавец имеет право в одностороннем порядке изменить цену на любую позицию Товара.'},
                {p: '3.3. В случае изменения цены на заказанный Товар Продавец обязуется в течение 3 дней проинформировать Покупателя об изменении цены Товара.'},
                {p: '3.4. Покупатель вправе подтвердить либо аннулировать Заказ на приобретение Товара, если цена изменена Продавцом после оформления Заказа.'},
                {p: '3.5. Изменение Продавцом цены на оплаченный Покупателем Товар не допускается.'},
                {p: '3.6. Продавец указывает стоимость доставки Товара на сайте Интернет-магазина либо сообщает Покупателю при оформлении заказа Оператором.'},
                {p: '3.7. Обязательства Покупателя по оплате Товара считаются исполненными с момента поступления Продавцом денежных средств.'},
                {p: '3.8. Расчеты между Продавцом и Покупателем за Товар производятся способами, указанными на сайте Интернет-магазина в разделе https://you-wanna.ru/about/payment/'},
            ]},
        {title:"4. ОФОРМЛЕНИЕ ЗАКАЗА", text:[
                {p: '4.1. Заказ Товара осуществляется Покупателем через Оператора по телефону +7 (903) 798 70 07 или через сервис сайта Интернет-магазина https://you-wanna.ru/personal/make/.'},
                {p: '4.2. При регистрации на сайте Интернет-магазина Покупатель обязуется предоставить следующую регистрационную информацию:'},
                {p: '4.2.1. фамилия, имя, отчество Покупателя или указанного им лица (получателя);'},
                {p: '4.2.2. адрес, по которому следует доставить Товар (если доставка до адреса Покупателя);'},
                {p: '4.2.3. адрес электронной почты;'},
                {p: '4.2.4. контактный телефон.'},
                {p: '4.3. Наименование, количество, ассортимент, артикул, цена выбранного Покупателем Товара указываются в корзине Покупателя на сайте Интернет-магазина.'},
                {p: '4.4. Если Продавцу необходима дополнительная информация, он вправе запросить ее у Покупателя. В случае не предоставления необходимой информации Покупателем, Продавец не несет ответственности за выбранный Покупателем Товар.'},
                {p: '4.5. При оформлении Заказа через Оператора (п. 4.1. настоящей Оферты) Покупатель обязуется предоставить информацию, указанную в п. 4.2. настоящей Оферты.'},
                {p: '4.6. Принятие Покупателем условий настоящей Оферты осуществляется посредством внесения Покупателем соответствующих данных в регистрационную форму на сайте Интернет-магазина или при оформлении Заказа через Оператора. После оформления Заказа через Оператора данные о Покупателе регистрируются в базе данных Продавца. Утвердив Заказ выбранного Товара, Покупатель предоставляет Оператору необходимую информацию в соответствии с порядком, указанном в п.4.2. настоящей Оферты.'},
                {p: '4.7. Продавец не несет ответственности за содержание и достоверность информации, предоставленной Покупателем при оформлении Заказа.'},
                {p: '4.8. Покупатель несет ответственность за достоверность предоставленной информации при оформлении Заказа.'},
                {p: '4.9. Договор купли-продажи дистанционным способом между Продавцом и Покупателем считается заключенным с момента выдачи Продавцом Покупателю кассового или товарного чека либо иного документа, подтверждающего оплату Товара.'},
            ]},
        {title:"5. ДОСТАВКА И ПЕРЕДАЧА ТОВАРА ПОКУПАТЕЛЮ", text:[
                {p: '5.1. Продавец оказывает Покупателю услуги по доставке Товара одним из способов указанных на сайте Интернет-магазина.'},
                {p: '5.2. Если Договор купли-продажи товара дистанционным способом (далее – Договор) заключен с условием о доставке Товара Покупателю, Продавец обязан в установленный Договором срок доставить Товар в место, указанное Покупателем, а если место доставки Товара Покупателем не указано, то по месту его жительства или регистрации.'},
                {p: '5.3. Место доставки Товара Покупатель указывает при оформлении Заказа на приобретение Товара.'},
                {p: '5.4. Срок доставки Товара Покупателю состоит из срока обработки заказа и срока доставки.'},
                {p: '5.5. Доставленный Товар передается Покупателю, а при отсутствии Покупателя - любому лицу, предъявившему квитанцию или иной документ, подтверждающий заключение Договора или оформление доставки Товара.'},
                {p: '5.6. В момент передачи Товара в обязательном порядке в письменной форме Покупателю сообщаются сведения, предусмотренные в Приложении № 2 к Договору.'},
                {p: '5.7. Информация о Товаре доводится до сведения Покупателя в технической документации, прилагаемой к Товару, на этикетках, путем нанесения маркировки или иным способом, принятым для отдельных видов товаров.'},
                {p: '5.8. Сведения об обязательном подтверждении соответствия Товара представляются в порядке и способами, которые установлены законодательством Российской Федерации о техническом регулировании, и включают в себя сведения о номере документа, подтверждающего такое соответствие, о сроке его действия и об организации, его выдавшей.'},
                {p: ''},
                {p: ''},
            ]},
        {title:"6. РЕКВИЗИТЫ ПРОДАВЦА", text:[{p:''}]},
        {title:"Продавец:", text:[
                {p: 'Индивидуальный предприниматель Кабулова Юлия Борисовна'},
            ]},
        {title:"", text:[
                {p: 'Телефон +7 (903) 798 70 07'},
            ]},
        {title:"Адрес:", text:[
                {p: '141315, Сергиево-Посадский р-н, г.Сергиев Посад г,'},
                {p: 'Дружбы ул., дом № 09 "а", кв.45'},
            ]},
        {title:"Банковские реквизиты", text:[
                {p: 'Банк: ПАО СБЕРБАНК Г. МОСКВА'},
                {p: 'К/с: 30101810400000000225'},
                {p: 'Бик: 044525225'},
            ]},
        {title:"", text:[
                {p: 'Внимательно ознакомьтесь с текстом публичной оферты, и если Вы не согласны с каким-либо пунктом оферты, Вы вправе отказаться от покупки Товаров, предоставляемых Продавцом, и не совершать действий, указанный в п. 2.1. настоящей Оферты.'},
            ]},
        {title:"", text:[
                {p: 'Согласен с договором'},
            ]},
        {title:"", text:[
                {p: ''},
                {p: ''},
                {p: ''},
                {p: ''},
                {p: ''},
                {p: ''},
                {p: ''},
                {p: ''},
                {p: ''},
                {p: ''},
            ]},
    ]

    return (
        <div className={"public_offer"}>
            <Helmet>
                <title>Публичная оферта интернет-магазина YOU WANNA</title>
                <meta name="description" content="Публичная оферта в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" />
            </Helmet>
            <div
                // style={{top:margTop, paddingBottom:margTop }}
                style={{marginTop:margTop }}
                className={"public_offer_wrapper"}>
                {/*{*/}
                {/*    settings.projectWidth &&  settings.projectWidth > 1024 ?*/}
                {/*        <div className={"indent_bread"}>*/}
                {/*            <BreadCrumbsBlack*/}

                {/*                items={[*/}
                {/*                    {name:"Youwanna", link:"/"},*/}
                {/*                    {name:"ПУБЛИЧНАЯ ОФЕРТА", link:"/content/public-offer/"}*/}
                {/*                ]}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*        :*/}
                {/*        ""*/}
                {/*}*/}
            </div>
            <div className={"pp_content"}>

                <h1 className={"pp_title"}>Публичная Оферта</h1>
                <div className={"pp_conteiner_text"}>
                    <div className={"pp_child_text"}>
                        {
                            publicArrayText ? publicArrayText.map((item, index) =>
                                <PolicyAndOffer
                                    item={item}
                                    index={index}
                                    key={index}
                                />
                            ) : ''
                        }
                    </div>
                </div>
            </div>
        </div>
    );
});

export default PublicOffer;