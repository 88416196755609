import React, {useEffect, useState} from 'react';
import './vacancy.css';
import {addNewVacancy, fetchAllVacancy, getOnePageContent, updateContentPage} from "../../../http/contentApi";
import OneVacancy from "./OneVacancy";
import {fetchAllCity} from "../../../http/settingsApi";
import OneImageHorizontalField from "../UI/OneImageHorizontalField/OneImageHorizontalField";
import {addImages, deleteImage} from "../../../http/imagesApi";
import {contentBackgroundImage} from "../../../utils/consts";

const Vacancy = () => {



    const [vacancyArray, setVacancyArray] = useState([])
    const [refreshDbVacancy,setRefreshDbVacancy] = useState(false) ;
    const [pageContent,setPageContent] = useState({});
    const [noJson,setNoJsonContent] = useState({})
    const [contentFieldsSaveTriger, setContentFieldsSaveTriger] = useState(false)

    useEffect(() => {
        getOnePageContent(3).then(response => {
            setPageContent(response)
            if(response.content) {
                setNoJsonContent(JSON.parse(response.content))
            }
        } )
        fetchAllVacancy().then(response => {
            setVacancyArray(response)
        })

    },[])

    useEffect(() => {
        fetchAllVacancy().then(response => {
               setVacancyArray(response)
        })
    },[refreshDbVacancy])


    useEffect(() => {
        setPageContent({...pageContent, content:JSON.stringify(noJson)})
    }, [noJson])



    const addNewVacancyHandler = async () => {
        addNewVacancy().then(response => {
            setRefreshDbVacancy(!refreshDbVacancy)
        } )
    }
    const uploadContentImageVacancy = (e) => {


        let newFile = e.target.files[0];

        let formData = new FormData();
        formData.append("image", newFile);

        if (noJson.img){
            deleteImage(noJson.img, "vacancy", null).then(response  => {
                setNoJsonContent({...noJson,img: ""})            })
            setContentFieldsSaveTriger(true)

        }

        addImages(formData,'vacancy',null, contentBackgroundImage).then(response => {
            if(response.message) {
                setNoJsonContent({...noJson,img:response.fileName})
            }
        })
        setContentFieldsSaveTriger(true)

    }

    const deleteImageOnClickVacancy = (img) => {
        deleteImage(img, "vacancy", null).then(response  => {
            setNoJsonContent({...noJson,img: ""})            })
    }

    const saveVacancyContent = (date) => {
        updateContentPage(date).then(response => {
            setContentFieldsSaveTriger(false)
        } )
    }


    return (

        <div className={"vacancy_admin_page"}>
            <span className={"vacancy_page_title"}> Вакансии </span>
            <div className={"vacancy_content_wrapper_adm"}>
                <div className={"vacancy_content_fields"}>
                    <div className={"vacancy_fields_content"}>
                        <div className={"one_field_vacancy_wrapper"}>
                            <span className={"one_field_name_label"}>Имя страницы</span>
                            <input
                                value={pageContent.name ? pageContent.name : ""}
                                onChange={e => (setPageContent({...pageContent, name: e.target.value}),
                                        setContentFieldsSaveTriger(true)
                                )}
                                className={"vacancy_field_s"} type="text"/>
                        </div>
                        <div className={"one_field_vacancy_wrapper"}>
                            <span className={"one_field_name_label"}>Title</span>
                            <input
                                value={pageContent.title ? pageContent.title : ""}
                                onChange={e => (setPageContent({...pageContent, title: e.target.value}),
                                        setContentFieldsSaveTriger(true)
                                )}
                                className={"vacancy_field_s"} type="text"/>
                        </div>
                        <div className={"one_field_vacancy_wrapper"}>
                            <span className={"one_field_name_label"}>Description</span>
                            <input
                                value={pageContent.description ? pageContent.description : ""}
                                onChange={e => (setPageContent({...pageContent, description: e.target.value}),
                                        setContentFieldsSaveTriger(true)
                                )}
                                className={"vacancy_field_s"} type="text"/>
                        </div>
                    </div>
                    <div className={"vacancy_horizontal_wrap_container"}>
                        <div className={"vacancy_content_image_field"}>
                            <OneImageHorizontalField
                                onChange={ e => uploadContentImageVacancy(e) }
                                image={noJson.img ?
                                    process.env.REACT_APP_API_URL+"uploads/images/vacancy/"+ noJson.img : null }
                                deleteImage={e => deleteImageOnClickVacancy(noJson.img)}

                            />
                        </div>
                    </div>
                </div>

                    <span
                        onClick={e => saveVacancyContent(pageContent)}
                        className={contentFieldsSaveTriger ? "save_content_vacancy trig" : "save_content_vacancy"}>
                    Сохранить
                    </span>




                <div className={"vacancies_controller"}>
                    <div className={"vacancies_container_filter"}></div>
                    <div className={"vacancies_container_buttons"}>
                        <span
                            onClick={ e => addNewVacancyHandler(e) }
                            className={"add_new_vacancy_btn"}>Добавить вакансию</span>
                    </div>
                </div>
                <div className={"vacancy_wrapper_content"}>
                    {vacancyArray ?
                        vacancyArray.map((item,index) =>
                            <OneVacancy item={item}
                                        index={index}
                                        key={item.id}
                                        vacancyArray={vacancyArray}
                                        setVacancyArray={setVacancyArray}
                                        setRefreshDbVacancy={e => setRefreshDbVacancy(e)}
                                        refreshDbVacancy={refreshDbVacancy}
                            />
                        )
                        : ""
                    }
                </div>
            </div>
        </div>
    );
};

export default Vacancy;