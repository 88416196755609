import React from 'react';
import './socialIcon.css'

const SocialIcons = (props) => {

    const activSocial = props.nameRecordingObject

    const oneSocialIcons =  {id:props.item.id,image_code:props.item.svg_social, link_svg: props.item.url_social, active_svg_output: props.item.active_svg_output };

    const addActivorDeleteSocial = (object) => {


            let reduceActiveIcons = {...props.noJson};
            let item = reduceActiveIcons[activSocial].filter((objectItem) => objectItem.id === object.id )

                if(!item[0]) {
                    reduceActiveIcons[activSocial].push(object)
                    props.setNoJsonContent(reduceActiveIcons)
                    props.setUpdateSocialSaveTriger(true)
                    return false;
                }

                 let customIndex = null;
                    reduceActiveIcons[activSocial].forEach((item,index) => {
                        if(item.id === object.id) {
                            customIndex = index
                        }
                    })

                    if(customIndex !== null) {
                        reduceActiveIcons[activSocial].splice(customIndex,1)
                        props.setNoJsonContent(reduceActiveIcons)
                        props.setUpdateSocialSaveTriger(true)
                    }

        return false;

    }

    function checkActiveSocial(object) {
        if(!props.noJson){
            return false
        }

        let reduceActiveIcons = {...props.noJson};
        // //
        // GOTO: не приходят данные, которые можно отфильтровать, поэтому пока заглушка

        if(!reduceActiveIcons[activSocial]) {
            return false
        }
        ///
        let item = reduceActiveIcons[activSocial].filter((objectItem) => objectItem.id === object.id )
        if(item[0]) {
            return true;
        }
        return false;

    }

    return (
        <div>
            {
                props.noJson ?
                    <div
                        onClick={e => addActivorDeleteSocial(Object.assign({}, oneSocialIcons))}
                        className={
                            checkActiveSocial(props.item) ? "social_icons_wrapper active" : "social_icons_wrapper"}>
                        <div dangerouslySetInnerHTML={{__html: props.item.svg_social}} className={"name_social_icons"}></div>
                        <span className="social_icons_name">{props.item.resource_name}</span>
                    </div>
                    : ""
            }
        </div>
    );
};

export default SocialIcons;