import React, {useContext, useEffect, useState} from 'react';
import ContentBreadcrumbs from "../../../components/Breadcrumbs/contentBreadcrumbs/ContentBreadcrumbs";
import {Context} from "../../../index";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {getOnePageContent} from "../../../http/contentApi";
import OneImageField from "../../administrator/UI/oneImageField/OneImageField";

const PageUnsubscribe = () => {
    const {settings} = useContext(Context)

    useEffect(() => {
        window.scrollTo(0, 0);
    },[])


    return (
        <div></div>
    );
};

export default PageUnsubscribe;