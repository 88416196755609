
import React from 'react';
import './miniCountController.css';

const MiniCountController = (props) => {
    return (
        <div className={"mini_counter_controller_component"}>
            <div className={"wrapper_for_mini_count_controller_wp_component"}>
                <div className={"wrapper_for_mini_count_controller_component min"}
                    onClick={e => props.decrease()}
                ></div>
                <div className={"wrapper_for_mini_count_controller_component center"}>{props.count}</div>
                <div className={"wrapper_for_mini_count_controller_component max"}
                     onClick={e => props.increase()}
                ></div>
            </div>

        </div>
    );
};

export default MiniCountController;