import React, {useState} from 'react';
import './ReviewsModal.css'
import {addReviews} from "../../../http/contentApi";
import CheckBoxPolitic from "../../UI/checkBoxPolitic/CheckBoxPolitic";

const ReviewsModal = (props) => {

    const reviewsGrade = [1,2,3,4,5,6,7,8,9,10]
    const [selectedScore,setSelectedScore] = useState(5)
    const [stateCheсkBoxReviews, setStateCheсkBoxReviews] = useState(false)

    const [modalContent, setModalContent] = useState({
        email:"",
        name: "",
        reviews: "",
        city: "",
        grade: 5,
    })



   const [errorText,setErrorText] = useState('');


    const closeModal = (onclick) => {
        if((modalContent.email !== '' || modalContent.name !== '' ||
            modalContent.reviews !== '' || modalContent.city !== '') && stateCheсkBoxReviews != false) {
            setErrorText('');

            addReviews(modalContent).then(res => {
                if(res.message) {
                    setModalContent({...modalContent, name:"",email: "",reviews: "", city: "", grade: 5})
                    props.closeClick()
                    props.setOpenModalReviewsSuccess(true)
                    return false;
                }
                setErrorText('Непредвиденная ошибка, обратитесь к администрации.')

            } )

            return false

        }
        setErrorText('Заполните что-то, помимо оценки и примите соглашение')

    }

    return (
        <div className={"ReviewsModal " + props.className}>
            <div className={"reviews_modal_title_and_close"}>
                <span className={"reviews_modal_title"}>Оставьте отзыв о нас</span>
                <div onClick={props.closeClick} className={"reviews_modal_close_parents"}>
                    <div className={"reviews_modal_close"}></div>
                </div>
            </div>

            <div className={"wrapper_modal_reviews_fields"}>
                <div className={"reviews_grade_conteiner"}>
                    <div className={"reviews_grade_title"}>Ваша оценка</div>
                    <div className={"reviews_your_mark"}>
                        {reviewsGrade ?
                            reviewsGrade.map((oneStar) => {
                                return (<div
                                    key={oneStar}
                                    className={selectedScore >= oneStar? "recall_score black" : "recall_score"}
                                    onClick={e => (setSelectedScore(oneStar),setModalContent({...modalContent, grade: oneStar}) )}
                                >
                                    {oneStar}
                                </div>)
                            })
                            :  ""
                        }
                    </div>
                    <div className={"reviews_fields"}>
                        <div className={"reviews_name_fields"}>
                            <span><noindex>E-mail</noindex></span>
                            <input
                                value={modalContent.email}
                                onChange={e =>  setModalContent({...modalContent,email:e.target.value})}
                                className={"user_fields_input"} type="text"/>
                        </div>
                        <div className={"reviews_name_fields"}>
                            <span>Имя</span>
                            <input
                                value={modalContent.name}
                                onChange={e => setModalContent({...modalContent,name:e.target.value})}
                                className={"user_fields_input"} type="text"/>
                        </div>
                        <div className={"reviews_name_fields"}>
                            <span>Отзыв</span>
                            <textarea
                                value={modalContent.reviews}
                                onChange={e => setModalContent({...modalContent,reviews:e.target.value})}
                                className={"user_fields_textarea"} type="text"/>
                        </div>
                        <div className={"reviews_name_fields"}>
                            <span>Город</span>
                            <input
                                value={modalContent.city}
                                onChange={e => setModalContent({...modalContent,city:e.target.value})}
                                className={"user_fields_input"} type="text"/>
                        </div>
                        {/*<div className={"loading_files_modal_wrapper"}>*/}
                        {/*    <div className={"img_load_reviews"}>*/}
                        {/*        <svg width="47" height="38" viewBox="0 0 47 38" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*            <path d="M32 27L24 19L16 27" stroke="black" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>*/}
                        {/*            <path d="M24 19V37" stroke="black" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>*/}
                        {/*            <path d="M40.7799 31.7829C42.7306 30.7194 44.2716 29.0367 45.1597 27.0001C46.0477 24.9636 46.2323 22.6894 45.6843 20.5363C45.1363 18.3832 43.8869 16.4739 42.1333 15.1098C40.3796 13.7457 38.2216 13.0044 35.9999 13.0029H33.4799C32.8745 10.6614 31.7462 8.48757 30.1798 6.64488C28.6134 4.8022 26.6496 3.3386 24.4361 2.36411C22.2226 1.38963 19.817 0.929615 17.4002 1.01866C14.9833 1.10771 12.6181 1.7435 10.4823 2.87823C8.34649 4.01296 6.49574 5.6171 5.06916 7.57006C3.64259 9.52302 2.6773 11.774 2.24588 14.1537C1.81446 16.5334 1.92813 18.98 2.57835 21.3094C3.22856 23.6389 4.3984 25.7906 5.99992 27.6029" stroke="black" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>*/}
                        {/*            <path d="M32 27L24 19L16 27" stroke="black" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>*/}
                        {/*        </svg>*/}
                        {/*    </div>*/}
                        {/*    <div className={"text_wrap_load_reviews"}>*/}
                        {/*        <span className={"load_black_text"}>Выберите или перетащите файл</span>*/}
                        {/*        <span className={"load_grey_text"}>JPG, PNG или PDF, размер не более 10MB</span>*/}
                        {/*    </div>*/}
                        {/*    <div className={"load_button_reviews"}>Выбрать файл</div>*/}
                        {/*</div>*/}
                    </div>
                    <div onClick={e => closeModal()} className={"reviews_submit_button"}>
                        Отправить
                    </div>
                    <div className={"reviews_span_policy"}>

                        <CheckBoxPolitic
                            setStateCheсkBox = {e=> setStateCheсkBoxReviews(e)}
                            stateCheсkBox ={stateCheсkBoxReviews}
                        />
                        {/*<span>Нажимая кнопку “ОТПРАВИТЬ”, вы соглашаетесь с нашей <a rel="noreferrer"  className={"reviews_style_policy"} href={"/personal/privacy-policy/"} target={"_blank"}>политикой кондфиденциальности</a></span>*/}
                    </div>
                    <div className={"error_text"}>{errorText}</div>
                </div>
            </div>

        </div>
    );
};





export default ReviewsModal;