import React, {useContext} from 'react';
import './leftBar.css';
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";


const LeftBar =  (props) => {


    return (
        <div className="logo_conteiner">
            {props.logoFooter ?
                <Link to={"/"} className="" href="/">
                    <img src={process.env.REACT_APP_API_URL+"uploads/images/footer/logo/"+props.logoFooter } alt="YouWanna - интернет-магазин одежды и аксессуаров" />
                </Link>
                : ""
            }

        </div>
    );
};

export default LeftBar;