import React, { useContext, useState } from 'react';
import OneCardsDelivery from "./OneCardsDelivery";
import TwoCardsDelivery from "./TwoCardsDelivery";
import { observer } from "mobx-react-lite";


const PageDelivery = observer((props) => {
    const [heightTracker, setHeightTracker] = useState(false);

    return (
        <div className={"wrapper_info_delivery"}>
            <div
                className={"parent_cards"}
            >

                <div className={"parent_relative"}>
                    {/*    <div id={"parent_absolut"} className={"parent_absolut"}>*/}
                    <div
                        id={"parent_conteiner"}
                        className={"parent_conteiner"}
                    >
                        {
                            props.deliveryNoJson.arrayDeliveryRegion ? props.deliveryNoJson.arrayDeliveryRegion.map((item, index) =>
                                <OneCardsDelivery
                                    item={item}
                                    key={index}
                                    index={index}
                                    setHeightTracker={e => setHeightTracker(e)}
                                    heightTracker={heightTracker}
                                />
                            ) : ""
                        }
                    </div>
                </div>
                {/*</div>*/}


            </div>
            <div className={"two_parents_cards"}>
                <div className={"parent_two_cards"}>

                    {
                        props.deliveryNoJson.arrayDeliveryMoscow ? props.deliveryNoJson.arrayDeliveryMoscow.map((item, index) =>
                            <TwoCardsDelivery
                                item={item}
                                key={index}
                                index={index}
                                setHeightTracker={e => setHeightTracker(e)}
                                heightTracker={heightTracker}
                            />
                        ) : ""
                    }

                </div>
            </div>

        </div>
    );
});

export default PageDelivery;