import {Link} from "react-router-dom";
import {Context} from "../../../index";
import React, {useContext} from 'react';

const ContactUs = () => {

    const {settings} = useContext(Context);
    return (
        <div className={"footer_contact_us footer_mobile_download"}>
            <div className={"social_contact_us"}>
                    <span className={"social_contact_us_seti_text"}>
                        Скачать приложение
                    </span>
                <div className="contact_us_qr">
                    <img className={"qr_image"} src="/files/images/footer/qr-code.svg" alt=""/>
                </div>

                    {
                        settings.projectWidth < 767 ?
                            <div className={"contact_us_link_wrapper mobile_store_list"}>
                                <Link to={'https://redirect.appmetrica.yandex.com/serve/1181988588196267022'} className="store_dowload">
                                    <img src="/files/images/footer/appstore.svg" alt=""/>
                                </Link>
                                <Link to={'https://redirect.appmetrica.yandex.com/serve/1181988588196267022'} className="store_dowload">
                                    <img src="/files/images/footer/google.svg" alt=""/>
                                </Link>
                            </div>

                            :
                            <div className={"contact_us_link_wrapper mobile_store_list"}>
                                <Link to={'https://apps.apple.com/ru/app/you-wanna/id6449462077'} className="store_dowload">
                                    <img src="/files/images/footer/appstore.svg" alt=""/>
                                </Link>
                                <Link to={'https://play.google.com/store/apps/details?id=ru.youwanna.android&hl=ru'} className="store_dowload">
                                    <img src="/files/images/footer/google.svg" alt=""/>
                                </Link>
                            </div>

                    }


            </div>
        </div>
    );
};

export default ContactUs;