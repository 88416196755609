import React, {useEffect, useState} from 'react';
import IsActiveIndicator from "../../UI/isActiveIndicator/IsActiveIndicator";
import {addImages, addPdfFile, deleteImage, deletePdfFile} from "../../../../http/imagesApi";
import OneImageField from "../../UI/oneImageField/OneImageField";
import {saveOneModal} from "../../../../http/settingsApi";


const OneModalComponent = (props) => {

    const [oneModal,setOneModal] = useState(null);
    const [saveState,setSaveState] = useState("");


    const saveOneModalHandler = (object) => {
        saveOneModal(object).then(response => {
            // console.log(response);
            if(response.message) {
                setSaveState(response.message);
                return false;
            }
            if(response.error) {
                setSaveState(response.error)
            }

        })
    }
    useEffect(() => {
        setOneModal(props.item);
    },[])

    const change = () => {
        setOneModal({...oneModal, isActive: !oneModal.isActive})
    }
    // console.log(oneModal)
    const uploadShoptImage = (e,nameField) => {

        let newFile = e.target.files[0];
        let formData = new FormData();

        formData.append("image", newFile);

        if (oneModal[nameField]){
            deleteImage(oneModal[nameField], "modal", null).then(response  => {
                setOneModal({...oneModal,[nameField]: ""})
            })
        }

        addImages(formData,'modal',null, null).then(response => {
            if(response.message) {
                setOneModal({...oneModal,[nameField]:response.fileName})
            }
        })

    }

    const deleteImageShopOnClick = (filename,nameField) => {
        deleteImage(filename, "modal", null).then(response  => {
            setOneModal({...oneModal,[nameField]: ""})             })
    }




    function handleChange(e,nameField) {

        let newFile = e.target.files[0];

        let formData = new FormData();

        formData.append("file", newFile);

        if (oneModal[nameField]){
            deletePdfFile(oneModal[nameField], "modal", null).then(response  => {
                setOneModal({...oneModal,[nameField]: ""})
            })
        }

        addPdfFile(formData,'modal',null, null).then(response => {
            if(response.message) {
                setOneModal({...oneModal,[nameField]:response.fileName})
            }
        })

    }

    const deleteFilePdfClick = (filename,nameField) => {
        deletePdfFile(filename, "modal", null).then(response  => {
            setOneModal({...oneModal,[nameField]: ""})})
    }

    // console.log(oneModal)

    return (
        <div className={"controll_modal_page"}>
            <IsActiveIndicator active={oneModal ? oneModal.isActive : false} change={e => change()}/>
            <div className={"modal_page_id"}>ID:{oneModal ? oneModal.id : ""}</div>
            <span className={"one_modal_page_name_input"}>Название модального окна</span>
            <input
                className={"one_modal_page_input"}
                value={oneModal ? oneModal.name : ""}
                onChange={e => setOneModal({...oneModal, name: e.target.value})}
                type="text"/>
            <span className={"one_modal_page_name_input"}>Текст №1</span>
            <input
                className={"one_modal_page_input"}
                value={oneModal ? oneModal.modal_oneText : ""}
                onChange={e => setOneModal({...oneModal, modal_oneText: e.target.value})}
                type="text"/>
            <span className={"one_modal_page_name_input"}>Текст №2</span>
            <input
                className={"one_modal_page_input"}
                value={oneModal ? oneModal.modal_twoText : ""}
                onChange={e => setOneModal({...oneModal, modal_twoText: e.target.value})}
                type="text"/>
            <span className={"one_modal_page_name_input"}>Промокод</span>
            <input
                className={"one_modal_page_input"}
                value={oneModal ? oneModal.modal_threeText : ""}
                onChange={e => setOneModal({...oneModal, modal_threeText: e.target.value})}
                type="text"/>
            <span className={"one_modal_page_name_input"}>Текст №3</span>
            <input
                className={"one_modal_page_input"}
                value={oneModal ? oneModal.modal_fooText : ""}
                onChange={e => setOneModal({...oneModal, modal_fooText: e.target.value})}
                type="text"/>
            <div className={"one_modal_image_upload_wrapper"}>
                <div className={"one_modal_image_download_container"}>
                    <OneImageField text={"Desctop image"}
                                   image={oneModal ?
                                       process.env.REACT_APP_API_URL+"uploads/images/modal/"+ oneModal.image_desctop : null }
                                   onChange={ e => uploadShoptImage(e,'image_desctop',null)}
                                   deleteImage={e => deleteImageShopOnClick(oneModal.image_desctop, "image_desctop")}
                    />
                </div>
                <div className={"one_modal_image_download_container"}>
                    <OneImageField text={"Mobile image"}
                                   image={oneModal ?
                                       process.env.REACT_APP_API_URL+"uploads/images/modal/"+ oneModal.image_mobile : null }
                                   onChange={ e => uploadShoptImage(e,'image_mobile',null)}
                                   deleteImage={e => deleteImageShopOnClick(oneModal.image_mobile,"image_mobile")}
                    />
                </div>
            </div>
            <div>
                <input type="file"
                       onChange={e => handleChange(e, 'file_modal', null)}/>
                {
                    oneModal && oneModal.file_modal != null && oneModal.file_modal != '' ?
                        <div className={"modal_pfd_file"}>
                            <div>файл загружен</div>
                            <div>{oneModal.file_modal}</div>
                            <a download href={oneModal ? process.env.REACT_APP_API_URL+"uploads/images/modal/"+ oneModal.file_modal : ""} without rel="noopener noreferrer" target="_blank">
                                <button trailingIcon="picture_as_pdf" label="Resume">
                                    PDF
                                </button>
                            </a>
                            <div
                                className={"button_deleted_pdf"}
                                onClick={e => deleteFilePdfClick(oneModal.file_modal,"file_modal")}
                            >
                                удалить
                            </div>
                        </div>
                        :
                        <div className={"modal_pfd_file"}>файл не загружен</div>
                }






            </div>


            <div
                onClick={e => saveOneModalHandler(oneModal)}
                className={"save_btn_modal_page"}>сохранить</div>
            <span className={"save_modal_indicator_text"}>{saveState}</span>

        </div>
    );
};

export default OneModalComponent;