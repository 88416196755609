import React, {useState} from 'react';
import IsActiveIndicator from "../../UI/isActiveIndicator/IsActiveIndicator";
import CompliteRemove from "../../UI/compliteremove/CompliteRemove";
import {removeOnePromo} from "../../../../http/settingsApi";
import moment from 'moment';
import "react-datetime/css/react-datetime.css";

const OnePromo = (props) => {

    const [openInfo,setOpenInfo] = useState("")
    const [removeState,setRemoveState] = useState(false)


    const deleteHandler = (promo) => {
        removeOnePromo(promo).then(response => {

            if(response.message) {
                setRemoveState(false);
                setTimeout(() => {
                    props.setRefreshPage(!props.refreshPage)
                },500)
            }
        })
    }

    return (
        <div key={props.item.id} className={"one_promo_information"}>
            <div className={"one_promo_left_container"}>
                <IsActiveIndicator
                    change={e => props.changeActivePromoHandler()}
                    active={props.item.is_active_promo} />
                <div className="one_promo_info_wrapper">
                    <span className={"one_promo_name_param"}> Название промокода </span> <span className={"one_promo_value_param"}> {props.item.name} </span>
                </div>
                <div className="one_promo_info_wrapper">
                    <span className={"one_promo_name_param"}> Код активации </span> <span className={"one_promo_value_param"}> {props.item.promocode_value} </span>
                </div>
                <div className="one_promo_info_wrapper">
                    <span className={"one_promo_name_param"}> Тип использования </span> <span className={"one_promo_value_param"}> {props.item.one_use ? "одно использование на 1 пользователя" : "многоразовый"} </span>
                </div>
                <div className="one_promo_info_wrapper">
                    <span className={"one_promo_name_param"}> Можно активировать с  </span> <span className={"one_promo_value_param"}> {props.item.start_summ +" руб."} </span>

                </div>
                <div className="one_promo_info_wrapper">
                    <span className={"one_promo_name_param"}> Тип скидки </span> <span className={"one_promo_value_param"}> {props.item.method_of_action === "percent" ? "процентный" : "фиксированный"} </span>

                </div>
                <div className="one_promo_info_wrapper">
                    <span className={"one_promo_name_param"}> Размер скидки </span> <span className={"one_promo_value_param"}> {props.item.method_of_action === "percent" ? props.item.amount_discount+"%" : props.item.amount_discount+" руб"} </span>
                </div>
                <div className={"one_promo_info_wrapper"}>
                    <span className={"one_promo_name_param"}> Кто может использовать </span> <span className={"one_promo_value_param"}> {props.item.auth_use ? "только авторизированные" : "все пользователи"} </span>
                </div>
                {
                    props.item.startDate ?
                        <div className={"one_promo_info_wrapper"}>
                            <span className={"one_promo_name_param"}> Дата начала действия промокода </span> <span className={"one_promo_value_param"}> {moment(props.item.startDate).format('L, H:mm')} </span>
                        </div>
                    : ""
                }
                {
                    props.item.endDate ?
                        <div className={"one_promo_info_wrapper"}>
                            <span className={"one_promo_name_param"}> Дата окончания действия промокода </span> <span className={"one_promo_value_param"}> {moment(props.item.endDate).format('L, H:mm')} </span>
                        </div>
                    : ""
                }
            </div>
            <div className={"one_promo_info"}>
                <div className={"one_promo_info_controll_panel"}>
                    { props.item.category_array[0] ?
                        <span onClick={ e => setOpenInfo("category")} className={"one_promo_controll_btn"}>Категории</span>
                        : ""
                    }
                    { props.item.productst_array[0] ?
                        <span onClick={ e => setOpenInfo("products")} className={"one_promo_controll_btn"}>Лист товаров</span>
                        : ""
                    }
                    { props.item.productst_array[0] || props.item.category_array[0] ?
                        <span onClick={ e => setOpenInfo(null)} className={"one_promo_controll_btn"}>Закрыть все</span>
                        : ""
                    }
                </div>
                {
                    openInfo === "category"  ?
                        <div className={"one_promo_category_list"}>
                            {
                                props.item.category_array.map((item,index) => {
                                    return(<div className={"one_promo_one_list"}>{index+1+") "+item.name}</div>)
                                })
                            }
                        </div> : ""
                }
                {
                    openInfo === "products"  ?
                        <div className={"one_promo_category_list"}>
                            {
                                props.item.productst_array.map((item,index) => {
                                    return(<div className={"one_promo_one_list"}>{index+1+") "+item.name +" цвет: "+ props.currColor(item.colorId)}</div>)
                                })
                            }
                        </div> : ""
                }
            </div>
            <div
                onClick={e => setRemoveState(!removeState)}
                className={"delete_one_promo"}>
                Удалить промокод
            </div>
            <CompliteRemove open={removeState} goRemove={e => deleteHandler(props.item)} canselRemove={e => setRemoveState(false)}  />

        </div>
    );
};

export default OnePromo;