import React, {useContext} from 'react';
import './controllBar.css';

import {observer} from "mobx-react-lite";
import UserItemControllBar from "../../UI/userItemControllBar/UserItemControllBar";
import ExitButton from "../exitButton/ExitButton";
import {Context} from "../../../index";


const ControllBar = observer((props) => {

    const {user, settings} = useContext(Context)
    let curPage = '';
    if(typeof user !='undefined' && typeof user.currentPage != 'undefined'){
        curPage = user.currentPage;
    }

    return (
        <div className={"personal_controll_bar"}>
            <div className={"support_fix_controll_bar"}>
                {/*<div className={"logo_fio_bar"}>*/}
                {/*    <img className={"user_logo_image"} src={"/files/images/logo_user_cabinet.png"} alt=""/>*/}
                {/*    <div className={"fio_container_controll_bar"}>*/}
                {/*        <span className={"user_fio_item"}>{user.user.name ? user.user.name : "Имя"}</span>*/}
                {/*        <span className={"user_fio_item"}>{user.user.surname ? user.user.surname : "Фамилия"}</span>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<span className={"bon_cart_info"}>Бонусная карта №9999999</span>*/}
                <div className="user_pages_controller">

                    {props.barItems.map((oneItem, index) =>

                        <UserItemControllBar
                            key={oneItem.position}
                            oneItem={oneItem}
                            index={index}
                            isActive={curPage == oneItem.toPage ? "active" : ""  }
                            trigerAnimate={props.trigerAnimate}
                            disabled={!oneItem.disabled ? "" : "disabled" }
                            paddingClass={oneItem.padding ? "padding" : ""}


                        />
                    )}

                </div>
                {/*<div className={"exit_wrapper_usercabinet"}>*/}
                {/*    <span className={"exit_text_usercabinet"}>Выход</span>*/}
                {/*    <ExitButton/>*/}
                {/*</div>*/}
            </div>
        </div>
    );
});

export default ControllBar;
