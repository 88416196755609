import React, {useState} from 'react';
import './oneReviews.css';
import {removeOneReviews, updateReviews} from "../../../../../http/contentApi";
import CompliteRemove from "../../../UI/compliteremove/CompliteRemove";
import TrashCont from "../../../UI/trashICont/TrashCont";


const OneReviews = (props) => {

    const maxStars = [1,2,3,4,5,6,7,8,9,10];
    const stars = props.item.stars;
    const [updateButton, setUpdateButton] = useState(false)


    const update = async (item) => {
        const data = await updateReviews(item)
        if(data.message) {
            setUpdateButton(false)
        }
    }

    const removeReviews = async (id) => {
        const data = await removeOneReviews(id);
        if(data.message) {
            setVendettaState(true);
            setTimeout(() => {
                let reduceArray = [...props.reviews];
                reduceArray.splice(props.index,1)
                props.setReviews(reduceArray)
            },800)

        }
    }

    const [vendettaState,setVendettaState] = useState(false)
    const [removeState,setRemove] = useState(false);


    return (
        <div className={vendettaState ? "one_reviews_block v" : "one_reviews_block" }>
         <CompliteRemove open={removeState} canselRemove={e => setRemove(false)} goRemove={ e => removeReviews(props.item.id)} />
            <div className={"controll_panel_one_reviews"}>

                <div className={"info_reviews_dispet"}>
                    <div className={"id_reviews "}>{props.item.id} </div>
                    <div className={"stars_reviews"}>
                        {
                            maxStars.map((oneStar,index) => {
                                 return(<div key={index} className={ stars >= oneStar ? "circle_reviews black" : "circle_reviews"}
                                 onClick={e => (props.setReviews([...props.reviews], [props.reviews[props.index].stars = oneStar]), setUpdateButton(true))}>
                                     {oneStar}
                                 </div>)
                            })
                        }
                    </div>
                </div>
                <div className={"right_block_reviews"}>
                    <div  onClick={e => update(props.item)} className={updateButton ? "reviews_save trigger" : "reviews_save"}>
                        сохранить
                    </div>
                    <div

                        onClick={e => (props.setReviews([...props.reviews], [props.reviews[props.index].moderate = !props.item.moderate]),setUpdateButton(true))}
                        className={props.item.moderate ? "is_active_reviews on" : "is_active_reviews"}>

                        <div className={"circle_active_p"}></div>
                    </div>

                    <div

                        className={"remove_panel"}>
                        <TrashCont onClick={e => setRemove(true)} />
                    </div>

                </div>

            </div>
            <div className={"reviews_field_wrapper"}>
                <span className={"reviews_field_label_name"}>Фальшивая дата</span>
                <input
                    value={props.reviews[props.index].custom_date ? props.reviews[props.index].custom_date : "" }
                    onChange={e => (props.setReviews([...props.reviews], [props.reviews[props.index].custom_date = e.target.value ]),setUpdateButton(true))}
                    className={"reviews_field"} type="text"/>
            </div>
            <div className={"reviews_field_wrapper"}>
                <span className={"reviews_field_label_name"}>email</span>
                <input

                    value={props.reviews[props.index].email_user ? props.reviews[props.index].email_user : "" }
                    onChange={e => (props.setReviews([...props.reviews], [props.reviews[props.index].email_user = e.target.value ]),setUpdateButton(true))}
                    className={"reviews_field"} type="text"/>
            </div>
            <div className={"reviews_field_wrapper"}>
                <span className={"reviews_field_label_name"}>Имя</span>
                <input
                    value={props.reviews[props.index].name_user ? props.reviews[props.index].name_user : "" }
                    onChange={e => (props.setReviews([...props.reviews], [props.reviews[props.index].name_user = e.target.value ]),setUpdateButton(true))}
                    className={"reviews_field"} type="text"/>
            </div>
            <div className={"reviews_field_wrapper"}>
                <span className={"reviews_field_label_name"}>Город</span>
                <input
                    value={props.reviews[props.index].city_user ? props.reviews[props.index].city_user : "" }
                    onChange={e => (props.setReviews([...props.reviews], [props.reviews[props.index].city_user = e.target.value ]),setUpdateButton(true))}
                    className={"reviews_field"} type="text"/>
            </div>
            <div className={"reviews_field_wrapper"}>
                <span className={"reviews_field_label_name"}>Отзыв</span>
                <textarea
                    value={props.reviews[props.index].review_text ? props.reviews[props.index].review_text : "" }
                    onChange={e => (props.setReviews([...props.reviews], [props.reviews[props.index].review_text = e.target.value ]),setUpdateButton(true))}
                    className={"reviews_field"} type="text"/>
            </div>
            <div className={"reviews_field_wrapper"}>
                <span className={"reviews_field_label_name"}>Ответ магазина</span>
                <textarea
                    value={props.reviews[props.index].answer_admin ? props.reviews[props.index].answer_admin : "" }
                    onChange={e => (props.setReviews([...props.reviews], [props.reviews[props.index].answer_admin = e.target.value ]),setUpdateButton(true))}
                    className={"reviews_field"} type="text"/>
            </div>

        </div>
    );
};

export default OneReviews;