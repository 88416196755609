import {$authHost,$host} from "./index";


/////////////All pages controller ////
export const addNewCity = async (name,mapurl) =>  {
    const {data} = await $authHost.post('api/settings/addnewcity', {name, mapurl});
    return data;
}

export const fetchAllCity = async () => {
    const {data} = await $authHost.post('api/settings/fetchcity');
    return data;
}

export const fetchCitiesByQuery = async (name, limit = 10) => {
    const {data} = await $authHost.post('api/settings/fetchcities', {name, limit});
    return data;
}

export const fetchCitysForOrderModal = async (name, limit = 10) => {
    const {data} = await $host.post('api/settings/fetchordercity', {name, limit});
    return data
}

export const updateOneCity = async (id,name,mapurl,activeForModal,sortForModal)=> {
    const {data} = await $authHost.post('api/settings/updateonecity', {id,name,mapurl,activeForModal,sortForModal})
    return data;
}

export const saveRunline = async (text,active,mod) => {
    const {data} = await $authHost.post('api/settings/savesettingsrunline',{text,active,mod})
    return data;
}

export const fetchSettingsData = async () => {
    const {data} = await $authHost.post('api/settings/fetchdata')
    return data;
}
export const fetchHeaderAndFooter = async (user) => {
    const {data} = await $host.post('api/settings/fetchheaderandfooter',{user})
    return data;
}
export const saveSettingsContainer = async (header) => {
    const {data} = await $authHost.post("api/settings/updatesettingscontainer", {header});
    return data;
}

export const saveFooterContent = async (footer, footerContent) => {
    const {data} = await $authHost.post("api/settings/savefooter", {footer, footerContent});
    return data;
}

//////////////SOCIAL//////////////
export const addNewSocial = async (fields) =>  {
    const {data} = await $authHost.post('api/settings/addnewsocial', {fields});
    return data;
}

export const fetchAllSocial = async (fields) =>  {
    const {data} = await $authHost.post('api/settings/fetchallsocial', {fields});
    return data;
}

export const updateOneSocial = async (fields) =>  {
    const {data} = await $authHost.post('api/settings/updateonesocial', {fields});
    return data;
}
export const deleteOneSocial = async (id) =>  {
    const {data} = await $authHost.post('api/settings/deleteonesocial', {id});
    return data;
}

////////// Delivery functional /////////
export const searchDependencyForDelivery = async (method,value) => {
    const {data} = await $authHost.post('api/settings/searchdependency',{method,value});
    return data;
}

export const addNewDeliveryMethod = async (fields) => {
    const {data} = await $authHost.post('api/settings/addnewdeliverymethod', {fields});
    return data;
}

export const fetchAllDeliveryMethods = async () => {
    const {data} = await $authHost.post('api/settings/fetchalldeliverymethods');
    return data;
}

export const changeActiveStatusOneDelivery = async (id,status) => {
    const {data} = await $authHost.post('api/settings/changeactiveonedelivery', {id,status} )
    return data;
}

export const saveNewContentDelivery = async  (fields) => {
    const {data} = await $authHost.post('api/settings/savenewcontentdelivery', {fields})
    return data;
}

export const deleteDeliveryMethod = async  (item) => {
    const {data} = await $authHost.post('api/settings/deletedeliverymethod', {item})
    return data;
}

export const fecthPaymentMethods = async () => {
    const {data} = await $authHost.post('api/settings/fetchpaymentmethods')
    return data;
}

export const addNewPromo = async (promo) => {
    const {data} = await $authHost.post('api/settings/addnewpromo',{promo})
    return data;
}

export const removeOnePromo = async (promo) => {
    const {data} = await $authHost.post('api/settings/removeonepromo',{promo})
    return data;
}

export const fetchAllPromo = async () => {
    const {data} = await $authHost.post('api/settings/fetchallpromo')
    return  data;
}

export const changeActivePromo = async (promo) => {
    const {data} = await $authHost.post('api/settings/changeactivepromo',{promo})
    return  data;
}
export const createNewStatus = async (status) => {
    const {data} = await $authHost.post('api/settings/createnewstatus',{status})
    return data;
}

export const updateStatus = async (status) => {
    const {data} = await $authHost.post('api/settings/updatestatus',{status})
    return data;
}

//#################### Modal PopUp ##################//
export const subscribeNewsletter  = async (email, id, deviceUUID, userAgent, method) => {
    const {data} = await $host.post('api/user/subscribeuser', {email, id, deviceUUID, userAgent, method})
    return data;
}
export const getAllModals = async () => {
    const {data} = await $authHost.post('api/settings/getallmodals' )
    return data;
}

export const saveOneModal = async (object) => {
    const {data} = await $authHost.post('api/settings/saveonemodal',{object})
    return data;
}
export const getOneModal = async (id) => {
    const {data} = await $host.post('api/settings/getonemodal',{id})
    return data;
}

export const getSitemapUrls = async () => {
    try {
        const response = await $authHost.get('/api/settings/sitemap-urls')

        return {success: true, data: response.data}
    } catch (error) {
        return {success: false, message: error.message}
    }
}

export const addSitemapUrl = async (url) => {
    try {
        const response = await $authHost.post('/api/settings/sitemap-urls', {url})

        return {success: true, data: response.data}
    } catch (error) {
        return {success: false, message: error.message}
    }
}

export const deleteSitemapUrl = async (id) => {
    try {
        const response = await $authHost.delete('/api/settings/sitemap-urls', {data: {id}})

        return {success: true, data: response.data}
    } catch (error) {
        return {success: false, message: error.message}
    }
}

export const updateSitemapUrl = async (id, url) => {
    try {
        const response = await $authHost.post('/api/settings/update-sitemap-urls', {id, url})

        return {success: true, data: response.data}
    } catch (error) {
        return {success: false, message: error.message}
    }
}

export const generateExcelExport = async (params) => {
    const {data} = await $authHost.post('/api/settings/generate-excel-export', {params} )

    return data;
}

export const getRedirects = async (limit, page) => {
    try {
        const response = await $authHost.get('/api/settings/redirects/', {params: {limit, page}})

        return {success: true, data: response.data}
    } catch (error) {
        return {success: false, message: error.message}
    }
}

export const createRedirect = async (from, to) => {
    try {
        const response = await $authHost.post('/api/settings/redirect/', {from, to})

        return {success: true, data: response.data}
    } catch (error) {
        return {success: false, message: error.message}
    }
}

export const updateRedirect = async (id, from, to) => {
    try {
        const response = await $authHost.post('/api/settings/update-redirect/', {id, from, to})

        return {success: true, data: response.data}
    } catch (error) {
        return {success: false, message: error.message}
    }
}

export const deleteRedirect = async (id) => {
    try {
        const response = await $authHost.delete('/api/settings/redirect', {data: {id}})

        return {success: true, data: response.data}//
    } catch (error) {
        return {success: false, message: error.message}
    }
}