import React, {useEffect, useMemo, useState} from 'react';
import './homePage.css'
import {getOnePageContent, getOneStock, updateContentPage} from "../../../../http/contentApi";
import OneImageField from "../../UI/oneImageField/OneImageField";
import {addImages, deleteImage} from "../../../../http/imagesApi";
import IsActiveIndicator from "../../UI/isActiveIndicator/IsActiveIndicator";
import index from "jwt-decode";
import {useParams} from "react-router-dom";
import {getchildsforslider} from "../../../../http/catalogApi";


const AdmHomePage = () => {

    let { itemId } = useParams();
    const [pageContentHome,setPageContentHome] = useState({});
    const [noJsonContentHome,setNoJsonContentHome] = useState({})
    const [homeSaveTriger, setHomeSaveTriger] =useState(false)
    const [updateBannerSaveTriger, setUpdateBannerSaveTriger] = useState(false)
    const [stateSwitchBlock, setStateSwitchBlock] = useState('image');
    const [sliderImages, setSliderImages] = useState([]);
    const imageTemplate = {id: null, image: ""}

    useEffect(() => {
        getOnePageContent(8).then(response => {
            setPageContentHome(response)
            let first = [];
            if (response.slider_images !== null) {
                first = response.slider_images.first;
            }
            addNewImageField(Object.assign({}, imageTemplate), first)
            if(response.content) {
                setNoJsonContentHome(JSON.parse(response.content))
                return false;
            }
            setNoJsonContentHome({

                oneBanner: {
                    img_pc: '',
                    img_tablet: '',
                    img_mobile: '',
                    video_pc: '',
                    video_tablet: '',
                    video_mobile: '',
                    titleBanner: '',
                    textBanner: '',
                    buttonName: '',
                    linkButton: '',
                    naming: "",
                    bannerStatus: 'image'
                },
                twoBanner:{
                    img_pc: '',
                    img_tablet: '',
                    img_mobile: '',
                    titleBanner: '',
                    buttonName: '',
                    linkButton: '',
                    naming: "",
                },
                threeBanner: {
                    img_pc: '',
                    img_tablet: '',
                    img_mobile: '',
                    titleBanner: '',
                    buttonName: '',
                    linkButton: '',
                    shadowleft: true,
                },
                fourthBanner: {
                    img_pc: '',
                    img_tablet: '',
                    img_mobile: '',
                    titleBanner: '',
                    buttonName: '',
                    linkButton: '',
                    shadowright: true
                },
                nameBlockNew: '',
                titleFormHome: '',
                saleAndTrends: {
                    commonName: '',
                    nameBlockSale: '',
                    nameBlockTrends: '',
                    trendsHome:[],
                    SaleHome:[],
                },
                leftBlockText:[],
                rightBlockText:[],

            });
        } );
    },[]);

    useEffect(() => {
        setPageContentHome({...pageContentHome, content:JSON.stringify(noJsonContentHome)});
        bannerStatusSwitcher(noJsonContentHome, 'oneBanner');
    }, [noJsonContentHome]);

    const bannerStatusSwitcher = (object, bannerName) => {
        if (object[bannerName] !== undefined) {
            if (object[bannerName].bannerStatus === 'slider') {
                setStateSwitchBlock('slider');
            }
            if (object[bannerName].bannerStatus === 'image') {
                setStateSwitchBlock('image');
            }
            if (object[bannerName].bannerStatus === 'video') {
                setStateSwitchBlock('video');
            }
        }
    };


    const saveHomeSeoContent = (date) => {
        updateContentPage(date).then(response => {
            setHomeSaveTriger(false)
        } )
    }

    const uploadImageHomePageSlider = (e, objectName, nameField, fieldType, index = null) => {
        let newFile = e.target.files[0];
        let formData = new FormData();
        formData.append(nameField, newFile);
        addImages(formData, 'homepage', objectName, null).then(response => {
            if(response.message) {
                switch (fieldType) {
                    case 'slider': {
                        sliderImages[index].image = response.fileName;
                        let filterImages = sliderImages.filter(item => item.image)
                        setPageContentHome({...pageContentHome, slider_images: {first: filterImages}})
                        addNewImageField(Object.assign({}, imageTemplate), sliderImages)
                        break;
                    }
                }
            }
        });
    }

    const setSliderHomePageHandler = (value, objectName, fieldName) => {
        setStateSwitchBlock(value);
        setNoJsonContentHome({...noJsonContentHome}, [noJsonContentHome.oneBanner.bannerStatus = value]);
        setUpdateBannerSaveTriger(true);
    }

    const deleteImageHomePageSlider = (filename, objectName, fieldType, id = null) => {
        deleteImage(filename, "homepage", objectName).then(response  => {
            switch (fieldType) {
                case 'slider': {
                    let newImages = sliderImages.filter(item => item.id !== id);
                    newImages.pop();
                    setPageContentHome({...pageContentHome, slider_images: {first: newImages}})
                    setSliderImages(newImages);
                    addNewImageField(Object.assign({}, imageTemplate), newImages);
                    break;
                }
            }
        });
    }

    const addNewImageField = (object, images) => {
        let reduceOtherImages = []
        if(images !== null) {
            reduceOtherImages = [...images];
            if (images.length) {
                let maxId = images.reduce((acc, curr) => acc.b > curr.b ? acc : curr).id;
                reduceOtherImages.push({...object, id:maxId+1});
                setSliderImages(reduceOtherImages)
            } else {
                reduceOtherImages.push({...object, id:1});
                setSliderImages(reduceOtherImages)
            }
        }
    }

    const uploadImageHomePage = (e,objectName ,nameField, videoAlowed = false) => {

        let newFile = e.target.files[0];
        let formData = new FormData();

        formData.append("image", newFile);
        formData.append("videoAlowed", videoAlowed)

        if (noJsonContentHome[objectName][nameField]){
            deleteImage([objectName][nameField], "homepage", objectName).then(response  => {
                setNoJsonContentHome({...noJsonContentHome}, [noJsonContentHome[objectName][nameField] = ""])
            })
        }

        addImages(formData,'homepage',objectName, null).then(response => {
            if(response.message) {
                setNoJsonContentHome({...noJsonContentHome}, [noJsonContentHome[objectName][nameField] = response.fileName])
                setUpdateBannerSaveTriger(true)
            }
        })

    }

    const deleteImageHomeBanner = (filename,objectName, nameField, videoAlowed = false) => {
        deleteImage(noJsonContentHome[objectName][nameField], "homepage", objectName).then(response  => {
            setNoJsonContentHome({...noJsonContentHome}, [noJsonContentHome[objectName][nameField] = ""])
            setUpdateBannerSaveTriger(true)
        })
    }

    const fieldsHanlerHomePage = (e,objectName, fieldName) => {
        setNoJsonContentHome({...noJsonContentHome}, [noJsonContentHome[objectName][fieldName] = e.target.value])
        setUpdateBannerSaveTriger(true)
    }


    const activationElementHome = (fildName) => {
        setNoJsonContentHome({...noJsonContentHome, [fildName]: !noJsonContentHome[fildName]})
        setUpdateBannerSaveTriger(true)
    }

    const activationTenBanner = (fildName, objectName) => {
        setNoJsonContentHome({...noJsonContentHome}, [noJsonContentHome[objectName][fildName]= !noJsonContentHome[objectName][fildName]])
        setUpdateBannerSaveTriger(true)
    }

    const oneBlockTitleHome =  {name:"", type: "title"};
    const oneBlockTextHome =  {value:"", type: "text"};
    const descriptionIconsText =  { arrayIcons: [{name:"", img: "" ,link:""}, {name:"", img: "" ,link:""}, {name:"", img: "" ,link:""}, {name:"", img: "" ,link:""}], type: "icons"};

    const addNewTextHome = (object, objectName) => {
        let reduceNewText = {...noJsonContentHome}
        reduceNewText[objectName].push(object)
        setNoJsonContentHome(reduceNewText)
    }

    const fieldsHomeText = (e,fieldName, objectName, index) => {
        setNoJsonContentHome({...noJsonContentHome}, [noJsonContentHome[objectName][index][fieldName] = e.target.value])
    }
    const fieldsHomeIcons = (e,fieldName, objectName, childObject,indexRight, indexIcons) => {
        setNoJsonContentHome({...noJsonContentHome}, [noJsonContentHome[objectName][indexRight][childObject][indexIcons][fieldName] = e.target.value])
    }

    const uploadImageIcons = (e,objectName ,nameField, childObject, indexRight, indexIcons) => {

        let newFile = e.target.files[0];
        let formData = new FormData();

        formData.append("image", newFile);

        if (noJsonContentHome[objectName][indexRight][childObject][indexIcons][nameField]){
            deleteImage(noJsonContentHome[objectName][indexRight][childObject][indexIcons][nameField], "homepage", objectName).then(response  => {
                setNoJsonContentHome({...noJsonContentHome}, [noJsonContentHome[objectName][indexRight][childObject][indexIcons][nameField] = ""])
            })
        }

        addImages(formData,'homepage',objectName, null).then(response => {
            if(response.message) {
                setNoJsonContentHome({...noJsonContentHome},  [noJsonContentHome[objectName][indexRight][childObject][indexIcons][nameField] = response.fileName])
                setUpdateBannerSaveTriger(true)
            }
        })

    }

    const deleteImageIcons = (filename,objectName, nameField, childObject, indexRight, indexIcons) => {
        deleteImage(noJsonContentHome[objectName][indexRight][childObject][indexIcons][nameField], "homepage", objectName).then(response  => {
            setNoJsonContentHome({...noJsonContentHome},  [noJsonContentHome[objectName][indexRight][childObject][indexIcons][nameField] = ""])
            setUpdateBannerSaveTriger(true)
        })
    }


    const delFieldsHome = (objectName,indexFields) => {
        setNoJsonContentHome({...noJsonContentHome}, [noJsonContentHome[objectName].splice(indexFields,1)])
    }

    const fieldsNameTitle = (e, fieldName) => {
        setNoJsonContentHome({...noJsonContentHome, [fieldName]: e.target.value})
        setUpdateBannerSaveTriger(true)
    }

    const oneBlockTrading =  {name:"", img: "" ,link:""};

    const addNewTrading = (object, objectName, childObject) => {
        let reduceNewText = {...noJsonContentHome}
        reduceNewText[objectName][childObject].push(object)
        setNoJsonContentHome(reduceNewText)
    }
    const fieldsTrading = (e,fieldName, objectName, childObject, index) => {
        setNoJsonContentHome({...noJsonContentHome}, [noJsonContentHome[objectName][childObject][index][fieldName] = e.target.value])
    }


    const uploadImageTrading = (e,objectName ,nameField, childObject, index) => {

        let newFile = e.target.files[0];
        let formData = new FormData();

        formData.append("image", newFile);

        if (noJsonContentHome[objectName][childObject][index][nameField]){
            deleteImage(noJsonContentHome[objectName][childObject][index][nameField], "homepage", objectName).then(response  => {
                setNoJsonContentHome({...noJsonContentHome}, [noJsonContentHome[objectName][childObject][index][nameField] = ""])
            })
        }

        addImages(formData,'homepage',objectName, null).then(response => {
            if(response.message) {
                setNoJsonContentHome({...noJsonContentHome},  [noJsonContentHome[objectName][childObject][index][nameField] = response.fileName])
                setUpdateBannerSaveTriger(true)
            }
        })

    }

    const deleteImageTrading = (filename,objectName, nameField, childObject, index) => {
        deleteImage(noJsonContentHome[objectName][childObject][index][nameField], "homepage", objectName).then(response  => {
            setNoJsonContentHome({...noJsonContentHome},  [noJsonContentHome[objectName][childObject][index][nameField] = ""])
            setUpdateBannerSaveTriger(true)
        })
    }

    const delTradingFields = (objectName, objectChild, indexFields) => {
        setNoJsonContentHome({...noJsonContentHome}, [noJsonContentHome[objectName][objectChild].splice(indexFields,1)])
    }


    const updatePAgeHome = (pageContentHome) => {
        updateContentPage(pageContentHome).then(response => {
            setUpdateBannerSaveTriger(false);
            if (response && !response.error) {
                window.location.reload();
            }
        } )
    }

    return (
        <div className={"adm_page_home"}>
            <div className={"title_adm_home"}>
                Главная страница
            </div>
            <div className={"content_wrapper_home_adm"}>
                <div className={"seo_content_home"}>

                    <div className={"one_field_vacancy_wrapper"}>
                        <span className={"one_field_name_label"}>Имя страницы</span>
                        <input
                            value={pageContentHome.name ? pageContentHome.name : ""}
                            onChange={e => (setPageContentHome({...pageContentHome, name: e.target.value}),
                                    setHomeSaveTriger(true)
                            )}
                            className={"vacancy_field_s"} type="text"/>
                    </div>
                    <div className={"one_field_vacancy_wrapper"}>
                        <span className={"one_field_name_label"}>Title</span>
                        <input
                            value={pageContentHome.title ? pageContentHome.title : ""}
                            onChange={e => (setPageContentHome({...pageContentHome, title: e.target.value}),
                                    setHomeSaveTriger(true)
                            )}
                            className={"vacancy_field_s"} type="text"/>
                    </div>
                    <div className={"one_field_vacancy_wrapper"}>
                        <span className={"one_field_name_label"}>Description</span>
                        <input
                            value={pageContentHome.description ? pageContentHome.description : ""}
                            onChange={e => (setPageContentHome({...pageContentHome, description: e.target.value}),
                                    setHomeSaveTriger(true)
                            )}
                            className={"vacancy_field_s"} type="text"/>
                    </div>
                           <span
                               onClick={e => saveHomeSeoContent(pageContentHome)}
                               className={homeSaveTriger ? "save_content_vacancy trig" : "save_content_vacancy"}>
                            Сохранить
                            </span>
                </div>

                <div className={"container_banner_home"}>
                    <div className={"img_home_wrap"}>
                        <div className={"name_banner"}>
                            Первый баннер
                        </div>

                        {
                            noJsonContentHome.oneBanner ?
                                <div className={"one_banner_wrapper"}>
                                    <div className={"fields_banner_admhome"}>
                                        <div className={"banner_fields_container"}>
                                            <span className="home_field_label_name">Заголовок баннера</span>
                                            <input
                                                value={noJsonContentHome.oneBanner.titleBanner ? noJsonContentHome.oneBanner.titleBanner : ""}
                                                onChange={e => fieldsHanlerHomePage(e,"oneBanner","titleBanner")}
                                                className="home_adm_field" type="text" />
                                        </div>
                                        <div className={"banner_fields_container"}>
                                            <span className="home_field_label_name">Текст под заголовком</span>
                                            <input
                                                value={noJsonContentHome.oneBanner.textBanner ? noJsonContentHome.oneBanner.textBanner : ""}
                                                onChange={e => fieldsHanlerHomePage(e,"oneBanner","textBanner")}
                                                className="home_adm_field" type="text" />
                                        </div>
                                        <div className={"banner_fields_container"}>
                                            <span className="home_field_label_name">Текст кнопки</span>
                                            <input
                                                value={noJsonContentHome.oneBanner.buttonName ? noJsonContentHome.oneBanner.buttonName : ""}
                                                onChange={e => fieldsHanlerHomePage(e,"oneBanner","buttonName")}
                                                className="home_adm_field" type="text" />
                                        </div>
                                        <div className={"banner_fields_container"}>
                                            <span className="home_field_label_name">Ссылка на страницу</span>
                                            <input
                                                value={noJsonContentHome.oneBanner.linkButton ? noJsonContentHome.oneBanner.linkButton : ""}
                                                onChange={e => fieldsHanlerHomePage(e,"oneBanner","linkButton")}
                                                className="home_adm_field" type="text" />
                                        </div>
                                        <div className={"banner_fields_container"}>
                                            <span className="home_field_label_name">Текст копирайта</span>
                                            <input
                                                value={noJsonContentHome.oneBanner.naming ? noJsonContentHome.oneBanner.naming : ""}
                                                onChange={e => fieldsHanlerHomePage(e,"oneBanner","naming")}
                                                className="home_adm_field" type="text" />
                                        </div>
                                        <div className={"banner_fields_container hex"}>
                                            <span className="home_field_label_name"> Цвет заголовка: </span>
                                            <div className='hex_container'>
                                                <input
                                                    value={noJsonContentHome.oneBanner.titleHex ? noJsonContentHome.oneBanner.titleHex : ""}
                                                    onChange={e => fieldsHanlerHomePage(e,"oneBanner","titleHex")}
                                                    className={"home_adm_field hex"} type="text"/>
                                                <div style={{background:noJsonContentHome.oneBanner.titleHex}} className={"banner_hex_preview"}> </div>
                                            </div>
                                        </div>
                                        <div className={"banner_fields_container hex"}>
                                            <span className="home_field_label_name"> Цвет кнопки при наведении (hex): </span>
                                            <div className='hex_container'>
                                                <input
                                                    value={noJsonContentHome.oneBanner.buttonHover ? noJsonContentHome.oneBanner.buttonHover : ""}
                                                    onChange={e => fieldsHanlerHomePage(e,"oneBanner","buttonHover")}
                                                    className={"home_adm_field hex"} type="text"/>
                                                <div style={{background:noJsonContentHome.oneBanner.buttonHover}} className={"banner_hex_preview"}> </div>
                                            </div>
                                        </div>
                                        <div className={"banner_fields_container hex"}>
                                            <span className="home_field_label_name"> Цвет текста и рамки конпки (hex): </span>
                                            <div className='hex_container'>
                                                <input
                                                    value={noJsonContentHome.oneBanner.buttonText ? noJsonContentHome.oneBanner.buttonText : ""}
                                                    onChange={e => fieldsHanlerHomePage(e,"oneBanner","buttonText")}
                                                    className={"home_adm_field hex"} type="text"/>
                                                <div style={{background:noJsonContentHome.oneBanner.buttonText}} className={"banner_hex_preview"}> </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"switch_and_arrows_home"}>
                                            <span className={"field_info"}>Выберите и сохраните режим работы баннера:</span>
                                            {
                                                <div className={"adm_page_home_switch"}>
                                                    <div
                                                        onClick={e => setSliderHomePageHandler('image')}
                                                        className={stateSwitchBlock === 'image' ? "switch_blocks active" : "switch_blocks"}
                                                    >
                                                        {'Картинки'}
                                                    </div>

                                                    <div
                                                        onClick={e => setSliderHomePageHandler('slider')}
                                                        className={stateSwitchBlock === 'slider' ? "switch_blocks slider active" : "switch_blocks slider"}
                                                    >
                                                        {'Слайдер'}
                                                    </div>

                                                    <div
                                                        onClick={e => setSliderHomePageHandler('video')}
                                                        className={stateSwitchBlock === 'video' ? "switch_blocks video active" : "switch_blocks video"}
                                                    >
                                                        {'Видео'}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div>
                                            <div>
                                                {
                                                    stateSwitchBlock === 'image' ?
                                                        <div className={"img_banner_admhome"}>
                                                            <div className={"one_img_block"}>
                                                                <OneImageField
                                                                    text={"Десктоп"}
                                                                    image={noJsonContentHome.oneBanner.img_pc ?
                                                                        process.env.REACT_APP_API_URL+"uploads/images/homepage/oneBanner/"+noJsonContentHome.oneBanner.img_pc  : null }
                                                                    onChange={ e => uploadImageHomePage(e,'oneBanner','img_pc')}
                                                                    deleteImage={ e => deleteImageHomeBanner(e,'oneBanner', 'img_pc')}
                                                                />
                                                                <div className={"photo_resolution"}>
                                                                    Версия для ПК: 1920*1080
                                                                </div>
                                                            </div>

                                                            <div className={"one_img_block"}>
                                                                <OneImageField
                                                                    text={"Планшет"}
                                                                    image={noJsonContentHome.oneBanner.img_tablet ?
                                                                        process.env.REACT_APP_API_URL+"uploads/images/homepage/oneBanner/"+noJsonContentHome.oneBanner.img_tablet  : null }
                                                                    onChange={ e => uploadImageHomePage(e,'oneBanner','img_tablet')}
                                                                    deleteImage={ e => deleteImageHomeBanner(e,'oneBanner', 'img_tablet')}
                                                                />
                                                                <div className={"photo_resolution"}>
                                                                    Версия для планшета: 768*1024
                                                                </div>
                                                            </div>

                                                            <div className={"one_img_block"}>
                                                                <OneImageField
                                                                    text={"Смартфон"}
                                                                    image={noJsonContentHome.oneBanner.img_mobile ?
                                                                        process.env.REACT_APP_API_URL+"uploads/images/homepage/oneBanner/"+noJsonContentHome.oneBanner.img_mobile  : null }
                                                                    onChange={ e => uploadImageHomePage(e,'oneBanner', 'img_mobile')}
                                                                    deleteImage={ e => deleteImageHomeBanner(e,'oneBanner', 'img_mobile')}
                                                                />
                                                                <div className={"photo_resolution"}>
                                                                    Версия для телефона: 451*800
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''
                                                }
                                            </div>
                                            <div className={"img_banner_slider"}>
                                                {
                                                    stateSwitchBlock === 'slider' ?
                                                        <div className={"admin_inputs_wrapper"}>
                                                            <div className='stock_slider_images'>
                                                                {
                                                                    sliderImages.map((item, index) => {
                                                                        return (
                                                                            <div className='stock_slider_one_image'>
                                                                                <OneImageField
                                                                                    text={"Изображение"}
                                                                                    image={item.image ?
                                                                                        process.env.REACT_APP_API_URL+"uploads/images/homepage/first/"+item.image  : null }
                                                                                    onChange={ e => uploadImageHomePageSlider(e, 'first', 'image', 'slider', index)}
                                                                                    deleteImage={ e => deleteImageHomePageSlider(item.image, 'first', 'slider', item.id)}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        : ''
                                                }
                                            </div>
                                            <div>
                                                {
                                                    stateSwitchBlock === 'video' ?
                                                        <div className={"img_banner_admhome"}>
                                                            <div className={"one_img_block"}>
                                                                <OneImageField
                                                                    text={"Десктоп"}
                                                                    image={noJsonContentHome.oneBanner.video_pc ?
                                                                        '/files/images/icon_video.png'  : null }
                                                                    onChange={ e => uploadImageHomePage(e,'oneBanner','video_pc', true)}
                                                                    deleteImage={ e => deleteImageHomeBanner(e,'oneBanner', 'video_pc', true)}
                                                                />
                                                                <div className={"photo_resolution"}>
                                                                    Версия для ПК: 1920*1080
                                                                </div>
                                                            </div>

                                                            <div className={"one_img_block"}>
                                                                <OneImageField
                                                                    text={"Планшет"}
                                                                    image={noJsonContentHome.oneBanner.video_tablet ?
                                                                        '/files/images/icon_video.png'  : null }
                                                                    onChange={ e => uploadImageHomePage(e,'oneBanner','video_tablet', true)}
                                                                    deleteImage={ e => deleteImageHomeBanner(e,'oneBanner', 'video_tablet', true)}
                                                                />
                                                                <div className={"photo_resolution"}>
                                                                    Версия для планшета: 768*1024
                                                                </div>
                                                            </div>

                                                            <div className={"one_img_block"}>
                                                                <OneImageField
                                                                    text={"Смартфон"}
                                                                    image={noJsonContentHome.oneBanner.video_mobile ?
                                                                        '/files/images/icon_video.png'  : null }
                                                                    onChange={ e => uploadImageHomePage(e,'oneBanner', 'video_mobile', true)}
                                                                    deleteImage={ e => deleteImageHomeBanner(e,'oneBanner', 'video_mobile', true)}
                                                                />
                                                                <div className={"photo_resolution"}>
                                                                    Версия для телефона: 451*800
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''

                        }


                        <div className={"name_banner"}>
                            Второй баннер
                        </div>
                        {
                            noJsonContentHome.twoBanner ?
                                <div className={"one_banner_wrapper"}>
                                    <div className={"fields_banner_admhome"}>
                                        <div className={"banner_fields_container"}>
                                            <span className="home_field_label_name">Текст баннера</span>
                                            <input
                                                value={noJsonContentHome.twoBanner.titleBanner ? noJsonContentHome.twoBanner.titleBanner : ""}
                                                onChange={e => fieldsHanlerHomePage(e,"twoBanner","titleBanner")}
                                                className="home_adm_field" type="text" />
                                        </div>
                                        <div className={"banner_fields_container"}>
                                            <span className="home_field_label_name">Текст кнопки</span>
                                            <input
                                                value={noJsonContentHome.twoBanner.buttonName ? noJsonContentHome.twoBanner.buttonName : ""}
                                                onChange={e => fieldsHanlerHomePage(e,"twoBanner","buttonName")}
                                                className="home_adm_field" type="text" />
                                        </div>
                                        <div className={"banner_fields_container"}>
                                            <span className="home_field_label_name">Ссылка на страницу</span>
                                            <input
                                                value={noJsonContentHome.twoBanner.linkButton ? noJsonContentHome.twoBanner.linkButton : ""}
                                                onChange={e => fieldsHanlerHomePage(e,"twoBanner","linkButton")}
                                                className="home_adm_field" type="text" />
                                        </div>
                                        <div className={"banner_fields_container"}>
                                            <span className="home_field_label_name">Текст копирайта</span>
                                            <input
                                                value={noJsonContentHome.twoBanner.naming ? noJsonContentHome.twoBanner.naming : ""}
                                                onChange={e => fieldsHanlerHomePage(e,"twoBanner","naming")}
                                                className="home_adm_field" type="text" />
                                        </div>
                                        <div className={"banner_fields_container hex"}>
                                            <span className="home_field_label_name"> Цвет заголовка: </span>
                                            <div className='hex_container'>
                                                <input
                                                    value={noJsonContentHome.twoBanner.titleHex ? noJsonContentHome.twoBanner.titleHex : ""}
                                                    onChange={e => fieldsHanlerHomePage(e,"twoBanner","titleHex")}
                                                    className={"home_adm_field hex"} type="text"/>
                                                <div style={{background:noJsonContentHome.twoBanner.titleHex}} className={"banner_hex_preview"}> </div>
                                            </div>
                                        </div>
                                        <div className={"banner_fields_container hex"}>
                                            <span className="home_field_label_name"> Цвет кнопки при наведении (hex): </span>
                                            <div className='hex_container'>
                                                <input
                                                    value={noJsonContentHome.twoBanner.buttonHover ? noJsonContentHome.twoBanner.buttonHover : ""}
                                                    onChange={e => fieldsHanlerHomePage(e,"twoBanner","buttonHover")}
                                                    className={"home_adm_field hex"} type="text"/>
                                                <div style={{background:noJsonContentHome.twoBanner.buttonHover}} className={"banner_hex_preview"}> </div>
                                            </div>
                                        </div>
                                        <div className={"banner_fields_container hex"}>
                                            <span className="home_field_label_name"> Цвет текста и рамки конпки (hex): </span>
                                            <div className='hex_container'>
                                                <input
                                                    value={noJsonContentHome.twoBanner.buttonText ? noJsonContentHome.twoBanner.buttonText : ""}
                                                    onChange={e => fieldsHanlerHomePage(e,"twoBanner","buttonText")}
                                                    className={"home_adm_field hex"} type="text"/>
                                                <div style={{background:noJsonContentHome.twoBanner.buttonText}} className={"banner_hex_preview"}> </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className={"img_banner_admhome"}>
                                        <div className={"one_img_block"}>
                                            <OneImageField
                                                text={"Десктоп"}
                                                image={noJsonContentHome.twoBanner.img_pc ?
                                                    process.env.REACT_APP_API_URL+"uploads/images/homepage/twoBanner/"+noJsonContentHome.twoBanner.img_pc  : null }
                                                onChange={ e => uploadImageHomePage(e,'twoBanner','img_pc')}
                                                deleteImage={ e => deleteImageHomeBanner(e,'twoBanner', 'img_pc')}
                                            />
                                            <div className={"photo_resolution"}>
                                                Версия для ПК: 1920*1080
                                            </div>
                                        </div>

                                        <div className={"one_img_block"}>
                                            <OneImageField
                                                text={"Планшет"}
                                                image={noJsonContentHome.twoBanner.img_tablet ?
                                                    process.env.REACT_APP_API_URL+"uploads/images/homepage/twoBanner/"+noJsonContentHome.twoBanner.img_tablet  : null }
                                                onChange={ e => uploadImageHomePage(e,'twoBanner','img_tablet')}
                                                deleteImage={ e => deleteImageHomeBanner(e,'twoBanner', 'img_tablet')}
                                            />
                                            <div className={"photo_resolution"}>
                                                Версия для планшета: 768*1024
                                            </div>
                                        </div>

                                        <div className={"one_img_block"}>
                                            <OneImageField
                                                text={"Смартфон"}
                                                image={noJsonContentHome.twoBanner.img_mobile ?
                                                    process.env.REACT_APP_API_URL+"uploads/images/homepage/twoBanner/"+noJsonContentHome.twoBanner.img_mobile  : null }
                                                onChange={ e => uploadImageHomePage(e,'twoBanner', 'img_mobile')}
                                                deleteImage={ e => deleteImageHomeBanner(e,'twoBanner', 'img_mobile')}
                                            />
                                            <div className={"photo_resolution"}>
                                                Версия для телефона: 451*800
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            : ''
                        }


                        <div className={"name_banner"}>
                            Третий баннер
                        </div>
                        {
                            noJsonContentHome.threeBanner ?
                                <div className={"one_banner_wrapper"}>
                                    <div className={"fields_banner_admhome"}>
                                        <div className={"banner_fields_container"}>
                                            <span className="home_field_label_name">Текст кнопки</span>
                                            <input
                                                value={noJsonContentHome.threeBanner.buttonName ? noJsonContentHome.threeBanner.buttonName : ""}
                                                onChange={e => fieldsHanlerHomePage(e,"threeBanner","buttonName")}
                                                className="home_adm_field" type="text" />
                                        </div>
                                        <div className={"banner_fields_container"}>
                                            <span className="home_field_label_name">Ссылка кнопки</span>
                                            <input
                                                value={noJsonContentHome.threeBanner.linkButton ? noJsonContentHome.threeBanner.linkButton : ""}
                                                onChange={e => fieldsHanlerHomePage(e,"threeBanner","linkButton")}
                                                className="home_adm_field" type="text" />
                                        </div>
                                        <div className={"activation_wrapper"}>
                                            <div className={"home_field_label_name"}>Активировать тень</div>
                                            <div
                                                onClick={e=> activationTenBanner("shadowleft", "threeBanner")}
                                                className={"inclusion_new_products"}>
                                                <IsActiveIndicator active={noJsonContentHome.threeBanner.shadowleft} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"img_banner_admhome"}>
                                        <div className={"one_img_block"}>
                                            <OneImageField
                                                text={"Десктоп"}
                                                image={noJsonContentHome.threeBanner.img_pc ?
                                                    process.env.REACT_APP_API_URL+"uploads/images/homepage/threeBanner/"+noJsonContentHome.threeBanner.img_pc  : null }
                                                onChange={ e => uploadImageHomePage(e,'threeBanner','img_pc')}
                                                deleteImage={ e => deleteImageHomeBanner(e,'threeBanner', 'img_pc')}
                                            />
                                            <div className={"photo_resolution"}>
                                                Версия для ПК: 960*1290
                                            </div>
                                        </div>

                                        <div className={"one_img_block"}>
                                            <OneImageField
                                                text={"Планшет"}
                                                image={noJsonContentHome.threeBanner.img_tablet ?
                                                    process.env.REACT_APP_API_URL+"uploads/images/homepage/threeBanner/"+noJsonContentHome.threeBanner.img_tablet  : null }
                                                onChange={ e => uploadImageHomePage(e,'threeBanner','img_tablet')}
                                                deleteImage={ e => deleteImageHomeBanner(e,'threeBanner', 'img_tablet')}
                                            />
                                            <div className={"photo_resolution"}>
                                                Версия для планшета: 768*1024
                                            </div>
                                        </div>

                                        <div className={"one_img_block"}>
                                            <OneImageField
                                                text={"Смартфон"}
                                                image={noJsonContentHome.threeBanner.img_mobile ?
                                                    process.env.REACT_APP_API_URL+"uploads/images/homepage/threeBanner/"+noJsonContentHome.threeBanner.img_mobile  : null }
                                                onChange={ e => uploadImageHomePage(e,'threeBanner', 'img_mobile')}
                                                deleteImage={ e => deleteImageHomeBanner(e,'threeBanner', 'img_mobile')}
                                            />
                                            <div className={"photo_resolution"}>
                                                Версия для телефона: 451*800
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''
                        }


                        <div className={"name_banner"}>
                            Четвертый баннер
                        </div>
                        {
                            noJsonContentHome.fourthBanner ?
                                <div className={"one_banner_wrapper"}>
                                    <div className={"fields_banner_admhome"}>
                                        <div className={"banner_fields_container"}>
                                            <span className="home_field_label_name">Текст кнопки</span>
                                            <input
                                                value={noJsonContentHome.fourthBanner.buttonName ? noJsonContentHome.fourthBanner.buttonName : ""}
                                                onChange={e => fieldsHanlerHomePage(e,"fourthBanner","buttonName")}
                                                className="home_adm_field" type="text" />
                                        </div>
                                        <div className={"banner_fields_container"}>
                                            <span className="home_field_label_name">Ссылка кнопки</span>
                                            <input
                                                value={noJsonContentHome.fourthBanner.linkButton ? noJsonContentHome.fourthBanner.linkButton : ""}
                                                onChange={e => fieldsHanlerHomePage(e,"fourthBanner","linkButton")}
                                                className="home_adm_field" type="text" />
                                        </div>
                                        <div className={"activation_wrapper"}>
                                            <div className={"home_field_label_name"}>Активировать тень</div>
                                            <div
                                                onClick={e=> activationTenBanner("shadowright", "fourthBanner")}
                                                className={"inclusion_new_products"}>
                                                <IsActiveIndicator active={noJsonContentHome.fourthBanner.shadowright} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"img_banner_admhome"}>
                                        <div className={"one_img_block"}>
                                            <OneImageField
                                                text={"Десктоп"}
                                                image={noJsonContentHome.fourthBanner.img_pc ?
                                                    process.env.REACT_APP_API_URL+"uploads/images/homepage/fourthBanner/"+noJsonContentHome.fourthBanner.img_pc  : null }
                                                onChange={ e => uploadImageHomePage(e,'fourthBanner','img_pc')}
                                                deleteImage={ e => deleteImageHomeBanner(e,'fourthBanner', 'img_pc')}
                                            />
                                            <div className={"photo_resolution"}>
                                                Версия для ПК: 960*1290
                                            </div>
                                        </div>

                                        <div className={"one_img_block"}>
                                            <OneImageField
                                                text={"Планшет"}
                                                image={noJsonContentHome.fourthBanner.img_tablet ?
                                                    process.env.REACT_APP_API_URL+"uploads/images/homepage/fourthBanner/"+noJsonContentHome.fourthBanner.img_tablet  : null }
                                                onChange={ e => uploadImageHomePage(e,'fourthBanner','img_tablet')}
                                                deleteImage={ e => deleteImageHomeBanner(e,'fourthBanner', 'img_tablet')}
                                            />
                                            <div className={"photo_resolution"}>
                                                Версия для планшета: 768*1024
                                            </div>
                                        </div>

                                        <div className={"one_img_block"}>
                                            <OneImageField
                                                text={"Смартфон"}
                                                image={noJsonContentHome.fourthBanner.img_mobile ?
                                                    process.env.REACT_APP_API_URL+"uploads/images/homepage/fourthBanner/"+noJsonContentHome.fourthBanner.img_mobile  : null }
                                                onChange={ e => uploadImageHomePage(e,'fourthBanner', 'img_mobile')}
                                                deleteImage={ e => deleteImageHomeBanner(e,'fourthBanner', 'img_mobile')}
                                            />
                                            <div className={"photo_resolution"}>
                                                Версия для телефона: 451*800
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''
                        }
                    </div>

                    <div className={"name_banner"}>
                        Вкл/Выкл Новинок, LookBook, Формы подписки
                    </div>
                    <div className={"one_banner_wrapper"}>
                        <div className={"activation_unit"}>
                            <div className={"banner_fields_container"}>
                                <span className="home_field_label_name">Заголовок новинок</span>
                                <input
                                    value={noJsonContentHome.nameBlockNew ? noJsonContentHome.nameBlockNew : ""}
                                    onChange={e => fieldsNameTitle(e,"nameBlockNew")}
                                    className="home_adm_field" type="text" />
                            </div>
                            <div className={"activation_wrapper"}>
                                <div className={"home_field_label_name"}>Активировать слайдер "Новинок"</div>
                                <div
                                    onClick={e=> activationElementHome("switchProductNew")}
                                    className={"inclusion_new_products"}>
                                    <IsActiveIndicator active={noJsonContentHome.switchProductNew} />
                                </div>
                            </div>
                        </div>

                        <div className={"activation_unit"}>
                            <div className={"banner_fields_container"}>
                                <span className="home_field_label_name">Заголовок lookbook</span>
                                <input
                                    value={noJsonContentHome.nameBlockLookbook ? noJsonContentHome.nameBlockLookbook : ""}
                                    onChange={e => fieldsNameTitle(e,"nameBlockLookbook")}
                                    className="home_adm_field" type="text" />
                            </div>
                            <div className={"activation_wrapper"}>
                                <div className={"home_field_label_name"}>Активировать слайдер "LOOKBOOK"</div>
                                <div
                                    onClick={e=> activationElementHome("switchLookBook")}
                                    className={"inclusion_new_products"}>
                                    <IsActiveIndicator active={noJsonContentHome.switchLookBook} />
                                </div>
                            </div>
                        </div>

                        <div className={"activation_unit"}>
                            <div className={"banner_fields_container"}>
                                <span className="home_field_label_name">Заголовок подписки</span>
                                <input
                                    value={noJsonContentHome.titleFormHome ? noJsonContentHome.titleFormHome : ""}
                                    onChange={e => fieldsNameTitle(e,"titleFormHome")}
                                    className="home_adm_field" type="text" />
                            </div>
                            <div className={"activation_wrapper"}>
                                <div className={"home_field_label_name"}>Активировать форму подписки</div>
                                <div
                                    onClick={e=> activationElementHome("switchSubscriptionForm")}
                                    className={"inclusion_new_products"}>
                                    <IsActiveIndicator active={noJsonContentHome.switchSubscriptionForm} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        noJsonContentHome.saleAndTrends ?
                            <div className={"wrap_scroll_trends_sale"}>
                                <div className={"title_sale_trends"}>
                                    <span className="home_field_label_name">Заголовок блока</span>
                                    <input
                                        value={noJsonContentHome.saleAndTrends.commonName ? noJsonContentHome.saleAndTrends.commonName : ""}
                                        onChange={e => fieldsHanlerHomePage(e,"saleAndTrends", "commonName" )}
                                        className="home_adm_field" type="text" />
                                </div>
                                <div className={"one_banner_wrapper"}>
                                    <div className={"sale_and_trend"}>
                                        <div className={"one_block_content"}>
                                            <div className={"banner_fields_container"}>
                                                <span className="home_field_label_name">Заголовок акций</span>
                                                <input
                                                    value={noJsonContentHome.saleAndTrends.nameBlockSale ? noJsonContentHome.saleAndTrends.nameBlockSale : ""}
                                                    onChange={e => fieldsHanlerHomePage(e,"saleAndTrends", "nameBlockSale" )}
                                                    className="home_adm_field" type="text" />
                                            </div>
                                            <div className={"add_button_home"}
                                                 onClick={e => addNewTrading(Object.assign({}, oneBlockTrading), 'saleAndTrends', 'SaleHome' )}
                                            >Добавить акцию</div>
                                            {
                                                noJsonContentHome.saleAndTrends.SaleHome ?  noJsonContentHome.saleAndTrends.SaleHome.map((itemSale, indexSale) =>
                                                    <div key={indexSale} className={"one_trading_block"}>
                                                        <div className={"icons_fields"}>
                                                            <div className={"banner_fields_container"}>
                                                                <span className="home_field_label_name">Наименование</span>
                                                                <input
                                                                    value={itemSale.name ? itemSale.name : ''}
                                                                    onChange={e => fieldsTrading(e,"name", 'saleAndTrends',"SaleHome", indexSale)}
                                                                    className="home_adm_field" type="text" />
                                                            </div>
                                                            <div className={"banner_fields_container"}>
                                                                <span className="home_field_label_name">Ссылка (укажите ссылку, чтобы элемент был кликабельным)</span>
                                                                <input
                                                                    value={itemSale.link ? itemSale.link : ''}
                                                                    onChange={e => fieldsTrading(e,"link", 'saleAndTrends',"SaleHome",indexSale)}
                                                                    className="home_adm_field" type="text" />
                                                            </div>
                                                        </div>
                                                        <div className={"one_img_block"}>
                                                            <OneImageField
                                                                text={"Изображение (разрешение 574*231)"}
                                                                image={itemSale.img ?
                                                                    process.env.REACT_APP_API_URL+"uploads/images/homepage/saleAndTrends/"+itemSale.img  : null }
                                                                onChange={ e => uploadImageTrading(e,'saleAndTrends','img', "SaleHome", indexSale)}
                                                                deleteImage={ e => deleteImageTrading(e,'saleAndTrends', 'img', "SaleHome", indexSale)}
                                                            />
                                                        </div>
                                                        <div
                                                            onClick={e => delTradingFields("saleAndTrends", 'SaleHome',  indexSale)}
                                                            className={"del_block_home"}>✖</div>
                                                    </div>
                                                ) : ''
                                            }
                                        </div>
                                        <div className={"one_block_content"}>
                                            <div className={"banner_fields_container"}>
                                                <span className="home_field_label_name">Заголовок трендов</span>
                                                <input
                                                    value={noJsonContentHome.saleAndTrends.nameBlockTrends ? noJsonContentHome.saleAndTrends.nameBlockTrends : ""}
                                                    onChange={e => fieldsHanlerHomePage(e,  "saleAndTrends", "nameBlockTrends")}
                                                    className="home_adm_field" type="text" />
                                            </div>
                                            <div className={"add_button_home"}
                                                 onClick={e => addNewTrading(Object.assign({}, oneBlockTrading), 'saleAndTrends', 'trendsHome' )}
                                            >Добавить тренд</div>
                                            {
                                                noJsonContentHome.saleAndTrends.trendsHome ?  noJsonContentHome.saleAndTrends.trendsHome.map((itemTrend, indexTrend) =>
                                                    <div key={itemTrend} className={"one_trading_block"}>
                                                        <div className={"icons_fields"}>
                                                            <div className={"banner_fields_container"}>
                                                                <span className="home_field_label_name">Наименование</span>
                                                                <input
                                                                    value={itemTrend.name ? itemTrend.name : ''}
                                                                    onChange={e => fieldsTrading(e,"name", 'saleAndTrends',"trendsHome", indexTrend)}
                                                                    className="home_adm_field" type="text" />
                                                            </div>
                                                            <div className={"banner_fields_container"}>
                                                                <span className="home_field_label_name">Ссылка (укажите ссылку, чтобы элемент был кликабельным)</span>
                                                                <input
                                                                    value={itemTrend.link ? itemTrend.link : ''}
                                                                    onChange={e => fieldsTrading(e,"link", 'saleAndTrends',"trendsHome",indexTrend)}
                                                                    className="home_adm_field" type="text" />
                                                            </div>
                                                        </div>
                                                        <div className={"one_img_block"}>
                                                            <OneImageField
                                                                text={"Изображение (разрешение 574*231)"}
                                                                image={itemTrend.img ?
                                                                    process.env.REACT_APP_API_URL+"uploads/images/homepage/saleAndTrends/"+itemTrend.img  : null }
                                                                onChange={ e => uploadImageTrading(e,'saleAndTrends','img', "trendsHome", indexTrend)}
                                                                deleteImage={ e => deleteImageTrading(e,'saleAndTrends', 'img', "trendsHome", indexTrend)}
                                                            />
                                                        </div>
                                                        <div
                                                            onClick={e => delTradingFields("saleAndTrends", 'trendsHome',  indexTrend)}
                                                            className={"del_block_home"}>✖</div>
                                                    </div>
                                                ) : ''
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>

                            : ""
                    }



                    <div className={"text_block_home"}>
                        <div className={"left_block_text"}>
                            <h3>Добавить текст в левый блок</h3>
                            <div className={"add_button_home"}
                                 onClick={e => addNewTextHome(Object.assign({}, oneBlockTitleHome), 'leftBlockText' )}
                            >Заголовок</div>
                            <div className={"add_button_home"}
                                 onClick={e => addNewTextHome(Object.assign({}, oneBlockTextHome), 'leftBlockText' )}
                            >Тестове поле</div>
                            <div className={"text_field_block"}>
                                {noJsonContentHome.leftBlockText ? noJsonContentHome.leftBlockText.map((itemLeft, index) => {
                                    if (itemLeft.type === "title") {
                                        return (
                                            <div className={"text_field_wrapper"} key={index}>
                                                <div className={"banner_fields_container"}>
                                                    <span className="home_field_label_name">Подзаголовок</span>
                                                    <input
                                                        value={itemLeft.name}
                                                        onChange={e => fieldsHomeText(e,"name", 'leftBlockText',index)}
                                                        className="home_adm_field" type="text" />
                                                </div>
                                                <div
                                                    onClick={e => delFieldsHome("leftBlockText", index)}
                                                    className={"del_block_home"}>✖</div>
                                            </div>

                                        )
                                    }
                                        if (itemLeft.type === "text") {
                                            return (
                                                <div className={"text_field_wrapper"} key={index}>
                                                    <div className={"banner_fields_container"}>
                                                        <span className="home_field_label_name">Текст</span>
                                                        <textarea
                                                            value={itemLeft.value ? itemLeft.value : ''}
                                                            onChange={e => fieldsHomeText(e,"value", 'leftBlockText',index)}
                                                            className="home_adm_field" type="text" />
                                                    </div>
                                                    <div
                                                        onClick={e => delFieldsHome("leftBlockText", index)}
                                                        className={"del_block_home"}>✖</div>
                                                </div>

                                            )
                                        }
                                    }

                                ) : ''}
                            </div>
                        </div>
                        <div className={"left_block_text"}>
                            <h3>Добавить текст в правый блок</h3>
                            <div className={"add_button_home"}
                                 onClick={e => addNewTextHome(Object.assign({}, oneBlockTitleHome), 'rightBlockText' )}
                            >Заголовок</div>
                            <div className={"add_button_home"}
                                 onClick={e => addNewTextHome(Object.assign({}, descriptionIconsText), 'rightBlockText' )}
                            >Блок с картинкой</div>
                            <div className={"add_button_home"}
                                 onClick={e => addNewTextHome(Object.assign({}, oneBlockTextHome), 'rightBlockText' )}
                            >Тестове поле</div>
                            <div className={"text_field_block"}>
                                {noJsonContentHome.rightBlockText ? noJsonContentHome.rightBlockText.map((itemRight, indexRight) => {
                                        if (itemRight.type === "title") {
                                            return (
                                                <div className={"text_field_wrapper"} key={indexRight}>
                                                    <div className={"banner_fields_container"}>
                                                        <span className="home_field_label_name">Подзаголовок</span>
                                                        <input
                                                            value={itemRight.name ? itemRight.name : ''}
                                                            onChange={e => fieldsHomeText(e,"name", 'rightBlockText',indexRight)}
                                                            className="home_adm_field" type="text" />
                                                    </div>
                                                    <div
                                                        onClick={e => delFieldsHome("rightBlockText", indexRight)}
                                                        className={"del_block_home"}>✖</div>
                                                </div>

                                            )
                                        }
                                    if (itemRight.type === "text") {
                                        return (
                                            <div className={"text_field_wrapper"} key={indexRight}>
                                                <div className={"banner_fields_container"}>
                                                    <span className="home_field_label_name">Текст</span>
                                                    <textarea
                                                        value={itemRight.value ? itemRight.value : ''}
                                                        onChange={e => fieldsHomeText(e,"value", 'rightBlockText',indexRight)}
                                                        className="home_adm_field" type="text" />
                                                </div>
                                                <div
                                                    onClick={e => delFieldsHome("rightBlockText", indexRight)}
                                                    className={"del_block_home"}>✖</div>
                                            </div>

                                        )
                                    }
                                    if (itemRight.type === "icons") {
                                        return (
                                            <div className={"icons_block_wrapper"}>

                                                {
                                                    itemRight.arrayIcons ? itemRight.arrayIcons.map((itemIcons, indexIcons) =>
                                                        <div key={indexIcons} className={"one_icons_block"}>

                                                            <div className={"icons_fields"}>
                                                                <div className={"banner_fields_container"}>
                                                                    <span className="home_field_label_name">Наименование</span>
                                                                    <input
                                                                        value={itemIcons.name ? itemIcons.name : ''}
                                                                        onChange={e => fieldsHomeIcons(e,"name", 'rightBlockText',"arrayIcons", indexRight ,indexIcons)}
                                                                        className="home_adm_field" type="text" />
                                                                </div>
                                                                <div className={"banner_fields_container"}>
                                                                    <span className="home_field_label_name">Ссылка
                                                                        (укажите ссылку, чтобы элемент был кликабельным)</span>
                                                                    <input
                                                                        value={itemIcons.link ? itemIcons.link : ''}
                                                                        onChange={e => fieldsHomeIcons(e,"link", 'rightBlockText',"arrayIcons",indexRight ,indexIcons)}
                                                                        className="home_adm_field" type="text" />
                                                                </div>
                                                            </div>
                                                            <div className={"one_img_block"}>
                                                                <OneImageField
                                                                    text={"Иконка"}
                                                                    image={itemIcons.img ?
                                                                        process.env.REACT_APP_API_URL+"uploads/images/homepage/rightBlockText/"+itemIcons.img  : null }
                                                                    onChange={ e => uploadImageIcons(e,'rightBlockText','img', "arrayIcons", indexRight, indexIcons)}
                                                                    deleteImage={ e => deleteImageIcons(e,'rightBlockText', 'img', "arrayIcons", indexRight, indexIcons)}
                                                                />
                                                            </div>

                                                        </div>
                                                    ) : ''
                                                }
                                                <div
                                                    onClick={e => delFieldsHome("rightBlockText", indexRight)}
                                                    className={"del_block_home"}>✖</div>
                                            </div>



                                        )
                                    }

                                }

                                ) : ''}
                            </div>
                        </div>

                    </div>





                </div>
                <span
                    onClick={e => updatePAgeHome(pageContentHome)}
                    className={ updateBannerSaveTriger === true ? "save_button_home_content active" : "save_button_home_content"}
                >Сохранить</span>
            </div>


        </div>
    );
};

export default AdmHomePage;