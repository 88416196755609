import React, {useContext, useEffect, useState} from 'react';
import "./selectPayType.css";
import {observer} from "mobx-react-lite";
import {getPaymentMethodsById} from "../../../http/basketApi";
import {pushGoogleAnalyticProductAction} from "../../../utils/googleAnalytic";
import { Context } from '../../../index';

const SelectPayType = observer((props) => {
    const {basket, delivery} = useContext(Context);
    const [allPaymentsType, setAllPaymentsType] = useState(null);

    useEffect(() => {
      if(delivery.active?.payment_type) {
          getPaymentMethodsById(delivery.active.payment_type).then(response => {
              if(response.error) {
                  return false;
              }

              setAllPaymentsType(response);
          })
      }
      basket.setSelectPaymentTypeError("");
    },[])

    const selectPaymentTypeHandler = (item) => {
        if(basket.basketItems && basket.basketItems.length) {
            const googleAnalyticBasketItems = basket.basketItems.map((basketItem) => ({...basketItem.item, currentSize: basketItem.currentSize, customProps: {quantity: basketItem.count}}))
            pushGoogleAnalyticProductAction('add_payment_info', googleAnalyticBasketItems, {payment_type: item.name})
        }
        basket.setSelectPaymentType(item);
        basket.setSelectPaymentTypeError("");       
    }

    return (
        <div className={"select_pay_type_page"}>
            <div className={"select_pay_type_wrapper"}>
                <div className={"select_pay_type_heading"}>3. Оплата</div>
                <div className={"payment_methods_wrap"}>
                 <span className={"select_pay_inform_line"}>
                    Способ оплаты:
                </span>
                    <div className={basket.selectPaymentType ? "all_pay_methods_for_select active" : "all_pay_methods_for_select"}>
                        {
                            allPaymentsType ?
                                allPaymentsType.map((item,index) => {
                                    return (
                                        <div  key={item.id}
                                              onClick={e => selectPaymentTypeHandler(item)}
                                              className={basket.selectPaymentType && basket.selectPaymentType.id === item.id ? "payment_select_button active" : "payment_select_button"}>
                                            <div className={"payment_content"}>
                                                <div className={"indicator_active_payment"}>
                                                    <div className={"indicator_payment_circle"}>
                                                        <span className={"active_payment_circle"}></span>
                                                    </div>
                                                </div>
                                                <div className={"title_option_pay"}>
                                                    {item.name}
                                                    {
                                                        item.code == 'ysplit'
                                                            ? <img className={"title_option_pay--ysplit"} src={"/files/images/payments/ysplit.svg"}></img>
                                                        : ""
                                                    }
                                                </div>
                                            </div>
                                            { item.code == "dolyami" ? 
                                                <div className={"dop_text_payment"}>
                                                    <span className={"dop_text_grey"}>Раздели покупку на 4 платежа.
                                                        Без процентов и переплат. </span>
                                                    <a href={"/shares/"} target={"_blank"} rel="noreferrer"  className={"dop_text_link"}>Читать подробнее</a>
                                                    <span className={"dop_text_bold"}> Обратите внимание! </span>
                                                    <span className={"dop_text_black"}>Подарочные сертификаты и бонусы к оплате не принимаются.</span>
                                                </div>
                                                : ""

                                            }

                                        </div>

                                    )
                                })
                                : ""
                        }
 <div className={"select_pay_description"}>
                    <p>
                   <span> После оплаты заказа, примерка будет не доступна. </span>
                    </p>
                </div>
                    </div>
                </div>
               
                <div ref={props.forwardedRef} className={"select_payment_type_error"}>
                    {basket.selectPaymentTypeError}
                </div>
                
            </div>
            <div className={"basket_controll_wrapper two_step"}>
                <div
                    onClick={e => basket.downStepPage(basket.startBasketNum)}
                    className={"basket_controll_back_step"}>
                    Назад
                </div>
            </div>
        </div>
    );
});

export default SelectPayType;
