import React, {useContext, useEffect, useMemo, useState} from 'react';
import './refundRequestPage.css'
import {Context} from "../../index";
import InputMask from 'react-input-mask';
import {countryArr} from "../../utils/consts";
import {observer} from "mobx-react-lite";
import ContentBreadcrumbs from "../Breadcrumbs/contentBreadcrumbs/ContentBreadcrumbs";
import EmailInput from "../UI/EmailInput/EmailInput";
import ReturnsApi from "../../http/returnsApi";
import {formatDate} from "../../utils/date";
import {login} from "../../http/userAPI";


const RefundRequestPage = observer((props) => {

    const {settings, user} = useContext(Context)

    let data = new Date();
    const [dataRefundPolicy, setDataRefundPolicy] = useState(formatDate(data));

    const [dataFromFields, setDataFromFields] = useState({
        status: 'Новый',
        createdAt: data
    })
    const [errorFromFields, setErrorFromFields] = useState('')

    const [selectionReturnForm, setSelectionReturnForm] = useState(null)
    //  Тут хранится маска для вывода маски в поля
    const [maskStatePhone, setMaskState] = useState(countryArr[0]);
    const [checkingNumberReturn, setCheckingNumberReturn] = useState(null)
    //фамилия
    const [checkingLastName, setCheckingLastName] = useState(null)
    //имя
    const [checkingFirstName, setCheckingFirstName] = useState(null)
    //отчество
    const [checkingPatronymic, setCheckingPatronymic] = useState(null)
    //email
    const [errEmailReturnForm, setErrEmailReturnForm] = useState(false);
    const [errMesEmailReturnForm, setErrMesEmailReturnForm] = useState(null);
    //артикул, размер, цвет
    const [checkArticle, setCheckArticle] = useState(null);
    const [checkSize, setCheckSize] = useState(null);
    const [checkColor, setCheckColor] = useState(null);
    //серия и номер паспорта
    const [checkPassportNumber, setCheckPassportNumber] = useState(null);
    //Дата выдачи паспорта
    const [checkDateIssue, setCheckDateIssue] = useState(null);
    //Кем выдан
    const [checkIssuingDepartment, setCheckIssuingDepartment] = useState(null);
    //номер заказа
    const [checkNumberOrder, setCheckNumberOrder] = useState(null);
    //дата оформления заказа
    const [checkDataDesignations, setCheckDataDesignations] = useState(null);
    //причина возврата
    const [checkReasonReturn, setCheckReasonReturn] = useState(null)
    //выбираем способ оплаты
    const [typePaymentReturn, setTypePaymentReturn] = useState(null)
    const [checkPaymentReturn, setCheckPaymentReturn] = useState(null)
    //чекбокс согласия
    const [checkProcessingConsent, setCheckProcessingConsent] = useState(false)
    //активация кнопки
    const [stateButtonReturn, setStateButtonReturn] = useState(false)

    const [articles, setArticles] = useState([
        {
            id: 1,
            article: '',
            size: '',
            color: '',
            errors: {
                article: '',
                size: '',
                color: ''
            }
        }
    ])


    useEffect(() => {
        window.scrollTo(0, 0)
        setDataFromFields({...dataFromFields, typeReturn: settings.refundOption})
    }, [])


    const choosingRefundOption = [
        {id: 1, name: 'Покупка в интернет-магазине по Москве и МО'},
        {id: 2, name: 'Покупка в интернет-магазине из регионов и других стран'}
    ]
    const clickDirectLink = (item) => {
        setDataFromFields({...dataFromFields, returnType: item.id})
        settings.setRefundOption(item.id)
    }

    //Поля ФИО
    const recordingReturnField = (e, fieldName) => {

        let letters = e.target.value.replace(/[^a-zA-ZА-Яа-яЁё ]/g, '');

        setDataFromFields({...dataFromFields, [fieldName]: letters})

        if (fieldName === 'firstName') {
            if (letters.length < 1) {
                setCheckingFirstName('Заполните имя')
            } else {
                setCheckingFirstName('')
            }
        }
        if (fieldName === 'lastName') {
            if (letters.length < 1) {
                setCheckingLastName('Заполните фамилию')
            } else {
                setCheckingLastName('')
            }
        }
        if (fieldName === 'middleName') {
            if (letters.length < 1) {
                setCheckingPatronymic('Заполните отчество')
            } else {
                setCheckingPatronymic('')
            }
        }

    }

    //Устанавливаем значение для инпута и проверяем на соответствие кол-ву цифр.
    const numberVerificationRefund = (number) => {
        user.setPhoneNumber(number);
        let replacenumber = number.replace(/[^\d]/g, '');

        //выводим ошибку номера
        if(Number(replacenumber.length) === Number(maskStatePhone.number)) {
            setCheckingNumberReturn('');
        } else {
            setCheckingNumberReturn('Заполните номер');
        }
        setDataFromFields({...dataFromFields, phone: replacenumber})
    }

    //Артикул, размер и цвет
    const articleField = (e, id) => {
        let articleUpper = e.target.value.replace(/[^a-zA-ZА0-9 ]/g, '');

        let articleName = articleUpper.toUpperCase()
        let newArticles = articles.map(item => {
            if (item.id !== id) return item
            if (articleName.length < 1) {
                item.errors.article = 'Заполните артикул'
            } else {
                item.errors.article = ''
            }
            return {...item, article: articleName}
        })
        setArticles(newArticles)
    }
    const sizeField = (e, id) => {
        let sizeUpper = e.target.value.toUpperCase()
        let sizeName = sizeUpper.replace(/[^a-zA-ZА ]/g, '');
        let newArticles = articles.map(item => {
            if (item.id !== id) return item
            if (sizeName.length < 1) {
                item.errors.size = 'Заполните размер'
            } else {
                item.errors.size = ''
            }
            return {...item, size: sizeName}
        })
        setArticles(newArticles)
    }
    const colorField = (e, id) => {
        let colorName = e.target.value.replace(/[^Яа-яЁё]/g, '');
        let newArticles = articles.map(item => {
            if (item.id !== id) return item
            if (colorName.length < 1) {
                item.errors.color = 'Заполните цвет'
            } else {
                item.errors.color = ''
            }
            return {...item, color: colorName}
        })
        setArticles(newArticles)
    }
    //Серия номер паспорта
    const serialNumberPassport = (e, number) => {
        let passport = e.target.value.toUpperCase()
        let passportNumber = passport.replace(/[^a-zA-ZА0-9 ]/g, '');
        let remText = passportNumber.replace(/ /g, "");
        let length = remText.length;
        if (length !='') {
            setCheckPassportNumber('')
        } else {
            setCheckPassportNumber('Введите серию и номер паспорта')
        }
        setDataFromFields({...dataFromFields,[number]:passportNumber})
    }

    //даты
    const fieldsDataReturn = (e, data) => {

        setDataFromFields({...dataFromFields, [data]:e.target.value})

        if (data === 'dateOfIssue') {
            if (e.target.value != '') {
                setCheckDateIssue('')
            } else {
                setCheckDateIssue('Заполните дату')
            }

        }

        if (data === 'orderPlacingDate') {
            if (e.target.value != '') {
                setCheckDataDesignations('')
            } else {
                setCheckDataDesignations('Заполните дату')
            }
        }


    }

    //кем выдан
    const issuedByWhom = (e, text) => {
        // let departmentName = e.target.value.replace(/[a-zA-Z!@#$%^*_|]/g,'');
        let departmentName = e.target.value.replace(/[!?&:/;+={}@#$%^*_|]/g,'');

        if (departmentName.length < 1) {
            setCheckIssuingDepartment('Заполните отделение выдачи')
        } else {
            setCheckIssuingDepartment('')
        }
        setDataFromFields({...dataFromFields, [text]: departmentName})
    }

    //номер заказа
    const numberOrder = (e, number) => {
        let onlyNumbers = e.target.value;

        if (onlyNumbers != '') {
            setCheckNumberOrder('')
        } else {
            setCheckNumberOrder('Укажите номер заказа')
        }
        setDataFromFields({...dataFromFields, [number]: onlyNumbers})
    }

    //Причина возврата
    const reasonForReturn = [
        {id: 1, name:'1-Заказала на выбор'},
        {id: 2, name:'2-Не подошел размер'},
        {id: 3, name:'3-Не устраивает качество'},
        {id: 4, name:'4-Брак товара'},
        {id: 5, name:'5-Не нравится стиль'},
        {id: 6, name:'6-Другое '},
    ]

    const reasonReturnHandler = (item) => {
        setDataFromFields({...dataFromFields, reasonForReturn: item.name})
        if (dataFromFields.reasonForReturn != '') {
            setCheckReasonReturn('')
        } else {
            setCheckReasonReturn('Выберите причину возврата')
        }
    }

    //Причина возврата
    const paymentTypes = [
        {id: 1, name:'Наличные'},
        {id: 2, name:'Картой на сайте'},
        {id: 3, name:'Безналичный расчет'},
        {id: 4, name:'Терминал'},
        {id: 5, name:'Долями'},
        {id: 6, name:'Яндекс Сплит'},
    ]

    const typesPayments = (item) => {
        setDataFromFields({...dataFromFields, type: item.name})
        setTypePaymentReturn(item.id)
        if (dataFromFields.type != '') {
            setCheckPaymentReturn('')
        } else {
            setCheckPaymentReturn('Выберите тип оплаты')
        }
    }

    const cardFields = (e, fieldName) => {
        if (fieldName == 'bankName') {
            setDataFromFields({...dataFromFields, [fieldName]: e.target.value})
        } else {
            let infoBank = e.target.value.replace(/[a-zA-ZА-Яа-яЁё\._-]/g, '');
            setDataFromFields({...dataFromFields, [fieldName]: infoBank})
        }
    }

    const [checkDeliveryServices, setCheckDeliveryServices] = useState(null)
    const [selectedPVZ, setSelectedPVZ] = useState(null)

    //Самостоятельный возврат, выбор ПВЗ
    const deliveryServices = [
        {id: 1, img:'/files/images/deliveryRefound/sdek.png', name: 'CDEK'},
        {id: 2, img:'/files/images/deliveryRefound/pochta.png', name: 'Почта России'},
    ]

    const deliveryRefound = (item) => {
        setDataFromFields({...dataFromFields, courierService: item.name})
        if (dataFromFields.courierService != '') {
            setSelectedPVZ('')
        } else {
            setSelectedPVZ('Выберите службу отправлкния')
        }
    }

    const departureNumber = (e, number) => {
        let onlyNumbers = e.target.value.replace(/[^a-zA-ZА0-9  -]/g, '');
        if (onlyNumbers != '') {
            setCheckDeliveryServices('')
        } else {
            setCheckDeliveryServices('Укажите трек-номер')
        }
        setDataFromFields({...dataFromFields, [number]: onlyNumbers})

    }

    useMemo(() => {
        let res = articles.some(item => {
            if (item.article == '' || item.size == '' || item.color == '') return false
            return Object.values(item.errors).every(value => value == '')
        })
        if (  checkingNumberReturn == '' && checkingLastName  == '' && checkingFirstName  == '' &&
            checkingPatronymic  == '' && errMesEmailReturnForm  == '' &&
            res
            && checkPassportNumber  == '' && checkDateIssue  == '' &&
            checkIssuingDepartment  == '' && checkNumberOrder  == '' && checkDataDesignations  == '' &&
            checkReasonReturn  == '' &&
            checkPaymentReturn  == '' && checkProcessingConsent === true
        ) {
            if (settings.refundOption && settings.refundOption == 1) {
                setStateButtonReturn(true)
                return
            }
            if (settings.refundOption && settings.refundOption == 2 && selectedPVZ  == '' && checkDeliveryServices  == '') {
                setStateButtonReturn(true)
                return
            }
            setStateButtonReturn(false)
        } else {
            setStateButtonReturn(false)
        }

    }, [checkingNumberReturn, checkingLastName, checkingFirstName, checkingPatronymic,
        errMesEmailReturnForm, articles, checkPassportNumber,checkDateIssue,
        checkIssuingDepartment, checkNumberOrder, checkDataDesignations,
        ,checkReasonReturn,
        checkPaymentReturn,checkProcessingConsent, selectedPVZ,checkDeliveryServices, settings.refundOption])



    const getReturnItem = async () => {
        if(!dataFromFields.issued_type){
            dataFromFields.issued_type = "Возврат"
        }

        const response = await ReturnsApi.create(dataFromFields, articles)

        if (!response.success) {
            setErrorFromFields(response.message)
        }
        if (!response.data || !response.data.length) {
            setErrorFromFields('Заявка не отправлена')
        }
        setDataFromFields({status: 'Новый'})
        settings.setRefundOption(null)
        props.setStateApplications(true)

    }

    const addArticle = () => {
        if (articles.length < 10) {
            let newArticles = [...articles];
            let maxId = newArticles.reduce((acc, curr) => acc.id > curr.id ? acc : curr).id;
            newArticles.push({
                id: maxId + 1,
                article: "",
                size: "",
                color: "",
                errors: {
                    article: '',
                    size: '',
                    color: ''
                }
            });
            setArticles(newArticles);
        }
    }

    const deleteArticle = (id) => {
        let newArticles = [...articles].filter(item => item.id !== id);
        setArticles(newArticles);
    }


    return (
        <div
            style={{marginTop: settings.headerHeight}}
            className={"refund_request_page"}>
            <ContentBreadcrumbs
                items={[
                    {name:"Главная", link:"/"},
                    {name:"Возврат", link:"/about/refund/"},
                    {name:"Оформление возврата", link:"/about/refund/making-refund/"}
                ]}
            />
            <div className={"application_wrapper"}>
                <h1 className={"application_title_page"}>Оформление возврата/обмена</h1>
                <div className={"application_fields_wrapper"}>
                    <div className={"refund_option"}>
                        {
                            settings.refundOption === null ? choosingRefundOption.map((item, index)=>
                                <div key={index}
                                     onClick={e=> clickDirectLink(item)}
                                     className={"one_type_payment"}>
                                    <div className={dataFromFields.typeReturn === item.id ? "selection_checkbox active" : "selection_checkbox"}></div>
                                    <div className={"name_type_payment"}>{item.name ? item.name : ''}</div>
                                </div>
                            ) : ''
                        }
                    </div>
                    <div className="one_field_refund_wrapper">
                        <span className={"one_field_refund mandatory"}>Фамилия</span>
                        <input
                            value={dataFromFields.lastName ? dataFromFields.lastName : ""}
                            onChange={e => recordingReturnField(e,'lastName')}
                            className={checkingLastName ? "refund_field error_refound" : "refund_field"} type="name"/>
                        <div className={"err_refund"}>
                            {checkingLastName ? checkingLastName : ''}
                        </div>
                    </div>
                    <div className="one_field_refund_wrapper">
                        <span className={"one_field_refund mandatory"}>Имя</span>
                        <input
                            value={dataFromFields.firstName ? dataFromFields.firstName : ""}
                            onChange={e => recordingReturnField(e,'firstName')}
                            className={checkingFirstName ? "refund_field error_refound" : "refund_field"} type="text"/>
                        <div className={"err_refund"}>
                            {checkingFirstName ? checkingFirstName : ''}
                        </div>
                    </div>
                    <div className="one_field_refund_wrapper">
                        <span className={"one_field_refund mandatory"}>Отчество</span>
                        <input
                            value={dataFromFields.middleName ? dataFromFields.middleName : ""}
                            onChange={e => recordingReturnField(e,'middleName')}
                            className={checkingPatronymic ? "refund_field error_refound" : "refund_field"} type="text"/>
                        <div className={"err_refund"}>
                            {checkingPatronymic ? checkingPatronymic : ''}
                        </div>
                    </div>
                    <div className="one_field_refund_wrapper">
                        <span className={"one_field_refund mandatory"}>Телефон</span>
                        <div className={checkingNumberReturn ? "refund_field error_refound" : "refund_field"}>
                            <InputMask
                                className={"phoneInput"}
                                placeholder={maskStatePhone.placeholder}
                                mask={maskStatePhone.mask}
                                value={dataFromFields.phone ? dataFromFields.phone : ''}
                                onChange={e => numberVerificationRefund(e.target.value)}
                            />
                        </div>
                        <div className={"err_refund"}>
                            {checkingNumberReturn ? checkingNumberReturn : ''}
                        </div>
                    </div>
                    <div className="one_field_refund_wrapper">
                        <EmailInput
                            requiredField={" "}
                            value={dataFromFields.email}
                            userFields={dataFromFields}
                            setNewValue={setDataFromFields}
                            fieldName={"email"}
                            errClass={errEmailReturnForm ? "error" : ""}
                            errController={setErrEmailReturnForm}
                            isErrEmail={errEmailReturnForm}
                            errMessageEmail={errMesEmailReturnForm}
                            setErrMessageEmail={setErrMesEmailReturnForm}
                        />
                    </div>
                    <div className="one_field_refund_wrapper">
                        <span className={"one_field_refund mandatory"}>Тип</span>
                        <select
                            className={'status_refund'}
                            value={dataFromFields.issued_type}
                            fieldName={"issued_type"}
                            onChange={e => recordingReturnField(e,'issued_type')}
                        >
                            <option value={"Возврат"}>Возврат</option>
                            <option value={"Обмен"}>Обмен</option>
                        </select>
                        <br/>
                    </div>
                    {
                        articles.map((item, index) => {
                            return (
                                <div key={item.id} className={"article_wrapper"}>
                                    {
                                        item.id !== 1 ?
                                        <div className={"article_delete_wrapper"}>
                                            <div
                                                onClick={e => deleteArticle(item.id)}
                                                className={"delete_article article_chose_ct"}></div>
                                        </div>
                                        : ""
                                    }

                                    <div className={"gorizontal_fields_wrapper__article"}>

                                        <div className="one_field_refund_wrapper">
                                            <span className={"one_field_refund mandatory"}>Артикул</span>
                                            <input
                                                value={item.article ? item.article : ""}
                                                placeholder='Укажите артикул'
                                                onChange={e => articleField(e, item.id)}
                                                className={item.errors.article ? "refund_field error_refound" : "refund_field"} type="text"/>
                                            <div className={"err_refund"}>
                                                {item.errors.article ? item.errors.article : ''}
                                            </div>
                                        </div>
                                        <div className="one_field_refund_wrapper">
                                            <span className={"one_field_refund mandatory"}>Размер</span>
                                            <input
                                                value={item.size ? item.size : ""}
                                                placeholder='Укажите размер'
                                                onChange={e => sizeField(e, item.id)}
                                                className={item.errors.size ? "refund_field error_refound" : "refund_field"} type="text"/>
                                            <div className={"err_refund"}>
                                                {item.errors.size ? item.errors.size : ''}
                                            </div>
                                        </div>
                                        <div className="one_field_refund_wrapper">
                                            <span className={"one_field_refund mandatory"}>Цвет</span>
                                            <input
                                                value={item.color ? item.color : ""}
                                                placeholder='Укажите цвет'
                                                onChange={e => colorField(e, item.id)}
                                                className={item.errors.color ? "refund_field error_refound" : "refund_field"} type="text"/>
                                            <div className={"err_refund"}>
                                                {item.errors.color ? item.errors.color : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className={"add_article_wrapper"}>
                        <div
                            onClick={e => addArticle()}
                            className={"add_article_button"}>
                            Добавить артикул
                        </div>
                    </div>

                    <div className={"subtitle_return_form"}>
                        Для оформления возврата нам необходимы
                        ваши паспортные данные
                    </div>
                    <div className={"gorizontal_fields_wrapper"}>
                        <div className="one_field_refund_wrapper">
                            <span className={"one_field_refund mandatory"}>Серия и номер паспорта</span>
                            <input
                                value={dataFromFields.passportSeriesAndNumber ? dataFromFields.passportSeriesAndNumber : ""}
                                onChange={e => serialNumberPassport(e,'passportSeriesAndNumber')}
                                className={checkPassportNumber ? "refund_field error_refound" : "refund_field"} type="text"
                                pattern="[0-9]{4} [0-9]{6}"
                            />
                            <div className={"err_refund"}>
                                {checkPassportNumber ? checkPassportNumber : ''}
                            </div>
                        </div>
                        <div className="one_field_refund_wrapper">
                            <span className={"one_field_refund mandatory"}>Дата выдачи</span>
                            <input
                                value={dataFromFields.dateOfIssue ? dataFromFields.dateOfIssue : ""}
                                onChange={e =>  fieldsDataReturn(e, 'dateOfIssue')}
                                className={checkDateIssue ? "refund_field error_refound" : "refund_field"}
                                type="date"
                                max={formatDate(data)}
                            />
                            <div className={"err_refund"}>
                                {checkDateIssue ? checkDateIssue : ''}
                            </div>
                        </div>
                    </div>
                    <div className="one_field_refund_wrapper">
                        <span className={"one_field_refund mandatory"}>Кем выдан</span>
                        <input
                            value={dataFromFields.issuedByWhom ? dataFromFields.issuedByWhom : ""}
                            onChange={e => issuedByWhom(e,'issuedByWhom')}
                            className={checkIssuingDepartment ? "refund_field error_refound" : "refund_field"} type="text"/>
                        <div className={"err_refund"}>
                            {checkIssuingDepartment ? checkIssuingDepartment : ''}
                        </div>
                    </div>
                    <div className="one_field_refund_wrapper">
                        <span className={"one_field_refund mandatory"}>Номер заказа</span>
                        <input
                            value={dataFromFields.orderNumber ? dataFromFields.orderNumber : ""}
                            onChange={e => numberOrder(e,'orderNumber')}
                            className={checkNumberOrder ? "refund_field error_refound" : "refund_field"} type="text"/>
                        <div className={"err_refund"}>
                            {checkNumberOrder ? checkNumberOrder : ''}
                        </div>
                    </div>
                    <div className={"gorizontal_fields_wrapper"}>
                        <div className="one_field_refund_wrapper">
                            <span className={"one_field_refund mandatory"}>Дата оформления заказа</span>
                            <input
                                value={dataFromFields.orderPlacingDate ? dataFromFields.orderPlacingDate : ""}
                                onChange={e =>  fieldsDataReturn(e, 'orderPlacingDate')}
                                className={checkDataDesignations ? "refund_field error_refound" : "refund_field"}
                                type="date"
                                max={formatDate(data)}
                            />
                            <div className={"err_refund"}>
                                {checkDataDesignations ? checkDataDesignations : ''}
                            </div>
                        </div>
                        <div className="one_field_refund_wrapper">
                            <span className={"one_field_refund mandatory"}>Дата оформления заявления</span>
                            <input
                                value={dataRefundPolicy ? dataRefundPolicy : ""}
                                className={"refund_field"}
                                type="date"
                                max={formatDate(data)}
                            />
                            <div className={"err_refund"}>

                            </div>
                        </div>
                    </div>
                    <div className="one_field_refund_wrapper">
                        <span className={"one_field_refund mandatory"}>Причина возврата</span>
                        <div className={"types_of_payment"}>
                            {
                                reasonForReturn ? reasonForReturn.map((item, index) =>
                                    <div key={index}
                                         onClick={e=> reasonReturnHandler(item)}
                                         className={"one_type_payment"}>
                                        <div className={dataFromFields.reasonForReturn  === item.name ? "selection_checkbox active" : "selection_checkbox"}></div>
                                        <div className={"name_type_payment"}>{item.name ? item.name : ''}</div>
                                    </div>
                                ) : ''
                            }
                        </div>
                        <div className={"err_refund"}>
                            {checkReasonReturn ? checkReasonReturn : ''}
                        </div>
                    </div>
                    <div className="one_field_refund_wrapper">
                        <span className={"one_field_refund mandatory"}>Тип оплаты</span>
                        <div className={"types_of_payment"}>
                            {
                                paymentTypes ? paymentTypes.map((item, index) =>
                                    <div key={index}
                                         onClick={e=> typesPayments(item)}
                                         className={"one_type_payment"}>
                                        <div className={dataFromFields.type === item.name ? "selection_checkbox active" : "selection_checkbox"}></div>
                                        <div className={"name_type_payment"}>{item.name ? item.name : ''}</div>
                                    </div>
                                ) : ''
                            }
                        </div>
                        <div className={"err_refund"}>
                            {checkPaymentReturn ? checkPaymentReturn : ''}
                        </div>
                        {
                            typePaymentReturn === 3 || typePaymentReturn === 1?
                                <div className={"card_data_wrapper"}>
                                    <div className={"description_field_card"}>
                                        <div className={"one_field_card"}>Наименование банка</div>
                                        <div className={"one_field_card"}>Кор.Счёт</div>
                                        <div className={"one_field_card"}>Расчётный счет банка</div>
                                        <div className={"one_field_card"}>Счет получателя</div>
                                        <div className={"one_field_card"}>Номер карты</div>
                                    </div>
                                    <div className={"card_value_wrapper"}>
                                        <div className={"card_value_child"}>
                                            <input
                                                value={dataFromFields.bankName ? dataFromFields.bankName : ""}
                                                onChange={e => cardFields(e,'bankName')}
                                                placeholder={'AO ”Тинькофф Банк”'}
                                                className={"one_value_card"} type="text"/>
                                            <input
                                                value={dataFromFields.bik ? dataFromFields.bik : ""}
                                                onChange={e => cardFields(e,'bik')}
                                                placeholder={'Бик: 234565214'}
                                                className={"one_value_card non_decorate"} type="text"/>
                                        </div>
                                        <input
                                            value={dataFromFields.correspondentAccount? dataFromFields.correspondentAccount : ""}
                                            onChange={e => cardFields(e,'correspondentAccount')}
                                            placeholder={'32154865452321456521'}
                                            className={"one_value_card"} type="text"/>
                                        <input
                                            value={dataFromFields.settlementAccount ? dataFromFields.settlementAccount : ""}
                                            onChange={e => cardFields(e,'settlementAccount')}
                                            placeholder={'6516847611114'}
                                            className={"one_value_card"} type="text"/>
                                        <input
                                            value={dataFromFields.account? dataFromFields.account : ""}
                                            onChange={e => cardFields(e,'account')}
                                            placeholder={'4848461156468451'}
                                            className={"one_value_card"} type="text"/>
                                        <input
                                            value={dataFromFields.cardNumber ? dataFromFields.cardNumber : ""}
                                            onChange={e => cardFields(e,'cardNumber')}
                                            placeholder={'3562 6448 9985 5632'}
                                            className={"one_value_card"} type="text"/>
                                    </div>
                                </div>
                                : ''
                        }

                        {
                            typePaymentReturn === 4 ?
                                <div className={"err_return_terminal"}>
                                    ВОЗВРАТ МОЖНО ОСУЩЕСТВИТЬ ТОЛЬКО В ТОМ МАГАЗИНЕ, ГДЕ ОСУЩЕСТВЛЯЛАСЬ ОПЛАТА ЧЕРЕЗ ТЕРМИНАЛ.
                                </div>
                                : ''
                        }

                        {
                            settings.refundOption && settings.refundOption === 2 ?
                                <div className={"self-refund"}>
                                    <div className={"subtitle_return_form no_restrictions"}>
                                        Выберете курьерскую службу (СДэк или Почта России)
                                    </div>
                                    <div className={"delivery_services_wrapper"}>
                                        <div className={"pvz_variant"}>
                                            {
                                                deliveryServices ? deliveryServices.map((item, index) =>
                                                    <div key={index}
                                                         onClick={e=> deliveryRefound(item)}
                                                         className={"one_pvz"}>
                                                        <div className={dataFromFields.courierService === item.name ? "selection_checkbox active" : "selection_checkbox"}></div>
                                                        {item.img ? <img src={item.img} alt="ПВЗ"/> : ''}
                                                        <div className={"err_refund"}>
                                                            {selectedPVZ ? selectedPVZ : ''}
                                                        </div>
                                                    </div>
                                                ) : ''
                                            }
                                        </div>
                                        <div className={"treck_number_wrap"}>
                                            <span className={"one_field_refund mandatory"}>Вставьте трек номер посылки</span>
                                            <input
                                                value={dataFromFields.trackNumber ? dataFromFields.trackNumber : ""}
                                                onChange={e => departureNumber(e,'trackNumber')}
                                                className={checkDeliveryServices ? "refund_field error_refound" : "refund_field"} type="text"/>
                                                <div className={"err_refund"}>
                                                    {checkDeliveryServices ? checkDeliveryServices : ''}
                                                </div>
                                        </div>
                                        <div className={"warning_message"}>Если вы не создали трек номер посылки, то вам необходимо зайти на сайт курьерской службы и оформить трек номер заранее</div>
                                    </div>
                                    <div className={"hint_message"}>Надежно упакуйте вещи в коробку и отправьте их нам на оценку.
                                        Если с вещью все в порядке, ожидайте возвращения денег в сроки указанные законодательством РФ.</div>
                                </div>
                                : ''
                        }
                    </div>
                </div>
                <div
                    onClick={e => stateButtonReturn ? getReturnItem() : ''}
                    className={stateButtonReturn === false ? "application_submission_button unactive" : "application_submission_button"}>
                    Отправить заявление
                </div>
                <div className={"soglasie_return"}>
                    <div
                        onClick={e => setCheckProcessingConsent(!checkProcessingConsent)}
                        className={checkProcessingConsent ? "checkbox_return active" : "checkbox_return"}>
                        <div className={"checkbox_return_child"}></div>
                    </div>
                    <div className={"return_politic"}>Нажимая кнопку “Отправить заявление”, вы соглашаетесь с нашей <a href={'https://you-wanna.ru/personal/privacy-policy/'} className={"link_politic_return"}>политикой конфиденциальности</a> </div>
                </div>
            </div>

        </div>
    );
});

export default RefundRequestPage;
