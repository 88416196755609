
import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";
import './MyOffersPage.css';
import {getOrdersByUserId, login} from "../../../../http/userAPI";
import OneOffer from "./OneOffer/OneOffer";

const MyOffers = observer((props) => {

    const {user} = useContext(Context)

    const [userOrders, setUserOrders] = useState(null);
    const [messageNoOrders, setMessageNoOrders] = useState("");

    useEffect(() => {
        if(user.user.id) {
            getOrdersByUserId(user.user.id).then(response => {
                // console.log(response)
                if(response.error) {
                    setMessageNoOrders(response.error);
                    return false;
                }
                setUserOrders(response)
            })
        }
    },[])



    return (
        <div className={"my_offers_container " +props.active}>
            <div className={"your_offers_title"}>Ваши покупки в интернет-магазине:</div>
            <div className={"my_offers_for_items_container"}>
                {
                    userOrders && userOrders[0] ?
                        userOrders.map((item,index) =>
                            <OneOffer key={item.id} item={item} />
                        )
                        :
                        <div className={"user_lc_no_my_offers"}>
                            К сожалению, у Вас нет ни одной покупки.
                        </div>
                }
            </div>
        </div>
    );
});

export default MyOffers;