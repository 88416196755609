import React, {useContext, useEffect, useState} from 'react';
import './Footer.css'
import LeftBar from "./leftbar/LeftBar";
import MidleBar from "./midleBar/MidleBar";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import PaymentMethods from "./paymentMethods/PaymentMethods";
import ContactUs from "./contactUs/ContactUs";
import MobileDownload from "./contactUs/MobileDownload";
import TextUnderFooter from "./texUnderFooter/textUnderFooter";
import {fetchSettingsData} from "../../http/settingsApi";

const Footer = observer(() => {

    const {settings} = useContext(Context);

    const [noJsonMenuFooter, setNoJsonMenuFooter] = useState('')
    const [noJsonContentFooter, setNoJsonContentFooter] = useState('')


    useEffect(() => {
        //Запрос получающий кастомные "категории" //
        fetchSettingsData().then(dataResponse => {
            if(dataResponse.footer) {
                const noJson = JSON.parse(dataResponse.footer);
                setNoJsonMenuFooter(noJson);
            }
            if(dataResponse.footer_content) {
                const noJson = JSON.parse(dataResponse.footer_content);
                setNoJsonContentFooter(noJson);
            }
        })
    }, [])


    return (
        <footer id={"footer"} className={settings.offHeader ? "hiddenFooter" : "footer"}>
            <div className={"first_line_footer"}>
                <LeftBar
                    logoFooter={noJsonContentFooter.logo}
                />
                <MidleBar
                    noJsonMenuFooter={noJsonMenuFooter}
                />

                <div className={"payment_and_social"}>
                    <div className={"payment_block"}>
                        <PaymentMethods
                            paymentFooter={noJsonContentFooter.paymentMethods}
                        />
                        {
                            settings.projectWidth > 767 ?
                                <div className={"with_suport"}>
                                    <a href='https://www.fasie.ru/'>
                                        При поддержке&nbsp;Фонда Содействия Инновациям
                                        <img className={"fond_icon"} src="/files/images/footer/with_fond_suport.png" alt=""/>
                                     </a>

                                </div>
                            : ""
                        }
                    </div>
                    <ContactUs
                        noJsonContentFooter={noJsonContentFooter}
                    />
                    {
                        settings.projectWidth < 767 ?
                            <div className={"with_suport"}>
                                <a href='https://www.fasie.ru/'>
                                    При поддержке&nbsp;Фонда Содействия Инновациям
                                    <img className={"fond_icon"} src="/files/images/footer/with_fond_suport.png" alt=""/>
                                </a>
                            </div>
                        : ""
                    }
                    <MobileDownload />
                </div>
            </div>
           <div className={"two_line_footer"}>
               <TextUnderFooter
                   dopTextFooter={noJsonContentFooter}
               />
           </div>
        </footer>
    );
});

export default Footer;



