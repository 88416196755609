import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import './userExist.css';
import {observer} from "mobx-react-lite";
import InputMask from "react-input-mask";
import {login, registration} from "../../../http/userAPI";
import {Context} from "../../../index";
import {InvisibleSmartCaptcha, SmartCaptcha} from '@yandex/smart-captcha';


const UserExist = observer((props) => {

    const captchaRef = useRef(null)

    const {user, basket, delivery} = useContext(Context)

    const [codeSelectCounter,setCodeSelectCounter] = useState(0);

    const [existUserState,setExistUserState] = useState(1);

    const [loginCode,setLoginCode] = useState("");

    const [errorMessage, setErrorMessage] = useState("");

    const [timer,setTimer] = useState(0);

    const [captcha, setCaptcha] = useState("");

    const [userErrorText, setUserErrorText] = useState("");

    const [childKey, setChildKey] = React.useState(1);

    const [visible, setVisible] = useState(true);

    const handleChallengeHidden = useCallback(() => setVisible(false), []);

    const resetChildComponent = () => {
        setChildKey(childKey + 1);
    };

    const onTokenExpired = () => {
        setVisible(true);
    }

    const onSuccessHandle = (token) => {
        setCaptcha(token);
        setVisible(false);
    }

    const sendAuthSmsToUserHandler = async (number) => {
        if(number.length <= 7 || number.length > 25) return;
        resetChildComponent();
        setVisible(true);
        
        let token = captcha;

        let replacePhoneNumber = number.replace(/[^\d]/g, '');

        let country = "Россия";

        const response = await registration(replacePhoneNumber,country,token);
        if(response.data) {
            if(response.data.success === true) {
                setExistUserState(2);
                setCaptcha("");
                setTimer(30);
            } else {
                setUserErrorText(response.data.error);
            }
        }
    }

    useEffect(() => {
        if(timer === 0) {
            return false;
        }
        setTimeout(() => {
            setTimer(timer-1);
        },1000)

    },[timer])

    useEffect(() => {
        if(loginCode.replace(/[^\d]/g, '').length < 4 ) {
            return false
        }
        if(codeSelectCounter >= 10) {
            setErrorMessage("Вы превысили количество попыток.");
            return false;
        }

        let replacePhoneNumber = delivery.customerInfo.telephone.replace(/[^\d]/g, '');
        login(replacePhoneNumber,loginCode).then(data => {
            // console.log(data);
            setCodeSelectCounter(codeSelectCounter+1);
            if(data.message){
                setErrorMessage(data.message);
            }
            if(data.id){
                user.setUser(data);
                user.setIsAuth(true);
                basket.setUserPhoneConfirm(true)
                setExistUserState(30)
                basket.delivery.setTelephoneValidShield("")
            }

        })

    },[loginCode])


    return (
        <div className={"find_user_by_number_container"}>

            {
                existUserState === 1 ?
                    <div className={"wrapper_for_exist_user_notificator"}>
                       {
                           props.showText ?
                               <span>
                                   <span className={"red_valid_text"}>{basket.findUserByNumber}</span><br/>
                                    <span className={"gray_valid_text"}>Если это ваш номер, подтвердите его кодом из СМС</span>
                               </span>
                           : ""
                       }
                        <span
                            onClick={ e => sendAuthSmsToUserHandler(delivery.customerInfo.telephone) }
                            className={captcha ? 'exist_get_me_sms' : 'exist_get_me_sms_unactive'}> Получить СМС </span>
                        <span className={"red_valid_text"}>{userErrorText}</span>
                        <div style={{display: "none"}} className={"captcha"}>
                            <InvisibleSmartCaptcha
                                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                onSuccess={onSuccessHandle}
                                onTokenExpired={onTokenExpired}
                                onChallengeHidden={handleChallengeHidden}
                                visible={visible}
                                hideShield={true}
                            />
                        </div>

                    </div> : ""
            }

            {
                existUserState === 2 ?
                    <div className={"wrapper_for_auth_field"}>
                        <div>
                            <span
                                onClick={ e => sendAuthSmsToUserHandler(delivery.customerInfo.telephone) }
                                className={ (!timer && codeSelectCounter < 10) ? "get_user_new_sms active" : "get_user_new_sms"}>отправить СМС еще раз  {timer ? "("+timer+")" : "" }</span>
                                <div style={{display: "none"}} className={"captcha"}>
                                    <InvisibleSmartCaptcha
                                        key={childKey}
                                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                        onSuccess={onSuccessHandle}
                                        onTokenExpired={onTokenExpired}
                                        onChallengeHidden={handleChallengeHidden}
                                        visible={visible}
                                    />
                                </div>
                        </div>
                        <InputMask
                            className={"exist_auth_sms_code"}
                            placeholder={"Код из SMS"}
                            mask={"9999"}
                            value={loginCode}
                            onChange={e => setLoginCode(e.target.value)}
                            alwaysShowMask={false}
                            maskChar={"X"}
                        />
                        {
                            errorMessage ?
                                <span className={"error_message_exist_user"}>{errorMessage}</span> : ""
                        }
                    </div>
                    : ""
            }
            {
                existUserState === 3 ?
                    <span className={"auth_exist_number_confirm"}>Номер подтвержден. Можете продолжить оформление</span>
                    : ""
            }
        </div>
    );
});

export default UserExist;