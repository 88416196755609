import React, {useEffect, useState} from 'react';
import "./modalPage.css"
import {catalogProductSizePreset, contentShopImage} from "../../../../utils/consts";
import OneImageField from "../../UI/oneImageField/OneImageField";
import {addImages, deleteImage} from "../../../../http/imagesApi";
import {getAllModals} from "../../../../http/settingsApi";
import OneModalComponent from "./OneModalComponent";
const ModalPage = () => {

//     const uploadModalImage = (e,id) => {
//
//         let newFile = e.target.files[0];
//
//         let formData = new FormData();
//         formData.append("image", newFile);
//
//         if (oneShop.img_shop){
//             deleteImage(oneShop.img_shop, "modal", null).then(response  => {
//                 setOneShop({...oneShop,img_shop: ""})            })
//         }
//
//         addImages(formData,'modal',null, contentShopImage).then(response => {
//             if(response.message) {
//                 setOneShop({...oneShop,img_shop:response.fileName})
//             }
//         })
//
//     }
//
//     const deleteImageShopOnClick = (filename) => {
//         deleteImage(filename, "shop", null).then(response  => {
//             setOneShop({...oneShop,img_shop: ""})             })
//     }
//
//.
    const [allModals,setAllModals] = useState([])

    useEffect(() => {
      getAllModals().then(response => {
          if(response) {
              setAllModals(response);
          }
      })
    },[])


    return (
        <div className={"wrapper_status_adm_page"}>
            <div className={"status_adm_title"}>
                Модальные окна
            </div>

            {
                allModals ? allModals.map(item => <OneModalComponent key={item.id} item={item} />)
                    : ""
            }

        </div>
    );
};

export default ModalPage;