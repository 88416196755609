import React from 'react';
import {Link} from "react-router-dom";

const OneInstaLook = (props) => {


    return (
        <div className={"one_circle_wrap"}>
            <Link className={"link_look"} to={"/lookbook/"+props.item.id+"/"}>
                <div className={"insta_circle"}>
                    {
                        props.item.img_mini ?
                            <img src={process.env.REACT_APP_API_URL+"uploads/images/lookbook/"+props.item.id+"/"+"90_"+props.item.img_mini} alt={'YouWanna - Lookbook '+(props.item.name ? props.item.name : '')}/>
                            : ''
                    }
                </div>
                {
                    props.item.name ?
                        <div className={"title_highlights"}>{props.item.name}</div>
                        : ""
                }
            </Link>
        </div>
    );
};

export default OneInstaLook;