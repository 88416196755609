import React, {useState} from 'react';
import './onelandry.css';
import CompliteRemove from "../../UI/compliteremove/CompliteRemove";
import {removeLandry, saveLandry} from "../../../../http/catalogApi";
import {addImages, deleteImage} from "../../../../http/imagesApi";
import TrashCont from "../../UI/trashICont/TrashCont";

const OneLandry = (props) => {



    const removeOneItem = async (id) => {
        const data = await removeLandry(id);
        if(data.message) {
            setDelState(true);
            setTimeout(()=> {
                let reduceArray = [...props.landryAll]
                reduceArray.splice(props.index,1);
                props.setLandryAll(reduceArray)
            },800)
        }
    }
    const saveOneLandry = async (item) => {
        const data = await saveLandry(item);
        if(data.message) {
            setTrigerSaveBtn(false);
        }

    }
    const dragDropHandlerImage = async (e) => {
        e.preventDefault();

        let files = [...e.target.files];
        if(props.item.img) {
           const data = await supportDeleteImage(props.item.img,'landry',props.item.id)
        }

        let formData = new FormData();
        formData.append("image", files[0]);
        addImages(formData,"landry", props.item.id).then(response => {
            if(response.message) {
                props.setLandryAll([...props.landryAll],[props.landryAll[props.index].img = response.fileName])
            }

        } )
        setTrigerSaveBtn(true)
    }

    const supportDeleteImage = async (fileName,parrent,child) => {
        const onDelete = await  deleteImage(fileName,parrent,child);
        if(onDelete){
            props.setLandryAll([...props.landryAll],[props.landryAll[props.index].img = ''])
            saveOneLandry(props.item).then(data => {

            })
        }

    }

    const [removeState,setRemoveState] = useState(false);

    const [delState,setDelState] = useState(false);

    const [trigerSaveBtn,setTrigerSaveBtn] = useState(false);



    return (
        <div className={delState ? "one_landry del" : "one_landry"}>
            <CompliteRemove open={removeState} goRemove={ e => removeOneItem(props.item.id,props.index) } canselRemove={e => setRemoveState(false)} />

            <div className={"one_landry_dashboard"}>
                <span className={"id_landry"}>
                    {props.item.id}
                </span>
                <span onClick={e => setRemoveState(true)} className={"span_delete_landry"}>Удалить</span>
            </div>
            <div className={"work_area_landry"}>
                <div className={"wrapper_field_op"}>
                    <span className={"name_field_op"}>Текст</span>
                    <input
                        className={"field_op"}
                        value={props.landryAll[props.index].text ? props.landryAll[props.index].text : ""}
                        onChange={ e => (props.setLandryAll([...props.landryAll], [props.landryAll[props.index].text = e.target.value], setTrigerSaveBtn(true)))}
                        type="text" name="" id=""/>
                </div>

                <div className={"images_container_landry"}>
                    <div className={"content_img_cont"}>
                        <div className={"one_img_container_landr"}>
                            {
                                props.item.img ?
                                    <div className={"image_drop_landry"}>

                                        <div
                                            onClick={e => supportDeleteImage(props.item.img,'landry',props.item.id)}
                                            className={"div_del_landry_img"}><TrashCont  /></div>
                                        <img src={process.env.REACT_APP_API_URL+"uploads/images/landry/"+props.item.id+"/"+props.item.img} alt=""/>
                                    </div>
                                    :  <div>загрузить <br/> фото</div>

                            }

                            <input className={"drop_area_landry"} type="file"
                                    onChange={e => dragDropHandlerImage(e) }
                                   name=""
                                   id=""/>
                        </div>
                    </div>
                </div>

            </div>
            <div
                onClick={e => saveOneLandry(props.item)}
                className={trigerSaveBtn ? "save_landry_btn trig" : "save_landry_btn" }>сохранить</div>
        </div>
    );
};

export default OneLandry;
