import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {priceToUserString} from "../../../../http/basketApi";


const OneLineOrder = (props) => {

    const [oneLine, setOneLine] = useState(null)
    const [createdAt, setCreatedAt] = useState(null)
    const [basketItemsForLine, setBasketItemsForLine] = useState(null);
    const [cityInfoLine, setCityInfoLine] = useState(null);



    useEffect( () => {
        setOneLine(props.item)
        let data = new Date(props.item.createdAt);
        setCreatedAt(data);

        try {
            if(props.item.BasketItems[0]) {
                setBasketItemsForLine(JSON.parse(props.item.BasketItems))
            }
            if(props.item.cityInfo[0]) {
                setCityInfoLine(JSON.parse(props.item.cityInfo))
            }
        } catch (error) {
            console.log('not valid json', props.item.BasketItems);
        }
    },[])

    // console.log(basketItemsForLine);
    return (
        <div className={"wrapper_container_for_one_line"}>
            {
                oneLine ?
                <Link to={"/admindashboard/orderlist/" + oneLine.custom_order_id} className={"order_one_line"}>
                    <div className={"one_line_sys_id"}>
                        {oneLine.retailCreatedId ? oneLine.retailCreatedId : ""}
                    </div>
                    <div className={"one_line_order_number"}> {oneLine.order_number ? "№ "+oneLine.order_number : ""} </div>
                    <div className={"one_line_order_id"}> {oneLine.custom_order_id ? "№ "+oneLine.custom_order_id : ""} </div>

                            <div className={"one_line_user_info"}>

                                <span>Имя:  <span className={"one_line_strong_span"}>{oneLine.name ? oneLine.name : ""}</span></span>
                                <span>Фамилия:  <span className={"one_line_strong_span"}>{oneLine.surname ? oneLine.surname : ""}</span></span>
                                <span>Отчество: <span className={"one_line_strong_span"}>{oneLine.patronymic ? oneLine.patronymic : ""}</span></span>
                            </div>


                    <div className={"one_line_order_status"}>
                        {oneLine.pre_order ?
                            <div className={"pre_order_info_for_line"}>Предзаказ</div>
                            :
                            ""
                        }
                        {oneLine.fast_order ?
                            <div className={"pre_order_info_for_line"}>Быстрый заказ</div>
                            :
                            ""
                        }
                        {oneLine.status_order ? oneLine.status_order.name : ""}
                    </div>
                    <div className={"one_line_order_credited"}> { oneLine.money_credited ? "Да" : "Нет"} </div>
                    <div className={"one_line_order_to_pay_summ"}>
                        <span>К оплате: {priceToUserString(oneLine.result_price)+ " ₽"}</span>
                        <span className={"mrt_span"}>Детально</span>
                        <span className={"one_line_low_text_span"}>Без скидки: {priceToUserString(oneLine.no_sale_summ)+ " ₽"} </span>
                        <span className={"one_line_low_text_span"}>скидка:
                            {
                                oneLine.no_sale_summ + oneLine.deliveryPrice !== oneLine.result_price ?
                                    priceToUserString(oneLine.no_sale_summ + oneLine.deliveryPrice - oneLine.result_price)+"  ₽"
                                    :
                                    "0 ₽"
                            }
                            {/*{priceToUserString(oneLine.no_sale_summ - oneLine.result_price - oneLine.deliveryPrice)+ " ₽"} */}
                        </span>
                        <span className={"one_line_low_text_span"}>Доставка: { oneLine.deliveryPrice ? priceToUserString(oneLine.deliveryPrice)+ " ₽" : "0 ₽"} </span>

                    </div>
                    <div style={basketItemsForLine && basketItemsForLine.length > 1 ? {overflowY:"scroll"}: {overflowY: "unset"}}
                         className={"one_line_items_basket"}>
                        {basketItemsForLine ? basketItemsForLine.map((item,index) => {
                            return(
                                <div className={"one_line_one_item"} key={index}>
                                    <span className={"one_line_one_item_name"}>{item.item.name}</span>
                                    <div className={"one_line_one_item_support_container"}>
                                        <img src={process.env.REACT_APP_API_URL+"uploads/images/order/214_"+item.item.img_preview} alt=""/>
                                        <div className={"one_line_one_item_support_container_inform"}>
                                            <span>Кол-во: {item.count} </span>
                                            <span>Размер: {item.currentSize}</span>
                                            <span>Цвет: {item.item.color.color_name}</span>
                                            <span>Цена: {item.item.sell_price ? priceToUserString(item.item.sell_price)+" ₽ ": priceToUserString(item.item.product.price)+" ₽"}</span>
                                        </div>
                                    </div>

                                </div>)
                        }) : ""}
                    </div>
                    <div className={"one_line_delivery_address"}>
                        <span>Город: {cityInfoLine ? cityInfoLine.value : ""} </span>
                        <span>Доставка: {oneLine.delivery ? oneLine.delivery.name : ""}</span>
                        { oneLine.delivery && oneLine.delivery.delivery_type === "sdek_pvz" ?
                          <div className={"one_line_pvz_container"}>
                              <span>code: {oneLine.pvz_code}</span>
                              <span>postal: {oneLine.pvz_postal}</span>
                              <span>Адресс: {oneLine.pvz_address}</span>
                          </div>
                            :
                            <span>
                            Адресс: {oneLine.orderAdressSearch}
                            </span>
                        }
                    </div>
                    <div className={"one_line_email_field"}>{oneLine.email}</div>
                    <div className={"one_line_telephone"}>{oneLine.telephone}</div>
                    <div className={"one_line_create_time"}> {createdAt ? ""+createdAt.toLocaleString()+"" : "" } </div>

                </Link> :
                    ""
            }
        </div>
    );
};


export default OneLineOrder;