import React from 'react'
import {Link} from "react-router-dom"

import './style.css'

const SeoMenuContent = (props) => {
    return (<div className="seo-menu-content">
        {
            props.headerMenu.length &&
            props.headerMenu.map((menuItem, index) => {
                return (
                    <div key={index}>
                        {
                            menuItem.child ?
                                menuItem.child.map((item, index) => {

                                    if (menuItem.child.skip === true) {
                                        return (
                                            <div key={index} className={"one_column_menu"}>
                                                <Link to={"/catalog/" + item.slug + "/"}
                                                      key={index}>{item.name}</Link>
                                                {
                                                    item.child_elements ?
                                                        item.child_elements.map((childItem, indexItem) => {
                                                            return (<Link key={childItem.slug}
                                                                          to={"/catalog/" + item.slug + "/" + childItem.slug + "/"}>{childItem.name}</Link>)
                                                        }) : ""
                                                }
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div key={index} className={"one_column_menu"}>
                                                <Link className={"child_menu_link"}
                                                      to={"/catalog/" + menuItem.child.slug + "/" + item.slug + "/"}
                                                      key={index}>{item.name}</Link>
                                                {
                                                    item.child_elements ?
                                                        item.child_elements.map((childItem, indexItem) => {
                                                            return (<Link key={indexItem}
                                                                          to={"/catalog/" + menuItem.child.slug + "/" + childItem.slug + "/"}>{childItem.name}</Link>)
                                                        }) : ""
                                                }
                                            </div>
                                        )
                                    }

                                }) : ""
                        }
                    </div>
                )
            })
        }
    </div>)
}

export {SeoMenuContent}