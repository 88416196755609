import React, { useEffect, useState, useRef } from 'react';
import { getVideos, saveVideo, deleteVideo } from '../../../../http/contentApi';
import './oneVideo.css';
import Pagination from '../../../../components/Paginstion';
import { ReactComponent as Loader } from '../../../../components/Loader/loader.svg'

const ITEMS_PER_PAGE = 10;
const OneVideo = () => {
	const queryParams = new URLSearchParams(window.location.search)
    let queryPage = +queryParams.get('page')

	if(!queryPage || queryPage <= 0)
		queryPage = 1

	const fileInputRef = useRef(null);
	const [newFile, setNewFile] = useState(null);
	const [videos, setVideos] = useState([]);
	const [error, setError] = useState('');
	const [message, setMessage] = useState('');
    const [allCount, setAllCount] = useState(0);
    const [showSpinner, setShowspinner] = useState(false);
    const [btnDisable, setBtnDisable] = useState(false);
    const [page, setPage] = useState(queryPage)

	useEffect(() => {
		getVideos(ITEMS_PER_PAGE, (page - 1) * ITEMS_PER_PAGE).then((response) => {
			setVideos(response.rows);
			setAllCount(response.count)
		});
	}, [page]);

	const uploadVideo = async (e) => {
		e.preventDefault();
		if (!btnDisable) {
			setMessage('');
			setBtnDisable(true);
			const form = e.target;
			const newFile = form.video.files[0];
			const description = form.description.value;
			const isTooBig = newFile && newFile.size > 209715200;

			if (!newFile || !description) {
				setError('Заполните описание и загрузите видео');
				setBtnDisable(false);
				setShowspinner(false);
				return;
			}

			if (newFile && description && !isTooBig) {
				setError('');
			}

			const formData = new FormData();
			formData.append('video', newFile);
			formData.append('description', description);
			setShowspinner(true);

			const {video, success, isUpdated, error = '', message = ''} = await saveVideo(formData);
			const isSucc = success && !isUpdated;

			setError(error);
			setMessage(message);

			if (isSucc) {
				e.target.reset();
				setVideos([video, ...videos]);
				setNewFile(null);
			}

			setShowspinner(false)
			setBtnDisable(false);
		}
	};

	const removeVideo = async (id) => {
		const resp = await deleteVideo(id);
		const isSucc = resp.success;
		const newVideos = videos.filter((video) => {
			return id !== video.id;
		});
		isSucc && setVideos(newVideos);
	};

	const onChange = (e) => {
		const newFile = e.target.files[0];
		setNewFile(newFile);
		const isTooBig = newFile.size > 209715200;
		setMessage('');
		if (isTooBig) {
			setError('Файл слишком большой');
		}
	};

	const clearFiles = (e) => {
		e.target.files = [];
		setNewFile(null);
	};

	return (
		<div className={'one_look'}>
			<div className="videos">
				<div className="videos__wrapper">
					<form className="videos__new" onSubmit={uploadVideo}>
						<label className="videos__description-label" htmlFor="video-name-input">
							Введите описание видео
						</label>

						<div className="video__input-container">
							<input
								type="text"
								name="description"
								className="video__video-name-input"
								id="video-name-input"
							/>

							<label className="video__upload-file-label">
								<span className="video__upload-file-icon">
									<svg
										width="14"
										height="14"
										viewBox="0 0 14 14"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M6.25 13.75V7.75H0.25V6.25H6.25V0.25H7.75V6.25H13.75V7.75H7.75V13.75H6.25Z"></path>
									</svg>
								</span>
								<input
									type="file"
									className="video__video-input"
									data-attach-input=""
									name="video"
									data-max-files="5"
									ref={fileInputRef}
									onChange={onChange}
								/>
								{!newFile && (
									<span className="video__file-text">Прикрепите файл</span>
								)}
							</label>

							{newFile && <NewFile onRemove={clearFiles} name={newFile.name} />}
						</div>

						<button className="video__submit-button save_fields_lookbook">
							{showSpinner ? <Loader className={'spinner'}/> : 'Сохранить'}
						</button>
						{error && <p className="videos__error">{error}</p>}
						{message && <p className="videos__message">{message}</p>}
					</form>
					<ul className="videos__list">
						{videos.length ?
							videos.map((video) => {
								const crAt = new Date(video.createdAt);
								const options = {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
								};
								const formattedDate = crAt.toLocaleString('ru-ru', options);
								return (
									<li key={video.id} className="videos__list-item" title={video.path}>
										<div className="videos__info">
											<button
												className="videos__list-delete-button save_fields_lookbook"
												onClick={() => removeVideo(video.id)}
											>
												Удалить
											</button>
											<span className="video__new-file-icon">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="video">
												<path d="M25 2.5H7A4.46 4.46 0 0 0 2.5 7v18A4.46 4.46 0 0 0 7 29.5h18a4.46 4.46 0 0 0 4.5-4.5V7A4.46 4.46 0 0 0 25 2.5ZM28.5 7v.5h-2.29l2-2A3.44 3.44 0 0 1 28.5 7Zm-.88-2.28L24.79 7.5h-3.58l4-4a3.42 3.42 0 0 1 2.41 1.18ZM16.21 7.5l4-4h3.58l-4 4Zm-5 0 4-4h3.58l-4 4Zm-5 0 4-4h3.58l-4 4Zm.79-4h1.79l-4 4H3.5V7A3.46 3.46 0 0 1 7 3.5Zm18 25H7A3.46 3.46 0 0 1 3.5 25V8.5h25V25a3.46 3.46 0 0 1-3.5 3.5Z" />
												<path d="M6.85 26.5A1.49 1.49 0 0 1 5.36 25v-8.21a.5.5 0 0 0-.5-.5.5.5 0 0 0-.5.5V25a2.49 2.49 0 0 0 2.49 2.5.5.5 0 1 0 0-1zm2.65 0h-.93a.5.5 0 0 0 0 1h.93a.5.5 0 0 0 0-1zm11.27-10L14 12.34a1.53 1.53 0 0 0-2.33 1.31V22a1.54 1.54 0 0 0 1.54 1.54 1.42 1.42 0 0 0 .79-.23l6.81-4.15a1.54 1.54 0 0 0 0-2.62zm-.53 1.76-6.8 4.16a.51.51 0 0 1-.54 0 .53.53 0 0 1-.27-.47v-8.3a.51.51 0 0 1 .27-.46.55.55 0 0 1 .27-.07.51.51 0 0 1 .27.08l6.8 4.15a.54.54 0 0 1 .26.46.5.5 0 0 1-.26.45z" />
											</svg>
											</span>
											<a
												href={
													process.env.REACT_APP_API_URL +
													'uploads/video/' +
													video.path
												}
												className="videos__text"
											>
												{video.path}
											</a>
											<span className="videos__description">
												{video.description}
											</span>
										</div>

										<span className="videos__date">{formattedDate}</span>
									</li>
								);
							}): ''}
					</ul>
				</div>
				
			</div>
			{
				allCount > 10 ? 
					<div className={"pagination_adm_wrapper"}>
						<Pagination
							page={page}
							count={allCount}
							limit={ITEMS_PER_PAGE}
							onChangePage={page => page && setPage(page)}
						/>	
					</div>
				: ''
			}
		</div>
	);
};

const NewFile = ({ name, onRemove }) => {
	return (
		<div className="video__new-file">
			<div className="video__new-file-icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="video">
					<path d="M25 2.5H7A4.46 4.46 0 0 0 2.5 7v18A4.46 4.46 0 0 0 7 29.5h18a4.46 4.46 0 0 0 4.5-4.5V7A4.46 4.46 0 0 0 25 2.5ZM28.5 7v.5h-2.29l2-2A3.44 3.44 0 0 1 28.5 7Zm-.88-2.28L24.79 7.5h-3.58l4-4a3.42 3.42 0 0 1 2.41 1.18ZM16.21 7.5l4-4h3.58l-4 4Zm-5 0 4-4h3.58l-4 4Zm-5 0 4-4h3.58l-4 4Zm.79-4h1.79l-4 4H3.5V7A3.46 3.46 0 0 1 7 3.5Zm18 25H7A3.46 3.46 0 0 1 3.5 25V8.5h25V25a3.46 3.46 0 0 1-3.5 3.5Z" />
					<path d="M6.85 26.5A1.49 1.49 0 0 1 5.36 25v-8.21a.5.5 0 0 0-.5-.5.5.5 0 0 0-.5.5V25a2.49 2.49 0 0 0 2.49 2.5.5.5 0 1 0 0-1zm2.65 0h-.93a.5.5 0 0 0 0 1h.93a.5.5 0 0 0 0-1zm11.27-10L14 12.34a1.53 1.53 0 0 0-2.33 1.31V22a1.54 1.54 0 0 0 1.54 1.54 1.42 1.42 0 0 0 .79-.23l6.81-4.15a1.54 1.54 0 0 0 0-2.62zm-.53 1.76-6.8 4.16a.51.51 0 0 1-.54 0 .53.53 0 0 1-.27-.47v-8.3a.51.51 0 0 1 .27-.46.55.55 0 0 1 .27-.07.51.51 0 0 1 .27.08l6.8 4.15a.54.54 0 0 1 .26.46.5.5 0 0 1-.26.45z" />
				</svg>
			</div>
			<span className="video__new-file-name">{name}</span>
			<div
				className="video__new-file-remove"
				title="Удалить"
				aria-label="Удалить"
				data-attach-remove
				onClick={onRemove}
			>
				<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
					<path d="M1.39995 13.3L0.699951 12.6L6.29995 6.99995L0.699951 1.39995L1.39995 0.699951L6.99995 6.29995L12.6 0.699951L13.3 1.39995L7.69995 6.99995L13.3 12.6L12.6 13.3L6.99995 7.69995L1.39995 13.3Z" />{' '}
				</svg>
			</div>
		</div>
	);
};

export default OneVideo;
