import React, {useState} from 'react';
import './previewDragDrop.css'
import {addImages, deleteImage} from "../../../http/imagesApi";
import DeleteImage from "../../../pages/administrator/deleteImage/DeleteImage";
import {catalogProductSizePreset} from "../../../utils/consts";

const PreviewDragDrop = (props) => {

    const [drag,setDrag] = useState(false)



    const dragStartHandler = (e) => {
        e.preventDefault();
        setDrag(true);
    }
    const dragLeaveHandler = (e) => {
        e.preventDefault();
        setDrag(false)
    }
    const dragDropHandler = (e,settings) => {
        e.preventDefault();

        if(props.oneProductCreate.previev_photo){
            deleteImage(props.oneProductCreate.previev_photo, props.oneProductCreate.id).then(data => {
            } )
        }


        // deleteImage
        let files = {};
        if(settings.method === "drop") {
            files = [...e.dataTransfer.files];
        } else {
            files = [...e.target.files];
        }


        let formData = new FormData();

        formData.append("image", files[0]);

         addImages(formData,props.oneProductCreate.id,null, catalogProductSizePreset).then((response) => {

             if(response.error) {
                 return false;
             }
             let {fileName} = response;
             props.setOneProductCreate({...props.oneProductCreate, previev_photo:fileName})

             //*******************************
             // let reader = new FileReader();
             // reader.onload = (event: any) => {
             //     const image = new Image();
             //     image.src = event.target.result;
             //     image.onload = () => {
             //            let imageWidth = image.width;
             //            let imageheight = image.height;
             //     };
             //
             // };
             // reader.readAsDataURL(files[0]);
             //*********************************

         })


        setDrag(false);
    }

    const supportDeleteImage = async (id,url) => {

        deleteImage(props.oneProductCreate.previev_photo, props.oneProductCreate.id).then(data => {
           if(data.data.error) {
               return false;
           }

           if(data.data.message === "ok") {
               props.setOneProductCreate({...props.oneProductCreate, previev_photo: "" })
           }

        })

    }



    return (
        <div className={"dragDrop_preview"}>

            {
                props.oneProductCreate.previev_photo
                ?
                <div className={"prev_photo_adm"}>
                    <DeleteImage
                        onClick={e => supportDeleteImage(props.oneProductCreate.id ,props.oneProductCreate.previev_photo)}
                        oneProductCreate={props.oneProductCreate}
                        setOneProductCreate={props.setOneProductCreate}
                    />
                    <img src={process.env.REACT_APP_API_URL+"uploads/images/"+props.oneProductCreate.id+"/"+props.oneProductCreate.previev_photo} alt=""/></div>
                : ""
            }
            {
                drag
                ? <div
                    onDragStart={e => dragStartHandler(e)}
                    onDragLeave={e => dragLeaveHandler(e)}
                    onDragOver={e => dragStartHandler(e)}
                    onDrop={e => dragDropHandler(e, {method: "drop"})}
                    className={"drop_area"}> Отпустите файл, чтобы загрузить </div>
                : <div
                    onDragStart={e => dragStartHandler(e)}
                    onDragLeave={e => dragLeaveHandler(e)}
                    onDragOver={e => dragStartHandler(e)}
                    
                    className={"drop_unactive_area"}>
                        <input
                            onChange={e => dragDropHandler(e, {method: "input"})}
                            className={"inp_file"} type="file"/>
                        {props.oneProductCreate.previev_photo ? "Файл загружен" : "Перетащите файл для загрузки"}  </div>
            }
        </div>
    );
};

export default PreviewDragDrop;