import {observer} from "mobx-react-lite";
import Heart from "../../../components/UI/Heart";
import React, {useEffect, useState} from "react";
import {priceToUserString} from "../../../http/basketApi";
import {Link, useNavigate} from "react-router-dom";
import Like from "../../../components/UI/Like/Like";
import {LazyLoadImage} from "react-lazy-load-image-component";

const RecommendItem = observer((props) => {

    const [colors, setColors] = useState(false)

    useEffect(() => {
        let colors = [];
        props.item.product.childProducts.forEach((item) => {
            if(colors.indexOf(item.color.hex) == -1){
                colors.push(item.color.hex)
            }
        })
        setColors(colors)
    }, [])

    function getUrl(){
        return props.item.product.product_url + props.item.product.articul +"/"
    }

    return (
        <div className={"basket_page_slide"}>
            <div className={"basket_page_slider_like"}>
                <Like
                    itemProduct={{...props.item, id: props.item.product.childProducts[0].id, color: props.item.product.childProducts[0].color}}
                />
            </div>
            <Link className={"basket_page_slider_image"} to={getUrl()}>
                {
                    props.item.product.childProducts[0].img_first ?
                        <LazyLoadImage src={
                            process.env.REACT_APP_API_URL+"uploads/images/"+props.item.product.id+"/"+props.item.product.childProducts[0].id+"/" + "950_"+ (props.item.product.childProducts[0].img_first)}
                                       alt={'YouWanna - ' + (props.item.product.name)}
                        /> : ""
                }
            </Link>
            <div className={"basket_page_slider_content"}>
                <Link className={"basket_page_slider_name"} to={getUrl()}>
                    <span>{props.item.product.name}</span>
                </Link>
                <div className={"basket_page_slider_row"}>
                    <span className={"basket_page_slider_price"}>{priceToUserString(props.item.product.price)+" ₽"}</span>
                    {
                        colors.length > 0 ?
                            <div className={"basket_page_slider_colors"}>
                                {
                                    colors.map((item,index) => {
                                        return (
                                            <span className={"basket_page_slider_color"} style={{backgroundColor: item}}></span>
                                        )
                                    })
                                }
                            </div> : ""
                    }


                </div>
            </div>
        </div>
    )
})

export default RecommendItem