import React, {useContext} from 'react'
import {Link} from 'react-router-dom'

import './style.css'
import {Context} from "../../index";
import {useLocation} from "react-router";

const Pagination = ({count, limit, page, onChangePage = () => {}}) => {

    const {settings} = useContext(Context)
    const location = useLocation()

    if (!count || !limit)
        return ''

    if (!page || page <= 0)
        page = 1

    if (count <= limit)
        return ''

    const allPages = Math.ceil(count / limit)

    const pageItems = []

    if (page < 1) {
        pageItems.push(page)
        pageItems.push(false)
        pageItems.push(allPages)
    } else if (page === allPages) {
        pageItems.push(page)
        pageItems.push(false)
        pageItems.push(allPages)
    } else {
        pageItems.push(page)
        pageItems.push(false)
        pageItems.push(allPages)
    }


    // if(page < 5) {
    //     for (let onePage = 1; onePage <= 5 && onePage <= allPages; onePage++)
    //         pageItems.push(onePage)
    //
    //     if(allPages > 5) {
    //         if(allPages - 5 > 1)
    //             pageItems.push(false)
    //
    //         pageItems.push(allPages)
    //     }
    // } else if(page >= allPages - 3) {
    //     pageItems.push(1)
    //     pageItems.push(false)
    //     for (let onePage = allPages - 4; onePage <= allPages; onePage++)
    //         pageItems.push(onePage)
    // } else {
    //     pageItems.push(1)
    //     pageItems.push(false)
    //     pageItems.push(page-1)
    //     pageItems.push(page)
    //     pageItems.push(page+1)
    //     pageItems.push(false)
    //     pageItems.push(allPages)
    // }


    const newQueryString = (key, value) => {
        let searchParams = new URLSearchParams(location.search);
          if (value < 2) {
            searchParams.delete(key);
        } else {
            searchParams.set(key, value);
        }
        return searchParams.toString()
    };

    return (
        <div className="pagination">
            {
                page > 1
                &&
                <Link to={`?${newQueryString('page', page - 1)}`}
                      onClick={() => onChangePage(page-1)}
                      className="pagination__btn pagination-prev">
                    <span>{settings.projectWidth && settings.projectWidth > 768 ? '❮ Назад' : '❮'}</span>
                </Link>
            }
            {pageItems.map((onePage, index) => (
                <Link
                    key={index}
                    to={`?${newQueryString('page', onePage)}`}
                    onClick={() => onChangePage(onePage)}
                    //добавила || onePage и 'non_click_pagination', блокирует клик на "из"
                    className={`pagination__btn ${page === onePage || onePage? 'current' : 'non_click_pagination'}`}>
                    <span className={''}>{onePage || 'из'}</span>
                </Link>
            ))}
            {
                page < allPages
                &&
                <Link to={`?${newQueryString('page', page + 1)}`}
                      onClick={() => onChangePage(page+1)}
                      className="pagination__btn pagination-next">
                    <span>{settings.projectWidth && settings.projectWidth > 768 ? 'Вперед ❯' : '❯'}</span>
                </Link>
            }
        </div>
    )
}

export default Pagination
