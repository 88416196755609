import React, {useRef, useLayoutEffect, useState, useMemo} from 'react';
import {Link} from "react-router-dom";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import './sliderNewProduct.css'
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Scrollbar} from "swiper";

SwiperCore.use([Scrollbar]);

const SliderNewProduct = (props) => {
    const targetRef = useRef();
    const [containerWidth, setContainerWidth] = useState(300);
    const sliderRef = useRef();

    const buttonLeftNew = () => {
        sliderRef.current.swiper.slidePrev();
    }
    const buttonRightNew = () => {
        sliderRef.current.swiper.slideNext();
    }

    useLayoutEffect(() => {
        if (targetRef.current) {
            if (window.outerWidth < 1024) {
                setContainerWidth(200);
            }
        }
    }, []);

    function urlHomeSliderNewProduct(item) {
        if (!item.product) {
            return false;
        }

        if (item.product.product_url) {
            return item.product.product_url + item.code + "/";
        }

        var url = "/catalog/new-collection/";

        url += "product/" + item.code + "/";
        return url;
    }

    const slides = useMemo(() => {
        return !!props.newProductHome && props.newProductHome.map((item, index) =>
            <SwiperSlide key={item.id}>
                <Link className={"link_one_cards"} to={urlHomeSliderNewProduct(item)}>
                    <div
                        key={index}
                        className={"one_img_new_scroll"}
                        ref={targetRef}
                    >
                        <img
                            ref={targetRef}
                            className={''}
                            src={
                                process.env.REACT_APP_API_URL +
                                'uploads/images/' +
                                item.productId +
                                '/' +
                                item.id +
                                '/' +
                                '950_' +
                                item.img_preview
                            }
                            alt={
                                'Новинка ' +
                                (item.name ? item.name : '') +
                                ' в интернет-магазине YouWanna - '
                            }
                        />

                    </div>
                </Link>
            </SwiperSlide>
        )
    }, [props.newProductHome])

    return (
        <div className={"block_new"}>
            <div className={"title_and_arrows"}>
                <div className={"title_new_block"}>
                    {
                        props.titleNewsSlider ?
                            <Link to='/catalog/new-collection/'>{props.titleNewsSlider}</Link>
                            : ''
                    }
                </div>
                <div className={"arrows_new_block"}>
                    <div onClick={e => buttonLeftNew()} className={"one_arrows_new"}>
                        <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.83 6L6.41 8.59L5 10L0 5L5 0L6.41 1.41L3.83 4L20 4V6L3.83 6Z" fill="#1A1A1A"/>
                        </svg>
                    </div>
                    <div onClick={e => buttonRightNew()} className={"one_arrows_new"}>
                        <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.17 6L13.59 8.59L15 10L20 5L15 0L13.59 1.41L16.17 4L0 4L0 6L16.17 6Z"
                                  fill="#1A1A1A"/>
                        </svg>
                    </div>
                </div>
            </div>
            {!!slides && (
                <Swiper
                    className={"product-slider"}
                    slidesPerView="auto"
                    watchSlidesProgress={true}
                    ref={sliderRef}
                    scrollbar={{draggable: true}}
                    observer={true}
                    onObserverUpdate={(swiper) => {
                        setTimeout(() => {
                            swiper.update();
                            swiper.emit('resize');
                            swiper.updateProgress();
                        }, 300)
                    }}
                >
                    {slides}
                </Swiper>
            )}
        </div>
    );
};

export default SliderNewProduct;