export const ADMIN_ROUTE = '/admindashboard/*'

export const HOMEPAGE_ROUTE = '/'
export const BASKET_ROUTE = '/personal/basket/'
export const USER_ROUTE = [
    '/personal/:searchParam',
    '/personal/'
]


export const CATALOG_ROUTE = [
    "/catalog/",
    "/catalog/:parrentCategory/",
    "/catalog/:parrentCategory/:childCategory/",
    "/catalog/:parrentCategory/:childCategory/:thirdCategory/",
    "/catalog/:parrentCategory/:childCategory/:thirdCategory/:fourthCategory/",
]

export const PRODUCT_ROUTE = [
    "/catalog/:parrentCategory/:childCategory/product/:code/",
    "/catalog/:parrentCategory/product/:code/",
    "/catalog/product/:code/",
]

export const CATALOG_FAKE_CATEGORY = [
    {name:"Бестселлеры",slug:"bestseller", weight:"", skip:false},
    {name:"Новинки",slug:"new-collection", weight:"", skip:false},
    {name:"Midseason sale",slug:"sale", weight:"", skip:false},
    {name:"Скоро в наличии",slug:"coming_soon", weight:"", skip:false},
    {name:"Каталог",slug:"", weight:"", skip:true},
]
//
export const CATALOG_LENDING_ROUTE = "/about/new/";
export const CONTENT_PAYMENT_PAGE = "/about/payment/";
export const REFOUND_PAGE = "/about/refund/";
export const DELIVERY_PAGE = "/about/delivery/";
export const NOT_FOUND = "/content/404/";
export const FAQ = "/faq/";
export const CONTACTS = "/about/contacts/";
export const BONUS_PROGRAM = "/bonus-program/";
export const PRIVACY_POLICY = "/personal/privacy-policy/";
export const PUBLIC_OFFER = "/personal/oferta/";
export const SHOP = "/shops/";
export const STOCKS_AND_TRENDS = "/about/stock-trend/";
export const ONE_STOCKS = "/about/stock-trend/stock/";
export const STOCKS_AND_TRENDS_DETAIL = "/about/info/:stock_trend_id";
export const REVIEWS = "/reviews/";
export const VACANCIES = "/about/vacancies/";
export const FAVORITES_NOT_AUTH = "/catalog/favorite_prods/";
export const ABOUT = "/about/";
export const UNSUBSCRIBE = "/unsubscribe/";
export const SITEMAP = "/sitemap/";
export const ORDER_MAKE = "/order/ID/:custom_order_id/";
export const SEARCH = "/search/"
export const REFUND_REQUEST_PAGE = "/about/refund/making-refund/"
export const SHARES = "/shares/"

export const LOOKBOOK =  "/lookbook/";
export const LOOKBOOK_DETAIL = "/lookbook/:id/";

export const FASHIONS =  "/fashions/";
export const FASHIONS_DETAIL = "/fashions/:id/";

export const LOOK_ROUTE = [
    "/catalog/product/:code/",
]

export const catalogProductSizePreset = [
    {width:950,height:1425},
    {width:607,height:911},
    {width:450,height:675},
    {width:300,height:450},
    {width:214,height:321},
]
//////ss
export const catalogHorizontalSizePreset = [
    {width:1280,height:845},
    {width:900,height:594},
    {width:600,height:396},
    {width:400,height:264},
    {width:300,height:198},
]
export const contentBackgroundImage = [
    {width:1280,height:554},
    {width:900,height:390},
    {width:600,height:260},
    {width:400,height:173},
    {width:300,height:130},
]

export const contentShopImage = [
    {width:279,height:395},
    {width:290,height:215},
]

export const LookBookPreviewsSizePreset = [
    {width:1300,height:866},
    {width:754,height:502},
    {width:350,height:233},
]

export const ImagesSizePreset = [
    {width:1300,height:866},
]
export const LookBookMiniSizePreset = [
    {width:90,height:90},
]
export const LookBookHorizontalSizePreset = [
    {width:1500,height:1000},
    {width:1005,height:670},
    {width:660,height:440},
]
export const LookBookVerticalSizePreset = [
    {width:750,height:1125},
    {width:504,height:756},
    {width:330,height:495},
]


    export const countryArr = [
        { name:"Россия" ,number:"11", imgPath:"/files/images/countries/RU.png", mask:"+79999999999", placeholder:"+7(___)___-__-__",	country_iso_code:"RU", parameters:"one"  },
        { name:"Беларусь"   , number:"12",  imgPath:"/files/images/countries/BY.png", mask:"+375999999999",    placeholder:"+375(__)___-__-__",	country_iso_code:"BY", parameters:"one"  },
        { name:"Украина" , number:"12",  imgPath:"/files/images/countries/UK.png", mask:"+380999999999",    placeholder:"+380(__)___-__-__",	country_iso_code:"UK", parameters:"one"  },
        { name:"Казахстан"   , number:"11",  imgPath:"/files/images/countries/KZ.png", mask:"+79999999999", placeholder:"+7(___)___-__-__",	country_iso_code:"KZ", parameters:"one"  },
        { name:"Узбекистан"  , number:"12",  imgPath:"/files/images/countries/UZ.png", mask:"+998999999999",    placeholder:"+998-__-___-____",	country_iso_code:"UZ", parameters:"one"  },
        { name:"Грузия"  , number:"12",  imgPath:"/files/images/countries/GE.png", mask:"+995999999999",    placeholder:"+995(___)___-___",	country_iso_code:"GE", parameters:"one"  },
        { name:"Латвия"  , number:"11",  imgPath:"/files/images/countries/LV.png", mask:"+37199999999", placeholder:"+371-__-___-___",	country_iso_code:"LV", parameters:"one"  },
        { name:"Молдова" , number:"11",  imgPath:"/files/images/countries/MD.png", mask:"+37399999999", placeholder:"+373-____-____",	country_iso_code:"MD", parameters:"one"  },
        { name:"Киргизия"    , number:"12",  imgPath:"/files/images/countries/KG.png", mask:"+996999999999",    placeholder:"+996(___)___-___",	country_iso_code:"KG", parameters:"one"  },
        { name:"Таджикистан" , number:"12",  imgPath:"/files/images/countries/TJ.png", mask:"+992999999999",    placeholder:"+992-__-___-____",	country_iso_code:"TJ", parameters:"one"  },
        { name:"Армения" , number:"11",  imgPath:"/files/images/countries/AM.png", mask:"+37499999999", placeholder:"+374-__-___-___",	country_iso_code:"AM", parameters:"one"  },
        { name:"Туркменистан"    , number:"11",  imgPath:"/files/images/countries/TM.png", mask:"+99399999999", placeholder:"+993-_-___-____",	country_iso_code:"TM", parameters:"one"  },
        { name:"Эстония" , number:"11",  imgPath:"/files/images/countries/EE.png", mask:"+37299999999", placeholder:"+372-____-____",	country_iso_code:"EE", parameters:"one"  },
        { name:"США" , number:"11",  imgPath:"/files/images/countries/US.png", mask:"+19999999999", placeholder:"+1(___)___-____",	country_iso_code:"US", parameters:"one"  },
        { name:"Канада"  , number:"11",  imgPath:"/files/images/countries/CA.png", mask:"+19999999999", placeholder:"+1(___)___-____",	country_iso_code:"CA", parameters:"one"  },
        { name:"Германия"    , number:"13",  imgPath:"/files/images/countries/DE.png", mask:"+4999999999999",   placeholder:"+49(___)___-_____",	country_iso_code:"DE", parameters:"one"  },
        { name:"Франция" , number:"11",  imgPath:"/files/images/countries/FR.png", mask:"+33999999999", placeholder:"+33(___)___-___",	country_iso_code:"FR", parameters:"one"  },
        { name:"Великобритания"  , number:"12",  imgPath:"/files/images/countries/GB.png", mask:"+449999999999",    placeholder:"+44-__-____-____",	country_iso_code:"GB", parameters:"one"  },
        { name:"Испания" , number:"11",  imgPath:"/files/images/countries/ES.png", mask:"+34999999999", placeholder:"+34(___)___-___",	country_iso_code:"ES", parameters:"one"  },
        { name:"Финляндия"   , number:"13",  imgPath:"/files/images/countries/FI.png", mask:"+3589999999999",   placeholder:"+358(___)___-__-__",	country_iso_code:"FI", parameters:"one"  },
        { name:"Остров Вознесения"   , number:"7",   imgPath:"/files/images/countries/AС.png", mask:"+2479999", placeholder:"+247-____",	country_iso_code:"AC", parameters:"one"  },
        { name:"Андорра" , number:"9",   imgPath:"/files/images/countries/AD.png", mask:"+376999999",   placeholder:"+376-___-___",	country_iso_code:"AD", parameters:"one"  },
        { name:"Объединенные Арабские Эмираты"   , number:"12",  imgPath:"/files/images/countries/AE.png", mask:"+971599999999",    placeholder:"+971-5_-___-____",	country_iso_code:"AE", parameters:"several"  },
        { name:"Объединенные Арабские Эмираты"   , number:"11",  imgPath:"/files/images/countries/AE.png", mask:"+97199999999", placeholder:"+971-_-___-____",	country_iso_code:"AE", parameters:"several"  },
        { name:"Афганистан"  , number:"11",  imgPath:"/files/images/countries/AF.png", mask:"+93999999999", placeholder:"+93-__-___-____",	country_iso_code:"AF", parameters:"one"  },
        { name:"Антигуа и Барбуда"   , number:"11",  imgPath:"/files/images/countries/AG.png", mask:"+12689999999", placeholder:"+1(268)___-____",	country_iso_code:"AG", parameters:"one"  },
        { name:"Ангилья" , number:"11",  imgPath:"/files/images/countries/AI.png", mask:"+12649999999", placeholder:"+1(264)___-____",	country_iso_code:"AI", parameters:"one"  },
        { name:"Албания" , number:"12",  imgPath:"/files/images/countries/AL.png", mask:"+355999999999",    placeholder:"+355(___)___-___",	country_iso_code:"AL", parameters:"one"  },
        { name:"Карибские Нидерланды"    , number:"10",  imgPath:"/files/images/countries/AN.png", mask:"+5999999999",  placeholder:"+599-___-____",	country_iso_code:"AN", parameters:"one"  },
        { name:"Нидерландские Антильские острова"    , number:"10",  imgPath:"/files/images/countries/AN.png", mask:"+5999999999",  placeholder:"+599-___-____",	country_iso_code:"AN", parameters:"several"  },
        { name:"Нидерландские Антильские острова"    , number:"11",  imgPath:"/files/images/countries/AN.png", mask:"+59999999999", placeholder:"+599-9___-____",	country_iso_code:"AN", parameters:"several"  },
        { name:"Ангола"  , number:"12",  imgPath:"/files/images/countries/AU.png", mask:"+244999999999",    placeholder:"+244(___)___-___",	country_iso_code:"AO", parameters:"one"  },
        { name:"Австралийская антарктическая база"   , number:"9",   imgPath:"/files/images/countries/AQ.png", mask:"+672199999",   placeholder:"+672-1__-___",	country_iso_code:"AQ", parameters:"one"  },
        { name:"Аргентина"   , number:"12",  imgPath:"/files/images/countries/AR.png", mask:"+549999999999",    placeholder:"+54(___)___-____",	country_iso_code:"AR", parameters:"one"  },
        { name:"Американское Самоа"  , number:"11",  imgPath:"/files/images/countries/AS.png", mask:"+16849999999", placeholder:"+1(684)___-____",	country_iso_code:"AS", parameters:"one"  },
        { name:"Австрия" , number:"12",  imgPath:"/files/images/countries/AT.png", mask:"+439999999999",    placeholder:"+43(___)___-____",	country_iso_code:"AT", parameters:"one"  },
        { name:"Австралия"   , number:"11",  imgPath:"/files/images/countries/AU.png", mask:"+61999999999", placeholder:"+61-_-____-____",	country_iso_code:"AU", parameters:"one"  },
        { name:"Аруба"   , number:"10",  imgPath:"/files/images/countries/AW.png", mask:"+2979999999",  placeholder:"+297-___-____",	country_iso_code:"AW", parameters:"one"  },
        { name:"Азербайджан" , number:"12",  imgPath:"/files/images/countries/AZ.png", mask:"+994999999999",    placeholder:"+994-__-___-__-__",	country_iso_code:"AZ", parameters:"one"  },
        { name:"Босния и Герцеговина"    , number:"10",  imgPath:"/files/images/countries/BA.png", mask:"+3879999999",  placeholder:"+387-__-_____",	country_iso_code:"BA", parameters:"several"  },
        { name:"Босния и Герцеговина"    , number:"9",   imgPath:"/files/images/countries/BA.png", mask:"+387999999",   placeholder:"+387-__-____",	country_iso_code:"BA", parameters:"several"  },
        { name:"Барбадос"    , number:"11",  imgPath:"/files/images/countries/BB.png", mask:"+12469999999", placeholder:"+1(246)___-____",	country_iso_code:"BB", parameters:"one"  },
        { name:"Бангладеш"   , number:"11",  imgPath:"/files/images/countries/BD.png", mask:"+88099999999", placeholder:"+880-__-___-___",	country_iso_code:"BD", parameters:"one"  },
        { name:"Бельгия" , number:"11",  imgPath:"/files/images/countries/BE.png", mask:"+32999999999", placeholder:"+32(___)___-___",	country_iso_code:"BE", parameters:"one"  },
        { name:"Буркина Фасо"    , number:"11",  imgPath:"/files/images/countries/BF.png", mask:"+22699999999", placeholder:"+226-__-__-____",	country_iso_code:"BF", parameters:"one"  },
        { name:"Болгария"    , number:"12",  imgPath:"/files/images/countries/BG.png", mask:"+359999999999",    placeholder:"+359(___)___-___",	country_iso_code:"BG", parameters:"one"  },
        { name:"Бахрейн" , number:"11",  imgPath:"/files/images/countries/BH.png", mask:"+97399999999", placeholder:"+973-____-____",	country_iso_code:"BH", parameters:"one"  },
        { name:"Бурунди" , number:"11",  imgPath:"/files/images/countries/BI.png", mask:"+25799999999", placeholder:"+257-__-__-____",	country_iso_code:"BI", parameters:"one"  },
        { name:"Бенин"   , number:"11",  imgPath:"/files/images/countries/BJ.png", mask:"+22999999999", placeholder:"+229-__-__-____",	country_iso_code:"BJ", parameters:"one"  },
        { name:"Бермудские острова"  , number:"11",  imgPath:"/files/images/countries/BM.png", mask:"+14419999999", placeholder:"+1(441)___-____",	country_iso_code:"BM", parameters:"one"  },
        { name:"Бруней-Даруссалам"   , number:"10",  imgPath:"/files/images/countries/BN.png", mask:"+6739999999",  placeholder:"+673-___-____",	country_iso_code:"BN", parameters:"one"  },
        { name:"Боливия" , number:"11",  imgPath:"/files/images/countries/BO.png", mask:"+59199999999", placeholder:"+591-_-___-____",	country_iso_code:"BO", parameters:"one"  },
        { name:"Бразилия"    , number:"12",  imgPath:"/files/images/countries/BR.png", mask:"+559999999999",    placeholder:"+55(__)____-____",	country_iso_code:"BR", parameters:"several"  },
        { name:"Бразилия"    , number:"12",  imgPath:"/files/images/countries/BR.png", mask:"+559979999999",    placeholder:"+55(__)7___-____",	country_iso_code:"BR", parameters:"several"  },
        { name:"Бразилия"    , number:"13",  imgPath:"/files/images/countries/BR.png", mask:"+5599999999999",   placeholder:"+55(__)9____-____",	country_iso_code:"BR", parameters:"several"  },
        { name:"Багамские Острова"   , number:"11",  imgPath:"/files/images/countries/BS.png", mask:"+12429999999", placeholder:"+1(242)___-____",	country_iso_code:"BS", parameters:"one"  },
        { name:"Бутан"   , number:"11",  imgPath:"/files/images/countries/BT.png", mask:"+97517999999", placeholder:"+975-17-___-___",	country_iso_code:"BT", parameters:"several"  },
        { name:"Бутан"   , number:"10",  imgPath:"/files/images/countries/BT.png", mask:"+9759999999",  placeholder:"+975-_-___-___",	country_iso_code:"BT", parameters:"several"  },
        { name:"Ботсвана"    , number:"11",  imgPath:"/files/images/countries/BW.png", mask:"+26799999999", placeholder:"+267-__-___-___",	country_iso_code:"BW", parameters:"one"  },
        { name:"Белиз"   , number:"10",  imgPath:"/files/images/countries/BZ.png", mask:"+5019999999",  placeholder:"+501-___-____",	country_iso_code:"BZ", parameters:"one"  },
        { name:"Дем. Респ. Конго (Киншаса)"  , number:"12",  imgPath:"/files/images/countries/CD.png", mask:"+243999999999",    placeholder:"+243(___)___-___",	country_iso_code:"CD", parameters:"one"  },
        { name:"Центральноафриканская Республика"    , number:"11",  imgPath:"/files/images/countries/CF.png", mask:"+23699999999", placeholder:"+236-__-__-____",	country_iso_code:"CF", parameters:"one"  },
        { name:"Конго (Браззавиль)"  , number:"12",  imgPath:"/files/images/countries/CG.png", mask:"+242999999999",    placeholder:"+242-__-___-____",	country_iso_code:"CG", parameters:"one"  },
        { name:"Швейцария"   , number:"11",  imgPath:"/files/images/countries/CH.png", mask:"+41999999999", placeholder:"+41-__-___-____",	country_iso_code:"CH", parameters:"one"  },
        { name:"Кот-д’Ивуар" , number:"11",  imgPath:"/files/images/countries/CI.png", mask:"+22599999999", placeholder:"+225-__-___-___",	country_iso_code:"CI", parameters:"one"  },
        { name:"Острова Кука"    , number:"8",   imgPath:"/files/images/countries/CK.png", mask:"+68299999",    placeholder:"+682-__-___",	country_iso_code:"CK", parameters:"one"  },
        { name:"Чили"    , number:"11",  imgPath:"/files/images/countries/CL.png", mask:"+56999999999", placeholder:"+56-_-____-____",	country_iso_code:"CL", parameters:"one"  },
        { name:"Камерун" , number:"11",  imgPath:"/files/images/countries/CM.png", mask:"+23799999999", placeholder:"+237-____-____",	country_iso_code:"CM", parameters:"one"  },
        { name:"Китайская Н.Р."  , number:"13",  imgPath:"/files/images/countries/CN.png", mask:"+8699999999999",   placeholder:"+86(___)____-____",	country_iso_code:"CN", parameters:"several"  },
        { name:"Китайская Н.Р."  , number:"12",  imgPath:"/files/images/countries/CN.png", mask:"+869999999999",    placeholder:"+86(___)____-___",	country_iso_code:"CN", parameters:"several"  },
        { name:"Китайская Н.Р."  , number:"14",  imgPath:"/files/images/countries/CN.png", mask:"+86999999999999",  placeholder:"+86-__-_____-_____",	country_iso_code:"CN", parameters:"several"  },
        { name:"Колумбия"    , number:"12",  imgPath:"/files/images/countries/CO.png", mask:"+579999999999",    placeholder:"+57(___)___-____",	country_iso_code:"CO", parameters:"one"  },
        { name:"Коста-Рика"  , number:"11",  imgPath:"/files/images/countries/CR.png", mask:"+50699999999", placeholder:"+506-____-____",	country_iso_code:"CR", parameters:"one"  },
        { name:"Куба"    , number:"10",  imgPath:"/files/images/countries/CU.png", mask:"+5399999999",  placeholder:"+53-_-___-____",	country_iso_code:"CU", parameters:"one"  },
        { name:"Кабо-Верде"  , number:"10",  imgPath:"/files/images/countries/CV.png", mask:"+2389999999",  placeholder:"+238(___)__-__",	country_iso_code:"CV", parameters:"one"  },
        { name:"Кюрасао" , number:"10",  imgPath:"/files/images/countries/CW.png", mask:"+5999999999",  placeholder:"+599-___-____",	country_iso_code:"CW", parameters:"one"  },
        { name:"Кипр"    , number:"11",  imgPath:"/files/images/countries/CY.png", mask:"+35799999999", placeholder:"+357-__-___-___",	country_iso_code:"CY", parameters:"one"  },
        { name:"Чехия"   , number:"12",  imgPath:"/files/images/countries/CZ.png", mask:"+420999999999",    placeholder:"+420(___)___-___",	country_iso_code:"CZ", parameters:"one"  },
        { name:"Джибути" , number:"11",  imgPath:"/files/images/countries/DJ.png", mask:"+25399999999", placeholder:"+253-__-__-__-__",	country_iso_code:"DJ", parameters:"one"  },
        { name:"Дания"   , number:"10",  imgPath:"/files/images/countries/DK.png", mask:"+4599999999",  placeholder:"+45-__-__-__-__",	country_iso_code:"DK", parameters:"one"  },
        { name:"Доминика"    , number:"11",  imgPath:"/files/images/countries/DM.png", mask:"+17679999999", placeholder:"+1(767)___-____",	country_iso_code:"DM", parameters:"one"  },
        { name:"Доминиканская Республика"    , number:"11",  imgPath:"/files/images/countries/DO.png", mask:"+18099999999", placeholder:"+1(809)___-____",	country_iso_code:"DO", parameters:"several"  },
        { name:"Доминиканская Республика"    , number:"11",  imgPath:"/files/images/countries/DO.png", mask:"+18299999999", placeholder:"+1(829)___-____",	country_iso_code:"DO", parameters:"several"  },
        { name:"Доминиканская Республика"    , number:"11",  imgPath:"/files/images/countries/DO.png", mask:"+18499999999", placeholder:"+1(849)___-____",	country_iso_code:"DO", parameters:"several"  },
        { name:"Алжир"   , number:"12",  imgPath:"/files/images/countries/DZ.png", mask:"+213999999999",    placeholder:"+213-__-___-____",	country_iso_code:"DZ", parameters:"one"  },
        { name:"Эквадор" , number:"12",  imgPath:"/files/images/countries/EC.png", mask:"+593999999999",    placeholder:"+593-__-___-____",	country_iso_code:"EC", parameters:"several"  },
        { name:"Эквадор" , number:"11",  imgPath:"/files/images/countries/EC.png", mask:"+59399999999", placeholder:"+593-_-___-____",	country_iso_code:"EC", parameters:"several"  },
        { name:"Египет"  , number:"12",  imgPath:"/files/images/countries/EG.png", mask:"+209999999999",    placeholder:"+20(___)___-____",	country_iso_code:"EG", parameters:"one"  },
        { name:"Эритрея" , number:"10",  imgPath:"/files/images/countries/ER.png", mask:"+2919999999",  placeholder:"+291-_-___-___",	country_iso_code:"ER", parameters:"one"  },
        { name:"Эфиопия" , number:"12",  imgPath:"/files/images/countries/ET.png", mask:"+251999999999",    placeholder:"+251-__-___-____",	country_iso_code:"ET", parameters:"one"  },
        { name:"Фиджи"   , number:"10",  imgPath:"/files/images/countries/FJ.png", mask:"+6799999999",  placeholder:"+679-__-_____",	country_iso_code:"FJ", parameters:"one"  },
        { name:"Фолклендские острова"    , number:"8",   imgPath:"/files/images/countries/FK.png", mask:"+50099999",    placeholder:"+500-_____",	country_iso_code:"FK", parameters:"one"  },
        { name:"Ф.Ш. Микронезии" , number:"10",  imgPath:"/files/images/countries/FM.png", mask:"+6919999999",  placeholder:"+691-___-____",	country_iso_code:"FM", parameters:"one"  },
        { name:"Фарерские острова"   , number:"9",   imgPath:"/files/images/countries/FO.png", mask:"+298999999",   placeholder:"+298-___-___",	country_iso_code:"FO", parameters:"one"  },
        { name:"Майотта" , number:"12",  imgPath:"/files/images/countries/FR.png", mask:"+262999999999",    placeholder:"+262-_____-____",	country_iso_code:"FR", parameters:"one"  },
        { name:"Сен-Пьер и Микелон"  , number:"9",   imgPath:"/files/images/countries/FR.png", mask:"+508999999",   placeholder:"+508-__-____",	country_iso_code:"FR", parameters:"one"  },
        { name:"Гваделупа"   , number:"12",  imgPath:"/files/images/countries/FR.png", mask:"+590999999999",    placeholder:"+590(___)___-___",	country_iso_code:"FR", parameters:"one"  },
        { name:"Габон"   , number:"10",  imgPath:"/files/images/countries/GA.png", mask:"+2419999999",  placeholder:"+241-_-__-__-__",	country_iso_code:"GA", parameters:"one"  },
        { name:"Гренада" , number:"11",  imgPath:"/files/images/countries/GD.png", mask:"+14739999999", placeholder:"+1(473)___-____",	country_iso_code:"GD", parameters:"one"  },
        { name:"Фр. Гвиана"  , number:"12",  imgPath:"/files/images/countries/GF.png", mask:"+594999999999",    placeholder:"+594-_____-____",	country_iso_code:"GF", parameters:"one"  },
        { name:"Гана"    , number:"12",  imgPath:"/files/images/countries/GH.png", mask:"+233999999999",    placeholder:"+233(___)___-___",	country_iso_code:"GH", parameters:"one"  },
        { name:"Гибралтар"   , number:"11",  imgPath:"/files/images/countries/GI.png", mask:"+35099999999", placeholder:"+350-___-_____",	country_iso_code:"GI", parameters:"one"  },
        { name:"Гренландия"  , number:"9",   imgPath:"/files/images/countries/GL.png", mask:"+299999999",   placeholder:"+299-__-__-__",	country_iso_code:"GL", parameters:"one"  },
        { name:"Гамбия"  , number:"10",  imgPath:"/files/images/countries/GM.png", mask:"+2209999999",  placeholder:"+220(___)__-__",	country_iso_code:"GM", parameters:"one"  },
        { name:"Гвинея"  , number:"11",  imgPath:"/files/images/countries/GN.png", mask:"+22499999999", placeholder:"+224-__-___-___",	country_iso_code:"GN", parameters:"one"  },
        { name:"Экваториальная Гвинея"   , number:"12",  imgPath:"/files/images/countries/GQ.png", mask:"+240999999999",    placeholder:"+240-__-___-____",	country_iso_code:"GQ", parameters:"one"  },
        { name:"Греция"  , number:"12",  imgPath:"/files/images/countries/GR.png", mask:"+309999999999",    placeholder:"+30(___)___-____",	country_iso_code:"GR", parameters:"one"  },
        { name:"Гватемала"   , number:"11",  imgPath:"/files/images/countries/GT.png", mask:"+50299999999", placeholder:"+502-_-___-____",	country_iso_code:"GT", parameters:"one"  },
        { name:"Гуам"    , number:"11",  imgPath:"/files/images/countries/GU.png", mask:"+16719999999", placeholder:"+1(671)___-____",	country_iso_code:"GU", parameters:"one"  },
        { name:"Гвинея-Бисау"    , number:"10",  imgPath:"/files/images/countries/GW.png", mask:"+2459999999",  placeholder:"+245-_-______",	country_iso_code:"GW", parameters:"one"  },
        { name:"Гайана"  , number:"10",  imgPath:"/files/images/countries/GY.png", mask:"+5929999999",  placeholder:"+592-___-____",	country_iso_code:"GY", parameters:"one"  },
        { name:"Гонконг" , number:"11",  imgPath:"/files/images/countries/HK.png", mask:"+85299999999", placeholder:"+852-____-____",	country_iso_code:"HK", parameters:"one"  },
        { name:"Гондурас"    , number:"11",  imgPath:"/files/images/countries/HN.png", mask:"+50499999999", placeholder:"+504-____-____",	country_iso_code:"HN", parameters:"one"  },
        { name:"Хорватия"    , number:"11",  imgPath:"/files/images/countries/HR.png", mask:"+38599999999", placeholder:"+385-__-___-___",	country_iso_code:"HR", parameters:"one"  },
        { name:"Гаити"   , number:"11",  imgPath:"/files/images/countries/HT.png", mask:"+50999999999", placeholder:"+509-__-__-____",	country_iso_code:"HT", parameters:"one"  },
        { name:"Венгрия" , number:"11",  imgPath:"/files/images/countries/HU.png", mask:"+36999999999", placeholder:"+36(___)___-___",	country_iso_code:"HU", parameters:"one"  },
        { name:"Индонезия"   , number:"12",  imgPath:"/files/images/countries/ID.png", mask:"+628999999999",    placeholder:"+62(8__)___-____",	country_iso_code:"ID", parameters:"several"  },
        { name:"Индонезия"   , number:"9",   imgPath:"/files/images/countries/ID.png", mask:"+629999999",   placeholder:"+62-__-___-__",	country_iso_code:"ID", parameters:"several"  },
        { name:"Индонезия"   , number:"10",  imgPath:"/files/images/countries/ID.png", mask:"+6299999999",  placeholder:"+62-__-___-___",	country_iso_code:"ID", parameters:"several"  },
        { name:"Индонезия"   , number:"11",  imgPath:"/files/images/countries/ID.png", mask:"+62999999999", placeholder:"+62-__-___-____",	country_iso_code:"ID", parameters:"several"  },
        { name:"Индонезия"   , number:"11",  imgPath:"/files/images/countries/ID.png", mask:"+62899999999", placeholder:"+62(8__)___-___",	country_iso_code:"ID", parameters:"several"  },
        { name:"Индонезия"   , number:"13",  imgPath:"/files/images/countries/ID.png", mask:"+6289999999999",   placeholder:"+62(8__)___-__-___",	country_iso_code:"ID", parameters:"several"  },
        { name:"Ирландия"    , number:"12",  imgPath:"/files/images/countries/IE.png", mask:"+353999999999",    placeholder:"+353(___)___-___",	country_iso_code:"IE", parameters:"one"  },
        { name:"Израиль" , number:"12",  imgPath:"/files/images/countries/IL.png", mask:"+972599999999",    placeholder:"+972-5_-___-____",	country_iso_code:"IL", parameters:"several"  },
        { name:"Израиль" , number:"11",  imgPath:"/files/images/countries/IL.png", mask:"+97299999999", placeholder:"+972-_-___-____",	country_iso_code:"IL", parameters:"several"  },
        { name:"Индия"   , number:"12",  imgPath:"/files/images/countries/IN.png", mask:"+919999999999",    placeholder:"+91(____)___-___",	country_iso_code:"IN", parameters:"one"  },
        { name:"Диего-Гарсия"    , number:"10",  imgPath:"/files/images/countries/IO.png", mask:"+2469999999",  placeholder:"+246-___-____",	country_iso_code:"IO", parameters:"one"  },
        { name:"Ирак"    , number:"13",  imgPath:"/files/images/countries/IQ.png", mask:"+9649999999999",   placeholder:"+964(___)___-____",	country_iso_code:"IQ", parameters:"one"  },
        { name:"Иран"    , number:"12",  imgPath:"/files/images/countries/IR.png", mask:"+989999999999",    placeholder:"+98(___)___-____",	country_iso_code:"IR", parameters:"one"  },
        { name:"Исландия"    , number:"10",  imgPath:"/files/images/countries/IS.png", mask:"+3549999999",  placeholder:"+354-___-____",	country_iso_code:"IS", parameters:"one"  },
        { name:"Италия"  , number:"12",  imgPath:"/files/images/countries/IT.png", mask:"+399999999999",    placeholder:"+39(___)____-___",	country_iso_code:"IT", parameters:"one"  },
        { name:"Ямайка"  , number:"11",  imgPath:"/files/images/countries/JM.png", mask:"+18769999999", placeholder:"+1(876)___-____",	country_iso_code:"JM", parameters:"one"  },
        { name:"Иордания"    , number:"12",  imgPath:"/files/images/countries/JO.png", mask:"+962999999999",    placeholder:"+962-_-____-____",	country_iso_code:"JO", parameters:"one"  },
        { name:"Япония"  , number:"12",  imgPath:"/files/images/countries/JP.png", mask:"+819999999999",    placeholder:"+81-__-____-____",	country_iso_code:"JP", parameters:"several"  },
        { name:"Япония"  , number:"11",  imgPath:"/files/images/countries/JP.png", mask:"+81999999999", placeholder:"+81(___)___-___",	country_iso_code:"JP", parameters:"several"  },
        { name:"Кения"   , number:"12",  imgPath:"/files/images/countries/KE.png", mask:"+254999999999",    placeholder:"+254-___-______",	country_iso_code:"KE", parameters:"one"  },
        { name:"Камбоджа"    , number:"11",  imgPath:"/files/images/countries/KH.png", mask:"+85599999999", placeholder:"+855-__-___-___",	country_iso_code:"KH", parameters:"one"  },
        { name:"Кирибати"    , number:"8",   imgPath:"/files/images/countries/KI.png", mask:"+68699999",    placeholder:"+686-__-___",	country_iso_code:"KI", parameters:"one"  },
        { name:"Коморы"  , number:"10",  imgPath:"/files/images/countries/KM.png", mask:"+2699999999",  placeholder:"+269-__-_____",	country_iso_code:"KM", parameters:"one"  },
        { name:"Сент-Китс и Невис"   , number:"11",  imgPath:"/files/images/countries/KN.png", mask:"+18699999999", placeholder:"+1(869)___-____",	country_iso_code:"KN", parameters:"one"  },
        { name:"Корейская НДР"   , number:"13",  imgPath:"/files/images/countries/KP.png", mask:"+8501919999999",   placeholder:"+850-191-___-____",	country_iso_code:"KP", parameters:"several"  },
        { name:"Корейская НДР"   , number:"11",  imgPath:"/files/images/countries/KP.png", mask:"+85099999999", placeholder:"+850-__-___-___",	country_iso_code:"KP", parameters:"several"  },
        { name:"Корейская НДР"   , number:"13",  imgPath:"/files/images/countries/KP.png", mask:"+8509999999999",   placeholder:"+850-___-____-___",	country_iso_code:"KP", parameters:"several"  },
        { name:"Корейская НДР"   , number:"9",   imgPath:"/files/images/countries/KP.png", mask:"+850999999",   placeholder:"+850-___-___",	country_iso_code:"KP", parameters:"several"  },
        { name:"Корейская НДР"   , number:"11",  imgPath:"/files/images/countries/KP.png", mask:"+85099999999", placeholder:"+850-____-____",	country_iso_code:"KP", parameters:"several"  },
        { name:"Корейская НДР"   , number:"20",  imgPath:"/files/images/countries/KP.png", mask:"+85099999999999999999",    placeholder:"+850-____-_____________",	country_iso_code:"KP", parameters:"several"  },
        { name:"Респ. Корея" , number:"11",  imgPath:"/files/images/countries/KR.png", mask:"+82999999999", placeholder:"+82-__-___-____",	country_iso_code:"KR", parameters:"one"  },
        { name:"Кувейт"  , number:"11",  imgPath:"/files/images/countries/KW.png", mask:"+96599999999", placeholder:"+965-____-____",	country_iso_code:"KW", parameters:"one"  },
        { name:"Каймановы острова"   , number:"11",  imgPath:"/files/images/countries/KY.png", mask:"+13459999999", placeholder:"+1(345)___-____",	country_iso_code:"KY", parameters:"one"  },
        { name:"Лаос"    , number:"13",  imgPath:"/files/images/countries/LA.png", mask:"+8562099999999",   placeholder:"+856(20__)___-___",	country_iso_code:"LA", parameters:"several"  },
        { name:"Лаос"    , number:"11",  imgPath:"/files/images/countries/LA.png", mask:"+85699999999", placeholder:"+856-__-___-___",	country_iso_code:"LA", parameters:"several"  },
        { name:"Ливан"   , number:"11",  imgPath:"/files/images/countries/LB.png", mask:"+96199999999", placeholder:"+961-__-___-___",	country_iso_code:"LB", parameters:"several"  },
        { name:"Ливан"   , number:"10",  imgPath:"/files/images/countries/LB.png", mask:"+9619999999",  placeholder:"+961-_-___-___",	country_iso_code:"LB", parameters:"several"  },
        { name:"Сент-Люсия"  , number:"11",  imgPath:"/files/images/countries/LC.png", mask:"+17589999999", placeholder:"+1(758)___-____",	country_iso_code:"LC", parameters:"one"  },
        { name:"Лихтенштейн" , number:"13",  imgPath:"/files/images/countries/LI.png", mask:"+4239999999999",   placeholder:"+423(___)___-____",	country_iso_code:"LI", parameters:"one"  },
        { name:"Шри-Ланка"   , number:"11",  imgPath:"/files/images/countries/LK.png", mask:"+94999999999", placeholder:"+94-__-___-____",	country_iso_code:"LK", parameters:"one"  },
        { name:"Либерия" , number:"11",  imgPath:"/files/images/countries/LR.png", mask:"+23199999999", placeholder:"+231-__-___-___",	country_iso_code:"LR", parameters:"one"  },
        { name:"Лесото"  , number:"11",  imgPath:"/files/images/countries/LS.png", mask:"+26699999999", placeholder:"+266-_-___-____",	country_iso_code:"LS", parameters:"one"  },
        { name:"Литва"   , number:"11",  imgPath:"/files/images/countries/LT.png", mask:"+37099999999", placeholder:"+370(___)__-___",	country_iso_code:"LT", parameters:"one"  },
        { name:"Люксембург"  , number:"12",  imgPath:"/files/images/countries/LU.png", mask:"+352999999999",    placeholder:"+352(___)___-___",	country_iso_code:"LU", parameters:"one"  },
        { name:"Ливия"   , number:"11",  imgPath:"/files/images/countries/LY.png", mask:"+21899999999", placeholder:"+218-__-___-___",	country_iso_code:"LY", parameters:"several"  },
        { name:"Ливия"   , number:"12",  imgPath:"/files/images/countries/LY.png", mask:"+218219999999",    placeholder:"+218-21-___-____",	country_iso_code:"LY", parameters:"several"  },
        { name:"Марокко" , number:"12",  imgPath:"/files/images/countries/MA.png", mask:"+212999999999",    placeholder:"+212-__-____-___",	country_iso_code:"MA", parameters:"one"  },
        { name:"Монако"  , number:"12",  imgPath:"/files/images/countries/MC.png", mask:"+377999999999",    placeholder:"+377(___)___-___",	country_iso_code:"MC", parameters:"several"  },
        { name:"Монако"  , number:"11",  imgPath:"/files/images/countries/MC.png", mask:"+37799999999", placeholder:"+377-__-___-___",	country_iso_code:"MC", parameters:"several"  },
        { name:"Черногория"  , number:"11",  imgPath:"/files/images/countries/ME.png", mask:"+38299999999", placeholder:"+382-__-___-___",	country_iso_code:"ME", parameters:"one"  },
        { name:"Мадагаскар"  , number:"12",  imgPath:"/files/images/countries/MG.png", mask:"+261999999999",    placeholder:"+261-__-__-_____",	country_iso_code:"MG", parameters:"one"  },
        { name:"Маршалловы Острова"  , number:"10",  imgPath:"/files/images/countries/MH.png", mask:"+6929999999",  placeholder:"+692-___-____",	country_iso_code:"MH", parameters:"one"  },
        { name:"Респ. Македония" , number:"11",  imgPath:"/files/images/countries/MK.png", mask:"+38999999999", placeholder:"+389-__-___-___",	country_iso_code:"MK", parameters:"one"  },
        { name:"Мали"    , number:"11",  imgPath:"/files/images/countries/ML.png", mask:"+22399999999", placeholder:"+223-__-__-____",	country_iso_code:"ML", parameters:"one"  },
        { name:"Бирма (Мьянма)"  , number:"10",  imgPath:"/files/images/countries/MM.png", mask:"+9599999999",  placeholder:"+95-__-___-___",	country_iso_code:"MM", parameters:"several"  },
        { name:"Бирма (Мьянма)"  , number:"9",   imgPath:"/files/images/countries/MM.png", mask:"+959999999",   placeholder:"+95-_-___-___",	country_iso_code:"MM", parameters:"several"  },
        { name:"Бирма (Мьянма)"  , number:"8",   imgPath:"/files/images/countries/MM.png", mask:"+95999999",    placeholder:"+95-___-___",	country_iso_code:"MM", parameters:"several"  },
        { name:"Монголия"    , number:"11",  imgPath:"/files/images/countries/MN.png", mask:"+97699999999", placeholder:"+976-__-__-____",	country_iso_code:"MN", parameters:"one"  },
        { name:"Макао"   , number:"11",  imgPath:"/files/images/countries/MO.png", mask:"+85399999999", placeholder:"+853-____-____",	country_iso_code:"MO", parameters:"one"  },
        { name:"Северные Марианские острова Сайпан"  , number:"11",  imgPath:"/files/images/countries/MP.png", mask:"+16709999999", placeholder:"+1(670)___-____",	country_iso_code:"MP", parameters:"one"  },
        { name:"Мартиника"   , number:"12",  imgPath:"/files/images/countries/MQ.png", mask:"+596999999999",    placeholder:"+596(___)__-__-__",	country_iso_code:"MQ", parameters:"one"  },
        { name:"Мавритания"  , number:"11",  imgPath:"/files/images/countries/MR.png", mask:"+22299999999", placeholder:"+222-__-__-____",	country_iso_code:"MR", parameters:"one"  },
        { name:"Монтсеррат"  , number:"11",  imgPath:"/files/images/countries/MS.png", mask:"+16649999999", placeholder:"+1(664)___-____",	country_iso_code:"MS", parameters:"one"  },
        { name:"Мальта"  , number:"11",  imgPath:"/files/images/countries/MT.png", mask:"+35699999999", placeholder:"+356-____-____",	country_iso_code:"MT", parameters:"one"  },
        { name:"Маврикий"    , number:"10",  imgPath:"/files/images/countries/MU.png", mask:"+2309999999",  placeholder:"+230-___-____",	country_iso_code:"MU", parameters:"one"  },
        { name:"Мальдивские острова" , number:"10",  imgPath:"/files/images/countries/MV.png", mask:"+9609999999",  placeholder:"+960-___-____",	country_iso_code:"MV", parameters:"one"  },
        { name:"Малави"  , number:"10",  imgPath:"/files/images/countries/MW.png", mask:"+2651999999",  placeholder:"+265-1-___-___",	country_iso_code:"MW", parameters:"several"  },
        { name:"Малави"  , number:"12",  imgPath:"/files/images/countries/MW.png", mask:"+265999999999",    placeholder:"+265-_-____-____",	country_iso_code:"MW", parameters:"several"  },
        { name:"Мексика" , number:"12",  imgPath:"/files/images/countries/MX.png", mask:"+529999999999",    placeholder:"+52(___)___-____",	country_iso_code:"MX", parameters:"several"  },
        { name:"Мексика" , number:"10",  imgPath:"/files/images/countries/MX.png", mask:"+5299999999",  placeholder:"+52-__-__-____",	country_iso_code:"MX", parameters:"several"  },
        { name:"Малайзия"    , number:"11",  imgPath:"/files/images/countries/MY.png", mask:"+60999999999", placeholder:"+60-__-___-____",	country_iso_code:"MY", parameters:"several"  },
        { name:"Малайзия"    , number:"11",  imgPath:"/files/images/countries/MY.png", mask:"+60999999999", placeholder:"+60(___)___-___",	country_iso_code:"MY", parameters:"several"  },
        { name:"Малайзия"    , number:"10",  imgPath:"/files/images/countries/MY.png", mask:"+6099999999",  placeholder:"+60-__-___-___",	country_iso_code:"MY", parameters:"several"  },
        { name:"Малайзия"    , number:"9",   imgPath:"/files/images/countries/MY.png", mask:"+609999999",   placeholder:"+60-_-___-___",	country_iso_code:"MY", parameters:"several"  },
        { name:"Мозамбик"    , number:"11",  imgPath:"/files/images/countries/MZ.png",   placeholder:"+258-__-___-___",	country_iso_code:"MZ", parameters:"one"  },
        { name:"Намибия" , number:"12",  imgPath:"/files/images/countries/NA.png", mask:"+264999999999",    placeholder:"+264-__-___-____",	country_iso_code:"NA", parameters:"one"  },
        { name:"Новая Каледония" , number:"9",   imgPath:"/files/images/countries/NC.png", mask:"+687999999",   placeholder:"+687-__-____",	country_iso_code:"NC", parameters:"one"  },
        { name:"Нигер"   , number:"11",  imgPath:"/files/images/countries/NE.png", mask:"+22799999999", placeholder:"+227-__-__-____",	country_iso_code:"NE", parameters:"one"  },
        { name:"Норфолк (остров)"    , number:"9",   imgPath:"/files/images/countries/NF.png", mask:"+672399999",   placeholder:"+672-3__-___",	country_iso_code:"NF", parameters:"one"  },
        { name:"Нигерия" , number:"13",  imgPath:"/files/images/countries/NG.png", mask:"+2349999999999",   placeholder:"+234(___)___-____",	country_iso_code:"NG", parameters:"several"  },
        { name:"Нигерия" , number:"11",  imgPath:"/files/images/countries/NG.png", mask:"+23499999999", placeholder:"+234-__-___-___",	country_iso_code:"NG", parameters:"several"  },
        { name:"Нигерия" , number:"10",  imgPath:"/files/images/countries/NG.png", mask:"+2349999999",  placeholder:"+234-__-___-__",	country_iso_code:"NG", parameters:"several"  },
        { name:"Нигерия" , number:"13",  imgPath:"/files/images/countries/NG.png", mask:"+2349999999999",   placeholder:"+234(___)___-____",	country_iso_code:"NG", parameters:"several"  },
        { name:"Никарагуа"   , number:"11",  imgPath:"/files/images/countries/NI.png", mask:"+50599999999", placeholder:"+505-____-____",	country_iso_code:"NI", parameters:"one"  },
        { name:"Нидерланды"  , number:"11",  imgPath:"/files/images/countries/NL.png", mask:"+31999999999", placeholder:"+31-__-___-____",	country_iso_code:"NL", parameters:"one"  },
        { name:"Норвегия"    , number:"10",  imgPath:"/files/images/countries/NO.png", mask:"+4799999999",  placeholder:"+47(___)__-___",	country_iso_code:"NO", parameters:"one"  },
        { name:"Непал"   , number:"11",  imgPath:"/files/images/countries/NP.png", mask:"+97799999999", placeholder:"+977-__-___-___",	country_iso_code:"NP", parameters:"one"  },
        { name:"Науру"   , number:"10",  imgPath:"/files/images/countries/NR.png", mask:"+6749999999",  placeholder:"+674-___-____",	country_iso_code:"NR", parameters:"one"  },
        { name:"Ниуэ"    , number:"7",   imgPath:"/files/images/countries/NU.png", mask:"+6839999", placeholder:"+683-____",	country_iso_code:"NU", parameters:"one"  },
        { name:"Новая Зеландия"  , number:"11",  imgPath:"/files/images/countries/NZ.png", mask:"+64999999999", placeholder:"+64(___)___-___",	country_iso_code:"NZ", parameters:"several"  },
        { name:"Новая Зеландия"  , number:"10",  imgPath:"/files/images/countries/NZ.png", mask:"+6499999999",  placeholder:"+64-__-___-___",	country_iso_code:"NZ", parameters:"several"  },
        { name:"Новая Зеландия"  , number:"12",  imgPath:"/files/images/countries/NZ.png", mask:"+649999999999",    placeholder:"+64(___)___-____",	country_iso_code:"NZ", parameters:"several"  },
        { name:"Оман"    , number:"11",  imgPath:"/files/images/countries/OM.png", mask:"+96899999999", placeholder:"+968-__-___-___",	country_iso_code:"OM", parameters:"one"  },
        { name:"Панама"  , number:"10",  imgPath:"/files/images/countries/PA.png", mask:"+5079999999",  placeholder:"+507-___-____",	country_iso_code:"PA", parameters:"one"  },
        { name:"Перу"    , number:"11",  imgPath:"/files/images/countries/PE.png", mask:"+51999999999", placeholder:"+51(___)___-___",	country_iso_code:"PE", parameters:"one"  },
        { name:"Французская Полинезия (Таити)"   , number:"9",   imgPath:"/files/images/countries/PF.png", mask:"+689999999",   placeholder:"+689-__-__-__",	country_iso_code:"PF", parameters:"one"  },
        { name:"Папуа-Новая Гвинея"  , number:"11",  imgPath:"/files/images/countries/PG.png", mask:"+67599999999", placeholder:"+675(___)__-___",	country_iso_code:"PG", parameters:"one"  },
        { name:"Филиппины"   , number:"12",  imgPath:"/files/images/countries/PH.png", mask:"+639999999999",    placeholder:"+63(___)___-____",	country_iso_code:"PH", parameters:"one"  },
        { name:"Пакистан"    , number:"12",  imgPath:"/files/images/countries/PK.png", mask:"+929999999999",    placeholder:"+92(___)___-____",	country_iso_code:"PK", parameters:"one"  },
        { name:"Польша"  , number:"11",  imgPath:"/files/images/countries/PL.png", mask:"+48999999999", placeholder:"+48(___)___-___",	country_iso_code:"PL", parameters:"one"  },
        { name:"Палестина"   , number:"12",  imgPath:"/files/images/countries/PS.png", mask:"+970999999999",    placeholder:"+970-__-___-____",	country_iso_code:"PS", parameters:"one"  },
        { name:"Португалия"  , number:"12",  imgPath:"/files/images/countries/PT.png", mask:"+351999999999",    placeholder:"+351-__-___-____",	country_iso_code:"PT", parameters:"one"  },
        { name:"Палау"   , number:"10",  imgPath:"/files/images/countries/PW.png", mask:"+6809999999",  placeholder:"+680-___-____",	country_iso_code:"PW", parameters:"one"  },
        { name:"Парагвай"    , number:"12",  imgPath:"/files/images/countries/PY.png", mask:"+595999999999",    placeholder:"+595(___)___-___",	country_iso_code:"PY", parameters:"one"  },
        { name:"Катар"   , number:"11",  imgPath:"/files/images/countries/QA.png", mask:"+97499999999", placeholder:"+974-____-____",	country_iso_code:"QA", parameters:"one"  },
        { name:"Реюньон" , number:"12",  imgPath:"/files/images/countries/RE.png", mask:"+262999999999",    placeholder:"+262-_____-____",	country_iso_code:"RE", parameters:"one"  },
        { name:"Румыния" , number:"11",  imgPath:"/files/images/countries/RO.png", mask:"+40999999999", placeholder:"+40-__-___-____",	country_iso_code:"RO", parameters:"one"  },
        { name:"Сербия"  , number:"12",  imgPath:"/files/images/countries/RS.png", mask:"+381999999999",    placeholder:"+381-__-___-____",	country_iso_code:"RS", parameters:"one"  },
        { name:"Руанда"  , number:"12",  imgPath:"/files/images/countries/RW.png", mask:"+250999999999",    placeholder:"+250(___)___-___",	country_iso_code:"RW", parameters:"one"  },
        { name:"Саудовская Аравия"   , number:"12",  imgPath:"/files/images/countries/SA.png", mask:"+966599999999",    placeholder:"+966-5-____-____",	country_iso_code:"SA", parameters:"several"  },
        { name:"Саудовская Аравия"   , number:"11",  imgPath:"/files/images/countries/SA.png", mask:"+96699999999", placeholder:"+966-_-___-____",	country_iso_code:"SA", parameters:"several"  },
        { name:"Соломоновы Острова"  , number:"10",  imgPath:"/files/images/countries/SB.png", mask:"+6779999999",  placeholder:"+677-___-____",	country_iso_code:"SB", parameters:"several"  },
        { name:"Соломоновы Острова"  , number:"8",   imgPath:"/files/images/countries/SB.png", mask:"+67799999",    placeholder:"+677-_____",	country_iso_code:"SB", parameters:"several"  },
        { name:"Сейшелы" , number:"10",  imgPath:"/files/images/countries/SC.png", mask:"+2489999999",  placeholder:"+248-_-___-___",	country_iso_code:"SC", parameters:"one"  },
        { name:"Судан"   , number:"12",  imgPath:"/files/images/countries/SD.png", mask:"+249999999999",    placeholder:"+249-__-___-____",	country_iso_code:"SD", parameters:"one"  },
        { name:"Швеция"  , number:"11",  imgPath:"/files/images/countries/SE.png", mask:"+46999999999", placeholder:"+46-__-___-____",	country_iso_code:"SE", parameters:"one"  },
        { name:"Сингапур"    , number:"11",  imgPath:"/files/images/countries/SG.png", mask:"+65659999999", placeholder:"+6565_______",	country_iso_code:"SG", parameters:"one"  },
        { name:"Остров Святой Елены" , number:"7",   imgPath:"/files/images/countries/SH.png", mask:"+2909999", placeholder:"+290-____",	country_iso_code:"SH", parameters:"one"  },
        { name:"Тристан-да-Кунья"    , number:"7",   imgPath:"/files/images/countries/SHT.png", mask:"+2909999", placeholder:"+290-____",	country_iso_code:"SHT", parameters:"one"  },
        { name:"Словения"    , number:"11",  imgPath:"/files/images/countries/SI.png", mask:"+38699999999", placeholder:"+386-__-___-___",	country_iso_code:"SI", parameters:"one"  },
        { name:"Словакия"    , number:"12",  imgPath:"/files/images/countries/SK.png", mask:"+421999999999",    placeholder:"+421(___)___-___",	country_iso_code:"SK", parameters:"one"  },
        { name:"Сьерра-Леоне"    , number:"11",  imgPath:"/files/images/countries/SL.png", mask:"+23299999999", placeholder:"+232-__-______",	country_iso_code:"SL", parameters:"one"  },
        { name:"Сан-Марино"  , number:"13",  imgPath:"/files/images/countries/SM.png", mask:"+3789999999999",   placeholder:"+378-____-______",	country_iso_code:"SM", parameters:"one"  },
        { name:"Сенегал" , number:"12",  imgPath:"/files/images/countries/SN.png", mask:"+221999999999",    placeholder:"+221-__-___-____",	country_iso_code:"SN", parameters:"one"  },
        { name:"Сомали"  , number:"11",  imgPath:"/files/images/countries/SO.png", mask:"+25299999999", placeholder:"+252-__-___-___",	country_iso_code:"SO", parameters:"one"  },
        { name:"Сомали"  , number:"10",  imgPath:"/files/images/countries/SO.png", mask:"+2529999999",  placeholder:"+252-_-___-___",	country_iso_code:"SO", parameters:"several"  },
        { name:"Сомали"  , number:"10",  imgPath:"/files/images/countries/SO.png", mask:"+2529999999",  placeholder:"+252-_-___-___",	country_iso_code:"SO", parameters:"several"  },
        { name:"Суринам" , number:"10",  imgPath:"/files/images/countries/SR.png", mask:"+5979999999",  placeholder:"+597-___-____",	country_iso_code:"SR", parameters:"several"  },
        { name:"Суринам" , number:"9",   imgPath:"/files/images/countries/SR.png", mask:"+597999999",   placeholder:"+597-___-___",	country_iso_code:"SR", parameters:"several"  },
        { name:"Южный Судан" , number:"12",  imgPath:"/files/images/countries/SS.png", mask:"+211999999999",    placeholder:"+211-__-___-____",	country_iso_code:"SS", parameters:"one"  },
        { name:"Сан-Томе и Принсипи" , number:"10",  imgPath:"/files/images/countries/ST.png", mask:"+2399999999",  placeholder:"+239-__-_____",	country_iso_code:"ST", parameters:"one"  },
        { name:"Сальвадор"   , number:"11",  imgPath:"/files/images/countries/SV.png", mask:"+50399999999", placeholder:"+503-__-__-____",	country_iso_code:"SV", parameters:"one"  },
        { name:"Синт-Маартен"    , number:"11",  imgPath:"/files/images/countries/SX.png", mask:"+17219999999", placeholder:"+1(721)___-____",	country_iso_code:"SX", parameters:"one"  },
        { name:"Сирия (Сирийская арабская республика)"   , number:"12",  imgPath:"/files/images/countries/SY.png", mask:"+963999999999",    placeholder:"+963-__-____-___",	country_iso_code:"SY", parameters:"one"  },
        { name:"Свазиленд"   , number:"11",  imgPath:"/files/images/countries/SZ.png", mask:"+26899999999", placeholder:"+268-__-__-____",	country_iso_code:"SZ", parameters:"one"  },
        { name:"Тёркс и Кайкос"  , number:"11",  imgPath:"/files/images/countries/TC.png", mask:"+16499999999", placeholder:"+1(649)___-____",	country_iso_code:"TC", parameters:"one"  },
        { name:"Чад" , number:"11",  imgPath:"/files/images/countries/TD.png", mask:"+23599999999", placeholder:"+235-__-__-__-__",	country_iso_code:"TD", parameters:"one"  },
        { name:"Того"    , number:"11",  imgPath:"/files/images/countries/TG.png", mask:"+22899999999", placeholder:"+228-__-___-___",	country_iso_code:"TG", parameters:"one"  },
        { name:"Таиланд" , number:"11",  imgPath:"/files/images/countries/TH.png", mask:"+66999999999", placeholder:"+66-__-___-____",	country_iso_code:"TH", parameters:"several"  },
        { name:"Таиланд" , number:"10",  imgPath:"/files/images/countries/TH.png", mask:"+6699999999",  placeholder:"+66-__-___-___",	country_iso_code:"TH", parameters:"several"  },
        { name:"Токелау" , number:"7",   imgPath:"/files/images/countries/TK.png", mask:"+6909999", placeholder:"+690-____",	country_iso_code:"TK", parameters:"one"  },
        { name:"Восточный Тимор" , number:"10",  imgPath:"/files/images/countries/TL.png", mask:"+6709999999",  placeholder:"+670-___-____",	country_iso_code:"TL", parameters:"several"  },
        { name:"Восточный Тимор" , number:"11",  imgPath:"/files/images/countries/TL.png", mask:"+67079999999", placeholder:"+670-___-_____",	country_iso_code:"TL", parameters:"several"  },
        { name:"Тунис"   , number:"11",  imgPath:"/files/images/countries/TN.png", mask:"+21699999999", placeholder:"+216-__-___-___",	country_iso_code:"TN", parameters:"one"  },
        { name:"Тонга"   , number:"8",   imgPath:"/files/images/countries/TO.png", mask:"+67699999",    placeholder:"+676-_____",	country_iso_code:"TO", parameters:"one"  },
        { name:"Турция"  , number:"12",  imgPath:"/files/images/countries/TR.png", mask:"+909999999999",    placeholder:"+90(___)___-____",	country_iso_code:"TR", parameters:"one"  },
        { name:"Тринидад и Тобаго"   , number:"11",  imgPath:"/files/images/countries/TT.png", mask:"+18689999999", placeholder:"+1(868)___-____",	country_iso_code:"TT", parameters:"one"  },
        { name:"Тувалу"  , number:"9",   imgPath:"/files/images/countries/TV.png", mask:"+688909999",   placeholder:"+688-90____",	country_iso_code:"TV", parameters:"several"  },
        { name:"Тувалу"  , number:"8",   imgPath:"/files/images/countries/TV.png", mask:"+68829999",    placeholder:"+688-2____",	country_iso_code:"TV", parameters:"several"  },
        { name:"Тайвань" , number:"12",  imgPath:"/files/images/countries/CN.png", mask:"+886999999999",    placeholder:"+886-_-____-____",	country_iso_code:"TW", parameters:"several"  },
        { name:"Тайвань" , number:"11",  imgPath:"/files/images/countries/CN.png", mask:"+88699999999", placeholder:"+886-____-____",	country_iso_code:"TW", parameters:"several"  },
        { name:"Танзания"    , number:"12",  imgPath:"/files/images/countries/TZ.png", mask:"+255999999999",    placeholder:"+255-__-___-____",	country_iso_code:"TZ", parameters:"one"  },
        { name:"Уганда"  , number:"12",  imgPath:"/files/images/countries/UG.png", mask:"+256999999999",    placeholder:"+256(___)___-___",	country_iso_code:"UG", parameters:"one"  },
        { name:"Уругвай" , number:"11",  imgPath:"/files/images/countries/UY.png", mask:"+59899999999", placeholder:"+598-_-___-__-__",	country_iso_code:"UY", parameters:"one"  },
        { name:"Ватикан" , number:"11",  imgPath:"/files/images/countries/VA.png", mask:"+39669899999", placeholder:"+39-6-698-_____",	country_iso_code:"VA", parameters:"one"  },
        { name:"Сент-Винсент и Гренадины"    , number:"11",  imgPath:"/files/images/countries/VC.png", mask:"+17849999999", placeholder:"+1(784)___-____",	country_iso_code:"VC", parameters:"one"  },
        { name:"Венесуэла"   , number:"12",  imgPath:"/files/images/countries/VE.png", mask:"+589999999999",    placeholder:"+58(___)___-____",	country_iso_code:"VE", parameters:"one"  },
        { name:"Британские Виргинские острова"   , number:"11",  imgPath:"/files/images/countries/VG.png", mask:"+12849999999", placeholder:"+1(284)___-____",	country_iso_code:"VG", parameters:"one"  },
        { name:"Американские Виргинские острова" , number:"11",  imgPath:"/files/images/countries/VI.png", mask:"+13409999999", placeholder:"+1(340)___-____",	country_iso_code:"VI", parameters:"one"  },
        { name:"Вьетнам" , number:"11",  imgPath:"/files/images/countries/VN.png", mask:"+84999999999", placeholder:"+84-__-____-___",	country_iso_code:"VN", parameters:"several"  },
        { name:"Вьетнам" , number:"12",  imgPath:"/files/images/countries/VN.png", mask:"+849999999999",    placeholder:"+84(___)____-___",	country_iso_code:"VN", parameters:"several"  },
        { name:"Вануату" , number:"10",  imgPath:"/files/images/countries/VU.png", mask:"+6789999999",  placeholder:"+678-__-_____",	country_iso_code:"VU", parameters:"several"  },
        { name:"Вануату" , number:"8",   imgPath:"/files/images/countries/VU.png", mask:"+67899999",    placeholder:"+678-_____",	country_iso_code:"VU", parameters:"several"  },
        { name:"Уоллис и Футуна" , number:"9",   imgPath:"/files/images/countries/WF.png", mask:"+681999999",   placeholder:"+681-__-____",	country_iso_code:"WF", parameters:"one"  },
        { name:"Самоа"   , number:"9",   imgPath:"/files/images/countries/WS.png", mask:"+685999999",   placeholder:"+685-__-____",	country_iso_code:"WS", parameters:"one"  },
        { name:"Йемен"   , number:"12",  imgPath:"/files/images/countries/YE.png", mask:"+967999999999",    placeholder:"+967-___-___-___",	country_iso_code:"YE", parameters:"several"  },
        { name:"Йемен"   , number:"10",  imgPath:"/files/images/countries/YE.png", mask:"+9679999999",  placeholder:"+967-_-___-___",	country_iso_code:"YE", parameters:"several"  },
        { name:"Йемен"   , number:"11",  imgPath:"/files/images/countries/YE.png", mask:"+96799999999", placeholder:"+967-__-___-___",	country_iso_code:"YE", parameters:"several"  },
        { name:"Южно-Африканская Респ."  , number:"11",  imgPath:"/files/images/countries/ZA.png", mask:"+27999999999", placeholder:"+27-__-___-____",	country_iso_code:"ZA", parameters:"one"  },
        { name:"Замбия"  , number:"12",  imgPath:"/files/images/countries/ZM.png", mask:"+260999999999",    placeholder:"+260-__-___-____",	country_iso_code:"ZM", parameters:"one"  },
        { name:"Зимбабве"    , number:"10",  imgPath:"/files/images/countries/ZB.png", mask:"+2639999999",  placeholder:"+263-_-______",	country_iso_code:"ZW", parameters:"one"  },
 ]

export const testFiltersData = {
    colors: [
        {
            id: 1,
            color: {
                hex: '#FFFFFF',
            },
            color_name: 'Белый'
        },
        {
            id: 2,
            color: {
                hex: '#7BA462',
            },
            color_name: 'Зеленый'
        },
        {
            id: 3,
            color: {
                hex: '#000000',
            },
            color_name: 'Черный'
        },
    ],
    sizes: [
        {
            id: 1,
            size_name: 'L'
        },
        {
            id: 2,
            size_name: 'S'
        },
        {
            id: 3,
            size_name: 'M'
        },
    ],
    trends: [
        {
            id: 1,
            trend_name: 'Кружево',
        },
        {
            id: 2,
            trend_name: 'Тотал деним',
        },
        {
            id: 3,
            trend_name: 'карго',
        },
    ],
    price_min: 3000,
    price_max: 23000,
}


export const stopDeliveryPriceSum = 5000;
