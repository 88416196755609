import {
    ADMIN_ROUTE,
    HOMEPAGE_ROUTE,
    USER_ROUTE,
    CATALOG_ROUTE,
    CATALOG_LENDING_ROUTE,
    CONTENT_PAYMENT_PAGE,
    REFOUND_AND_DELIVERY_PAGE,
    NOT_FOUND,
    REFOUND_PAGE,
    DELIVERY_PAGE,
    INTEGRATION_HELPER,
    FAQ,
    CONTACTS,
    PRIVACY_POLICY,
    PUBLIC_OFFER,
    SHOP,
    // STOCKS_AND_TRENDS,
    // ONE_STOCKS,
    REVIEWS,
    VACANCIES,
    FAVORITES_NOT_AUTH,
    BASKET_ROUTE,
    ABOUT,
    SITEMAP,
    PRODUCT_ROUTE,
    ORDER_ROUTE,
    ORDER_MAKE,
    SEARCH,
    LOOKBOOK,
    LOOK_ROUTE,
    LOOKBOOK_DETAIL,
    REFUND_REQUEST_PAGE,
    SHARES,
    STOCKS_AND_TRENDS_DETAIL, UNSUBSCRIBE, BONUS_PROGRAM,
    FASHIONS,
    FASHIONS_DETAIL
}
    from "./utils/consts"
import Admin from "./pages/administrator/Admin"
import Homepage from "./pages/homepage/Homepage";
import User from "./pages/user/User";
import CatalogLanding from "./pages/catalogLanding/CatalogLanding";
import Catalog from "./pages/catalog/Catalog";
import Payment from "./pages/content/payment/Payment";
import Refound from "./pages/content/refoundAndDelivery/Refound";
import Delivery from "./pages/content/refoundAndDelivery/Delivery";
import NotFound from "./pages/content/notFound/NotFound";
import faq from "./pages/content/faq/faq";
import Contacts from "./pages/content/contacts/Contacts";
import PrivacyPolicy from "./pages/content/PrivacyPages/PrivacyPolicy";
import PublicOffer from "./pages/content/PrivacyPages/PublicOffer";
import shop from "./pages/content/shop/Shop";
import StocksAndTrends from "./pages/content/stocksAndTrends/StocksAndTrends";
import PageStockTrend from "./pages/content/stocksAndTrends/OnePageStockTrends/PageStockTrend";
import Reviews from "./pages/content/reviews/Reviews";
import Vacancies from "./pages/content/vacancies/Vacancies";
import FavoritesNotAuthorized from "./components/personal/contentArea/Favorites/FavoritesNotAuthorized";
import Basket from "./pages/basket/Basket";
import PageAbout from "./pages/content/about/PageAbout";
import OneProductPage from "./pages/catalog/oneProductPage/OneProductPage";
import Order_confirm from "./pages/order/Order_confirm";
import SearchProductSite from "./pages/search/SearchProductSite";
import LookBook from "./pages/content/lookbook/LookBook";
import OnePageLookBook from "./pages/content/lookbook/OnePageLookBook";
import RefundRequestPage from "./components/refunds/RefundRequestPage";
import RequestForRefund from "./pages/content/refoundAndDelivery/refundRequestPages/RequestForRefund";
import SharesInfo from "./pages/shares/SharesInfo";
import StocksAndTraindsDetail from "./pages/content/stocksAndTrends/StocksAndTrendsDetail/StocksAndTrendsDetail";
import StocksAndTrendsDetail from "./pages/content/stocksAndTrends/StocksAndTrendsDetail/StocksAndTrendsDetail";
import Sitemap from "./pages/sitemap/Sitemap";
import PageUnsubscribe from "./pages/content/unsubscribe/PageUnsubscribe";
import {BonusProgram} from "./pages/bonus-program/bonus-program";
import Fashions from "./pages/content/fashions/Fashions";
import Fashion from "./pages/content/fashion/Fashion";
import { StocksAndTrendsDetailWrapper } from "./pages/content/stocksAndTrends/StocksAndTrendsDetailWrapper/StocksAndTrendsDetailWrapper";


export const authRoutes = [
    {
        path: ADMIN_ROUTE,
        Component: Admin
    }
]


export const publicRoutes = [
    {
        path: HOMEPAGE_ROUTE,
        Component: Homepage,
    },
    {
        path: CATALOG_LENDING_ROUTE,
        Component: CatalogLanding,
    },
    {
        path: CONTENT_PAYMENT_PAGE,
        Component: Payment,
    },
    // {
    //     path: REFOUND_AND_DELIVERY_PAGE,
    //     Component: RefoundAndDelivery,
    // },
    {
        path: REFOUND_PAGE,
        Component: Refound,
    },
    {
        path: DELIVERY_PAGE,
        Component: Delivery,
    },
    {
        path: FAQ,
        Component: faq,
    },
    {
        path: CONTACTS,
        Component: Contacts,
    },
    {
        path: BONUS_PROGRAM,
        Component: BonusProgram,
    },
    {
        path: PRIVACY_POLICY,
        Component: PrivacyPolicy,
    },
    {
        path: PUBLIC_OFFER,
        Component: PublicOffer,
    },
    {
        path: SHOP,
        Component: shop,
    },
    {
        path: BASKET_ROUTE,
        Component: Basket,
    },
    // {
    //     path: STOCKS_AND_TRENDS,
    //     Component: StocksAndTrends,
    // },
    {
        path: STOCKS_AND_TRENDS_DETAIL,
        Component: StocksAndTrendsDetailWrapper,
    },
    // {
    //     path: ONE_STOCKS,
    //     Component: PageStockTrend,
    // },
    // {
    //     path: REVIEWS,
    //     Component: Reviews,
    // },
    {
        path: VACANCIES,
        Component: Vacancies,
    },
    {
        path: FAVORITES_NOT_AUTH,
        Component: FavoritesNotAuthorized,
    },
    {
        path: ABOUT,
        Component: PageAbout,
    },
    {
        path: UNSUBSCRIBE,
        Component: PageUnsubscribe,
    },
    {
        path: ORDER_MAKE,
        Component: Order_confirm,

    },
    {
        path: SEARCH,
        Component: SearchProductSite,

    },
    {
        path: FASHIONS,
        Component: Fashions,
    },
    {
        path: LOOKBOOK,
        Component: LookBook,
    },
    {
        path: LOOKBOOK_DETAIL,
        Component: OnePageLookBook,
    },
    {
        path: REFUND_REQUEST_PAGE,
        Component: RequestForRefund,
    },
    {
        path: SHARES,
        Component: SharesInfo,
    },
    {
        path: '*',
        Component: NotFound,
    },
    {
        path: SITEMAP,
        Component: Sitemap,
    },


]

export const publicRoutesUser = [
    {
        path: USER_ROUTE,
        Component: User,
    }
]
export const publicRoutesCatalog = [
    {
        path: CATALOG_ROUTE,
        Component: Catalog,
    }
]
// export const routesLookBook = [
//
// ]


export const publicRoutesProduct = [
    {
        path: PRODUCT_ROUTE,
        Component: OneProductPage,
    }
]

