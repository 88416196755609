import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import "./LoadingAdmin.css";
import {adminLogin} from "../../http/userAPI";

const LoadingAdmin = observer(() => {

    const {settings,user} = useContext(Context);
    const [formState, setFormState] = useState('spinnerActive');

    useEffect(() => {

        setTimeout(() => {
            setFormState('formActive');
        }, 1000)

    },[])

    const [loginState, setLoginState] = useState('');
    const [passwordState, setPasswordState] = useState('');
    const [errorLog,setErrorLog] = useState('');
    const loginSetAndValid = (value) => {
        if(value.length*1 > 25) {
            return false;
        }
        setLoginState(value);
    }
    const passwordSetAndValid = (value) => {
        if(value.length*1 > 45) {
            return false;
        }
        setPasswordState(value);
    }

    const adminLoginPassword = async () => {
        const data = await adminLogin(loginState,passwordState);
        if (data.error) {
            setErrorLog(data.error);
            return false;
        }
        if(data.id) {
            user.setUser(data);
            user.setIsAuth(true);
        }

    }

    return (
        <div style={{marginTop:settings.headerHeight}}  className={"main adminCheck"}>
               <div className={"spin_wrapper " + formState}>
                   <div className={"spinner "}></div>
               </div>

                <div className={"admin_auth_form_container " + formState}>
                        <span className="auth_page_title">Авторизация</span>
                        <div className="auth_form_for_admin">
                            <div className="inputwrapper_auth_form">
                                <span className={"name_field_auth"}>Login</span>
                                <input
                                    className={"auth_input_atm login"}
                                    type="text"
                                    value={loginState}
                                    onChange={e => loginSetAndValid(e.target.value) }
                                />
                            </div>
                            <div className="inputwrapper_auth_form">
                                <span className={"name_field_auth"}>Password</span>
                                <input
                                    className={"auth_input_atm password"}
                                    type="password"
                                    value={passwordState}
                                    onChange={e => passwordSetAndValid(e.target.value) }
                                />
                            </div>
                            <div className={"error_field_auth_admin_page"}>{errorLog}</div>
                            <span
                                onClick={e => adminLoginPassword() }
                                className={"auth_button_admin"}>Войти</span>
                        </div>
                </div>
        </div>


    );
});

export default LoadingAdmin;
