import React, {useContext} from 'react';
import './registrationornot.css'
import {Context} from "../../../../../../index";
import {useNavigate} from "react-router";

const RegistrationOrNot = () => {

    const {user, basket} = useContext(Context)
    const navigate = useNavigate();

    const goRegistrationStep = () => {
        user.setStepModals('registration')
    }

    const goOrderAndCloseModal = () => {
        basket.setStartBasketNum(2)
        navigate('/personal/basket/')
        user.setOpenAuthModal(0);
        user.setShadowOverlay(false)
        user.setAskRegistration(false)

    }

    return (
        <div className={'registration_or_not_wrapper'}>
            <div className={"question_in_basket_wrapper"}>
                <span className={"question_in_basket_heading"}>
                    Получайте бонусы за ваши покупки
                </span>
                <div className={"question_in_basket_agitation"}>
                    Войдите/Зарегистрируйтесь, чтобы получить кешбэк бонусами с вашей покупки уже сейчас.
                    Не теряйте возможность получить скидку до 30% на следующий заказ.
                </div>
                <div
                    onClick={ e => goRegistrationStep() }
                    className={"question_in_basket_button_registration_or_not"}>

                    ВОЙТИ / зарегистрироваться
                </div>
                <span onClick={e => goOrderAndCloseModal()} className={"dont_want_registration"}>Продолжить как гость</span>
            </div>
        </div>
    );
};

export default RegistrationOrNot;