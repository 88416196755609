import {$authHost, $host} from "./index";

class ReturnsApi {

    returnError(error) {
        const res = {success: false, message: 'Непредвиденная ошибка'}
        if (error.response.data.message)
            res.message = error.response.data.message

        return res
    }

    /**
     * Список возвратов
     * @param {number} limit
     * @param {number} page
     * @param {{selected: number}} dateSort
     * @returns {Promise<{success: boolean, message: string}|{data: { row: {
     *     id: number,
     *     type: string,
     *     createdAt: string,
     *     lastName: string,
     *     firstName: string,
     *     middleName: string,
     *     phone: string,
     *     article: string,
     *     size: string,
     *     color: string,
     *     email: string,
     *     passportSeriesAndNumber: string,
     *     issuedByWhom: string,
     *     dateOfIssue: string,
     *     reasonForReturn: string,
     *     orderNumber: number,
     *     orderPlacingDate: string,
     *     bankName: string,
     *     bik: number,
     *     correspondentAccount: number,
     *     settlementAccount: number,
     *     account: number,
     *     cardNumber: string,
     *     courierService: string,
     *     trackNumber: string,
     *     returnType: string,
     *     status: string,
     *     additionalFields: string
     * }[], count: number}, success: boolean}>}
     */
    async getAll(limit = 25, page = 1, dateSort = 'asc', statusSort = "%", searchParam = null, searchQuery = null) {
        try {
            const response = await $authHost.post('/api/returns/getAll', {limit, page, dateSort, statusSort, searchParam, searchQuery})

            return {success: true, data: response.data}
        } catch (error) {
            return this.returnError(error)
        }
    }

    /**
     * @param id
     * @returns {Promise<{success: false, message: string}|{data: {
     *     id: number,
     *     type: string,
     *     createdAt: string,
     *     lastName: string,
     *     firstName: string,
     *     middleName: string,
     *     phone: string,
     *     article: string,
     *     size: string,
     *     color: string,
     *     email: string,
     *     passportSeriesAndNumber: string,
     *     issuedByWhom: string,
     *     dateOfIssue: string,
     *     reasonForReturn: string,
     *     orderNumber: number,
     *     orderPlacingDate: string,
     *     bankName: string,
     *     bik: number,
     *     correspondentAccount: number,
     *     settlementAccount: number,
     *     account: number,
     *     cardNumber: string,
     *     courierService: string,
     *     trackNumber: string,
     *     returnType: string,
     *     status: string,
     *     additionalFields: string
     * }, success: true}>}
     */
    async get(id) {
        try {
            const response = await $authHost.post('/api/returns/', {id})

            return {success: true, data: response.data}
        } catch (error) {
            return this.returnError(error)
        }
    }

    /**
     * @param {{
     *     id: number,
     *     type: string,
     *     createdAt: string,
     *     lastName: string,
     *     firstName: string,
     *     middleName: string,
     *     phone: string,
     *     article: string,
     *     size: string,
     *     color: string,
     *     email: string,
     *     passportSeriesAndNumber: string,
     *     issuedByWhom: string,
     *     dateOfIssue: string,
     *     reasonForReturn: string,
     *     orderNumber: number,
     *     orderPlacingDate: string,
     *     bankName: string,
     *     bik: number,
     *     correspondentAccount: number,
     *     settlementAccount: number,
     *     account: number,
     *     cardNumber: string,
     *     courierService: string,
     *     trackNumber: string,
     *     returnType: string,
     *     status: string,
     *     additionalFields: string
     * }} returnItem
     * @returns {Promise<{success: false, message: string}|{data: {
     *     id: number,
     *     type: string,
     *     createdAt: string,
     *     lastName: string,
     *     firstName: string,
     *     middleName: string,
     *     phone: string,
     *     article: string,
     *     size: string,
     *     color: string,
     *     email: string,
     *     passportSeriesAndNumber: string,
     *     issuedByWhom: string,
     *     dateOfIssue: string,
     *     reasonForReturn: string,
     *     orderNumber: number,
     *     orderPlacingDate: string,
     *     bankName: string,
     *     bik: number,
     *     correspondentAccount: number,
     *     settlementAccount: number,
     *     account: number,
     *     cardNumber: string,
     *     courierService: string,
     *     trackNumber: string,
     *     returnType: string,
     *     status: string,
     *     additionalFields: string
     * }, success: true}>}
     */
    async update(returnItem) {
        try {
            const response = await $authHost.post('/api/returns/update', returnItem)

            return {success: true, data: response.data}
        } catch (error) {
            return this.returnError(error)
        }
    }

    /**
     * @param {number} id
     * @returns {Promise<{success: boolean, message: string}|{data: any, success: boolean}>}
     */
    async delete(id) {
        try {
            const response = await $authHost.delete('/api/returns/delete', {data: {id}})

            return {success: true, data: response.data}
        } catch (error) {
            return this.returnError(error)
        }
    }

    /**
     *
     * @param {{
     *     type: string,
     *     lastName: string,
     *     firstName: string,
     *     middleName: string,
     *     phone: string,
     *     article: string,
     *     size: string,
     *     color: string,
     *     email: string,
     *     passportSeriesAndNumber: string,
     *     issuedByWhom: string,
     *     dateOfIssue: string,
     *     reasonForReturn: string,
     *     orderNumber: number,
     *     orderPlacingDate: string,
     *     bankName: string,
     *     bik: number,
     *     correspondentAccount: number,
     *     settlementAccount: number,
     *     account: number,
     *     cardNumber: string,
     *     courierService: string,
     *     trackNumber: string,
     *     returnType: string,
     *     status: string,
     *     additionalFields: string
     * }} returnItem
     * @returns {Promise<{success: boolean, message: string}|{data: any, success: boolean}>}
     */
    async create(returnItem, articles) {
        try {
            delete returnItem.id
            const response = await $host.post('/api/returns/create', {returnItem, articles})

            return {success: true, data: response.data}
        } catch (error) {
            return this.returnError(error)
        }
    }

    async generateExcelExport(params) {
        try {
            const {data} = await $authHost.post('/api/returns/export-excel', {params} )
        
            return data;
        } catch (error) {
            return this.returnError(error)
        }
    }
}


export default new ReturnsApi()
