import {makeAutoObservable} from "mobx";

export default class UserStore {

    constructor() {
        this._isAuth = false
        this._stepAuth = 1;
        this._openAuthModal = "0";
        this._shadowOverlay = false;
        this._askRegistration = true;
        this._phoneNumber = "";
        this._getMeCodeButton = "unactive";
        this._counterSecondCode = 0;
        this._authSmsCode = '';
        this._stateOpenModalWindow = false;
        this._currentPage = "profile";
        this._activeSubs = false;
        ////По умолчанию всегда "регистрация"
        /// 1) registration  - состояние модалки, чтобы зарегистрироваться
        /// 2) authStep  -  состояние модалки, чтобы авторизироваться
        /// 3) welcome  -  состояние модалки при успешной авторизации
        /// 4) changeNumber - состояние изменения номера
        /// 5) unset ???
        /// 6) basketStep
        /// 7) orderRegistrationOrNot  -- предложение зарегаться или продолжить "так"
        /// 8) GiveSity

        this._stepModals = "registration";
        this._user = {};
        this._codeMessage = "";
        this._country = "";
        makeAutoObservable(this)
    }

    setIsAuth(bool){
        this._isAuth = bool
    }
    setActiveSubs(bool) {
        this._activeSubs = bool
    }
    setStepAuth(number) {
        this._stepAuth = number
    }
    setShadowOverlay(bool) {
        this._shadowOverlay = bool;
    }
    setStateOpenModalWindow(bool) {
        this._stateOpenModalWindow = bool;
    }
    setUser(obj) {
        this._user = obj;
    }
    setAskRegistration(bool) {
        this._askRegistration = bool;
    }

    setPhoneNumber(number) {
        this._phoneNumber = number
    }
    setOpenAuthModal(number) {

        this._openAuthModal = number
    }
    setGetMeCodeButton(string) {
        this._getMeCodeButton = string
    }
    setCounterSecondCode(number) {
        this._counterSecondCode = number;
    }
    setAuthSmsCode(number) {
        this._authSmsCode = number
    }

    setStepModals(string) {
        this._stepModals = string;
    }

    setCodeMessage(string) {
        this._codeMessage = string;
    }
    setCountry(string) {
        this._country = string;
    }
    setCurrentPage(string) {
        this._currentPage = string;
    }


    get openAuthModal(){
        return this._openAuthModal;
    }
    get activeSubs(){
        return this._activeSubs;
    }

    get isAuth() {
        return this._isAuth
    }
    get user() {
        return this._user;
    }
    get stepAuth() {
        return this._stepAuth;
    }
    get askRegistration() {
        return this._askRegistration;
    }
    get shadowOverlay() {
        return this._shadowOverlay;
    }
    get stateOpenModalWindow() {
        return this._stateOpenModalWindow;
    }
    get phoneNumber() {
        return this._phoneNumber;
    }
    get getMeCodeButton () {
        return this._getMeCodeButton;
    }
    get counterSecondCode () {
        return this._counterSecondCode;
    }
    get authSmsCode() {
        return this._authSmsCode;
    }
    get stepModals() {
        return this._stepModals;
    }
    get codeMessage() {
        return this._codeMessage;
    }
    get country() {
        return this._country;
    }
    get currentPage() {
        return this._currentPage;
    }

}

