import React, {useEffect, useState} from 'react';
import './admPayment.css'
import {getOnePageContent, updateContentPage} from "../../../../http/contentApi";
import OneImageField from "../../UI/oneImageField/OneImageField";
import {addImages, deleteImage} from "../../../../http/imagesApi";

const AdmPayment = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])


    const [pageContentPayment,setPageContentPayment] = useState({});
    const [noJsonPayment,setNoJsonContentPayment] = useState({})
    const [trigerSeoPayment,setTrigerSeoPayment] = useState(false)
    const [triggerContentPay,setTriggerContentPay] = useState(false)

    useEffect(() => {
        getOnePageContent(9).then(response => {
            setPageContentPayment(response)
            if(response.content) {
                setNoJsonContentPayment(JSON.parse(response.content))
                return false;
            }
            setNoJsonContentPayment({

                titleStep: '',
                arrayStepPayment:[],
                titleTextPayment: '',
                arrayImgPayment:[],
                textPayment: ''
            })
        } )

    },[])

    const saveSeoPaymentContent = (date) => {
        updateContentPage(date).then(response => {
            setTrigerSeoPayment(false)
        } )
    }

    useEffect(() => {
        setPageContentPayment({...pageContentPayment, content:JSON.stringify(noJsonPayment)})
    }, [noJsonPayment])



    const oneStep =  {number:"", title: "", text:"" };

    const addNewStepPayment = (object) => {
        let reduceNoJsonOneStep = {...noJsonPayment}
        reduceNoJsonOneStep.arrayStepPayment.push(object)
        setNoJsonContentPayment(reduceNoJsonOneStep)
        setTriggerContentPay(true)
    }

    const fieldsHanlerStep = (e,fieldName, index) => {
        setNoJsonContentPayment({...noJsonPayment}, [noJsonPayment.arrayStepPayment[index][fieldName] = e.target.value])
        setTriggerContentPay(true)
    }

    const deleteOneStep = (id) => {
        let deleteNoJsonPayment = {...noJsonPayment};
        deleteNoJsonPayment.arrayStepPayment.splice(id,1)
        setNoJsonContentPayment(deleteNoJsonPayment)
        setTriggerContentPay(true)
    }

    const fieldsPayContent = (e,fieldName) => {
        setNoJsonContentPayment({...noJsonPayment, [fieldName]: e.target.value})
        setTriggerContentPay(true)
    }

    const onePayBlock =  {img:"", name: ""};

    const addNewOnePay = (object) => {
        let reduceNoJsonPay = {...noJsonPayment}
        reduceNoJsonPay.arrayImgPayment.push(object)
        setNoJsonContentPayment(reduceNoJsonPay)
        setTriggerContentPay(true)
    }

    const fieldsHanlerPay = (e,fieldName, index) => {
        setNoJsonContentPayment({...noJsonPayment}, [noJsonPayment.arrayImgPayment[index][fieldName] = e.target.value])
        setTriggerContentPay(true)
    }
    const deleteOnePay = (id) => {
        let deleteNoJsonPayBlock = {...noJsonPayment};
        deleteNoJsonPayBlock.arrayImgPayment.splice(id,1)
        setNoJsonContentPayment(deleteNoJsonPayBlock)
        setTriggerContentPay(true)
    }


    const uploadImgPay = (e,objectName ,nameField, index) => {

        let newFile = e.target.files[0];
        let formData = new FormData();

        formData.append("image", newFile);

        if (noJsonPayment){
            deleteImage(noJsonPayment[nameField], "payment", null).then(response  => {
                setNoJsonContentPayment({...noJsonPayment}, [noJsonPayment[objectName][index][nameField] = ""])
            })
        }

        addImages(formData,'payment',null, null).then(response => {
            if(response.message) {
                setNoJsonContentPayment({...noJsonPayment}, [noJsonPayment[objectName][index][nameField] = response.fileName])
                setTriggerContentPay(true)
            }
        })

    }

    const deleteImgPay = (filename, objectName, nameField, index) => {
        deleteImage(noJsonPayment[nameField], "payment", null).then(response  => {
            setNoJsonContentPayment({...noJsonPayment}, [noJsonPayment[objectName][index][nameField] = ""])
            setTriggerContentPay(true)
        })
    }


    const updatePageAdmPayment = (pageContentPayment) => {
        updateContentPage(pageContentPayment).then(response => {
            setTriggerContentPay(false)
        } )
    }



    return (
        <div className={"page_adm_payment"}>
            <div className={"adm_title_payment"}>
                Оплата
            </div>
            <div className={"seo_content_payment"}>
                <div className="one_field_payment_wrapper">
                    <span className={"one_field_payment"}>Имя страницы</span>
                    <input
                        value={pageContentPayment.name ? pageContentPayment.name : ""}
                        onChange={e => (setPageContentPayment({...pageContentPayment, name: e.target.value}),
                                setTrigerSeoPayment(true)
                        )}
                        className={"payment_field_adm"} type="text"/>
                </div>
                <div className="one_field_payment_wrapper">
                    <span className={"one_field_payment"}>title</span>
                    <input
                        value={pageContentPayment.title ? pageContentPayment.title : ""}
                        onChange={e => (setPageContentPayment({...pageContentPayment, title: e.target.value}),
                                setTrigerSeoPayment(true)
                        )}
                        className={"payment_field_adm"} type="text"/>
                </div>
                <div className="one_field_payment_wrapper">
                    <span className={"one_field_payment"}>description</span>
                    <input
                        value={pageContentPayment.description ? pageContentPayment.description : ""}
                        onChange={e => (setPageContentPayment({...pageContentPayment, description: e.target.value}),
                                setTrigerSeoPayment(true)
                        )}
                        className={"payment_field_adm"} type="text"/>
                </div>
                <span
                    onClick={e => saveSeoPaymentContent(pageContentPayment)}
                    className={trigerSeoPayment ? "save_seo_payments trig" : "save_seo_payments"}>
                    Сохранить
                    </span>
            </div>
            <div className={"container_payment_content_adm"}>
                <div className="one_field_payment_wrapper">
                    <span className={"one_field_payment"}>Заголовк шагов оплаты</span>
                    <input
                        value={noJsonPayment.titleStep ? noJsonPayment.titleStep : ""}
                        onChange={e => fieldsPayContent(e, "titleStep")}
                        className={"payment_field_adm"} type="text"/>
                </div>
                {
                    noJsonPayment.arrayStepPayment && noJsonPayment.arrayStepPayment.length != 5 ?
                        <div className={"step_payment_wrapper"}>
                            <div
                                onClick={e => addNewStepPayment(Object.assign({}, oneStep ))}
                                className={"button_new_step"}>Добавить шаг</div>
                        </div>
                        :''
                }

                <div className={"parents_pay_step"}>
                    {
                        noJsonPayment.arrayStepPayment ? noJsonPayment.arrayStepPayment.map((item, index) =>
                            <div
                                key={index}
                                className={"one_step_payment"}>
                                <div className={"onestep_pay_delete"}>
                                    <div
                                        onClick={e => deleteOneStep(index)}
                                        className={"child_pay_delete"}>
                                    </div>
                                </div>
                                <div className="one_field_payment_wrapper">
                                    <span className={"one_field_payment"}>Номер шага</span>
                                    <input
                                        value={item.number ? item.number : ""}
                                        onChange={e => fieldsHanlerStep(e, "number", index)}
                                        className={"payment_field_adm"} type="text"/>
                                </div>
                                <div className="one_field_payment_wrapper">
                                    <span className={"one_field_payment"}>Заголовк шага</span>
                                    <input
                                        value={item.title ? item.title : ""}
                                        onChange={e => fieldsHanlerStep(e, "title", index)}
                                        className={"payment_field_adm"} type="text"/>
                                </div>
                                <div className="one_field_payment_wrapper">
                                    <span className={"one_field_payment"}>Текст шага</span>
                                    <textarea
                                        value={item.text ? item.text : ""}
                                        onChange={e => fieldsHanlerStep(e, "text", index)}
                                        className="payment_field_adm textarea" type="text" />
                                </div>
                            </div>
                        ) : ''
                    }
                </div>
                <div className={"step_pay_wrapper"}>
                    <div className="one_field_payment_wrapper">
                        <span className={"one_field_payment"}>Заголовок текстого блока</span>
                        <input
                            value={noJsonPayment.titleTextPayment ? noJsonPayment.titleTextPayment : ""}
                            onChange={e => fieldsPayContent(e, "titleTextPayment")}
                            className={"payment_field_adm"} type="text"/>
                    </div>
                    <div
                        onClick={e => addNewOnePay(Object.assign({}, onePayBlock ))}
                        className={"button_new_step"}>Добавить оплаты</div>
                    <div className={"pay_img_wrapper"}>
                        {
                            noJsonPayment.arrayImgPayment ? noJsonPayment.arrayImgPayment.map((itemPay, indexPay) =>
                                <div
                                    key={indexPay}
                                    className={"one_step_payment"}>
                                    <div className={"onestep_pay_delete"}>
                                        <div
                                            onClick={e => deleteOnePay(indexPay)}
                                            className={"child_pay_delete"}>
                                        </div>
                                    </div>
                                    <div className={"container_img_pay"}>
                                        <OneImageField
                                            text={"Иконка оплаты"}
                                            image={itemPay.img ?
                                                process.env.REACT_APP_API_URL+"uploads/images/payment/"+itemPay.img  : null }
                                            onChange={ e => uploadImgPay(e,'arrayImgPayment','img', indexPay)}
                                            deleteImage={ e => deleteImgPay(e,'arrayImgPayment', 'img', indexPay)}
                                        />
                                    </div>
                                    <div className="one_field_payment_wrapper">
                                        <span className={"one_field_payment"}>Описани оплаты</span>
                                        <input
                                            value={itemPay.name ? itemPay.name : ""}
                                            onChange={e => fieldsHanlerPay(e, "name", indexPay)}
                                            className={"payment_field_adm"} type="text"/>
                                    </div>
                                </div>
                            ) : ''
                        }
                    </div>
                </div>
                <div className="one_field_payment_wrapper">
                    <span className={"one_field_payment"}>Текстовая информация</span>
                    <textarea
                        value={noJsonPayment.textPayment ? noJsonPayment.textPayment : ""}
                        onChange={e => fieldsPayContent(e, "textPayment")}
                        className={"payment_field_adm textarea_big"} type="text"/>
                </div>

            </div>
            <span
                onClick={e => updatePageAdmPayment(pageContentPayment)}
                className={ triggerContentPay === true ? "save_seo_payments trig" : "save_seo_payments"}
            >Сохранить</span>
        </div>
    );
};

export default AdmPayment;