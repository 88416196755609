import React from 'react';
import './shopMaps.css'

const ShopMaps = (props) => {

    return (
        <div className={"modalMaps " + props.className}>
            <div className={"modalMaps_child"}>
                {
                    props.cityArray && props.activMapsShop ?
                        <div className={"container_maps_modal"}>
                            <div onClick={props.closeClick} className={"maps_modal_close_parents"}>
                                <div className={"city_modal_maps"}>{props.reduceArray.name}</div>
                                <div className={"maps_modal_close"}></div>
                            </div>
                            <div className={"modal_maps_iframe"}>
                                <iframe
                                    title={"Карта магазинов"}
                                    src={props.activMapsShop}
                                    width="100%" height="100%" frameBorder="0">
                                </iframe>
                            </div>
                        </div>
                        :
                        <div className={"map_error"}>
                            Магазины на карте не отмечены.
                        </div>
                }
            </div>
        </div>
    );
};

export default ShopMaps;