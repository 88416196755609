import React, {useContext, useEffect, useState} from 'react';
import './VacanciesCards.css';
import VacanciesModal from "./VacanciesModal";
import {Context} from "../../index";



const VacanciesCards = (props) => {

    const {user, settings} = useContext(Context)

    const [prowerId, setProwerId] = useState('')
    const [polychaemArray,setPolychaemArray] = useState([])
    const modalHandler = () => {
        props.openModal()
        setProwerId(props.vacancyId)
        user.setStateOpenModalWindow(true)
    }
    useEffect(() => {
        let reduceFilter = props.reduceArray.filter(function(id) {
            return id.id === prowerId;
        });
        setPolychaemArray(reduceFilter[0])
    },[prowerId] )


    return (
        <div className={"vacancies_cards"}>
            <div
                className={"vacancies_cards_subtitle"}>
                {props.item.name}
            </div>
            <div className={"employment_wage_wrapper"}>
                {/*<div className={"employment_wage"}>*/}
                {
                    settings.projectWidth && settings.projectWidth > 768 ?
                        <div className={"employment_wage"}>
                            <div className={"employment_wage_container"}>
                                <div className={"employment_wage_subtitle"}>
                                    Тип занятости
                                </div>
                                <div className={"employment_wage_subtitle"}>
                                    Требуемый опыт работы
                                </div>
                                <div className={"employment_wage_subtitle"}>
                                    Заработная плата
                                </div>
                            </div>
                            <div className={"employment_wage_container"}>

                                <div className={"employment_wage_text"}>
                                    {props.item.work_type}
                                </div>
                                <div className={"employment_wage_text"}>
                                    {props.item.work_expirience}
                                </div>
                                <div className={"employment_wage_text"}>
                                    {props.item.work_price}
                                </div>
                            </div>
                        </div>
                        :
                        <div className={"employment_wage"}>
                            <div className={"employment_wage_container"}>
                                <div className={"employment_wage_subtitle"}>
                                    Тип занятости
                                </div>
                                <div className={"employment_wage_text"}>
                                    {props.item.work_type}
                                </div>
                            </div>
                            <div className={"employment_wage_container"}>
                                <div className={"employment_wage_subtitle"}>
                                    Заработная плата
                                </div>
                                <div className={"employment_wage_text"}>
                                    {props.item.work_price}
                                </div>
                            </div>
                            <div className={"employment_wage_container"}>
                                <div className={"employment_wage_subtitle"}>
                                    Требуемый опыт работы
                                </div>
                                <div className={"employment_wage_text"}>
                                    {props.item.work_expirience}
                                </div>
                            </div>
                        </div>
                }



                {/*</div>*/}
                {/*<div className={"employment_wage"}>*/}
                {/*    <div className={"employment_wage_container"}>*/}
                {/*       */}
                {/*      */}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={"vacancies_button"}>

                    <div
                        onClick={e => modalHandler() }
                        className={"button_response_vacancies"}>Откликнуться</div>
                    {
                        props.item.url_link ?
                            <a href={props.item.url_link} target={"_blank"}  rel="noreferrer" className={"button_learn_more"}>Узнать подробнее</a>
                            :
                            ""
                    }

                </div>
            </div>


            {
                polychaemArray ?
                        <VacanciesModal className={ props.activeModalVacancy === true ?  "active" : "hide"  }
                            item={polychaemArray}
                            closeModalVacancies={props.closeModalVacancies}
                        />
                    : ""
            }

        </div>
    );
};

export default VacanciesCards;