import {makeAutoObservable} from "mobx";

export default class LastviewStore {

    total = 8;

    constructor() {
        const lastViewedStorage = localStorage.getItem('lastViewed');
        this._viewed = lastViewedStorage ? JSON.parse(lastViewedStorage) : [];
        makeAutoObservable(this)
    }

    get lastViewed() {
        return this._viewed;
    }

    setViwed(array) {
        this._viewed = array;
    }

    setLastViewed(items) {
        this.setViwed(items);
        localStorage.setItem('lastViewed', JSON.stringify(items))
    }

    addViewed(id) {
        const lastViewed = [...this.lastViewed];
        if (this.lastViewed.length === this.total) {
            lastViewed.shift();
        }
        if (!this.lastViewed.includes(id)) {
            lastViewed.push(id);
            this.setLastViewed(lastViewed);
        }

    }

    removeViewed() {
        this.setLastViewed([]);
    }
}