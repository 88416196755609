import React, {useCallback, useContext, useEffect, useState} from 'react';
import './NotFound.css'
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import {getOnePageContent} from "../../../http/contentApi";
import Helmet from "react-helmet";

const NotFound = observer(() => {

    const {settings} = useContext(Context);

    const [notFound, setNotFound] = useState('')
    const [noJsonPageNotFound, seNoJsonPageNotFound] = useState({})

    useEffect(() => {
        window.scrollTo(0, 0)

        getOnePageContent(14).then(response => {
            if (response) {
                setNotFound(response)
            }
            if(response.content) {
                seNoJsonPageNotFound(JSON.parse(response.content))
                return false;
            }

        } )

    },[])

    const [imgUnderResolution, setImgUnderResolution] = useState('')


    useEffect(() => {
        if (settings.projectWidth > 1024) {
            setImgUnderResolution(noJsonPageNotFound.imgPC)
            return false;
        }
        if (1025 > settings.projectWidth && settings.projectWidth > 768) {
            setImgUnderResolution(noJsonPageNotFound.imgTablet)
            return false;
        }
        if (settings.projectWidth < 769) {
            setImgUnderResolution(noJsonPageNotFound.imgMobile)
            return false;
        }
    }, [settings.projectWidth, noJsonPageNotFound])

    const handleScroll = useCallback((e) => {
        const header = document.getElementById('header');
        if (header) {
            if (window.scrollY > 100) {
                header.classList.add('_scrolling')
            } else {
                header.classList.remove('_scrolling')
            }
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])



    return (
        <div className={"wrap_notfound"}>
            <Helmet>
                <title>{notFound.title ? notFound.title : ""}</title>
                <meta name="description" content={notFound.description ? notFound.description : ""}/>
            </Helmet>
            <div style={{display: 'none'}}>
                status code 404
            </div>
            <div
                // style={{marginTop:settings.headerHeight}}
                  className={"child_nf"}>
                <div className={"content_nf_wrapper"} style={{paddingTop: `${settings.headerHeight}px`}}>
                    <img className={"background_nf"} src={process.env.REACT_APP_API_URL+"uploads/images/notfound/"+imgUnderResolution} alt=""/>
                    <div className={"content_nf"}>
                        {
                            noJsonPageNotFound.titlePageError ?
                                <div className={"mistake"}>{ noJsonPageNotFound.titlePageError}</div>
                                : ''
                        }
                        <div className={"text_nf"}>
                            {
                                noJsonPageNotFound.textPageError ?
                                    <div className={"style_text_nf"}>{ noJsonPageNotFound.textPageError}</div>
                                    : ''
                            }
                            {
                                noJsonPageNotFound.buttonNotFound && noJsonPageNotFound.linkNotFound ?
                                    <Link to={noJsonPageNotFound.linkNotFound} className={"button_nf"}>{noJsonPageNotFound.buttonNotFound}</Link>
                                    : ''
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default NotFound;