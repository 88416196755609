
import React, {useContext, useEffect, useState} from 'react';
import {priceToUserString} from "../../../../../http/basketApi";
import {Context} from "../../../../../index";
import {Link} from "react-router-dom";




const OneOffer = (props) => {


    const {settings} = useContext(Context)

    const [orderItemsLc,setOrderItemsLc] = useState(null)
    const [openOneCart,setOpenOneCart] = useState(false)

    useEffect(() => {
        // console.log(props.item.BasketItems)
        try {
            setOrderItemsLc(JSON.parse(props.item.BasketItems));
        } catch (error) {
            console.log('not valid json', props.item.BasketItems)
        }
        // console.log(JSON.parse(props.item.BasketItems))
    },[])

    function getHumanDate(data) {

        var formDate = new Date(data);

        let days = formDate.getDate()

        let ages = formDate.getFullYear();

        return days+" "+getMonth(formDate.getMonth())+" "+ages;

    }



    const getMonth = (number) => {
        switch (number) {
            case 0:
            return "Января"
                break;
            case 1:
                return "февраля"
                break;
            case 2:
                return "Марта"
                break;
            case 3:
                return "Апреля"
                break;
            case 4:
                return "Мая"
                break;
            case 5:
                return "Июня"
                break;
            case 6:
                return "Июля"
                break;
            case 7:
                return "Августа"
                break;
            case 8:
                return "Сентября"
                break;
            case 9:
                return "Октября"
                break;
            case 10:
                return "Ноября"
                break;
            case 11:
                return "Декабря"
                break;
            default:

                break;
        }

    }



    return (
        <div className={"border_user_lc_one"}>
            <div className={openOneCart ? "user_lc_one_order_wrapper active" : "user_lc_one_order_wrapper"}>
                <div className={"user_lc_default_vision_zone"}>
                    <div className={"user_lc_default_vision_zone_top_line"}>
                    <span className={"user_lc_order_number_and_price"}>
                        Заказ №{props.item.order_number}
                    </span>
                        <span className={"user_lc_order_number_and_price price"}>
                        {priceToUserString(props.item.result_price)+"  ₽"}
                    </span>
                    </div>
                    {
                        settings.projectWidth && settings.projectWidth > 1024 ?
                            <div className={"user_lc_for_data_container"}>
                                <div>
                                    <span className={"user_lc_gray_span"}>Дата оформления: </span>
                                    <span className={"user_lc_data_span"}>{getHumanDate(props.item.createdAt)}</span>
                                </div>

                                {
                                    orderItemsLc ?
                                        <div className={"container_counter_order"}>
                                            <span className={"user_lc_item_counter"}>Товары: </span>
                                            <span className={"user_lc_count_span"}>{orderItemsLc.length}</span>
                                        </div>

                                        : ""
                                }
                            </div>
                            :
                            <div className={"user_lc_for_data_container"}>
                                <div className={"user_lc_title"}>
                                    <span className={"user_lc_gray_span"}>Дата оформления: </span>
                                    <span className={"user_lc_item_counter"}>Товары: </span>
                                </div>
                                {
                                    orderItemsLc ?
                                        <div className={"container_counter_order"}>
                                            <span className={"user_lc_data_span"}>{getHumanDate(props.item.createdAt)}</span>
                                            <span className={"user_lc_count_span"}>{orderItemsLc.length}</span>
                                        </div>

                                        : ""
                                }
                            </div>
                    }

                </div>
                <div className={"user_lc_detail_hidden_zone"}>
                    {  orderItemsLc && openOneCart ? orderItemsLc.map((item,index) => {
                            return(
                                <div key={index} className={"user_lc_one_order_item"}>
                                    <div className="user_lc_one_order_image_container">
                                        <img src={process.env.REACT_APP_API_URL+"uploads/images/order/214_"+item.item.img_preview}
                                             alt={'YouWanna - ' +(item.item.name ? item.item.name : '')}/>
                                    </div>
                                    <div className={"info_product_and_price_lc"}>
                                        <div className="user_lc_one_order_content_container">
                                            <div className="user_lc_one_order_item_name">
                                                {item.item.name}
                                            </div>
                                            <div className="user_lc_one_order_item_atricul">
                                                Артикул: {item.item.product.articul}
                                            </div>
                                            {
                                                settings.projectWidth && settings.projectWidth < 769 ?
                                                    <div className="user_lc_one_order_price_container">
                                                     <span  className={"user_lc_one_order_price_container_act_price"}>
                                                         {/*<span className={"user_lc_one_order_price_counter"}>{item.count+" x "} </span>  */}
                                                         { item.item.sell_price ? priceToUserString(item.item.sell_price*item.count)+"  ₽" : priceToUserString(item.item.product.price*item.count)+" ₽"}
                                                     </span>
                                                        {
                                                            item.item.sell_price != 0 ?
                                                                <span className={"user_lc_one_order_price_container_old_price"}>{priceToUserString(item.item.product.price*item.count)+"  ₽"}</span>
                                                                : ""
                                                        }
                                                    </div>
                                                    :
                                                    ""
                                            }
                                            <div className="user_lc_one_order_price_counter">
                                                Кол-во: {item.count}
                                            </div>
                                            <div className="user_lc_one_order_item_color">
                                                Цвет: {" "+item.item.color.color_name}
                                            </div>
                                            <div className="user_lc_one_order_item_size">
                                                Размер: {" "+item.currentSize}
                                            </div>
                                        </div>
                                        {
                                            settings.projectWidth && settings.projectWidth > 768 ?
                                                <div className="user_lc_one_order_price_container">
                                                     <span onClick={e=> console.log(item.item)} className={"user_lc_one_order_price_container_act_price"}>
                                                         {/*<span className={"user_lc_one_order_price_counter"}>{item.count+" x "} </span>  */}
                                                         { item.item.sell_price ? priceToUserString(item.item.sell_price*item.count)+"  ₽" : priceToUserString(item.item.product.price*item.count)+" ₽"}
                                                     </span>
                                                    {
                                                        item.item.sell_price != 0 ?
                                                            <span className={"user_lc_one_order_price_container_old_price"}>{priceToUserString(item.item.product.price*item.count)+"  ₽"}</span>
                                                            : ""
                                                    }
                                                </div>
                                                :
                                                ""
                                        }

                                    </div>


                                </div>
                            )
                        })
                        : ""
                    }

                    <div className={"user_lc_itg_block"}>

                        {/*<div className="user_lc_itg_block_counter_block">*/}
                        {/*    Количество товаров: {orderItemsLc ? orderItemsLc.length : 0} шт.*/}
                        {/*</div>*/}
                        <div className="user_lc_itg_block_no_sell">
                            <span>Стоимость до скидок: </span>
                            <span className={"user_lc_itg_block_no_sell_special"}>{priceToUserString(props.item.no_sale_summ + props.item.deliveryPrice)+"  ₽"}</span>
                        </div>
                        <div className="user_lc_itg_block_no_sell">
                            <span>Скидка: </span>
                            <span className={"user_lc_itg_block_no_sell_special"} >{priceToUserString(props.item.no_sale_summ - (props.item.result_price-props.item.deliveryPrice))+"  ₽"}</span>
                        </div>
                        <div className="user_lc_itg_block_no_sell">
                            <span>Стоимость доставки: </span>
                            <span className={"user_lc_itg_block_no_sell_special"}>{priceToUserString(props.item.deliveryPrice ? props.item.deliveryPrice : 0)+"  ₽"}</span>
                        </div>
                        <div className="user_lc_itg_block_first_line">
                            <span>Итого:</span><span className={"price_color"}>{priceToUserString(props.item.result_price)+"  ₽"}</span>
                        </div>
                    </div>
                </div>
                <div
                    onClick={e => setOpenOneCart(!openOneCart)}
                    className={"user_lc_button_change_hidden_zone"}>
                    {openOneCart ? "Свернуть" : "Подробнее" }
                </div>
                {
                    props.item.paymentAmount !== props.item.result_price ?
                        <Link
                            to={"/order/id/"+props.item.custom_order_id+"/"}
                            className={"user_lc_button_change_hidden_zone pay"}>
                            Оплатить
                        </Link> : ""
                }

            </div>
        </div>

    );
};


export default OneOffer;