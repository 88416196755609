import React, { useState } from 'react';
import './CardsDelivery.css'
import {Link} from "react-router-dom";


const OneCardsDelivery = (props) => {
    const [isActive, setIsActive] = useState(false);

    const content = (
        <div className={"child_shipping_methods"}>
            <div>
                {
                    props.item.textArrayBlock ?  props.item.textArrayBlock.map((item,index) =>
                        <div
                            key={index}
                            className={"subtitle_conteiner"}>
                            {item.name ?
                                <span className={"subtitle_delivery"}>{item.name}</span>
                                :
                                ""
                            }
                            {item.value ?
                                <span className={"text_delivery"}>{item.value}</span>
                                :
                                ""
                            }
                        </div>
                    ) : ""
                }
            </div>
            <div>
                {
                    props.item.buttonName && props.item.linkButton ?
                        <div className={"pickup_button child_shipping_button"}>
                            <Link to={props.item.linkButton} className={"child_shipping_btn"}>{props.item.buttonName}</Link>
                        </div>
                        :
                        ""
                }
            </div>
        </div>
    )

    return (
        <div className={"shipping_methods"}>
            <div
                className={"cards_title_delivery" + (isActive ? ' _active' : '')}
                onClick={() => setIsActive(!isActive)}
            >
                <span className={"delivery_arrows_mob"}>{props.item.titleBlock ? props.item.titleBlock : ''}</span>
            </div>

            {window.innerWidth <= 1024 ?
                isActive ? content : ''
                : content
            }
        </div>
    );
};

export default OneCardsDelivery;