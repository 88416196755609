import React, {useContext} from 'react';
import {Context} from "../../../../../index";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";

const MyFavoriteProducts = observer((props) => {

    const {user, settings} = useContext(Context);

    return (

            <div className={"bar_icon_wrapper stroke favorites"}>
                <div className={settings.favoriteArray && settings.favoriteArray.length > 0 ? "count_indicator_favorites active" : "count_indicator_favorites"}>{settings.favoriteArray ? settings.favoriteArray.length : ""}</div>
                {
                    settings.projectWidth && settings.projectWidth > 768 ?
                        <div className={settings.favoriteArray && settings.favoriteArray != '' ? "favorite_icons_header active" : "favorite_icons_header"}>
                            {
                                user.isAuth ?
                                    <div onClick={e => user.setCurrentPage('favorites')}>
                                        <Link to=
                                                  {{
                                                      pathname: "/personal/favorites/",
                                                  }}
                                        >
                                            <svg width="28" height="24" viewBox="0 0 28 24" fill="none" >
                                                <path d="M13.2919 4.04055L13.9967 4.73939L14.7008 4.03975L15.8488 2.899C15.8489 2.89892 15.8489 2.89884 15.849 2.89876C18.4027 0.367003 22.54 0.367081 25.0936 2.89899C27.636 5.41986 27.6346 9.49283 25.0946 12.0263C25.0943 12.0266 25.0939 12.027 25.0936 12.0273L14.0359 22.9848L14.0357 22.985C14.0263 22.9942 14.0144 23 13.9974 23C13.9804 23 13.9684 22.9942 13.9591 22.985L2.9077 12.0274C2.9077 12.0274 2.9077 12.0274 2.9077 12.0274C0.364095 9.50538 0.364107 5.41997 2.9077 2.89797L2.9077 2.89797C5.45602 0.371271 9.59117 0.371281 12.1394 2.89796L12.1395 2.89799L13.2919 4.04055Z" stroke="white" strokeWidth="2"/>
                                            </svg>
                                        </Link>
                                    </div>

                                    :
                                    <Link to={"/personal/favorites/"} >
                                        <svg width="28" height="24" viewBox="0 0 28 24" fill="none" >
                                            <path d="M13.2919 4.04055L13.9967 4.73939L14.7008 4.03975L15.8488 2.899C15.8489 2.89892 15.8489 2.89884 15.849 2.89876C18.4027 0.367003 22.54 0.367081 25.0936 2.89899C27.636 5.41986 27.6346 9.49283 25.0946 12.0263C25.0943 12.0266 25.0939 12.027 25.0936 12.0273L14.0359 22.9848L14.0357 22.985C14.0263 22.9942 14.0144 23 13.9974 23C13.9804 23 13.9684 22.9942 13.9591 22.985L2.9077 12.0274C2.9077 12.0274 2.9077 12.0274 2.9077 12.0274C0.364095 9.50538 0.364107 5.41997 2.9077 2.89797L2.9077 2.89797C5.45602 0.371271 9.59117 0.371281 12.1394 2.89796L12.1395 2.89799L13.2919 4.04055Z" stroke="white" strokeWidth="2"/>
                                        </svg>
                                    </Link>
                            }
                        </div>
                        :
                        <div className={props.openMenuLk === true ? "container_lk_title open_menu" : "container_lk_title"} onClick={e => user.setCurrentPage('favorites')}>
                            <Link  to={{pathname: "/personal/favorites/"}}>
                                <div className={"mobile_bar_icons"}>Вишлист</div>
                            </Link>
                        </div>


                }

            </div>
    );
});

export default MyFavoriteProducts;