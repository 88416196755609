import React from 'react';

import './css/Error.css'

const Error = ({error}) => {
    return (
        <div className="returns-error">
            {error}
        </div>
    );
};

export default Error;