import React from 'react';
import BreadcrumbsItemBlack from "./BreadcrumbsItemBlack";
import './BreadCrumbsBlack.css';

const BreadCrumbsBlack = (props) => {
    return (
        <div className={"breadcrumbsblack_content"} itemScope itemType="http://schema.org/BreadcrumbList" >
            {props.items.map((item,index) =>
                <BreadcrumbsItemBlack
                    key={index}
                    item={item}
                    position={index+1}
                />
            )}
        </div>
    );
};

export default BreadCrumbsBlack;
