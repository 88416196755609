import {$authHost,$host} from "./index";

export const addImages = async (fields,parrentName,childName,resizePreset) => {
    const {data} = await $authHost.post("api/catalog/addimages", fields)
    if(data.error) {
        return data;
    }

    if (data.fileName) {
        const dataTransfer = await transferImage(data.fileName,parrentName,childName,resizePreset);
        return dataTransfer;
    }
}

export const transferImage = async (fileName,parrentName,childName,resizePreset) => {
    const {data} = await $authHost.post('api/catalog/transferimage', {fileName,parrentName,childName,resizePreset})
    if(data.error) {
        return data;
    }
    return data;
}

export const deleteImage = async (fileName,parentName,childName) => {
    const data = await  $authHost.post('api/catalog/deleteimage', {fileName,parentName,childName})
    return data;
}

export const addPdfFile = async (fields,parrentName,childName,resizePreset) => {

    const {data} = await $authHost.post("api/catalog/addpdf", fields)
    if(data.error) {
        return data;
    }

    if (data.fileName) {
        const dataTransfer = await transferImage(data.fileName,parrentName,childName,resizePreset);
        return dataTransfer;
    }
}

export const deletePdfFile = async (fileName,parentName,childName) => {
    const data = await  $authHost.post('api/catalog/deletepdf', {fileName,parentName,childName})
    return data;
}