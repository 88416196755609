import React from 'react';
import './CardsRefound.css'

const TextCardsShop = (props) => {
    return (
        <div className={"subline_text"}>
            <span className={"subline_refound"}>{props.item.name}</span>
            <p className={"text_content"}>{props.item.value}</p>
            {
                props.item.application ?
                    <a
                        className={"mini_block_return_button"}
                        download
                        href={process.env.REACT_APP_API_URL + "uploads/images/refund/arrayRefund/" + props.item.application}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Шаблон заявления на возврат
                    </a>
                    : ''
            }
        </div>
    );
};

export default TextCardsShop;