import React, {useState} from 'react';
import './OneLineItem.css';
import {Link} from "react-router-dom";
import IsActiveIndicator from "../../../../UI/isActiveIndicator/IsActiveIndicator";
// import {changeStockStatus} from "../../../../../../http/catalogApi";
import TrashCont from "../../../../UI/trashICont/TrashCont";
import { changeStockStatus, deleteOneStock } from '../../../../../../http/contentApi';
const OneLineItem = (props) => {

    const changeActive = async (id,status) => {
        const data = await changeStockStatus(id,status);
        if(!data.error) {
            props.setStockAndTrends([...props.stockAndTrends],[props.stockAndTrends[props.index].status = data.status])
        }
    }

    const  date = new Date(props.item.updatedAt);

    const humanDateFormat =  date.toLocaleString();

    const createHumanFormat = new Date(props.item.createdAt).toLocaleString();

    const deleteOneItem = (id) => {
        deleteOneStock(id).then(response => {
            if (!response.error) {
                props.setStockAndTrends([...props.stockAndTrends].filter(item => item.id !== id))
            }
        })
    }

    return (
        <div className={"one_stock_or_trend_line"}>
            <Link className={"link_st"} to={"/admindashboard/content/stock-trend/oneItem/"+props.item.id+"/"}  >
                <div className={"id_block_on_pr_line"}>{props.item.id}</div>
                <div className={"image_product_or_line"}>
                    {props.item.image ?
                        <img src={process.env.REACT_APP_API_URL+"uploads/images/stockAndTrends/"+props.item.id+"/"+props.item.image} alt=""/>
                        :
                        <img className={"no_image"} src={process.env.REACT_APP_API_URL+"uploads/images/no-image.jpg"} alt=""/>
                    }
                </div>

                <div  className={"name_line"}> {props.item.name} </div>

            </Link>

            <IsActiveIndicator active={props.item.status} change={e => changeActive(props.item.id, !props.item.status)}
            />

            <div className={"other_controls_tab"}>
                <div className={"left_line_bar"}></div>
                <div className={"right_line_bar"}>
                    <div className={"date_createdat_container"}> {createHumanFormat ? createHumanFormat : ""}</div>
                    <div className={"date_updateat_container"}> {humanDateFormat ? humanDateFormat : ""}</div>
                    {/* <div className={"last_redactor"}> {props.item.redactor} </div> */}
                    <div  className={"remove_div"} onClick={e => deleteOneItem(props.item.id)}>
                        <TrashCont />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default OneLineItem;