import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import s from './InfoDetail.module.scss';
import '../StocksAndTrendsDetail/StocksAndTrendsDetail.css';
import './InfoDetail.scss';
import SwiperCore, { Pagination } from 'swiper';
import RecommendationsSlider from '../../../../components/RecommendationsSlider/RecommendationsSlider';

SwiperCore.use([Pagination]);
const InfoDetail = ({ pageData, error, isHaveSlider }) => {
	const { stock_trend_id } = useParams();
    const id = +stock_trend_id;
	console.log(pageData,' page data');
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<div className={s.infoDetail}>
				<div className={s.infoDetailImg}>
					<h1 className={s.infoDetailTitle}>{pageData.name}</h1>
					<img
						src={
							process.env.REACT_APP_API_URL +
							'uploads/images/stockAndTrends/' +
							pageData.id +
							'/' +
							pageData.detail_image
						}
						alt={pageData.name}
						width="680"
						height="816"
					/>
				</div>

				<div className="stocks-trends__container">
					{pageData && pageData.slider_images && (
						<Main
							mainText={pageData.main_text}
							items={pageData.slider_images}
							content={pageData.main_text}
							title={pageData.name}
							id={pageData.id}
							isHaveSlider={isHaveSlider}
						/>
					)}
					{pageData.slider_images && pageData.slider_images.length ? (
						<RecommendationsSlider
							items={pageData.slider_images}
							id={pageData.id}
							sliderName={'Inspiration'}
						/>
					) : (
						''
					)}
					{pageData.top_slider_active && pageData.top_slider_products.length ? (
						<RecommendationsSlider
							items={pageData.top_slider_products}
							id={pageData.id}
							sliderName={pageData.top_slider_name || 'Подборка от наших стилистов'}
						/>
					) : (
						''
					)}
					{pageData.bottom_slider_active && pageData.bottom_slider_products.length ? (
						<RecommendationsSlider
							items={pageData.bottom_slider_products}
							id={pageData.id}
							sliderName={
								pageData.bottom_slider_name || 'Подборка от наших стилистов'
							}
						/>
					) : (
						''
					)}
				</div>
			</div>
		</>
	);
};

const Main = ({ mainText, isHaveSlider }) => {
	return (
		<section className={s.infoDetailSection}>
			<div className={s.infoDetailContent}>
				{isHaveSlider ? (
					<div>{mainText}</div>
				) : (
					<div dangerouslySetInnerHTML={{ __html: mainText }}></div>
				)}
			</div>
		</section>
	);
};

export default InfoDetail;
