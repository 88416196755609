import React, {useContext, useEffect, useState} from 'react';
import './addOneProduct.css';
import ChildProductTabs from "./childProductTabs/ChildProductTabs";
import ChildProductOneItem from "./ChildProductOneItem/ChildProductOneItem";
import {
    addChildProduct,
    fetchChangeableCategories,
    fetchColors,
    fetchSizes,
    fetchTrends,
    getOneProduct,
    updateOneProduct
} from "../../../../http/catalogApi";
import PreviewDragDrop from "../../../../components/dragAndDrop/previewDragDrop/PreviewDragDrop";
import {useParams} from "react-router";
import Obmer from "./obmer/Obmer";
import Sostav from "./sostav/Sostav";
import {Context} from "../../../../index";
import SliderController from "./slidersController/SliderController";
import IsActiveIndicator from "../../UI/isActiveIndicator/IsActiveIndicator";
import Spiner from "../../spiner/Spiner";

const AddOneProduct = initialState => {


    const {user} = useContext(Context);

    let {productid} = useParams();

    const [oneProductCreate,setOneProductCreate] = useState({})

    const [responseStatus, setResponseStatus] = useState("");
    const [refreshDatabase,setRefreshDatabase] = useState(false)
    const [landrys, setLandrys] = useState([]);
    const [activeLandrys, setActiveLandrys] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [selectCategoryId,setSelectCategoryId] = useState([]);
    const [selectCategoryUrlId,setSelectCategoryUrlId] = useState([]);
    const [showPreloader, setShowPreloader] = useState(false);

    const addNewActiveLandryOrDelete = (id) => {
        if(activeLandrys.includes(id)){
            let reduceArray = [...activeLandrys];
            reduceArray.splice(activeLandrys.lastIndexOf(id),1);
            setActiveLandrys(reduceArray)
        } else {
            let reduceLandrys = [...activeLandrys];
            reduceLandrys.push(id)
            setActiveLandrys(reduceLandrys)
        }
    }

    useEffect(()=>{

          getOneProduct(productid).then(data => {
             
              setLandrys(data.data.landry)
              setTrendsActive(data.data.trends)
              let activeLandry = [];
              if(data.data[0].landry_product_table){
                  data.data[0].landry_product_table.forEach((item,index) => {
                      activeLandry.push(item.id);
                  })
              }
              if(activeLandry) {
                  setActiveLandrys(activeLandry)
              }

              let reduceObject = {...data.data[0]}
              let redactor = "ID: "+user.user.id+", "+user.user.name;
              reduceObject = {...reduceObject, redactor:redactor};

              if(reduceObject.childProducts) {
                  reduceObject.childProducts.forEach((item,index) => {
                      if (index === 0) {
                          item.activePage = true
                      } else {
                          item.activePage = false
                      }
                  })
              }
              setOneProductCreate(reduceObject)

                if(data.data[0].obmer) {
                    let obmerNewState = JSON.parse(data.data[0].obmer)
                    if (obmerNewState.length === undefined) {
                        setObmerState([...obmerState, {}])
                    }
                    setObmerState(obmerNewState)
                }
              if(data.data[0].sostav) {
                  let sostavNewState = JSON.parse(data.data[0].sostav)
                  setSostavState(sostavNewState)
              }
              if(data.data[0].product_category){
                  let categoryArrayToState = [];
                  data.data[0].product_category.forEach((item,index) => {
                      categoryArrayToState.push(item)
                  })

                  setSelectCategoryId(categoryArrayToState)
              }

          }  );

    },[refreshDatabase])


    const updateProduct = async (item) => {
        setShowPreloader(true);
        let jsonSostav = JSON.stringify(sostavState);
        let jsonObmer = JSON.stringify(obmerState);

        let reduceArrayProduct =   {...oneProductCreate};
        reduceArrayProduct.landrys = activeLandrys;
        reduceArrayProduct.obmer = jsonObmer
        reduceArrayProduct.sostav = jsonSostav
        reduceArrayProduct.selected_category = selectCategoryId;
        reduceArrayProduct.trends = trendsActive;
        if (selectCategoryUrlId.length)
            reduceArrayProduct.product_url = getCategoryUrl();

        setOneProductCreate(reduceArrayProduct)

        const {data} = await updateOneProduct(reduceArrayProduct)
        // return false;

        if(data.error) {
            setShowPreloader(false);
            setResponseStatus(data.error)
            return false;
        }

        setResponseStatus("")
        window.location.reload();
    }
    const [colors,setColors] = useState([]);
    const [size,setSize] = useState([]);
    const [trends,setTrends] = useState([]);
    const [trendsActive,setTrendsActive] = useState([]);
    //получаем цвета и размеры

    useEffect(()=> {
        fetchColors().then(data => {
            setColors([...data].sort((a,b) => a.color_name?.localeCompare(b.color_name)))
        })
        fetchChangeableCategories().then(response => {
            setAllCategories(response)
        } )
    }, [])

    useEffect(() => {

        if(!allCategories.length || !oneProductCreate.product_url)
            return

        const product_url = oneProductCreate.product_url.replace(/\/catalog\/|\/product\//gm, '')
        const categorySlugs = product_url.split('/')
        const categoryIds = categorySlugs
			.map((categorySlug) => {
				const selectedCategory = allCategories.filter(
					({ slug }) => slug === categorySlug
				)[0];
				return selectedCategory && selectedCategory.id;
			})
			.filter((item) => !!item);
		if (categoryIds.length) {
			setSelectCategoryUrlId(categoryIds);
		}

    }, [allCategories, oneProductCreate])

    useEffect(()=> {
        fetchSizes().then(data => {
            setSize(data)
        })
        fetchTrends().then(data => {
            setTrends(data)
        })
    }, [])

    const trendsController = (item) => {
        let array = [...trendsActive];
        let index = array.indexOf(item.id);
        if (index !== -1) {
            array.splice(index, 1)
            setTrendsActive(array);
        } else {
            array.push(item.id)
            setTrendsActive(array);
        }
    }

    // ########################BACKEND#################################


    // Добавление нового торг предложения
    const addNewChildProduct = (object) => {

        addChildProduct().then(response => {
            setOneProductCreate({...oneProductCreate}, [oneProductCreate.childProducts.push(response)])
        })
    }

    // Переключение товара на активный цвет
    const changeActiveChildProduct = (indexElement) => {

        oneProductCreate.childProducts.forEach((item,index) => {

            index === indexElement

                    ?
                     setOneProductCreate({...oneProductCreate}, [oneProductCreate.childProducts[indexElement].activePage = true ])
                    :
                    setOneProductCreate({...oneProductCreate}, [oneProductCreate.childProducts[index].activePage = false ])
        })

    }

    const deleteChildProduct = (index) => {
        const reducedArr = oneProductCreate;
        reducedArr.childProducts.splice(index,1);
        setOneProductCreate({...oneProductCreate}, reducedArr)
    }






    const [sostavState,setSostavState] = useState([

    ]);
    const oneBLockSost =  {
        name_sost: "",
        fields: [
            { value: ""}
        ]
    };
    const oneItemBlockSost = { value: ""};
    const sostavHandler = (object) => {
        setSostavState([...sostavState, object])
    }

    const [newReduceObmer,setNewReduceObmer] = useState({
        sizeModel: '',
        mestoName: '',
        obmerSize: ''
    })


    const [obmerState,setObmerState] = useState([

        ]);

    const [characteristicState,setCharacteristic] = useState([

    ]);


    const oneObmer = {size_name_obmer:"", position:"",fields:[{name:"",value:""}]}
    const obmerFields = {name:"",value:""};

    // const obmerAddHandler = (array) => {
    //
    //     let object = {position:"",size_name_obmer:"", fields:[]};
    //
    //     for(const oneItem of array) {
    //         let fields = object.fields;
    //         fields.push({name:oneItem.title, value:""})
    //         object = {...object, fields:fields}
    //     }
    //
    //     setObmerState([...obmerState, object])
    // }

    const obmerAddHandler = (object) => {
        setObmerState([...obmerState, object])
    }

    const characteristicFields = {title:""};

    const obmerAddCharacteristic = (object) => {
        setCharacteristic([...characteristicState, object])
    }

    const [searchCategoryQuery,setSearchCategoryQuery] = useState('');

    
    function categoryAfterSearchArray() {
        if(searchCategoryQuery) {
            return [...allCategories].filter(item => item.name.toLowerCase().includes(searchCategoryQuery.toLowerCase()))
        } else {
            return allCategories;
        }
    }

    const categoryProductHandler = (id) =>  {
        if(selectCategoryId.includes(id)) {
            const index = selectCategoryId.lastIndexOf(id);
            let reduceArrayCategory = [...selectCategoryId];
            reduceArrayCategory.splice(index,1);
            setSelectCategoryId(reduceArrayCategory)
        } else {
            let reduceArrayCategory = [...selectCategoryId];
            reduceArrayCategory.push(id)
            setSelectCategoryId(reduceArrayCategory)

        }
    }

    const categoryUrlProductHandler = (id) =>  {
        if(selectCategoryUrlId.includes(id)) {
            const index = selectCategoryUrlId.lastIndexOf(id);
            let reduceArrayCategory = [...selectCategoryUrlId];
            reduceArrayCategory.splice(index,1);
            setSelectCategoryUrlId(reduceArrayCategory)
        } else {
            let reduceArrayCategory = [...selectCategoryUrlId];
            reduceArrayCategory.push(id)
            setSelectCategoryUrlId(reduceArrayCategory)

        }
    }

    const getCategoryUrl = () => {
        let url = '/catalog/'

        url += selectCategoryUrlId.map((categoryId) => {
            return allCategories.filter(({id}) => id === categoryId)[0].slug
        }).join('/')

        url += '/product/'

        return url.replace('//', '/')
    }

    const changeProductSertificateStatus = (newValue) => {
        setOneProductCreate({...oneProductCreate, electron_sertificate: newValue})
    }

    const changeSecretStatus = (newValue) => {
        setOneProductCreate({...oneProductCreate, is_secret: newValue})
    }

    const setSellPriceChildProduct = (value, index) => {
        if (value >= 100 || value < 0 || !oneProductCreate.price) {
            return
        }
        let sellPrice = oneProductCreate.price - Math.floor(oneProductCreate.price * value / 100);
        if (!value) {
            sellPrice = 0;
        }
        
        setOneProductCreate({...oneProductCreate}, [oneProductCreate.childProducts[index].sell_price = Number(sellPrice), oneProductCreate.childProducts[index].sell_value = value])
    }

    useEffect(() => {
        if (oneProductCreate && oneProductCreate.childProducts) {
            oneProductCreate.childProducts.forEach(item => {
                if (item.sell_value) {
                    let sellPrice = oneProductCreate.price - Math.floor(oneProductCreate.price * item.sell_value / 100);
                    item.sell_price = sellPrice;
                }
            });
        }
    }, [oneProductCreate.price])
    

    return (
        <>
        { 
            showPreloader ?
                <Spiner />
            :
                <div className={"add_one_product"}>
                    <div className={"add_product_space_wrap"}>
                        <span className={"page_name_add_product"}>Add new Product</span>
                        <div className={"add_product_space_container"}>


                            <div className={"main_add_product_work_area"}>
                                <div className={"product_information"}>
                                    {/*Имя*/}
                                    <div className={"admin_inputs_wrapper"}>
                                        <span className={"field_info"}><span className={"red_sup"}>*</span>Имя продукта</span>

                                        <input

                                            className={"admin_field_styles"}
                                            placeholder={"Укажите имя"}
                                            value={oneProductCreate.name ? oneProductCreate.name : ""}
                                            onChange={e => setOneProductCreate({...oneProductCreate, name: e.target.value})}
                                            type="text" name="" id=""/>
                                    </div>

                                    <div className={"admin_inputs_wrapper"}>
                                        <span className={"field_info"}><span className={"red_sup"}>*</span>Артикул продукта</span>
                                        <input
                                            className={"admin_field_styles"}
                                            placeholder={"Укажите артикул"}
                                            value={oneProductCreate.articul ? oneProductCreate.articul : ""}
                                            onChange={e => setOneProductCreate({...oneProductCreate, articul: e.target.value})}
                                            type="text" name="" id=""/>
                                    </div>

                                    <div className={"admin_inputs_wrapper"}>
                                        <span className={"field_info"}><span className={"red_sup"}>*</span>Цена продукта</span>
                                        <input
                                            className={"admin_field_styles"}
                                            placeholder={"Укажите цену (только цифры)"}
                                            value={oneProductCreate.price ? oneProductCreate.price : ""}
                                            onChange={e => setOneProductCreate({...oneProductCreate, price: e.target.value})}
                                            type="text" name="" id=""
                                        />
                                    </div>
                                    <div className={"admin_inputs_wrapper"}>
                                        <div className={"wrap_for_row_field"}>
                                            <span className={"field_info"}><span className={"red_sup"}>*</span> Электронный сертификат </span>
                                            <IsActiveIndicator active={oneProductCreate.electron_sertificate} change={e => changeProductSertificateStatus(!oneProductCreate.electron_sertificate)} />
                                        </div>
                                            <span className={"fields_text_aft"}>Включая этот параметр, товар можно будет купить только через форму оформления электронного сертификата</span>
                                    </div>
                                    <div className={"admin_inputs_wrapper"}>
                                        <div className={"wrap_for_row_field"}>
                                            <span className={"field_info"}><span className={"red_sup"}>*</span> Скрытый раздел </span>
                                            <IsActiveIndicator active={oneProductCreate.is_secret} change={e => changeSecretStatus(!oneProductCreate.is_secret)} />
                                        </div>
                                            <span className={"fields_text_aft"}>Товар будет исключен из выдачи в каталоге и фидах и станет доступен только в скрытой категории</span>
                                    </div>


                                    <span className={"mtgn_title_field"}>Отобразится только в админке. На странице товары ( 1 фото ). </span>
                                    <PreviewDragDrop
                                        setOneProductCreate={setOneProductCreate}
                                        oneProductCreate={oneProductCreate}
                                    />


                                {/*editor*/}
                                    <div className={"textarea_wrapper_f_vysev"}>
                                        <textarea
                                        value={oneProductCreate.description_product_content}
                                        onChange={e => setOneProductCreate({...oneProductCreate,description_product_content: e.target.value})}
                                        >

                                        </textarea>
                                    </div>
                                        <div className={"sostav_wrapper"}>
                                            <span className={"sostav_title"}>Состав</span>
                                            <div onClick={ e => sostavHandler(Object.assign({}, oneBLockSost)) } className={"add_new_sostav"}>Добавить часть состава</div>
                                            <div className={"for_sostav_container"}>
                                                {
                                                    sostavState ?
                                                        sostavState.map((item,index) =>
                                                            <Sostav
                                                                key={index}
                                                                index={index}
                                                                item={item}
                                                                sostavState={sostavState}
                                                                setSostavState={setSostavState}
                                                                oneItemBlockSost={oneItemBlockSost}

                                                            />
                                                        )
                                                        : ""}
                                            </div>


                                        </div>

                                    <div className={"obmer_wrapper"}>
                                        <span className={"obmer_title"}> Обмеры </span>
                                        {/*<div className={"size_model_field"}>*/}
                                        {/*    Размер на модели*/}
                                        {/*    <input*/}

                                        {/*        className={"admin_field_styles"}*/}
                                        {/*        placeholder={"Текстовая информация о размере на модели"}*/}
                                        {/*        value={newReduceObmer.sizeModel ? newReduceObmer.sizeModel : ""}*/}
                                        {/*        onChange={e => setNewReduceObmer({...newReduceObmer, sizeModel: e.target.value})}*/}
                                        {/*        type="text" name="" id=""/>*/}
                                        {/*</div>*/}
                                        {/*<div*/}
                                        {/*    onClick={e => obmerAddCharacteristic(Object.assign({}, characteristicFields))}*/}
                                        {/*    className={"add_name_obmer"}>*/}
                                        {/*    Добавить характеристику обмера*/}
                                        {/*</div>*/}
                                        {/*    {*/}
                                        {/*        characteristicState ? characteristicState.map((item, index) =>*/}
                                        {/*            <CharacteristicObmer*/}
                                        {/*                key={index}*/}
                                        {/*                item={item}*/}
                                        {/*                index={index}*/}
                                        {/*                characteristicState={characteristicState}*/}
                                        {/*                setCharacteristic={e=> setCharacteristic(e)}*/}
                                        {/*                oneProductCreate={oneProductCreate}*/}
                                        {/*                obmerState={obmerState}*/}
                                        {/*                newReduceObmer={newReduceObmer}*/}
                                        {/*                setNewReduceObmer={e=> setNewReduceObmer(e)}*/}
                                        {/*            />*/}
                                        {/*            )*/}
                                        {/*            :*/}
                                        {/*            ""*/}
                                        {/*    }*/}


                                        <div onClick={e => obmerAddHandler(Object.assign({}, oneObmer))} className={"add_new_obmer"}>Добавить обмер</div>
                                        {

                                            obmerState ?
                                                obmerState.map((item,index) =>
                                                    <Obmer
                                                        key={index}
                                                        item={item}
                                                        index={index}
                                                        size={size}
                                                        obmerState={obmerState}
                                                        setObmerState={setObmerState}
                                                        obmerFields ={obmerFields}
                                                        oneProductCreate={oneProductCreate}
                                                        setOneProductCreate={setOneProductCreate}
                                                        characteristicState={characteristicState}
                                                        // newReduceObmer={newReduceObmer}
                                                        // setNewReduceObmer={e=> setNewReduceObmer(e)}
                                                    />
                                                )
                                        
                                                :""
                                        }
                                    </div>

                                    {/*Вкладыши*/}
                                    <div style={{fontSize:"18px",fontWeight:"600",textAlign:"center",marginTop:"25px"}}>Особенности ухода за одеждой</div>
                                    <div className={"landrys_wrapper"}>
                                        { landrys ?
                                            landrys.map((item,index) => {
                                                return(
                                                    <div key={index} className={"landry_target_item"}>
                                                    <div className={activeLandrys.includes(item.id) ? "landry_wpapper_padding_content active" : "landry_wpapper_padding_content"}
                                                        onClick={ e => addNewActiveLandryOrDelete(item.id)}
                                                    >
                                                        <div className={"landry_img_container"}>
                                                            <img src={process.env.REACT_APP_API_URL+"uploads/images/landry/"+item.id+"/"+item.img} alt=""/>
                                                        </div>
                                                        <div className={"landry_text_wrapper"}>
                                                            {item.text}
                                                        </div>
                                                    </div>
                                                    </div>
                                                    )
                                            })
                                            : ""
                                        }
                                    </div>

                                    <SliderController
                                        oneProductCreate={oneProductCreate}
                                        setOneProductCreate={setOneProductCreate}
                                        allCategories={allCategories}
                                    />

                                    <div className={"all_sizes_block"}>
                                        <span className={"show_all_sizes"}>Нажатие на тренд добавляет его</span>
                                        <div className={"wrapper_sizes_container_onprd"}>
                                        {
                                            trends ?
                                            trends.map((item, index) => {
                                                    return(<div onClick={ e => trendsController(item) } className={trendsActive.length && trendsActive.includes(item.id) ? "trend_one_item_in_all active" : "trend_one_item_in_all"} key={index}> {item.name}</div>)
                                                }) : ""
                                        }
                                        </div>
                                    </div>

                                </div>


                                <div className={"child_products_heading"}>"+" для создания товара и выбора цвета.</div>
                                <span className={"support_info_add_one"}><span className={"red_sup"}>*</span> Товары без цвета сохранены не будут</span>
                                <div className={"tabs_product_container"}>


                                    {
                                        oneProductCreate.childProducts
                                            ?
                                        oneProductCreate.childProducts.map((item,index) =>
                                        <ChildProductTabs
                                            index={index}

                                            key={index}
                                            item={item}
                                            delete={deleteChildProduct}
                                            colors={colors}
                                            onClick={e => changeActiveChildProduct(index)
                                            }
                                        />
                                        )
                                            : ""
                                    }
                                    <div className={"add_new_collor_product"}
                                        onClick={e => addNewChildProduct(e)}
                                    >
                                        +
                                    </div>

                                </div>

                                <div className={"pages_child_product_area"}>

                                    {
                                        oneProductCreate.childProducts ?
                                        oneProductCreate.childProducts.map((item,index) =>

                                            {

                                                return(


                                                    <ChildProductOneItem
                                                        name={
                                                            oneProductCreate.childProducts[index].name !== "" ?
                                                                oneProductCreate.childProducts[index].name
                                                                :
                                                                oneProductCreate.name
                                                        }
                                                        fields={oneProductCreate}
                                                        onChangeName={e => setOneProductCreate({...oneProductCreate}, [oneProductCreate.childProducts[index].name = e.target.value])}
                                                        onChangeSellValue ={e => setSellPriceChildProduct(e.target.value, index)}
                                                        onChangeSort ={e => setOneProductCreate({...oneProductCreate}, [oneProductCreate.childProducts[index].sort = e.target.value])}
                                                        onChangeCode ={e => setOneProductCreate({...oneProductCreate}, [oneProductCreate.childProducts[index].code = e.target.value])}
                                                        onChangeTitle ={e => setOneProductCreate({...oneProductCreate}, [oneProductCreate.childProducts[index].title = e.target.value])}
                                                        onChangeDescription ={e => setOneProductCreate({...oneProductCreate}, [oneProductCreate.childProducts[index].description = e.target.value])}

                                                        key={index}
                                                        parrentId={oneProductCreate.id}
                                                        index={index}
                                                        item={item}
                                                        colors={colors}
                                                        size={size}
                                                        setOneProductCreate={setOneProductCreate}
                                                        oneProductCreate={oneProductCreate}
                                                    />
                                                )
                                            }


                                        )
                                            : ""
                                    }

                                </div>


                            <div className={"save_wrapper_info"}>
                                <div className="save_informer">

                                </div>
                                <span onClick={e => updateProduct() } className={"save_btn"}>Сохранить</span>
                            </div>


                            </div>


                            <div className={"right_bar_product_add"}>
                                <div className={"category_container_right_bar"}>
                                    <span className={"product_category_heading"}>Категории товара</span>
                                    <div className={"category_filter_input_container"}>
                                        <input value={searchCategoryQuery ? searchCategoryQuery : ""}
                                                onChange={e => setSearchCategoryQuery(e.target.value)}
                                            placeholder={"Поиск по категориям..."}
                                            className={"input_filter_category"}
                                            type="text"/>
                                    </div>
                                    <div className={"category_setter_wrap"}>
                                        {
                                            categoryAfterSearchArray()[0] ?
                                                categoryAfterSearchArray().map((item,index) => {
                                                    return(
                                                        <div

                                                            className={"one_item_category_setter active"}
                                                            onClick={e => categoryProductHandler(item.id)}
                                                            key={item.id}>

                                                            <div className={ selectCategoryId && selectCategoryId.includes(item.id) ? "category_check_box active" : "category_check_box"}> </div>
                                                            <div className={"category_check_name"}>{item.name}</div>
                                                        </div>
                                                    )
                                                })
                                                : <div> Не найдено </div>
                                        }
                                    </div>
                                    <span className={"category_counter"}>{selectCategoryId ? "Выбрано категорий: " + selectCategoryId.length : ""}</span>
                                </div>
                                <br/>
                                <div className={"category_container_right_bar"}>
                                    <span className={"product_category_heading"}>URL товара</span>
                                    <span className={"product_category_url"}>url: {getCategoryUrl()}«артикул товара»</span>
                                    <div className={"category_setter_wrap"}>
                                        {
                                            categoryAfterSearchArray()[0] ?
                                                categoryAfterSearchArray().map((item,index) => {
                                                    return(
                                                        <div
                                                            className={"one_item_category_setter active"}
                                                            onClick={e => categoryUrlProductHandler(item.id)}
                                                            key={item.id}>

                                                            <div className={ selectCategoryUrlId && selectCategoryUrlId.includes(item.id) ? "category_check_box active" : "category_check_box"}> </div>
                                                            <div className={"category_check_name"}>{item.name}</div>
                                                        </div>
                                                    )
                                                })
                                                : <div> Не найдено </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={responseStatus ? "save_informer_widget active" : "save_informer_widget" }>
                        {responseStatus ?
                            responseStatus.map((item,index) => {
                            return(<div key={index} className={"response_error_item"}>{ Number(index+1) +") "+ item.err}</div>)
                            }) : ""
                        }
                    </div>
                </div>
        }

        </>
    );
};

export default AddOneProduct;
