import React, { useContext, useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "./BigBlock.css";
import WiewButton from "../../UI/WiewButton/WiewButton";
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";
import SliderBigBlock from "../sliderBigBlock/SliderBigBlock";


const BigBlock = observer( (props) => {
    const {settings} = useContext(Context);

    const [imgSuitableResolution, setImgSuitableResolution] = useState('')

    useEffect(() => {

        if (settings.projectWidth > 768) {
            setImgSuitableResolution(getSrc('pc'));
            return false;
        }
        if (769 > settings.projectWidth && settings.projectWidth > 658) {
            setImgSuitableResolution(getSrc('tablet'));
            return false;
        }
        if (settings.projectWidth < 659) {
            setImgSuitableResolution(getSrc('mobile'));
            return false;
        }

    }, [settings.projectWidth]);


    const getSrc = (device) => {
        if (props.item.bannerStatus === undefined || settings.projectWidth < 659) return props.item["img_" + device];
        switch (props.item.bannerStatus) {
            case 'image': {
                return props.item["img_" + device];
            }
            case 'video': {
                return props.item["video_" + device];
            }
            default: {
                return props.item["img_" + device];
            }
        }
    }

    const bannerBlock = () => {
        if ((props.item.bannerStatus === undefined || props.item.bannerStatus === 'image' || settings.projectWidth < 660) && imgSuitableResolution) {
            return (
                <LazyLoadImage
                    className=""
                    src={
                        process.env.REACT_APP_API_URL +
                        'uploads/images/homepage/' +
                        props.objectName +
                        '/' +
                        imgSuitableResolution
                    }
                    alt="YouWanna - интернет-магазин одежды и аксессуаров"
                />
        )
        } else if (props.item.bannerStatus === 'video' && imgSuitableResolution) {
            return (
                <video autoPlay muted loop className="">
                    <source src={process.env.REACT_APP_API_URL + "uploads/images/homepage/"+props.objectName+"/" +imgSuitableResolution}
                            type='video/mp4'/>
                </video>
            )
        } else if (props.item.bannerStatus === 'slider' && props.sliderImages && settings.projectWidth >= 660) {
            return (
                <SliderBigBlock
                    sliderBannerName={props.sliderBannerName}
                    sliderImages={props.sliderImages}
                />
            )
        } else {
            return '';
        }
    }

    return (
            <div className="reuse_big_block">
                {
                    bannerBlock()
                }

                {
                    props.item.titleBanner || props.item.buttonName || props.item.textBanner?
                        <div className="flying_block">
                            {
                                props.item.titleBanner ?
                                    <span className="bigWords" style={props.item.titleHex ? {color:props.item.titleHex} : {color:"#FFFFFF"}}>{props.item.titleBanner}</span>
                                    : ''
                            }
                            {
                                props.item.textBanner ?
                                    <span className="bigWordsText">{props.item.textBanner}</span>
                                    : ''
                            }
                            {
                                props.item.linkButton && props.item.buttonName ?
                                <WiewButton link={props.item.linkButton} button={props.item.buttonName} hover={props.item.buttonHover} text={props.item.buttonText} />
                                    : ''
                            }
                        </div>
                    : ""

                }

                {
                    props.item.naming && settings.projectWidth > 1024?
                        <div className={"big_block_naming"}>{props.item.naming}</div>
                    : ""

                }

            </div>
    );
});

export default BigBlock;
