import React, {useState} from 'react';
import {deleteOneVacancy, updateOneVacancy} from "../../../http/contentApi";
import CityChanger from "../UI/cityChanger/CityChanger";
import CompliteRemove from "../UI/compliteremove/CompliteRemove";



const OneVacancy = (props) => {


    const [saveTriger, setSaveTriger] = useState(false);


    const fieldsHanler = (e,fieldName) => {
        props.setVacancyArray([...props.vacancyArray],[props.vacancyArray[props.index][fieldName] = e.target.value])
        setSaveTriger(true)
    }

    const saveOneVacancyHandler = (fields) => {
        updateOneVacancy(fields).then(response => {
            if(response.message) {
                setSaveTriger(false)
            }
        } )
    }

    const setCity = (value) => {
        props.setVacancyArray([...props.vacancyArray],[props.vacancyArray[props.index].city = value, props.vacancyArray[props.index].cityId = value.id])
        setSaveTriger(true);
    }

    const deleteOneVacancyHandler = (id) => {
        deleteOneVacancy(id).then(response => {
            if(response.message){
                props.setRefreshDbVacancy(!props.refreshDbVacancy)
            }
        } )
    }

    const [removeState,setRemoveState] = useState(false)

    const controllDeleteState = (bool) => {
        setRemoveState(bool)
    }

    return (
        <div className={"one_vacancy_adm"}>
            <CompliteRemove open={removeState} canselRemove={ e => controllDeleteState(false)} goRemove={e => deleteOneVacancyHandler(props.item.id)} />
            <div className={"top_line_vacancy"}>
                <div className={"vacancy_id"}> {props.item.id} </div>
                <div className={"vacandy_block_button"}>
                    <span onClick={e => controllDeleteState(true)} className={"btn_vacancy"}>Удалить</span>
                    <span
                        onClick={e => saveOneVacancyHandler(props.item)}
                        className={saveTriger ? "btn_vacancy trig" : "btn_vacancy" }>Сохранить</span>
                </div>
            </div>
            <div className={"fields_vacancy_wrapper"}>
                <div className={"fields_container_vacancy"}>
                    <div className={"one_field_vacancy_wrapper"}>
                        <span className={"one_field_name_label"}>Название вакансии</span>
                        <input
                            value={props.item.name ? props.item.name : ""}
                            onChange={e => fieldsHanler(e,"name") }
                            className={"vacancy_field_s"} type="text"/>
                    </div>
                    <div className={"one_field_vacancy_wrapper"}>
                        <span className={"one_field_name_label"}>Тип занятости</span>
                        <input
                            value={props.item.work_type ? props.item.work_type : ""}
                            onChange={e => fieldsHanler(e,"work_type") }
                            className={"vacancy_field_s"} type="text"/>
                    </div>
                    <div className={"one_field_vacancy_wrapper"}>
                        <span className={"one_field_name_label"}>Требуемый опыт работы</span>
                        <input
                            value={props.item.work_expirience ? props.item.work_expirience : ""}
                            onChange={e => fieldsHanler(e,"work_expirience") }
                            className={"vacancy_field_s"} type="text"/>
                    </div>
                    <div className={"one_field_vacancy_wrapper"}>
                        <span className={"one_field_name_label"}>Заработная плата</span>
                        <input
                            value={props.item.work_price ? props.item.work_price : ""}
                            onChange={e => fieldsHanler(e,"work_price") }
                            className={"vacancy_field_s"} type="text"/>
                    </div>
                    <div className={"one_field_vacancy_wrapper"}>
                        <span className={"one_field_name_label"}>Имя менеджера</span>
                        <input
                            value={props.item.manager_name ? props.item.manager_name : ""}
                            onChange={e => fieldsHanler(e,"manager_name") }
                            className={"vacancy_field_s"} type="text"/>
                    </div>
                    <div className={"one_field_vacancy_wrapper"}>
                        <span className={"one_field_name_label"}>Номер менеджера (визуал)</span>
                        <input
                            value={props.item.manager_number_frontend ? props.item.manager_number_frontend : ""}
                            onChange={e => fieldsHanler(e,"manager_number_frontend") }
                            className={"vacancy_field_s"} type="text"/>
                    </div>
                    <div className={"one_field_vacancy_wrapper"}>
                        <span className={"one_field_name_label"}>Номер менеджера (функционал, без " + ( ) ")</span>
                        <input
                            value={props.item.manager_number_back ? props.item.manager_number_back : ""}
                            onChange={e => fieldsHanler(e,"manager_number_back") }

                            className={"vacancy_field_s"} type="text"/>
                    </div>
                    <div className={"one_field_vacancy_wrapper"}>
                        <span className={"one_field_name_label"}>Ссылка (HH, TG, WS)</span>
                        <input
                            value={props.item.url_link ? props.item.url_link : ""}
                            onChange={e => fieldsHanler(e,"url_link") }
                            className={"vacancy_field_s"} type="text"/>
                    </div>

                </div>
                <div className={"city_changer_wrapper"}>
                    {
                        props.vacancyArray ?
                            <CityChanger
                                allCity={props.cityArray}
                                targetCity={props.item.city}
                                setCity={e => setCity(e)}
                                isBottom={true}
                                cityName={props.cityName}

                            />
                            : ""
                    }
                </div>
            </div>
        </div>
    );
};


export default OneVacancy;