import React, {useContext} from 'react';
import './VacanciesModal.css'
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const VacanciesModal = observer((props) => {

    const {settings} = useContext(Context)

    return (
        <div className={"VacanciesModal " + props.className}>
            <div className={"vacancies_modal_close_and_title"}>
                <div className={"vacancies_cards_subtitle_modal"}>
                    {props.item.name}

                </div>
                <div onClick={props.closeModalVacancies}
                     className={"vacancies_modal_close"}>
                </div>
            </div>
                <div className={"employment_wage_wrapper_modal"}>
                    {
                        settings.projectWidth && settings.projectWidth > 768 ?
                            <div className={"employment_wage_modal"}>
                                <div className={"employment_wage_container_modal"}>
                                    <div className={"employment_wage_subtitle_modal"}>
                                        Тип занятости
                                    </div>
                                    <div className={"employment_wage_subtitle_modal"}>
                                        Требуемый опыт работы
                                    </div>
                                    <div className={"employment_wage_subtitle_modal"}>
                                        Заработная плата
                                    </div>
                                </div>
                                <div className={"employment_wage_container_modal"}>
                                    <div className={"employment_wage_text_modal"}>
                                        {props.item.work_type}
                                    </div>
                                    <div className={"employment_wage_text_modal"}>
                                        {props.item.work_expirience}
                                    </div>
                                    <div className={"employment_wage_text_modal"}>
                                        {props.item.work_price}
                                    </div>
                                </div>
                            </div>
                        :
                            <div className={"employment_wage_modal"}>
                                <div className={"employment_wage_container_modal"}>
                                    <div className={"employment_wage_subtitle_modal"}>
                                        Тип занятости
                                    </div>
                                    <div className={"employment_wage_text_modal"}>
                                        {props.item.work_type}
                                    </div>


                                </div>
                                <div className={"employment_wage_container_modal"}>
                                    <div className={"employment_wage_subtitle_modal"}>
                                        Требуемый опыт работы
                                    </div>
                                    <div className={"employment_wage_text_modal"}>
                                        {props.item.work_expirience}
                                    </div>
                                </div>
                                <div className={"employment_wage_container_modal"}>
                                    <div className={"employment_wage_subtitle_modal"}>
                                        Заработная плата
                                    </div>
                                    <div className={"employment_wage_text_modal"}>
                                        {props.item.work_price}
                                    </div>
                                </div>
                            </div>
                    }

                    <div className={"vacancies_button_modal"}>
                        {settings.projectWidth && settings.projectWidth > 427 ?
                            <a className={"button_response_vacancies_modal"}
                               href={"tel:"+props.item.manager_number_back}
                               target={"_blank"}
                               rel="noreferrer"> Позвонить {props.item.manager_number_frontend} / {props.item.manager_name ? props.item.manager_name : " — "} </a>
                            :
                            <a className={"button_response_vacancies_modal"}
                               href={"tel:"+props.item.manager_number_back}
                               target={"_blank"}
                               rel="noreferrer"> Позвонить {props.item.manager_number_frontend} </a>
                        }
                    </div>
                </div>


        </div>
    );
});

export default VacanciesModal;