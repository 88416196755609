import React, {useEffect} from 'react';
import './RunningLine.css'

const RunningLine = (props) => {

    // console.log(props)
    // console.log("RLONE" )
    // useEffect(() => {
    //
    // },[])

    let fakearr = ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",]

    const runlineActiveText = () => {
        let text = "";
        for (let i = 0; i < 50; i++) { // выведет 0, затем 1, затем 2
            text += props.runline.runline_text;
        }
        return text;
    }
    return (

        <div className="run_line">
            {
                props.runline && !props.runline.runline ?
                    <span className='run_text'>{props.runline.runline_text ? props.runline.runline_text : "" }</span>
                    :
                    <marquee className={"run_text"} direction="right" scrollamount="5">{
                        fakearr.map((item,index) => {
                            return(<span className={"runline_copy"} key={index}>{props.runline.runline_text}</span>)

                        })
                    }</marquee>

            }

        </div>
    );
};

export default RunningLine;