import React from 'react';
import "./contentBreadcrumbs.css";
import BreadcrumbsItem from "./BreadcrumbsItem";

const ContentBreadcrumbs = (props) => {
    return (
        <div itemScope className={"breadcrumbs_content"} style={{top:props.margTop}} itemType="http://schema.org/BreadcrumbList" >
            {props.items.map((item,index) =>
                <BreadcrumbsItem
                    key={index}
                    item={item}
                    position={index+1}
                />
            )}
        </div>
    );
};

export default ContentBreadcrumbs;
