import React, { useState } from 'react';
import './WiewButton.css'
const WiewButton = (props) => {

    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };

    const dynamicStyle = {
        background: isHover ? props.hover : "",
        color: isHover ? "#FFFFFF" : props.text,
        transition: "background 0.2s ease 0s, border-color 0.2s ease 0s, color 0.2s ease 0s",
        borderColor: isHover ? props.hover : props.text
    }

    const staticStyle = {
        background: isHover ? "#1A1A1A" : "",
        color:  isHover ? "#FFFFFF" : "",
        transition: "background 0.2s ease 0s, border-color 0.2s ease 0s, color 0.2s ease 0s",
        borderColor: isHover ? "#1A1A1A" : ""
    }
  
    return (
        <a 
            className={props.black ? "goPageButton black" : "goPageButton"} 
            style={props.hover && props.text ? dynamicStyle : staticStyle} 
            href={props.link}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >{props.button}</a>
    );
};

export default WiewButton;