import React from 'react';
import './isActiveCheckbox.css'

const IsActiveCheckbox = ({active, change}) => {
    return (
            <div className={active ? "wrapper_indicator on" : "wrapper_indicator"}>
                <div
                    onClick={change}
                    className={"is_active_item"}>
                    <div className={"circle_active_item"}>

                    </div>
                </div>
            </div>
    );
};

export default IsActiveCheckbox;