import React, {useContext, useEffect, useRef, useState} from 'react';
import "./lookBook.css"
import {observer} from "mobx-react-lite";
import {fetchLookBook, fetchMiniLookBook} from "../../../http/contentApi";
import ContentBreadcrumbs from "../../../components/Breadcrumbs/contentBreadcrumbs/ContentBreadcrumbs";
import {Context} from "../../../index";
import OneInstaLook from "./oneInstaLook";
import {Link, useParams} from "react-router-dom";
import Pagination from "../../../components/Paginstion";
import Helmet from "react-helmet";
import {useLocation} from "react-router";
import {Swiper, SwiperSlide} from "swiper/react";

const LookBook = observer(() => {

    const {settings} = useContext(Context)

    const [pageLookBokContent, setPageLookBokContent] = useState({})
    const [miniLookArray, setMiniLookArray] = useState([])
    const [pageSeoPropsLookBok, setPageSeoPropsLookBok] = useState(false)

    const [arrowsLeftState, setArrowsLeftState] = useState(false)
    const [arrowsRightState, setArrowsRightState] = useState(false)

    //для метатегов, определяем есть ли ?page=
    const location = useLocation();
    const [pathNameLook, setPathNameLook] = useState('')

    useEffect(() => {
        if(location) {
            let tmp = location.search
            setPathNameLook(tmp) ;
        }
    }, [location])

    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')


    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = parseInt(params.get("page")); // is the number

    } else if (!page || page <= 0) {
        page = 1
    }


    const [lookBookArray,setLookBookArray] = useState(null);

    const limit = 3;
    const [allCount,setAllCount] = useState(0)

    useEffect(() => {

        window.scrollTo(0, 0)

        fetchLookBook(limit,(page-1)*limit,true).then(response =>  {
            setLookBookArray(response.rows);
            setAllCount(response.count);
            setPageLookBokContent(response)
        })
        fetchMiniLookBook().then(response =>  {
            setMiniLookArray(response)
            if (response.length > 6 && settings.projectWidth > 649) {
                setArrowsRightState(true)
            }
            if (response.length > 4 && settings.projectWidth < 650) {
                setArrowsRightState(true)
            }
        })
    },[page])

    const sliderRef = useRef();
    const clickButtonRight = () => sliderRef.current?.swiper.slideNext();
    const clickButtonLeft = () => sliderRef.current?.swiper.slidePrev();

    return (
        <div style={{marginTop:settings.headerHeight}}>
            <Helmet>
                <title>Lookbook интернет-магазина YOU WANNA {page && pathNameLook ? ' - страница №'+page : ''}</title>
                <meta name="description" content={"Lookbook в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" + (page && pathNameLook ? ' - страница №'+page : '')} />
            </Helmet>
            <ContentBreadcrumbs
                margTop={settings.headerHeight}
                items={[
                    {name:"Главная", link:"/"},
                    {name:"Lookbook", link:"/lookbook"}
                ]}
            />


            <div className={"page_look_wrapper"}>
                <div className={"slider_inst_look"}>
                    {
                        !!miniLookArray && miniLookArray.length > 6 && settings.projectWidth > 768 &&
                            <div className={"arrows_insta_wrap left"}>
                                <div className={arrowsLeftState ? "arrows_insta left" : "arrows_insta left block"} onClick={clickButtonLeft}>
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.82471 12.0334L9.39055 9.42941L7.97288 8.02712L3.00022 13.0543L8.02742 18.027L9.42971 16.6093L6.83562 14.0334L23.0054 13.9452L22.9945 11.9453L6.82471 12.0334Z" fill="#1A1A1A"/>
                                    </svg>
                                </div>
                            </div>
                    }

                    {
                        !!settings.projectWidth && settings.projectWidth < 769 &&
                            <div className={"arrows_insta_wrap left"}>
                                <div className={"arrows_insta left"}>
                                </div>
                            </div>
                    }

                    <Swiper
                        slidesPerView="auto"
                        spaceBetween={30}
                        slidesPerGroup={3}
                        watchSlidesProgress={true}
                        freeMode={true}
                        ref={sliderRef}
                        onProgress={(swiper) => {
                            setArrowsLeftState(swiper.progress > 0);
                            setArrowsRightState(swiper.progress < 1);
                        }}
                        style={settings.projectWidth > 768 ? {maxWidth:692} : {maxWidth:settings.projectWidth-1}}
                        id="con"
                        className={"scroll_limiter"}>

                        {
                            miniLookArray ? miniLookArray.map((item, index) =>
                                <SwiperSlide key={index} style={{maxWidth: 'max-content'}}>
                                    <OneInstaLook
                                        item={item}
                                        index={index}
                                    />
                                </SwiperSlide>
                            ) : ''
                        }
                    </Swiper>

                    {
                        !!miniLookArray && miniLookArray.length > 6 && settings.projectWidth > 768 &&
                            <div className={"arrows_insta_wrap right"}>
                                <div className={arrowsRightState ? "arrows_insta right" : "arrows_insta right block"} onClick={clickButtonRight}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.17 13L15.59 15.59L17 17L22 12L17 7L15.59 8.41L18.17 11H2V13H18.17Z" fill="#1A1A1A"/>
                                    </svg>
                                </div>
                            </div>
                    }

                    {
                        !!settings.projectWidth && settings.projectWidth < 769 &&
                            <div className={"arrows_insta_wrap right"}>
                                <div className={"arrows_insta right"}>
                                </div>
                            </div>
                    }
                </div>
                <div className={"big_img_look"}>
                    {
                        lookBookArray ? lookBookArray.map((itemBig, indexBig) =>
                                <Link key={indexBig} to={"/lookbook/"+itemBig.id+"/"} className={"look_big_wrapper"}>
                                        <div className={"img_preview_look"}>
                                            {
                                                itemBig.img_preview ?
                                                    <img src={process.env.REACT_APP_API_URL+"uploads/images/lookbook/"+itemBig.id+"/"+"1300_"+itemBig.img_preview} alt={'YouWanna - Lookbook '+(itemBig.name ? itemBig.name : '')}/>
                                                    : ''
                                            }
                                        </div>
                                        {itemBig.name ?
                                            <div className={"preview_look_title"}>
                                                {itemBig.name}
                                            </div>
                                            : ''
                                        }
                                        <div className={"to_page_look"}>
                                            Узнать больше
                                        </div>
                                </Link>

                        ) : ''
                    }
                </div>
            </div>
            <div className={"lookbook_pagination"}>
                <Pagination page={page} limit={limit} count={allCount}/>
            </div>
        </div>
    );
});

export default LookBook;