import React, { useEffect, useState } from 'react';

import './css/ReturnsListItem.css'
import { formatDate } from "../../../../utils/date";
import ReturnsApi from "../../../../http/returnsApi";

const ReturnsListItem = ({
    item,
    setCheck,
    check
}) => {
    const statusList = [
        {id: 0, value: 'Новый'},
        {id: 1, value: 'В обработке'},
        {id: 2, value: 'Выполнен'},
    ]
    let articles;
    try{
        articles = JSON.parse(item.articles)
    } catch (e) {
        articles = [];
    }


    const updateReturn = async (newItem, value) => {
        const returnItem = {...newItem, status: value};
        const response = await ReturnsApi.update(returnItem)

        if (!response.success)
            console.log(response.message)
    }

    const getArticle = () => {
        if(articles.length > 0){
            let srt = [];
            articles.forEach( (el) => {
                srt.push(el.article)
            })
            return srt.join('\n')
        }
        return item.article
    }

    const getSize = () => {
        if(articles.length > 0){
            let srt = [];
            articles.forEach( (el) => {
                srt.push(el.size)
            })
            return srt.join('\n')
        }
        return item.size
    }

    const getColor = () => {
        if(articles.length > 0){
            let srt = [];
            articles.forEach( (el) => {
                srt.push(el.color)
            })
            return srt.join('\n')
        }
        return item.color
    }

    const delReturnsList = async () => {
        const response = await ReturnsApi.delete(item.id)

        if (!response.success) {
            console.log('ОШИБКА')
        }
        setCheck(!item.check)
    }


    return (
        <div
            onClick={e => (setCheck(!check))}
            className={"returns-list-item"}>
            <a href={`/admindashboard/returns/${item.id}`} className="">
                <div className="returns-list-item__field">
                    {item.id}
                </div>
                <div className="returns-list-item__field">
                    {item.returnType == 2 ? "Самостоятельный возврат" : "Возврат онлайн-магазин"}
                </div>
                <div className="returns-list-item__field">
                    {formatDate(new Date(item.createdAt))}
                </div>
                <div className="returns-list-item__field">
                    {item.lastName} {item.firstName} {item.middleName}
                </div>
                <div className="returns-list-item__field">
                    {item.phone}
                </div>
                <div className="returns-list-item__field">
                    {getArticle()}
                </div>
                <div className="returns-list-item__field">
                    {item.orderNumber}
                </div>
                <div className="returns-list-item__field">
                    {getSize()}
                </div>
                <div className="returns-list-item__field">
                    {getColor()}
                </div>
                <div className="returns-list-item__field" onClick={(e => e.preventDefault())}>
                        <select
                            className={'status_refund'}
                            onChange={e => updateReturn(item, e.target.value)}
                            value={item.status}>
                            {statusList.map((m, ix) => {
                                return <option
                                    key={m.id}
                                    value={m.value}
                                >
                                    {m.value}
                                </option>
                            })};
                        </select>
                    </div>
                <div className="returns-list-item__field">
                    {item.dateOfIssue}
                </div>
            </a>
            <div
                onClick={e => delReturnsList(item.id)}
                className={"trash_box returns-list-item__field"}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 10L14 17" stroke="#BFBFBF" strokeWidth="2" strokeLinecap="round" strokeLinecap="round"/>
                    <path d="M10 10L10 17" stroke="#BFBFBF" strokeWidth="2" strokeLinecap="round" strokeLinecap="round"/>
                    <path d="M18 6H6V20C6 20.5523 6.44772 21 7 21H17C17.5523 21 18 20.5523 18 20V6Z" stroke="#BFBFBF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M4 6H20" stroke="#BFBFBF" strokeWidth="2" strokeLinecap="round" strokeLinecap="round"/>
                    <path d="M15 3H9C8.44772 3 8 3.44772 8 4V6H16V4C16 3.44772 15.5523 3 15 3Z" stroke="#BFBFBF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        </div>

    );
};

export default ReturnsListItem;
