import React, {useState} from 'react';
import "./OneTrendAdmin.css";
import {deleteTrend, updateTrend} from "../../../../http/catalogApi";

const OneTrendAdmin = (props) => {

    const remTrend = async (id) => {
        const {data} = await deleteTrend(id)
        if(data.message){
            props.setTrendTriger(!props.trendTriger)
            return false;
        }
        if(data.length){
            setErrMessage(data);
        }

    }

    const updateTrandHandler = async (item) => {
        updateTrend(item).then(response => {
            if(response.error) {
                setUpdateStatus(response.error);
                return false;
            }
            if(response.message) {
                setUpdateStatus(response.message);
                return false;
            }
            return false;
         })
    }
    const [updateStatus,setUpdateStatus] = useState("");
    const [errMessage, setErrMessage] = useState("");

    return (
        <div className={"one_trend_wrapper"}>
            <div className={"one_trend_position_field_container"}>
                <span className={"label_position_field"}>Название </span>
                <input
                    className={"trend_field_pos"}
                    value={props.item.name ? props.item.name : "" }
                    onChange={ e => props.setTrends([...props.trends],[props.trends[props.index].name = e.target.value])}
                    type="text"/>
            </div>
            <div className={"update_one_trend_button"} onClick={e => updateTrandHandler(props.item)}> Сохранить </div>

            <div onClick={e => remTrend(props.item.id)} className={"delete_trend_button"}> Удалить </div>
            <div className={"update_information_this_trend"}> {updateStatus ? updateStatus : ""} </div>
            <div className={errMessage.error ? "trend_delete_response_server active" : "trend_delete_response_server"}>
            </div>
        </div>

    );
};

export default OneTrendAdmin;