import React, {useEffect, useState} from 'react';
import './cityManager.css'
import {addNewCity, fetchAllCity} from "../../../../http/settingsApi";
import OneCityLine from "./OneCityLine";


const CityManager = () => {

    const   [newCity,setNewCity] = useState({})
    const   [errorMessage, setErrorMessage] = useState("")
    const   [refreshDbCity,setRefreshDbCity] = useState(false);
    const   [cityList,setCityList] = useState([]);

    useEffect(() => {
        fetchAllCity().then(response => {
            setCityList(response)
        } )
    },[])
    useEffect(() => {
        fetchAllCity().then(response => {
            setCityList(response)
        } )
    },[refreshDbCity])


    const cityHandler = (value) => {
        setNewCity({...newCity, name:value})
    }
    const cityHandlerUrl = (value) => {
        setNewCity({...newCity, map_url:value})
    }

    const addNewCityHandler = (newCity) => {
        addNewCity(newCity.name, newCity.map_url).then(response => {
            if(response.error) {
                setErrorMessage(response.error)
            }
            if(response.message) {
                setNewCity({...newCity, name:"", map_url: ""})
                setErrorMessage("")
                setRefreshDbCity(!refreshDbCity)
            }
        })
    }


    return (
        <div className={"city_page_wrapper"}>
            <span className={"city_page_title"}> Города </span>
            <div className={"city_page_container"}>
                <div className={"controll_horizontal_barr"}>
                    <div className={"search_citys"}>
                        want after add search / sort
                    </div>
                    <div className="add_controll_block">
                        <div className={"error_message_city_page"}>
                            {errorMessage}
                        </div>
                        <input
                            placeholder={"Введите название"}
                            className={"input_city_st"}
                            value={newCity.name ? newCity.name : ""}
                            onChange={e => cityHandler(e.target.value)}
                            type="text"

                        />
                        <input
                            placeholder={"Введите ссылку на карту"}
                            className={"input_city_st"}
                            value={newCity.map_url ? newCity.map_url : ""}
                            onChange={e => cityHandlerUrl(e.target.value)}
                            type="text"

                        />
                        <span
                            onClick={e => addNewCityHandler(newCity) }
                            className={"add_new_city_button"}>Добавить город</span>
                    </div>

                </div>

                <div className={"city_list_container"}>
                    <div className={"heading_sity_list_lable"}>
                        <div className={"sity_list_heading_id_container"}>ID</div>
                        <div className={"sity_list_heading_name_container"}>Название города</div>
                        <div className={"sity_list_heading_map_container"}>Карта для страницы магазинов</div>
                        <div className={"sity_list_heading_order_check_container"}>Выводится в оформлении заказа</div>
                        <div className={"sity_list_heading_order_filter_container"}>Сортировка для оформления заказ</div>
                    </div>
                    {
                        cityList ?
                            cityList.map((item,index) =>
                                <OneCityLine key={item.id} index={index} item={item} setCityList={setCityList} cityList={cityList} setRefreshDbCity={setRefreshDbCity} refreshDbCity={refreshDbCity} />

                            )

                            : ""
                    }

                </div>
            </div>
        </div>

    );
};

export default CityManager;