import React from 'react';
import './FormInput.css';

const FormInput = (props) => {


    return (

        <div className="input_contaner_sub">
            <div className={"name_input"}>Email</div>
            {/*<span className={props.value ? "input_placeholder active" : "input_placeholder"}> E-mail </span>*/}
            <input
                className="subs_Form_Input" {...props} >
            </input>
        </div>

    );
};

export default FormInput;