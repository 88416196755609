import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from "react-helmet";

import './refoundAndDelivery.css';
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import PageDelivery from "../../../components/DeliveryAndRefound/BlockDelivery/PageDelivery";
import {getOnePageContent} from "../../../http/contentApi";


const RefoundAndDelivery = observer(() => {

    const {settings} = useContext(Context)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [deliveryNoJson, setDeliveryNoJson] = useState({})

    useEffect(() => {
        getOnePageContent(10).then(response => {

            if (response.content) {
                setDeliveryNoJson(JSON.parse(response.content))
            }
        })
    }, [])

    return (
        <div
            style={{marginTop: settings.headerHeight}}
            className={"refound_and_delivery"}>
            <Helmet>
                <title>{deliveryNoJson.titleDelivery ? deliveryNoJson.titleDelivery : ""}</title>
                <meta name="description" content={deliveryNoJson.descriptionDelivery ? deliveryNoJson.descriptionDelivery : ""}/>
            </Helmet>
            <div className={"refound_and_delivery_child"}>
                <div className={"conteiner_banner"}>
                    <div className={"title_and_categories"}>
                        <h1 className={"title_delivery"}>{deliveryNoJson.nameDelivery}</h1>
                    </div>
                </div>
                <PageDelivery
                    deliveryNoJson={deliveryNoJson}
                />
            </div>
        </div>
    );
});

export default RefoundAndDelivery;