import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from "react-helmet";

import './reviews.css';
import {fetchAllReviews, getOnePageContent} from "../../../http/contentApi";
import {Context} from "../../../index";
import ContentBreadcrumbs from "../../../components/Breadcrumbs/contentBreadcrumbs/ContentBreadcrumbs";
import ReviewsCards from "../../../components/ReviewsPage/reviewsCards/ReviewsCards";
import {observer} from "mobx-react-lite";
import ReviewsModal from "../../../components/ReviewsPage/reviewsModal/ReviewsModal";
import ResultReviwsModal from "../../../components/ReviewsPage/reviewsModal/ResultReviwsModal";

const Reviews = observer(() => {

    const {user} = useContext(Context)
    const {settings} = useContext(Context)

    const [openModalReviews, setOpenModalReviews] = useState(false)
    const [openModalReviewsSuccess, setOpenModalReviewsSuccess] = useState(false)


    const goModalReviews = () => {
        user.setShadowOverlay(true);
        setOpenModalReviews(true);
        user.setStateOpenModalWindow(true)
    }

    const closeModalReviews = () => {
        setOpenModalReviews(false);
        user.setShadowOverlay(false);
        user.setStateOpenModalWindow(false)
    }

    const closeModalReviewsResult = () => {
        setOpenModalReviewsSuccess(false);
        user.setShadowOverlay(false);
        user.setStateOpenModalWindow(false)

    }

    useEffect(() => {
        if (user.shadowOverlay === false) {
            setOpenModalReviews(false);
        }
    }, [user.shadowOverlay])


    useEffect(() => {
        if (openModalReviewsSuccess === true) {
            user.setShadowOverlay(true);
            setOpenModalReviewsSuccess(true);
            user.setStateOpenModalWindow(true)
        }
    }, [openModalReviewsSuccess])




    const [reviews, setReviews] = useState([]);
    const [otherValues, setOtherValues] = useState({
        goodCount: 0,
        countItems: 0,
        procent: 0,
    })

    const [fechParams, setFetchParams] = useState({
        moderate: true,
        field: 'createdAt',
        sort: 'DESC',
    })
    const [contentPage, setContentPage] = useState({});
    useEffect(() => {
        window.scrollTo(0, 0)
        getOnePageContent(1).then(response => {
            // setContentPage(response)
            if (response.content) {
                let reduceArray = {...response, content: JSON.parse(response.content)};

                setContentPage(reduceArray)
            }

        })
    }, [])


    useEffect(() => {
        fetchAllReviews(fechParams.moderate, fechParams.field, fechParams.sort).then(data => {
            if (data.error) {

                return false;
            }
            if (data.data) {
                setOtherValues({
                    ...otherValues,
                    goodCount: data.goodCount,
                    countItems: data.countItems,
                    procent: data.procent
                })

                setReviews(data.data)
                return false;
            }

            setFetchParams({...fechParams, field: "stars", sort: "ASC"})
            setReviews(data)
        })

    }, [fechParams])

    let roundedProcent = Math.ceil(otherValues.procent)



    const [sorting, setSorting] = useState('');



    const [topHeightReviews, setTopHeightReviews] = useState('');

    useEffect(()=> {

        if (contentPage.content && settings.projectWidth > 1024) {
            let topBlockProcent = document.getElementById('reviews_check_top').offsetTop
            // console.log(topBlockProcent)
            setTopHeightReviews(topBlockProcent)
        }
    }, [settings.projectWidth, contentPage])


    return (
        <div className={"reviews_page_wrapper"}>
            <div
                style={{marginTop: settings.headerHeight}}
                className={"reviews_page"}>
                {
                    (contentPage.title || contentPage.description)
                    &&
                    <Helmet>
                        <title>{contentPage.title}</title>
                        <meta name="description" content={contentPage.description} />
                    </Helmet>
                }
                {
                    contentPage.content ?
                        <div
                            style={{top: settings.headerHeight}}
                            className={"reviews_banner"}>

                            {/*{*/}
                            {/*    contentPage.content.img ?*/}
                            {/*        <img className="banner_reviews_image" src={*/}
                            {/*            process.env.REACT_APP_API_URL+"uploads/images/reviews/"+ contentPage.content.img*/}
                            {/*        } alt=""/> : ""*/}
                            {/*}*/}


                            {/*{settings.projectWidth && settings.projectWidth > 1024 ?*/}
                            {/*    <ContentBreadcrumbs*/}
                            {/*    margTop={settings.headerHeight}*/}
                            {/*    items={[*/}
                            {/*    {name:"Youwanna", link:"/"},*/}
                            {/*    {name:"ОТЗЫВЫ", link:"/content/reviews/"}*/}
                            {/*]}*/}
                            {/*    />*/}

                            {/*    :*/}

                            {/*    ""*/}

                            {/*}*/}


                            {/*<div className={"reviews_abs_div_content"}>*/}
                            {/*    <div className={"reviews_static_div_content"}>*/}
                            <div className={"reviews_headlines"}>
                                <h1 className={"reviews_page_title"}>{contentPage.name}</h1>
                                <h2
                                    className={"reviews_subline"}>{contentPage.content.text ? contentPage.content.text : ""}</h2>
                            </div>

                            <div id={"reviews_check_top"} className={"reviews_check"}>
                                <div className={"total_number_reviews_conteiner"}>
                                    <span className={"total_number_reviews subline"}>Всего отзывов:</span>
                                    <span
                                        className={"total_number_reviews"}>{otherValues.countItems > 0 ? otherValues.countItems : "-"}</span>
                                </div>
                                <div className={"reviews_scale_conteiner"}>
                                    <div className={"reviews_indicators"}>
                                        <div className={"reviews_indicators_child"}>
                                            {/*<div className={"reviews_indicators_positive"}></div>*/}
                                            <span>Положительных</span>
                                        </div>
                                        <div className={"reviews_scale"}>
                                            <div style={{width: roundedProcent + "%"}}
                                                 className={"reviews_scale_child positive"}></div>
                                        </div>
                                    </div>
                                    <div className={"reviews_indicators"}>
                                        <div className={"reviews_indicators_child"}>
                                            {/*<div className={"reviews_indicators_negative"}></div>*/}
                                            <span>Отрицательных</span>
                                        </div>
                                        <div className={"reviews_scale"}>
                                            <div style={{width: 100 - roundedProcent + "%"}}
                                                 className={"reviews_scale_child negative"}></div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"reviews_btn_additions"}>
                                    <span onClick={e => goModalReviews()}
                                          className={"reviews_btn_style"}>Оставить отзыв</span>
                                </div>
                            </div>
                            {/*</div>*/}
                            {/*</div>*/}
                        </div>
                        : ""
                }


                {/*<div className={"reviews_sorting_conteiner"}>*/}
                {/*    <span onClick={event => setFetchParams({...fechParams, field: "stars", sort: "DESC"},setSorting('positive'))} className={sorting === 'positive' ? "reviews_sorting active" : "reviews_sorting good"}>Сначала положительные</span>*/}
                {/*    <span onClick={event => setFetchParams({...fechParams, field: "stars", sort: "ASC"}, setSorting('negative'))} className={sorting === 'negative' ? "reviews_sorting active" : "reviews_sorting bad"}>Сначала отрицательные</span>*/}
                {/*</div>*/}
                <div
                    style={{marginTop: topHeightReviews}}
                    className={"container_reviews"}>
                    {reviews ?
                        reviews.map((item, index) =>
                            <ReviewsCards
                                key={index}
                                item={item}
                            />
                        )
                        : "Отзывов нет"
                    }
                </div>
                {/*<div className={"reviews_btn_additions black"}>*/}
                {/*    <span className={"reviews_btn_style black"}>Загрузить еще</span>*/}
                {/*</div>*/}
                <ReviewsModal
                    className={openModalReviews === true ? "active" : "hide"}
                    closeClick={() => closeModalReviews()}
                    openModalReviewsSuccess={openModalReviewsSuccess}
                    setOpenModalReviewsSuccess={setOpenModalReviewsSuccess}
                />
                <ResultReviwsModal className={openModalReviewsSuccess === true ? "active" : "hide"}
                                   closeClick={() => closeModalReviewsResult()}
                />
            </div>
        </div>
    );
});

export default Reviews;