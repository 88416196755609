import React, {useEffect, useMemo, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import './allProducts.css'
import {addProduct, getAllProducts, serchChildProductsForAdminCatalog} from "../../../../http/catalogApi";
import OneProductLine from "./oneProductLine/OneProductLine";
import OneChildLine from "./oneChildLine/OneChildLine";
import {generateExcelExport} from "../../../../http/settingsApi";
import Pagination from "../../../../components/Paginstion";

const AllProducts = () => {

    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')
    let mode = +queryParams.get('mode')
    let limit = 25
    if (!page || page <= 0) {
        page = 1
    }

    const [allCount, setAllCount] = useState(0)
    const [searchParam,setSearchParam] = useState('name');
    const [sortField,setSortField] = useState('updatedAt');
    const [sortParam,setSortParam] = useState('ASC');
    const [searchText, setSearchText] = useState('');
    const [excelMenuSwitch, setExcelMenuSwitch] = useState(false);
    const [excelOptions, setExcelOptions] = useState({'notifyAdmission': false, 'available': false});

    const setExcelOptionsBtn = (e) => {
        if (!excelMenuSwitch) {
            e.target.style.backgroundColor = 'black';
            e.target.style.color = 'white';
        } else {
            setExcelOptions({'notifyAdmission': false, 'available': false});
            e.target.style.backgroundColor = 'transparent';
            e.target.style.color = 'inherit';
        }
        setExcelMenuSwitch(!excelMenuSwitch);
    }

    const setNotifyAdmissionCheckboxChange = () => {
        setExcelOptions({'notifyAdmission': !excelOptions.notifyAdmission, 'available':  false});
    }

    const setAvailableCheckboxChange = () => {
        setExcelOptions({'notifyAdmission': false, 'available':  !excelOptions.available});
    }

    // Если меняем параметр сортировки - устанавливается новый + АСК. Если кликаем повторно, меняем DESC -> ASC / ASC -> DESC
    const changeSortFieldHandler = (param) => {
        if(sortField !== param ) {
            setSortField(param);
            setSortParam('ASC')
            return false;
        }
        if (sortParam === "ASC") {
            setSortParam('DESC')
        } else {
            setSortParam('ASC')
        }


    }

    const Navigate = useNavigate()
    const [products,setProducts] = useState("");

    const [operatingMode, setOperatingMode] = useState(mode || 1);

    const [searchValueForChild,setSearchValueForChild] = useState("");
    const [sortParametr,setSortParametr] = useState("ASC");

    const [childProducts,setChildProducts] = useState(null);

    useEffect(() => {

        if (operatingMode === 1) {
            getAllProducts(sortField, sortParam, searchText, searchParam, page, limit).then(data => {
                setProducts(data.data.rows)
                setAllCount(0)
                setAllCount(data.data.count)
                const maxPage =  Math.ceil(data.data.count / limit)
                if (page > maxPage) {
                    Navigate(`/admindashboard/products?page=${maxPage}&mode=1`)
                }
            })
        }
        if (operatingMode === 2) {
            serchChildProductsForAdminCatalog("sort", sortParametr, searchValueForChild, page, limit).then(response => {
                setChildProducts(response.rows)
                setAllCount(0)
                setAllCount(response.count)
                const maxPage =  Math.ceil(response.count / limit)
                if (page > maxPage) {
                    Navigate(`/admindashboard/products?page=${maxPage}&mode=2`)
                }
            })
        }

    },[sortField, sortParam, operatingMode, sortParametr, searchValueForChild, page, searchText])


    const addNewProductClick = async (e) => {
       const {data} = await addProduct();
        if(data.error) {
            return false;
        }
        Navigate("/admindashboard/products/oneitem/"+data.id+"/")
    }


    const searchHandler = (e) => {
        e.preventDefault()
        setSearchText(e.target.value)
    }


    /*
    const sortedAndSearchedProduct = useMemo( () => {
        if(searchText) {
            return [...products].filter(item => String(item[searchParam]).toLowerCase().includes(searchText.toLowerCase()) );
        }
        return products;
    }, [searchText,products])
    */


    const [urlDownload,setUrlDownload] = useState("")
    const [inProcess,setInProcess] = useState(false)

    const generateExcelExportHandler = () => {
        setUrlDownload("");
        setInProcess(true)
        generateExcelExport(excelOptions).then(response => {
            if(response) {
                setUrlDownload(response)
                setInProcess(false)
            }
        })
    }



    return (
        <div className={"all_products_page_wrapper"}>
            <div className={"all_products_controll_bord"}>

                <div className={"info_bord_adm_panel"}>
                    <span className={"info_name"}>Все продукты</span>
                </div>
                <div className="add_new_product">
                    <span onClick={e => addNewProductClick()}> Добавить продукт </span>
                </div>
                <div className={'info_bord_adm_panel_add'}>
                    { inProcess && <span className='loading'>In process... </span>}
                    <a className={urlDownload ? "downl active" : "downl"} href={process.env.REACT_APP_API_URL+urlDownload} download={true}>скачать таблицу</a>
                    <button className="excel_dropdown_btn" onClick={setExcelOptionsBtn}>С опциями выгрузки</button>
                    {
                        excelMenuSwitch ?
                            <div className="excel_dropdown_menu">
                                <div>
                                    <input id={'excel_radio_notify_admission'} type="radio" name="drone" value="notifyAdmission" checked={excelOptions.notifyAdmission} onChange={setNotifyAdmissionCheckboxChange}/>
                                    <label htmlFor="excel_radio_notify_admission">Только с "Уведомить о поступлении"</label>
                                </div>

                                <div>
                                    <input id={'excel_radio_available'} type="radio" name="drone" value="available" checked={excelOptions.available} onChange={setAvailableCheckboxChange}/>
                                    <label htmlFor="excel_radio_available">Только с "Доступные к покупке"</label>
                                </div>
                            </div>
                            : ''
                    }
                    <span
                        onClick={e => generateExcelExportHandler()}
                        className={inProcess ? "generate_excel_feed disabled" : "generate_excel_feed"}>Сгенерировать таблицу товаров</span>
                </div>
            </div>

            <div className={"products_panel_wrapper"}>
                <div className="table_products_adm">
                        <div className={"filters_and_controll_products_block"}>

                            {
                                operatingMode === 1 ?
                                    <div className={"search_filter_container"}>
                                        <div className={"controls_button_search"}>
                                            <div
                                                onClick={e => setSearchParam("name")}
                                                className={searchParam === "name" ? "search_btn_param active" : "search_btn_param"}>Название</div>
                                            <div
                                                onClick={e => setSearchParam("id")}
                                                className={searchParam === "id" ? "search_btn_param active" : "search_btn_param"}>ID</div>
                                            <div
                                                onClick={e => setSearchParam("articul")}
                                                className={searchParam === "articul" ? "search_btn_param active" : "search_btn_param"}>Артикул</div>
                                        </div>

                                        <div className={"container_for_search_field"}>
                                            <input className={"search_input_products_page"} type="text"
                                                   value={searchText}
                                                   onChange={e => searchHandler(e)}
                                                   placeholder={"Поиск..."}
                                            />
                                        </div>
                                    </div>
                                :
                                <div className={"search_and_sort_for_child"}>
                                    <input placeholder={"Введите название..."} className={"search_child_input"} value={searchValueForChild} onChange={e => setSearchValueForChild(e.target.value)} type="text"/>
                                    <span className={"sort_heading_notificator"}>Направление сортировки</span>
                                    <div className={"sort_container_test_wrapper"}>
                                        <span
                                            className={ sortParametr === "ASC" ? "btn_for_control_catalog_mode active" :  "btn_for_control_catalog_mode" }
                                         onClick={e=> setSortParametr("ASC")}
                                        >по возрастанию</span>
                                        <span
                                            className={ sortParametr === "DESC" ? "btn_for_control_catalog_mode active" :  "btn_for_control_catalog_mode" }
                                         onClick={e => setSortParametr("DESC")}
                                        >по убыванию </span>
                                    </div>
                                </div>
                            }

                            <div className={"container_for_change_mode_catalog_admin_page"}>
                                <span className={"catalog_change_mode_heading"}>
                                    Выводить к просмотру:
                                </span>
                                <div className={"catalog_mode_change_buttons"}>
                                    <span
                                        onClick={e => setOperatingMode(1)}
                                        className={ operatingMode === 1 ? "btn_for_control_catalog_mode active" :"btn_for_control_catalog_mode" }>Товары</span>
                                    <span
                                        onClick={e => setOperatingMode(2)}
                                        className={ operatingMode === 2 ? "btn_for_control_catalog_mode active" :"btn_for_control_catalog_mode" }>Торговые предложения</span>
                                </div>
                            </div>

                        </div>
                    {
                        operatingMode === 1 ?
                          <div className={"wrapper_for_operating_mode_container"}>
                            <div className={"adm_marker_block"}>
                                <div className={"lft_block"}>
                                    <div onClick={e => changeSortFieldHandler("id")} className={sortField === "id" ? "info_of_product sort_field_p id active" : "info_of_product id sort_field_p  "}>ID</div>
                                    <div className={"info_of_product  img"}>IMG</div>
                                    <div onClick={e => changeSortFieldHandler("name")} className={sortField === "name" ? "info_of_product sort_field_p name active" : "info_of_product sort_field_p name " }> Название </div>
                                    <div onClick={e => changeSortFieldHandler("articul")} className={sortField === "articul" ? "info_of_product articul sort_field_p active" : "info_of_product articul sort_field_p"}> Артикул </div>
                                    <div className={"info_of_product predl"}> кол-во </div>
                                    <div className={"info_of_product status"}> Статус </div>
                                </div>
                                <div className={"rgt_block"}>
                                    <div onClick={e => changeSortFieldHandler("createdAt")} className={sortField === "createdAt" ? "time_bl_rgt sort_field_p active" : "time_bl_rgt sort_field_p"}>
                                        Дата созан.
                                    </div>
                                    <div onClick={e => changeSortFieldHandler("updatedAt")} className={sortField === "updatedAt" ? "time_bl_rgt sort_field_p active" : "time_bl_rgt sort_field_p"}>
                                        Посл. ред
                                    </div>
                                    <div className={"user_bl_rgt"}>
                                        Редактор
                                    </div>
                                    <div className={"del_bl_rgt"}>
                                        Удал.
                                    </div>

                                </div>
                            </div>
                            <div className={"product_wrapper_gener"}>

                                {
                                /*
                                    sortedAndSearchedProduct ?
                                        sortedAndSearchedProduct.map((item,index) =>
                                            <OneProductLine key={index} index={index} item={item} products={products} setProducts={setProducts}
                                            />
                                        )
                                        : ""
                                */
                                        products ?
                                        products.map((item,index) =>
                                            <OneProductLine key={index} index={index} item={item} products={products} setProducts={setProducts}
                                            />
                                        )
                                        : ""
                                }
                            </div>
                        </div>
                        :
                            <div className={"wrapper_for_operating_mode_container"}>
                                <div className={"wrapper_for_operating_mode_container"}>
                                    <div className={"adm_marker_block"}>

                                    </div>
                                    <div className="product_wrapper_gener">
                                        {
                                            childProducts ?
                                                childProducts.filter(item => (item && item.product && item.product.id && item.color && item.color)).map(item => <OneChildLine key={item.id} item={item}/>)
                                                : ""
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <div className="pagination_adm_wrapper">
                    <Pagination page={page} limit={limit} count={allCount} />
            </div>
        </div>
    );
};

export default AllProducts;