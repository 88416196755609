import React, {useState} from 'react';
import './OneColor.css';
import { fetchColors, removeOneColor, updateOneColor} from "../../../../http/catalogApi";
import {addImages, deleteImage} from "../../../../http/imagesApi";




const OneColor = (props) => {

const   [buttonState, setButtonState] = useState(false);

const changeNameHandler = (value) => {

    props.setCollorsArray([...props.colorsArray],[props.colorsArray[props.index].color_name = value])

        if(!buttonState) {
            setButtonState(true);
        }
    }

    const updateColor = async (object) => {
      const data = await updateOneColor(object);
        if(data.data.error) {
            return false;
        }

        if(data.data.message) {
            setButtonState(false);
        }

    }

    const uploadImageHandler = (e) => {
       let files = [...e.target.files]
        let formData = new FormData();
        formData.append("image", files[0]);

        addImages(formData,"colors", props.item.id).then((response) => {

            if(props.item.img){
                deleteImage(props.item.img, "colors", props.item.id).then(data => {
                } )
            }
            if(response.error) {
                return false;
            }
            let {fileName} = response;
            props.setCollorsArray([...props.colorsArray],[props.colorsArray[props.index].img = fileName])

            //*******************************
            // let reader = new FileReader();
            // reader.onload = (event: any) => {
            //     const image = new Image();
            //     image.src = event.target.result;
            //     image.onload = () => {
            //            let imageWidth = image.width;
            //            let imageheight = image.height;
            //     };
            //
            // };
            // reader.readAsDataURL(files[0]);
            //*********************************

        })

    }
    const [falseRemoveItems,setFalseRemoveItems] = useState([]);
    const removeColorHandler = async (id) => {
        const {data} = await removeOneColor(id);
        if (data.length) {
            setFalseRemoveItems(data);
        }
        if (data.message) {
            fetchColors().then(data => {
                props.setCollorsArray(data);
            } )
        }
    }

    return (
        <div className={"oneLineColor"}>

            <div className={falseRemoveItems.length ? "eror_div active" : "eror_div"}>
                {
                    falseRemoveItems.length ? <div className={"error_sup_message"}> Нельзя удалить. Используется в товарах:</div> : ""
                }
            {
                falseRemoveItems.length ?
                    falseRemoveItems.map((item,index) => {
                        return(<a className={"to_product_link_color"} href={"../products/oneitem/"+item.productId} key={index}  target={"_blank"}> {item.productId}</a>)
                    })
                    : ""
            }

            </div>
            <div className={"one_line_wrapper_sup"}>

            <div className={"color_items_wrap"}>
                <div className={"id_container_color"}>
                    {props.item.id}
                </div>
                <div className={"name_field colors_field_wrapper"}>
                    <span>название </span>
                    <input
                        value={props.item.color_name ? props.item.color_name : ""}
                        onChange={event => changeNameHandler(event.target.value)}
                        className={"params_color_input"} type="text" name="" id=""/>
                </div>
                <div className={"name_field colors_field_wrapper"}>
                    <span> цвет (hex): </span>
                    <input
                        value={props.item.hex ? props.item.hex : ""}
                        onChange={event => props.setCollorsArray([...props.colorsArray],[props.colorsArray[props.index].hex = event.target.value])}
                        className={"params_color_input"} type="text" name="" id=""/>
                    <div style={{background:props.item.hex}} className={"hex_preview"}> </div>
                </div>

                <div className={"image_container_colors"}>
                    <div className={"go_color_outp"}>
                        Загрузить
                        <input
                                onChange={e => uploadImageHandler(e)}
                            type="file" name="" id=""/>
                    </div>
                    {props.item.img ?

                        <img className={"color_img_circle"} src={process.env.REACT_APP_API_URL+"uploads/images/colors/"+props.item.id+"/"+props.item.img} alt=""/> :""
                    }
                </div>
            </div>

            <div className={"name_field colors_field_wrapper"}>
                <span> CODE: </span>
                <input
                    value={props.item.color_code ? props.item.color_code : ""}
                    onChange={event => props.setCollorsArray([...props.colorsArray],[props.colorsArray[props.index].color_code = event.target.value])}
                    className={"params_color_input"} type="text" name="" id=""/>
            </div>
            <div className={"right_control_wrapper"}>
                <div
                    onClick={e => removeColorHandler(props.item.id)}
                    className={"delete_color"}>
                    удалить
                </div>
                <div
                    onClick={event => updateColor(props.item)}
                    className={ buttonState ? "save_color_controll need_save" : "save_color_controll"}>
                    сохранить
                </div>
            </div>
        </div>
        </div>
    );
};

export default OneColor;