import React, {createContext} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import main from "./store/mainStore";
export const Context = createContext(null);

const stores = {
    main,
    user: main.user,
    settings: main.settings,
    basket: main.basket,
    delivery: main.delivery,
    lastViewedStore: main.lastviewed
};

ReactDOM.render(
    <Context.Provider value={{...stores}}>
        <App/>
    </Context.Provider>,

    document.getElementById('root')
);


