import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from "react-helmet";

import './StocksAndTrends.css';
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import BreadCrumbsBlack from "../../../components/Breadcrumbs/breadCrumbsBlack/BreadCrumbsBlack";
import SwitchesST from "../../../components/StocksAndTrends/switchesStocksAndTrends/SwitchesST";
import StocksTrends from "../../../components/StocksAndTrends/stocksTrends/stocksTrends";



const StocksAndTrends = observer(() => {

    const {settings} = useContext(Context)
    const margTop = settings.headerHeight

    const stocksArray = [
        {img:"/files/images/stocksTrends/stocks2.png", name:"Скидка 10% по промокоду YOU10!", category:"trends"},
        {img:"/files/images/stocksTrends/trends3.png", name:"Скидка 2% по промокоду 345678!", category:"trends"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скидка 5% по промокоду bigsale!", category:"trends"},
        {img:"/files/images/stocksTrends/trends2.png", name:"1231232Sale по промокоду bingo10!", category:"trends"},
        {img:"/files/images/stocksTrends/trends3.png", name:" по промокоду love!", category:"trends"},

        {img:"/files/images/stocksTrends/stocks2.png", name:"Дополнительная скидка 5% по промокоду APRIL!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"До121312 3Подаро кполнительн ая скидка 5% по промокоду APRIL!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скид1213123Подарокка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скид1213123Подарокка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"С1213123Подаро кки1213123Подаро к1213123Подарокдка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"Дополнительная скидка 5% по промокоду APRIL!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"До1213123Подаро кполнительная скидка 5% по промокоду APRIL!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скид1213123По дарокка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скид1213123Подарокка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"С1213123По  дарокки1 213123Подарок1213123Подарокдка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"Дополнительная скидка 5% по промокоду APRIL!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"До121 3123Под арокполнительная скидка 5% по промокоду APRIL!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скид1213123П одарокка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скид12 13123Подарокка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"С1213123Пода рокки1213 23Подарок1 13123Подарокдка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"Дополнительная скидка 5% по промокоду APRIL!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"До1213123 Подарокпол  нительная скидка 5% по промокоду APRIL!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скид1213123Подарокка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скид1213123П одарокка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"С1213123Под арокки12 13123Пода рок1213123Подарокдка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"Дополнительная скидка 5% по промокоду APRIL!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"До1213123По дарокполнительная  скидка 5% по промокоду APRIL!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скид1213123Подарокка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скид1213123Подарокка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"С1213123 Подарокки 1213123Пода рок1213123Подарокдка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"Дополнительная скидка 5% по промокоду APRIL!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"До1213 123Подарокполнительная скидка 5% по промокоду APRIL!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скид1213123Подарокка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скид1213123Подарокка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"С1213 123Подарокки12131 23Подарок12 3123Подарокдка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"Дополнительная скидка 5% по промокоду APRIL!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"До121312 3Подарокполнительная скидка 5% по промокоду APRIL!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скид1213123Подарокка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"Скид12131 23Подарокка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/trends1.png", name:"С121312 3Подарокки12 13123Пода ок1213123Подарокдка 10% по промокоду YOU10!", category:"stock"},
        {img:"/files/images/stocksTrends/stocks2.png", name:"Скидка 10% по промокоду YOU10!", category:"stock"},
    ]


    const [pagesActive,setPagesActive] = useState("stock");
    const [pagesCount, setPagesCount] = useState([])
    const [currentPage, setCurrentPage] = useState(0) // страница
    const step = 6; //шаг

    let categoryArray =  stocksArray.filter(function(categorySelection) {
        return categorySelection.category === pagesActive;
    }); // фильтр тренды акции
    let newPosts =  categoryArray.slice(currentPage*step,currentPage*step+step);  //делим на массивы
    let TotalPages = Math.ceil( categoryArray.length / step); // количество страниц
    let pageTotal = TotalPages+1 //общее количество страниц

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
        let arr = [];
        if (pageTotal > 4) {
            if (currentPage > 1) {
                for (let i = currentPage - 2; i <= currentPage + 2; i++) {
                    arr.push({index: i, number: Number (i+1)})
                    if (i === TotalPages-1) break
                }
            } else {
                for (let i = 0; i <= 2; i++) {
                    arr.push({index: i, number: Number (i+1)})
                    if (i === TotalPages) break
                }
            }
        } else {
            for (let i = 0; i < TotalPages; i++) {
                arr.push({index: i, number: Number (i+1)})
            }
        }
        setPagesCount(arr)
    }, [pagesActive, currentPage])


    return (
        <div style={{top:margTop, marginBottom:margTop}} className={"stocks-trends"}>
            <Helmet>
                <title>Акции и тренды</title>
                <meta name="description" content=" для женщин в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07."/>
            </Helmet>
            {settings.projectWidth && settings.projectWidth > 1024 ?
                <div className={"st_bread"}>
                    <BreadCrumbsBlack
                        items={[
                            {name: "Youwanna", link: "/"},
                            {name: "АКЦИИ И ТРЕНДЫ", link: "/content/info/"}
                        ]}
                    />
                </div>
                :
                ""
            }
            <div className={"st_content"}>
                <h1 className={"st_title_page"}>Акции и тренды</h1>
                <SwitchesST
                    st_stocks={"Акции"}
                    st_trends={"Тренды"}
                    setPagesStocks={e => (setPagesActive("stock"), setCurrentPage(0))}
                    setPagesTrends={e => (setPagesActive("trends"), setCurrentPage(0))}
                    pagesActive={pagesActive}
                />
                <div className={"stocks_wrap"}>
                    {
                        newPosts ?
                            newPosts.map( (item, index)=>
                            <StocksTrends
                                key={index}
                                item={item}
                            />
                        )
                        : ""
                    }
                </div>
            </div>


            <div className={"paginaciya"}>
                {pagesCount.length > 1 ?
                        pagesCount.map((item,index, key ) => {
                            return (
                                <div key={index} id={item.index} className={currentPage === item.index ? "click_page active" : "click_page" } onClick={e => setCurrentPage(item.index)}>
                                   {item.number}
                                </div>
                           )
                        })

                        : ""
                }
                <div className={"total_page"}>
                    {
                        currentPage+3 >= TotalPages ?
                            ""
                            :
                            <div onClick={e => setCurrentPage(TotalPages-1)} className={currentPage+1 === TotalPages ? "click_page active" : "click_page" }>
                                {TotalPages}
                            </div>
                    }
                </div>
            </div>


        </div>
    );
});

export default StocksAndTrends;