import React, { useContext, useEffect, useState } from 'react';

import SearchIcon from "./SearchIcon/SearchIcon";
import BasketIcon from "./BasketIcon/BasketIcon";
import MyFavoriteProducts from "./MyFavoriteProducts/MyFavoriteProducts";
import ModalAuth from "./modalAuth/ModalAuth";
import ShadowOverlay from "../../../UI/ShadowOverlay/ShadowOwerlay";
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";
import SwitchUserIcon from "./UserIcon/SwitchUserIcon";
import FlyBasketNotification from "../../../UI/FlyBasketNotification/FlyBasketNotification";
import {useNavigate} from "react-router";
import WelcomStep from "./modalAuth/WelcomStep";


const IconsBar = observer((props) => {
    const {user,settings,basket} = useContext(Context);
    let navigate  = useNavigate();

    useEffect(() => {
        const header = document.getElementById('header');
        if (user.openAuthModal === 1 || user.shadowOverlay === true || props.stateSearcht) {
            if (header) {
                header.style.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`;
            }
            document.body.style.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`;
            document.body.style.overflowY = "hidden";
        }
        else {
            document.body.style.overflowY = "scroll";
            document.body.style.paddingRight = "0px";
            if (header) {
                header.style.paddingRight = "0px";
            }
        }
    }, [user.openAuthModal, user.shadowOverlay, props.stateSearcht])


    const openAuthModalFunction = () =>  {
        user.setOpenAuthModal(1);
        user.setShadowOverlay(true);
        user.setStepModals("registration")
    }

    const closeAuthModalFunction = () => {
        user.setShadowOverlay(false);
        user.setOpenAuthModal(0);
        settings.setResultModalActive(false)


        if(user.user.telephone !== "" && user.user.telephone !== undefined && user.stepModals != "basketStep") {
            user.setStepModals("unset");
            navigate('/personal/profile/');
            user.setCurrentPage('profile');
        }
    }

    const handleModalAuthClose = () => {
        user.setShadowOverlay(false);
        user.setOpenAuthModal(0);
        settings.setResultModalActive(false);
        user.setStepModals("unset");
    }

    return (
        <div className={`Icons_bar_in_headerLine${props.stateSearcht ? ' _active-search' : ''}`}>
            {
                settings.projectWidth && settings.projectWidth < 769 ?
                    (
                        <>
                            {/*{*/}
                            {/*    props.activeMobMenu === true?*/}
                            {/*        <SwitchUserIcon onClick={ () => openAuthModalFunction()} />*/}
                            {/*        :*/}
                            {/*        ""*/}
                            {/*}*/}
                            <SearchIcon
                                stateSearcht = {props.stateSearcht}
                                setStateSearcht={props.setStateSearcht}
                            />
                            {/*{*/}
                            {/*   props.activeMobMenu === true ?*/}
                            {/*        <MyFavoriteProducts />*/}
                            {/*        :*/}
                            {/*        ""*/}
                            {/*}*/}
                            {props.stateSearcht ? '' : (
                                <BasketIcon />
                            )}
                            <ModalAuth className={ user.openAuthModal === 1 ?  "active" : "hide"  } closeClick={ () => closeAuthModalFunction() } onClose={handleModalAuthClose}  />
                            {/*<ShadowOverlay className={ user.shadowOverlay === true ?  "active" : "hide"  } closeClick={ () => closeAuthModalFunction() }  />*/}
                            <FlyBasketNotification />
                        </>
                    )
                    :
                    (
                        <>
                            <SearchIcon
                                stateSearcht = {props.stateSearcht}
                                setStateSearcht={props.setStateSearcht}
                            />
                            <BasketIcon />
                            <MyFavoriteProducts />
                            <SwitchUserIcon onClick={ () => openAuthModalFunction()} />
                            <ModalAuth className={ user.openAuthModal === 1 ?  "active" : "hide"  } closeClick={ () => closeAuthModalFunction() } onClose={handleModalAuthClose}  />
                            <FlyBasketNotification />
                        </>
                    )
            }
            {/*<ShadowOverlay className={ user.shadowOverlay === true ?  "active" : "hide"  } closeClick={ () => closeAuthModalFunction() }  />*/}
        </div>
    );
});


export default IconsBar;