import React, {useEffect, useState} from 'react';
import {saveAdmUser} from "../../../http/userAPI";

const RedactOneUser = (props) => {


    const [userFields,setUserFields] = useState(false);
    const roles = [{role:"USER"},{role:"ADMIN"}]

    useEffect(() => {
            setUserFields(props.user);
    },[])


    const saveUserInAdm = (user) => {
        saveAdmUser(user).then(response => {
            if(response.message) {
                props.setClose(false)
            }
        })
    }

    const convertDate = (date) => {
        return date.split('.').reverse().join('-')
    }

    const unConvertDate = (date) => {
        return date.split('-').reverse().join('.')
    }

    return (
        <div className={"redact_user_modal"}>
            <div className={"redact_user_top_controll_line"}>
                <div onClick={e => props.setClose(false)} className={"redact_user_close"}><noindex>закрыть</noindex></div>
            </div>
            <div className={"container_for_user_fields"}>
                <span>ID: {userFields.id}</span>
                <div className={"container_for_user_field_wrap"}>
                    <span>Имя:</span>
                    <input
                        className={"user_field_redact"}
                        value={userFields.name ? userFields.name : ""}
                        onChange={e => setUserFields({...userFields,name:e.target.value})}

                        type="text"/>
                </div>
                <div className={"container_for_user_field_wrap"}>
                    <span>Фамилия:</span>
                    <input
                        className={"user_field_redact"}
                        value={userFields.surname ? userFields.surname : ""}
                        onChange={e => setUserFields({...userFields,surname:e.target.value})}

                        type="text"/>
                </div>
                <div className={"container_for_user_field_wrap"}>
                    <span>Отчество:</span>
                    <input
                        className={"user_field_redact"}
                        value={userFields.patronymic ? userFields.patronymic : ""}
                        onChange={e => setUserFields({...userFields,patronymic:e.target.value})}

                        type="text"/>
                </div>
                <div className={"container_for_user_field_wrap no_tuch"}>
                    <span>Телефон:</span>
                    <input
                        className={"user_field_redact"}
                        value={userFields.login ? userFields.login :"" } type="text" name="" id=""
                        onChange={e => setUserFields({...userFields,login:e.target.value})}

                    />
                </div>
                <div className={"container_for_user_field_wrap no_tuch"}>
                    <span>Дата рождения:</span>
                    <input
                        className={"user_field_redact"}
                        value={userFields.birthdate ? convertDate(userFields.birthdate) :"" } type="date" name="" id=""
                        onChange={e => setUserFields({...userFields,birthdate:unConvertDate(e.target.value)})}

                    />
                </div>
                <div className={"container_for_user_field_wrap no_tuch"}>
                    <span>Email:</span>
                    <input
                        className={"user_field_redact"}
                        value={userFields.email ? userFields.email :"" } type="text" name="" id=""
                        onChange={e => setUserFields({...userFields,email:e.target.value})}
                    />

                </div>

                <div className={"container_for_user_field_wrap"}>
                    <span>Доступ к сертификатам:</span>
                    <input
                        className={"user_field_redact"}
                        type="checkbox" name="" id=""
                        checked={userFields.certRole}
                        onChange={e => setUserFields({...userFields,certRole:e.target.checked})}
                    />

                </div>

                <div className={"container_for_user_field_wrap"}>
                    <span className={"controll_user_fw"}>Роль: {userFields.role}</span>

                       <div className={"btns_for_set_role"}>
                           {
                               roles ?
                                   roles.map((item,index) => {
                                       return(
                                           <div
                                               onClick={e => setUserFields({...userFields,role:item.role})}
                                               className={userFields.role === item.role ? "btn_set_role_btn active" : "btn_set_role_btn" }
                                               key={index}>
                                               {item.role}
                                         </div>)
                                   }) : ""
                           }
                       </div>
                </div>

                <div
                    onClick={e => saveUserInAdm(userFields)}
                    className={"go_save_user_fields"}>
                    Сохранить
                </div>


            </div>
        </div>
    );
};

export default RedactOneUser;