import React from 'react';
import './ContactsCards.css';
import {Link} from "react-router-dom";

const ContactsCards = (props) => {


    return (
        <div className={"c_one_contacts"}>
            <div className={"c_one_contacts_child"}>
                <span className={"c_one_contacts_title"}>{props.item.title}</span>
                <div className={"wrap_cards_contact"}>
                    <span className={"c_one_contacts_text"}>{props.item.text_cards}</span>
                    {
                        props.item.active_target_blank === true
                            ?
                            <a href={props.item.link} className={"c_one_contacts_button"} target={"_blank"} rel="noreferrer">{props.item.name_buttom}</a>
                            :
                            <Link to={props.item.link} className={"c_one_contacts_button"}>{props.item.name_buttom}</Link>
                    }
                </div>
            </div>
        </div>
    );
};

export default ContactsCards;