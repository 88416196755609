import React, {useContext} from 'react';
import './exitButton.css';
import {Context} from "../../../index";
const ExitButton = () => {
    const {user} = useContext(Context);
    const exitUser = () => {
        user.setUser("");
        user.setIsAuth(false);
        user.setStepModals('registration')
        localStorage.token = "";
    }
    return (
        <div onClick={e => exitUser()} className={"exit_user_block"}>
            {/*<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*    <path d="M11.4618 21.0133H2.86543C2.33818 21.0133 1.9103 20.5854 1.9103 20.0582V2.86548C1.9103 2.33823 2.33823 1.91035 2.86543 1.91035H11.4618C11.99 1.91035 12.4169 1.48341 12.4169 0.955219C12.4169 0.427027 11.99 0 11.4618 0H2.86543C1.28562 0 0 1.28566 0 2.86548V20.0582C0 21.638 1.28562 22.9236 2.86543 22.9236H11.4618C11.99 22.9236 12.4169 22.4966 12.4169 21.9685C12.4169 21.4403 11.99 21.0133 11.4618 21.0133Z" fill="#1A1A1A"/>*/}
            {/*    <path d="M22.7148 10.0059L16.9074 4.27498C16.533 3.90438 15.9275 3.90918 15.5569 4.28455C15.1863 4.65992 15.1901 5.26452 15.5664 5.63513L19.7165 9.73079H8.59575C8.06756 9.73079 7.64062 10.1577 7.64062 10.6859C7.64062 11.2141 8.06756 11.6411 8.59575 11.6411H19.7165L15.5664 15.7368C15.1901 16.1074 15.1873 16.712 15.5569 17.0873C15.7441 17.2764 15.9905 17.372 16.2369 17.372C16.4796 17.372 16.7221 17.2803 16.9074 17.0969L22.7148 11.3659C22.8962 11.1864 22.9994 10.9418 22.9994 10.6859C22.9994 10.43 22.8972 10.1864 22.7148 10.0059Z" fill="#1A1A1A"/>*/}
            {/*</svg>*/}
            Выйти из аккаунта
        </div>
    );
};

export default ExitButton;