import React from 'react';
import './basketpromokod.css'

const BasketPromokod = (props) => {
    return (
        <div className={"fly_basket_promokod"}>

            <div className={"wrapper_for_fly_basket_promo"}>
                <input
                    value={props.value}
                    onChange={props.onChange}
                    autocomplete="you_wanna_promocode"
                    placeholder={"ПРОМОКОД"} 
                    name="promocode"
                    className={"fly_basket_input_promokod"} 
                    type="text"
                />
                <div
                    onClick={props.onClick}
                    className={"go_active_promo"}></div>
            </div>
            <span style={{color:props.color}} className={"fly_basket_promokod_notification"}> {props.notification ? props.notification : ""} </span>

        </div>
    );
};

export default BasketPromokod;