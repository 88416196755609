import React, {useContext, useEffect, useRef, useState} from 'react';
import './User.css'
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import Personal from "../../components/personal/Personal";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import {Link} from "react-router-dom";
import basket from "../../http/basketApi";
import OneProductCart from "../catalog/OneProductCart";
import {Helmet} from "react-helmet";



const User = observer(() => {

    const {settings, user} = useContext(Context);

    useEffect(() => {
        // window.scrollTo(0, settings.scrollStatusCatalog);
    },[])


    if (user.openAuthModal === 1) {
        document.body.style.overflowY = "hidden";
        document.body.style.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`;
    } else {
        document.body.style.overflowY = "scroll";
        document.body.style.paddingRight = "0px";
    }

    const openAuthModalExit = () =>  {
        user.setOpenAuthModal(1);
        user.setShadowOverlay(true);
    }




    // const [widthWrapperFavorite, setWidthWrapperFavorite] = useState(0);
    // const [widthOneCards, setWidthOneCards] = useState(0);
    // const elementRef = useRef(null);
    // const elementRefOneCards = useRef(null);
    // const [withFavoriteBorder, setWithFavoriteBorder] = useState(0)
    //
    // useEffect(() => {
    //
    //     if (settings.projectWidth > 768 && settings.favoriteArray != '') {
    //         setWidthWrapperFavorite(elementRef.current.offsetWidth);
    //         setWidthOneCards(elementRefOneCards.current.offsetWidth);
    //         let withTitleFavorite = Math.floor(widthWrapperFavorite/widthOneCards)
    //         let finalResultFavoriteWidth = widthOneCards*withTitleFavorite
    //         console.log("ОКРУГЛЕНИЕ")
    //         console.log(withTitleFavorite)
    //         setWithFavoriteBorder(finalResultFavoriteWidth)
    //     }
    //     // setWithFavoriteBorder('unset')
    //
    // }, [settings.projectWidth, settings.favoriteArray])

    // определяем высоту при переходе, чтобы при возврате проскролить на это же место
    const handleScroll = () => {
        settings.setScrollStatusCatalog(window.scrollY);
    };

    const getPagePersonal = () => {
        if(user.currentPage === "profile" ) {
            return (
                <Helmet>
                    <title>Личный кабинет интернет-магазина YOU WANNA</title>
                    <meta name="description" content="Личный кабинет в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" />
                </Helmet>
            )
        }
        if(user.currentPage === "favorites") {
            return (
                <Helmet>
                    <title>Избранные товары интернет-магазина YOU WANNA</title>
                    <meta name="description" content="Избранные товары в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" />
                </Helmet>
            )
        }

        if (user.currentPage === "order") {
            return (
                <Helmet>
                    <title>Заказы интернет-магазина YOU WANNA</title>
                    <meta name="description" content="Заказы в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" />
                </Helmet>
            )
        }

    }


        return (

        <div
            style={{marginTop:settings.headerHeight}}
            className={"main user_page"}>
            {getPagePersonal() }
            {
                !user.isAuth ?
                    <div className={"wrapper_center_page"}>
                        {
                            window.location.pathname != '/personal/favorites/' ?
                                <div   onClick={() => openAuthModalExit()} className="entrance_text_usercabinet">Войти в аккаунт</div>
                                :
                                <div className={"container_background_relative"}>
                                    {/*<img  style={{top:settings.headerHeight}} className={"background_exit"} src="/files/images/ui/yw.png" alt=""/>*/}
                                    { settings.favoriteArray && settings.favoriteArray != '' && settings.projectWidth && settings.projectWidth < 769 ?
                                        <h1
                                            className={"title_favorite"}>
                                            Товары, которые вам понравились
                                        </h1>
                                        :
                                        ""
                                    }
                                    <div className={"not_authorized_container"}>
                                        <div   onClick={() => openAuthModalExit()} className="entrance_text_usercabinet">Войти в аккаунт</div>
                                        {/*<div className={"entrance_user_block"}>*/}
                                        {/*    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4618 21.0133H2.86543C2.33818 21.0133 1.9103 20.5854 1.9103 20.0582V2.86548C1.9103 2.33823 2.33823 1.91035 2.86543 1.91035H11.4618C11.99 1.91035 12.4169 1.48341 12.4169 0.955219C12.4169 0.427027 11.99 0 11.4618 0H2.86543C1.28562 0 0 1.28566 0 2.86548V20.0582C0 21.638 1.28562 22.9236 2.86543 22.9236H11.4618C11.99 22.9236 12.4169 22.4966 12.4169 21.9685C12.4169 21.4403 11.99 21.0133 11.4618 21.0133Z" fill="#1A1A1A"></path><path d="M22.7148 10.0059L16.9074 4.27498C16.533 3.90438 15.9275 3.90918 15.5569 4.28455C15.1863 4.65992 15.1901 5.26452 15.5664 5.63513L19.7165 9.73079H8.59575C8.06756 9.73079 7.64062 10.1577 7.64062 10.6859C7.64062 11.2141 8.06756 11.6411 8.59575 11.6411H19.7165L15.5664 15.7368C15.1901 16.1074 15.1873 16.712 15.5569 17.0873C15.7441 17.2764 15.9905 17.372 16.2369 17.372C16.4796 17.372 16.7221 17.2803 16.9074 17.0969L22.7148 11.3659C22.8962 11.1864 22.9994 10.9418 22.9994 10.6859C22.9994 10.43 22.8972 10.1864 22.7148 10.0059Z" fill="#1A1A1A"></path></svg>*/}
                                        {/*</div>*/}
                                    </div>
                                    {/*<Link to={"/"}*/}
                                    {/*    className={"not_authorized_button"}>*/}
                                    {/*    НА ГЛАВНУЮ*/}
                                    {/*</Link>*/}
                                    <div className={"wrapper_favorite_product"}>
                                        {/*<div className={"favorite_product_child"}>*/}
                                        {settings.favoriteArray && settings.favoriteArray != '' && settings.projectWidth && settings.projectWidth > 768 ?
                                            <h1 className={"title_favorite"}>
                                                Товары, которые вам понравились
                                            </h1>
                                            :
                                            ""
                                        }

                                        <div
                                            // style = {{maxWidth: withFavoriteBorder}}
                                            // ref={elementRef}
                                            className={"products_wrapper standart favorite"}>
                                            {
                                                settings.favoriteArray && settings.favoriteArray != '' ? settings.favoriteArray.map((item, index) =>

                                                        <OneProductCart
                                                            item={item}
                                                            index={index}
                                                            key={item.id}
                                                            handleScroll = {e => handleScroll()}
                                                            // elementRefOneCards={elementRefOneCards}
                                                        />

                                                    ) :
                                                    <div className={"favorite_error"}>
                                                        Список избранных товаров пуст
                                                    </div>

                                            }
                                        </div>
                                        {/*</div>*/}
                                    </div>

                                </div>
                        }

                    </div>
                    :
                    // <div className={"main user_page"}>
                    //   <div>{/*<Breadcrumbs />*/}</div>

                        <div className={"wrapper_center_page"}>
                            <Personal
                                // elementRef={elementRef}
                                // elementRefOneCards={elementRefOneCards}
                            />
                        </div>

                    // </div>
            }
        </div>
    );

});

export default User;
