import React from 'react';

const OptionCountry = (props) => {
    return (
        <div onClick={props.onClick} className={"countryWrapper"}>
            <div className={"countryItem"}>
                <img className={"countryImage"} src={props.country.imgPath} alt="" />
                <span className={"countryName"}>{props.country.name} {props.country.parameters === 'several' ? ('('+props.country.number+' цифр'+')') : ''}</span>
            </div>
        </div>
    );
};

export default OptionCountry;