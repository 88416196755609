import {$authHost,$host} from "./index";
import axios from "axios";


/////////////All pages controller ////
export const addNewPage = async (name) =>  {
    const {data} = await $authHost.post('api/content/addnewpage', name);
    return data;
}

export const getOnePageContent = async (id) => {
    const {data} = await $host.post('api/content/getonepagecontent', {id})
    return data;
}

export const updateContentPage = async (contentFields) => {
    const {data} = await $authHost.post('api/content/updateonepagecontent', {contentFields})
    return data;

}

export const getCommonContent = async (code) => {
    const {data} = await $host.post('api/content/getcommoncontent', {code})
    return data;
}

export const updateCommonContent = async (code, text) => {
    const {data} = await $authHost.post('api/content/updatecommoncontent', {code, text})
    return data;
}

export const getAllStockAndTrends = async (sortField,sortParam) => {
    const data = await $authHost.post('api/content/getallstockandtrends', {sortField,sortParam})

    return data;
}

export const setSortStockAndTrends = async (id, startSort, endSort) => {
    const data = await $authHost.post('api/content/setsortstockandtrends', {id, startSort, endSort})

    return data;
}

export const getActiveStockAndTrends = async () => {
    const data = await $authHost.post('api/content/getactivestockandtrends')

    return data;
}

export const changeStockStatus = async (id,status) => {
    const {data} = await $authHost.post('api/content/stockchangestatus', {id,status});

    return data;
}
export const addNewStockOrTrend = async () => {
    const data = await $authHost.post('api/content/addstockortrend', )

    return data;
}

export const getOneStock = async (id) => {
    const data = await $authHost.post('api/content/getonestock', {id})

    return data;
}

export const updateOneStock = async(fields, redactor) => {
    const data = await $authHost.post("api/content/updatestock", fields)
    return data;
}

export const deleteOneStock = async(id) => {
    const data = await $authHost.post("api/content/deleteonestock", {id})
    return data;
}
//////////////////////////////////////


export const addReviews = async (item) => {
    const {data} = await $authHost.post('api/content/addreviews' ,{item})
    return data;
}
//moderate & all
export const fetchAllReviews = async (moderate,field,sort,isActive) => {

    const {data} = await $host.post('api/content/getallreviews', {moderate,field,sort,isActive})
    return  data;
}

export const updateReviews = async  (item) => {
    const {data} = await $authHost.post('api/content/updateonereviews', {item})
    return data;
}

export const removeOneReviews = async (id) => {
    const {data} = await  $authHost.post('api/content/removeonereviews', {id})
    return data;
}

//################VACANCIES###################

export const addNewVacancy = async () => {
    const {data} = await $authHost.post('api/content/addonevacancy')
    return data;
}
export const fetchAllVacancy = async () => {
    const {data} = await $host.post('api/content/fetchallvacancy')
    return data;
}

export const updateOneVacancy = async (fields) => {
    const {data} = await $authHost.post('api/content/updateonevacancy', {fields});
    return data;
}

export const deleteOneVacancy = async (id) => {
    const {data} = await $authHost.post('api/content/deleteonevacancy',{id})
    return data;
}

export const getCityAndVacancy = async () => {
    const {data} = await $host.post('api/content/getvacancyandcity');

    return data;
}
//#################### Markets Page ##################//



export const fetchAllMarkets = async (isOnlyActive) => {
    const {data} = await $host.post('api/content/fetchAllMarkets',{isOnlyActive})
    return data;
}

export const addNewMarket = async (fields) => {
    const {data} = await $authHost.post('api/content/addnewmarket',{fields})
    return data;
}

export const deleteOneMarket = async (id) => {
    const {data} = await $authHost.post('api/content/deleteonemarket', {id})
    return data;
}

export const updateOneMarket = async (fields) => {
    const {data} = await $authHost.post('api/content/updateonemarket',{fields})
    return data;
}


//#################### LookBook Page ##################//

export const fetchLookBook = async (limit, offset, getCount) => {
    const {data} = await $authHost.post('api/content/fetchalllookbook',{limit, offset, getCount})
    return data;
}
export const fetchAdmLookBook = async (limit, offset, getCount) => {
    const {data} = await $authHost.post('api/content/fetchadmlookbook',{limit, offset, getCount})
    return data;
}

export const fetchOneLookBook = async (id, edit = false) => {
    const {data} = await $authHost.post('api/content/fetchonelookbook',{id, edit})
    return data;
}

export const fetchMiniLookBook = async (id) => {
    const {data} = await $authHost.post('api/content/fetchminilookbook',{id})
    return data;
}

export const updateLookBook = async (content) => {
    const {data} = await $authHost.post('api/content/updatelookbook',{content})
    return data;
}

export const deleteLookBook = async (id) => {
    const {data} = await $authHost.post('api/content/destroylookbook',{id})
    return data;
}

export const creatureLookBook = async () => {
    const data = await $authHost.post('api/content/createlookbook')
    return data;
}

export const fetchLookBookHome= async (limit) => {
    const {data} = await $authHost.post('api/content/fetchlookforhomepage',{limit})
    return data;
}

export const getStockAndTrendsById = async (id) => {
    try {
        const {data} =  await $authHost.post('api/content/getstockbyid', {id});
        return data;
    } catch (error) {
        return error.response
    }
    
}

export const fetchAllImages = async (limit, offset, getCount, isActive = false) => {
    const {data} = await $authHost.post('api/content/fetchallimages', {limit, offset, getCount, isActive})
    return data;
}

export const setSortImages = async (id, startSort, endSort = false) => {
    const {data} = await $authHost.post('api/content/setsortimages', {id, startSort, endSort})
    return data;
}

export const deleteImage = async (id) => {
    const {data} = await $authHost.post('api/content/destroyimage',{id})
    return data;
}

export const fetchAdmImages = async (limit, offset, getCount) => {
    const {data} = await $authHost.post('api/content/fetchadmimages',{limit, offset, getCount})
    return data;
}

export const fetchOneImage = async (id, edit = false) => {
    const {data} = await $authHost.post('api/content/fetchoneimage',{id, edit})
    return data;
}

export const fetchImageById = async (id) => {
    const {data} = await $authHost.post('api/content/fetchimagebyid', {id: id})
    return data;
}



export const fetchProductsByImageId = async (ids) => {
    const {data} = await $authHost.post('api/catalog/fetchproductsbyid', {arrayId: ids.map(Number)})
    return data;
}

export const saveImage = async (content, isUpdate = false) => {
    const {data} = await $authHost.post('api/content/saveimage',{content, isUpdate})
    return data;
}

export const saveVideo = async (payload) => {
    const {data} = await $authHost.post('api/content/savevideo', payload);
    return data;
}

export const deleteVideo = async (id) => {
    const {data} = await $authHost.post('api/content/deletevideo',{id})
    return data;
}

export const getVideos = async (limit, offset) => {
    const {data} = await $authHost.post('api/content/getvideos',{limit, offset})
    return data;
}