import React from 'react';
import './VacanciesCityMobile.css'

const VacanciesCityMobile = (props) => {

    const activeCityVacancies = () => {
        props.setActiveCity(props.item.id)
        props.setStateActiveCityMob(!props.stateActiveCityMob)
        props.setNameActiveCity(props.item.name)
    }


    return (
        <div
            onClick={e => activeCityVacancies()}
            className = {props.activeCity === props.item.id ?"state_city_mob active" : "state_city_mob"}>
            {props.item.city}
        </div>
    );
};

export default VacanciesCityMobile;