import React from 'react'

const ArrowLeft = () => {
  return (
    <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 2L2 11.0454L12 20" stroke="black" stroke-width="1.5" stroke-linecap="square"/>
</svg>

  )
}

export default ArrowLeft