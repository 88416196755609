import React from 'react';
import "./sostav.css";

const Sostav = (props) => {
    //
    const nameChanger = (value) => {
        props.setSostavState([...props.sostavState],[props.sostavState[props.index].name_sost = value])
    }

    const addNewSostav = (object) => {
        props.setSostavState([...props.sostavState],[props.sostavState[props.index].fields.push(object)])
    }

    const deleteStrokeSostav = (index) => {
       const reduceArray = [...props.sostavState];
        reduceArray[props.index].fields.splice(index,1);
        props.setSostavState(reduceArray);
    }

    const deleteOneBlock = (index) => {
        const reduceArray = [...props.sostavState];
        reduceArray.splice(index,1);
        props.setSostavState(reduceArray);
    }

    const sostavChangeInput = (val,index) => {
        props.setSostavState([...props.sostavState],[props.sostavState[props.index].fields[index].value = val])
    }


    return (
        <div className={"one_sostav_wrapper_item"}>

            <div className={"one_sostav"}>
                <div className={"controll_panel_sostav_wrapper"}>
                    <div onClick={e => addNewSostav(Object.assign({}, props.oneItemBlockSost))} className={"add_params"}>добавить параметры +</div>
                    <div onClick={e => deleteOneBlock(props.index)} className={"delete_block"}>удалить блок</div>
                </div>

                <div className={"name_sost"}>
                    <span className={"field_sost_name"}> Название: </span>
                    <input className={"sostav_field"} type="text"
                           value={props.sostavState[props.index].name_sost}
                           onChange={e => nameChanger(e.target.value)}
                    />
                </div>
                {
                    props.sostavState[props.index].fields ?
                        props.sostavState[props.index].fields.map((item,index) => {
                            return(
                                <div key={index} className={"field_value_container"}>
                                    <div className={"value_content_container"}>
                                        <input
                                            value={props.sostavState[props.index].fields[index].value}
                                            onChange={e => sostavChangeInput(e.target.value, index)}
                                            className={"value_field_sost"} type="text"/>
                                    </div>
                                    <div onClick={e=> deleteStrokeSostav(index)} className={"del_sost"}></div>
                                 </div>
                            )
                        })
                        : ""
                }

            </div>
        </div>

    );
};

export default Sostav;