import React, {useEffect, useState} from 'react';
import './headerAndFooterSettings.css';
import {
    fetchAllSocial,
    fetchSettingsData,
    saveFooterContent,
    saveRunline,
    saveSettingsContainer
} from "../../../../http/settingsApi";
import {fetchClearCategory, setFeedCategories} from "../../../../http/catalogApi";
import SocialIcons from "../../content/contacts/socialIcons/SocialIcons";
import ActiveSocialIcons from "../../content/contacts/activeSocialIcons/ActiveSocialIcons";
import OneImageField from "../../UI/oneImageField/OneImageField";
import {addImages, deleteImage} from "../../../../http/imagesApi";
import { getParentCategories } from '../../../../http/catalogApi';
import OneImageHorizontalField from "../../UI/OneImageHorizontalField/OneImageHorizontalField";

const HeaderAndFooterSettings = () => {

    const [customCategories, setCustomCategories] = useState([])
    const [headerMenu, setHeaderMenu] = useState([])
    const [runLine,setRunLine] = useState(null)
    const [footerMenu, setFooterMenu] = useState('')
    const [footerContent, setFooterContent] = useState('')
    const [iconsSocialFooter, setIconsSocialFooter] = useState('');
    const [triggerFooter, setTriggerFooter] = useState(false);
    const [serverResponseFooter, setServerResponseFooter] = useState('')



    useEffect(() => {
        //Запрос получающий кастомные "категории" //
        fetchSettingsData().then(dataResponse => {
            if(dataResponse.header) {

                const noJson = JSON.parse(dataResponse.header);
                setHeaderMenu(noJson);
            }
            if(dataResponse) {
                setRunLine(dataResponse)
            }
            if(dataResponse.footer) {
                const noJson = JSON.parse(dataResponse.footer);
                setFooterMenu(noJson);
                if (dataResponse.footer) {
                    const noJson = JSON.parse(dataResponse.footer);
                    setFooterMenu(noJson);
                }
                if (dataResponse.footer_content) {
                    const noJsonContent = JSON.parse(dataResponse.footer_content);
                    setFooterContent(noJsonContent);
                } else {
                    setFooterContent({
                        contactUs: [],
                        datesExistence: '',
                        dopNumberContact: [],
                        footerSocial:[],
                        linkToPolicy: '',
                        logo: '',
                        paymentMethods: [],
                        textClickable: '',
                        textToPolicy: ''

                    })
                }

                if (dataResponse) {
                    setRunLine(dataResponse)
                }

                fetchClearCategory().then(categoryResponse => {
                    setCustomCategories(categoryResponse)
                })
            }
        })
    }, [])

    useEffect(() => {

        if (customCategories.length > 0) {
            const newHeaderMenu = [];

            // проверяем, есть ли корневые
            for (const rootCategory of headerMenu) {
                const category = customCategories.find(elem => rootCategory.id === elem.id);
                if (category) {

                    const newRootCategory = category;
                    newRootCategory.child = [];

                    if (newRootCategory.slug === 'catalog')  {
                        newRootCategory.skip = true;
                        newRootCategory.slug = '';
                    }

                    if (rootCategory?.child) {
                        // проверяем, есть ли child
                        for (const childItem of rootCategory.child) {
                            const childItemFind = customCategories.find(elem => childItem.id === elem.id);
                            if (childItemFind) {

                                const childTemp = childItemFind;
                                childTemp.child_elements = []

                                if (childItem?.child_elements) {
                                    for (const childElem of childItem.child_elements) {
                                        const childElemFind = customCategories.find(elem => childElem.id === elem.id);
                                        if (childElemFind) {
                                            childTemp.child_elements.push(childElem);
                                        }
                                    }

                                    // добавляем child_elements
                                    /*for (const customCategory of customCategories) {
                                        if (customCategory?.parentId === childTemp.id) {
                                            const exsist = childTemp.child_elements.find(elem => elem.id === customCategory.id);
                                            if (!exsist) {
                                                childTemp.child_elements.push(customCategory);
                                            }
                                        }
                                    }*/
                                }

                                newRootCategory.child.push(childTemp);
                            }
                        }

                        // добавляем child
                        /*for (const customCategory of customCategories) {
                            if (customCategory?.parentId === newRootCategory.id) {
                                const childExsist = newRootCategory.child.find(elem => elem.id === customCategory.id);
                                if (!childExsist) {
                                    newRootCategory.child.push(customCategory);
                                }
                            }
                        }*/
                    }

                    newHeaderMenu.push(newRootCategory);
                }
            }

            setHeaderMenu(newHeaderMenu);
        }

    },[customCategories])



    useEffect((fields) => {
        fetchAllSocial(fields).then(response => {
            if (!response) {
                return false;
            }
            setIconsSocialFooter(response)
        })
    },[])

    useEffect((fields) => {
        if (triggerFooter === true) {
            setServerResponseFooter('')
        }
    },[triggerFooter])

    const [dragElement, setDragElement] = useState({});
    const [dragTopLevelElement, setDragTopLevelElement] = useState({})
    const [targetDragTopLevelElement, setTargetDragTopLevelElement] = useState({})

    const parrentItemStartDragHandler = (item, index) => {
        setDragElement(item);
    }

    const onDragOverHandler = (e) => {
        e.preventDefault();
    }

    const onDragOverHandlerTopLevel = (e, element) => {
        if(!element.name) {
            return false;
        }
        e.preventDefault();
        const reduceMenu = [...headerMenu]
        reduceMenu.push(element)
        reduceMenu.map((item,index) => item.position = Number(index+1))
        setHeaderMenu(reduceMenu)
    }
    const deleteElementOnTopLevelHandler = (index) => {

        const reduceMenu = [...headerMenu]
        reduceMenu.splice(index,1)
        reduceMenu.map((item,index) => item.position = Number(index+1))
        setHeaderMenu(reduceMenu)
    }

    // ########SORT TOP LEVEL########
    const startDragablePositionTopLevel = (e,item) => {
        setDragElement({})
        setDragTopLevelElement(item)
    }
    const onDragOverTopPosition = (e,item) => {
        e.preventDefault();
        setTargetDragTopLevelElement(item);
    }
    const onDragLeaveTopPosition = (e,item) => {
        e.preventDefault();
        setTargetDragTopLevelElement({});
    }
    const onDragDropTopPosition = (e,item) => {
        e.preventDefault();

        let reduceMenu = [...headerMenu]
        reduceMenu = reduceMenu.map((item,index) => {
            if(item.position === dragTopLevelElement.position) {
                return({...item, position: targetDragTopLevelElement.position})
            }
            if(item.position === targetDragTopLevelElement.position) {
                return({...item, position: dragTopLevelElement.position})
            }
            return item
        })
        reduceMenu.sort((a,b) => a.position - b.position )

        setHeaderMenu(reduceMenu)
    }

    // ####SELECT NUMBER COLUMN#####
    const [numberColumn,setNumberColumn] = useState(null)

    const deleteColumnHandler = (numberColumn, index) => {

        let reduceArray = [...headerMenu];

        reduceArray[numberColumn].child.splice(index,1);
        setHeaderMenu(reduceArray)
    }


    const onDragOverChild = (e) => {
        e.preventDefault();
    }

    //Добавляем первую вложенность (родительские категории)
    const onDropOverChild = (numberColumn,dragElement) => {
        let reduceArray = [...headerMenu];
        if(reduceArray[numberColumn].child) {
            reduceArray[numberColumn].child.push(dragElement);
        } else {
            reduceArray[numberColumn].child = [];
            reduceArray[numberColumn].child.push(dragElement);
        }
        setHeaderMenu(reduceArray);
    }

    const onDragOverColumnChild = (e) => {
        e.preventDefault();
    }
    const onDropOverColumnChild = (numberColumn, index ,dragElement) => {
        let reduceArray = [...headerMenu];
        if(reduceArray[numberColumn].child[index].child_elements) {
            reduceArray[numberColumn].child[index].child_elements.push(dragElement);
        } else {
            reduceArray[numberColumn].child[index].child_elements = [];
            reduceArray[numberColumn].child[index].child_elements.push(dragElement);
        }
        setHeaderMenu(reduceArray);
    }

    const deleteOneElementInColumn = (numberColumn,index, indexElement) => {

        let reduceArray = [...headerMenu];
        reduceArray[numberColumn].child[index].child_elements.splice(indexElement,1);
        setHeaderMenu(reduceArray)
    }

    const saveData = async (dataFields) => {
        const categoriesId = [];
        for (const root of dataFields) {
            categoriesId.push(root.id);
            if (root.child) {
                for (const child of root.child) {
                    categoriesId.push(child.id);
                    if (child.child_elements) {
                        for (const childElem of child.child_elements) {
                            categoriesId.push(childElem.id);
                        }
                    }
                }
            }
        }

        setFeedCategories(categoriesId).then(response => {

        })


        const menuJson = JSON.stringify(dataFields);
        saveSettingsContainer(menuJson).then(response => {

        })
    }

    const [responsSaveRunline,setResponsSaveRunline] = useState("");

    const saveRunHandler = (text,active,mod) => {

        saveRunline(text,active,mod).then(response => {
            if(response.message) {
                setResponsSaveRunline("Сохранено успешно")
            } else {
                setResponsSaveRunline("Возникла проблема, обратитесь к разработчику")
            }
        })
    }

    const culumnFooter = {nameColumn:"",fields:[{name:"",value:"", website:true}]};
    const footerFieldsSite = {name:"",value:"", website:true};
    const footerFieldsOther = {name:"",value:"", website:false};

    const addNewBlockFooter = (object) => {
        let reduceNewColumnFooter = [...footerMenu]
        if (footerMenu.length === 4) {
            return false;
        }
        reduceNewColumnFooter.push(object)
        setFooterMenu(reduceNewColumnFooter)
        setTriggerFooter(true)
    }


    const fieldsFooter = (e,fieldName, index) => {
        setFooterMenu([...footerMenu], [footerMenu[index][fieldName] = e.target.value])
        setTriggerFooter(true)
    }

    const fieldChildFooter = (e,fieldName, index, indexField) => {
        setFooterMenu([...footerMenu], [footerMenu[index].fields[indexField][fieldName] = e.target.value])
        setTriggerFooter(true)
    }

    const addDopFieldsColumn = (object, index) => {
        let reduceNewLine = [...footerMenu]
        reduceNewLine[index].fields.push(object)
        setFooterMenu(reduceNewLine)
        setTriggerFooter(true)
    }

    const deleteOneColumnFooter = (id) => {
        let deleteBlockColumn = [...footerMenu];
        deleteBlockColumn.splice(id,1)
        setFooterMenu(deleteBlockColumn)
        setTriggerFooter(true)
    }
    const deleteOneFieldColumn = (index, indexChild) => {
        let deleteOneLineColumn = [...footerMenu];
        deleteOneLineColumn[index].fields.splice(indexChild,1)
        setFooterMenu(deleteOneLineColumn)
        setTriggerFooter(true)
    }


    const dopFieldsContact = {name:"",link:"", website:''};
    const onePayBlock =  {img:""};

    const linkDopContact = [
        {id:0, website: "не выбрано"},
        {id:1, website: "сайт"},
        {id:2, website: "сторонний"},
        {id:3, website: "телефон"},
    ]

    const addFieldsFeedback = (object, objectName) => {
        let reduceNewContact= {...footerContent}
        reduceNewContact[objectName].push(object)
        setFooterContent(reduceNewContact)
        setTriggerFooter(true)
    }

    const fieldsFooterContacts = (e, fieldName, index) => {
        setFooterContent({...footerContent}, [footerContent.dopNumberContact[index][fieldName] = e.target.value])
        setTriggerFooter(true)
    }

    const deleteDopFieldContacts = (id, objectName) => {
        let deleteFieldContact = {...footerContent};
        deleteFieldContact[objectName].splice(id,1)
        setFooterContent(deleteFieldContact)
        setTriggerFooter(true)
    }
    const uploadPayFooter = (e,objectName ,nameField, index, childFolder) => {

        let newFile = e.target.files[0];
        let formData = new FormData();
        formData.append("image", newFile);

        if (footerContent[objectName][index][nameField]){
            deleteImage(footerContent[objectName][index][nameField], "footer", [childFolder]).then(response  => {
                setFooterContent({...footerContent}, [footerContent[objectName][index][nameField] = ""])
            })
        }

        addImages(formData,'footer',[childFolder], null).then(response => {
            if(response.message) {
                setFooterContent({...footerContent}, [footerContent[objectName][index][nameField] = response.fileName])
                setTriggerFooter(true)
            }
        })

    }

    const deletePayFooter = (filename, objectName, nameField, index, childFolder) => {
        deleteImage(footerContent[objectName][index][nameField], "footer", [childFolder]).then(response  => {
            setFooterContent({...footerContent}, [footerContent[objectName][index][nameField] = ""])
            setTriggerFooter(true)
        })
    }

    const deleteImgPayFooter = (id, objectName) => {
        let delOnePay = {...footerContent};
        delOnePay[objectName].splice(id,1)
        setFooterContent(delOnePay)
        setTriggerFooter(true)
    }

    const uploadLogoFooter = (e,nameField, childFolder) => {
        let newFile = e.target.files[0];
        let formData = new FormData();
        formData.append("image", newFile);

        if (footerContent.logo){
            deleteImage(footerContent.logo, "footer", [childFolder]).then(response  => {
                setFooterContent({...footerContent,logo: ""})            })
            setTriggerFooter(true)

        }
        addImages(formData,'footer',[childFolder]).then(response => {
            if(response.message) {
                setFooterContent({...footerContent,logo:response.fileName})
                setTriggerFooter(true)
            }
        })

    }

    const deleteLogoFooter = (filename,nameField, childFolder) => {
        deleteImage(filename, "footer", [childFolder]).then(response  => {
            setFooterContent({...footerContent,logo: ""})
            setTriggerFooter(true)

        })
    }

    const fieldsFooterContent = (e, fieldName) => {
        setFooterContent({...footerContent, [fieldName]: e.target.value})
        setTriggerFooter(true)
    }

    const saveFooter = async (footerMenu, footerContent) => {
        const menuFooterJson = JSON.stringify(footerMenu);
        const contentFooterJson = JSON.stringify(footerContent);

        saveFooterContent(menuFooterJson, contentFooterJson).then(response => {
            if (response.message) {
                setServerResponseFooter("Сохранено успешно")
                setTriggerFooter(false)
            } else {
                setServerResponseFooter("Возникла проблема, обратитесь к разработчику")
                setTriggerFooter(true)
            }

        })
    }

    return (
        <div className={"main_settings_header_and_footer"}>
            <div className={"content_container_slayer"}>
                <div className={"running_line_div_controller"}>
                    <div>Управление бегущей строкой</div>
                    <div className={"wrapper_for_controll_runline"}>
                        {
                            runLine ?
                                <div className={"wrapper_for_contrl_run_line"}>
                                    <span>Включить / выключить строку</span>
                                    <div
                                        onClick={e => setRunLine({...runLine, header_line_active: !runLine.header_line_active} )}
                                        className={runLine.header_line_active ? "btn_controll_run_line active" : "btn_controll_run_line"}>
                                        {
                                            runLine.header_line_active ?
                                                "Включено" : "Выключено"
                                        }

                                    </div>
                                    <span className={"run_line_span"} >Выберите тип строки (статичная, бегущая)</span>
                                    <div
                                        onClick={e => setRunLine({...runLine, runline: !runLine.runline} )}
                                        className={runLine.runline ? "btn_controll_run_line active" : "btn_controll_run_line"}>
                                        {
                                            runLine.runline ?
                                                "Бегущая" : "Статичная"
                                        }
                                    </div>
                                    <span className={"run_line_span"} >Отображаемый текст</span>
                                    <input
                                        className={"runline_text_input"}
                                        value={runLine.runline_text ? runLine.runline_text : ""}
                                        onChange={ e => setRunLine({...runLine, runline_text: e.target.value})}
                                        type="text"/>

                                    <div
                                        onClick={e => saveRunHandler(runLine.runline_text,runLine.header_line_active, runLine.runline)}
                                        className={"save_runline_btn"}> Сохранить </div>
                                    <span className={"save_runline_notificator"}>{responsSaveRunline}</span>
                                </div>

                                : ""
                        }

                    </div>
                </div>
                <span className={"support_header_menu_page"}> Меню формируется за счет файла констант (каталог, скоро в наличии, sale, новинки и бестселлеры), поскольку они не являются категориями.
                Остальные пункты меню - при помощи менеджера категорий. </span>
                <div className={"work_area_settings_menu_clear"}>
                    {customCategories[0] ?
                        customCategories.map((item, index) => {
                            return (
                                <div
                                    draggable="true"
                                    onDragStart={e => parrentItemStartDragHandler(Object.assign({}, item), index)}
                                    key={index}
                                    className={!item.only_parent ? "one_category_btn" : "one_category_btn only_parrent"}>
                                    {item.name}
                                </div>
                            )
                        })

                        : ""
                    }
                </div>
                <span className={"support_header_menu_page"}> Верхний уровень меню (отображается в шапке). Чтобы добавить новый - просто перетащите его из выборки сверху. </span>
                <div
                    className={"for_drag_drop_menu_settings"}

                    onDrop={e => onDragOverHandlerTopLevel(e, dragElement)}
                    onDragOver={e => onDragOverHandler(e)}
                >
                    {
                        headerMenu[0] ?
                            headerMenu.map((item,index) => {
                                return(
                                    <div
                                        key={index}
                                        draggable={true}
                                        onDragStart={e => startDragablePositionTopLevel(e, item)}
                                        onDragOver={e => onDragOverTopPosition(e,item) }
                                        onDragLeave={e => onDragLeaveTopPosition(e,item) }
                                        onDrop={e => onDragDropTopPosition(e,item)}
                                        onClick={e => setNumberColumn(index)}
                                        className={numberColumn === index ? "one_item_header_top_menu active" : "one_item_header_top_menu"}>

                                        <div
                                            onClick={e => deleteElementOnTopLevelHandler(index)}
                                            className={"delete_top_menu_level"}>удалить</div>
                                        <div className={"name_top_menu_level_item"}> {item.name}</div>
                                    </div>
                                )
                            }) : ""

                    }

                </div>
                <span className={"support_header_menu_page"}> Вложенный уровень, активируется кликом на пункт меню. </span>


                <div

                    className={"detail_area_menu"}>
                    <div
                        onDrop={e => onDropOverChild(numberColumn,dragElement)}
                        onDragOver={e => onDragOverChild(e)}
                        className={"add_new_column"}> Перетащите сюда, чтобы создать столбец </div>
                    <div className={"columns_wrapper_menu"}>
                        {

                            numberColumn !== null && headerMenu[numberColumn] ?

                                headerMenu[numberColumn].child ?

                                    headerMenu[numberColumn].child.map((item,index) => {
                                        return(
                                            <div

                                                onDragOver={ e => onDragOverColumnChild(e)}
                                                onDrop={e => onDropOverColumnChild(numberColumn, index, dragElement)}
                                                key={index} className={"area_menu_one_column"}


                                            >
                                                <div
                                                    onClick={e => deleteColumnHandler(numberColumn, index)}
                                                    className={"delbutton_column"}>X</div>
                                                {item.name}
                                                {
                                                    item.child_elements ?
                                                        item.child_elements.map((item,indexElement) => {
                                                            return(<div key={indexElement}
                                                                        className={"last_child_item_menu"}
                                                            >
                                                                <div
                                                                    onClick={e => deleteOneElementInColumn(numberColumn, index, indexElement)}
                                                                    className={"del_button_one_item"}>X</div>
                                                                {item.name}
                                                            </div>)
                                                        })
                                                        : ""
                                                }
                                            </div>)
                                    })
                                    : "" : ""

                        }
                    </div>

                </div>
                <div
                    onClick={ e => saveData(headerMenu) }
                    className={"save_btn_aft"}>Сохранить</div>
            </div>

            <div className={"container_foooter_column"}>
                <div className={"content_container_slayer"}>
                    <div className={"support_footer"}>Меню футера</div>
                    <div className={"name_input_footer"}>Максимальное количество столбцов 4, максмимальная длинна наименования столбца и наименования страницы 30 символов. </div>
                    <div
                        // onClick={e=> sortingСolumns(linkFooter)}
                        onClick={e=> addNewBlockFooter(Object.assign({}, culumnFooter ))}
                        className={footerMenu.length === 4 ? "add_new_column_footer non_active" : "add_new_column_footer"}>
                        Добавить столбец футера
                    </div>
                    <div className={"constructor_menu_footer"}>
                        {
                            footerMenu ? footerMenu.map((item, index) =>

                                <div key={index} className={"one_column_footer"}>
                                    <div className={"del_one_column"}>
                                        <div
                                            onClick={e=> deleteOneColumnFooter(index)}
                                            className={"del_column_child"}>✖</div>
                                    </div>
                                    <div>
                                        <div className={"one_input_field"}>
                                            <span className="name_input_footer">Наименование столбца</span>
                                            <input
                                                value={item.nameColumn ? item.nameColumn : ""}
                                                onChange={e => fieldsFooter(e, "nameColumn", index)}
                                                className="field_footer" type="text" maxlength="30" />
                                        </div>
                                        <div className={"wrapper_new_field"}>
                                            <div
                                                className={"add_new_line_footer"}
                                                onClick={e=> addDopFieldsColumn(footerFieldsSite, index )}
                                            >Добавить пункт +</div>
                                            <div
                                                className={"add_new_line_footer"}
                                                onClick={e=> addDopFieldsColumn(footerFieldsOther, index )}
                                            >Ссылка на сторонний ресурс</div>
                                        </div>
                                    </div>

                                    {
                                        item.fields ? item.fields.map((itemField, indexField) =>
                                            <div key={indexField} className={"one_field_footer"}>
                                                <div className={"one_input_field"}>
                                                    <span className="name_input_footer">Наименование страницы</span>
                                                    <input
                                                        value={itemField.name ? itemField.name : ""}
                                                        onChange={e => fieldChildFooter(e, "name", index, indexField)}
                                                        className="field_footer" type="text"  maxlength="30"/>
                                                </div>

                                                <div className={"one_input_field"}>
                                                    <span className="name_input_footer">Ссылка на страницу</span>
                                                    <input
                                                        value={itemField.value ? itemField.value : ""}
                                                        onChange={e => fieldChildFooter(e, "value", index, indexField)}
                                                        className="field_footer" type="text"  maxlength="30"/>

                                                </div>
                                                <div className={"del_one_column"}>
                                                    <div
                                                        onClick={e=> deleteOneFieldColumn(index, indexField)}
                                                        className={"del_column_child"}>✖</div>
                                                </div>

                                            </div>
                                        ) : ''
                                    }

                                </div>

                            ) : ''
                        }
                    </div>
                    <div className={"other_content_footer"}>
                        <div className="support_footer">Остальной контент подвала</div>

                        <div className={"wrapper_social_footer"}>
                            <div className={"subtitle_block_footer"}>Социальные сети</div>
                            <div className={"subtitle_social_footer"}>Для добавления и удаления элемента со страницы, необходимо нажать на нужный элемент</div>
                            <div className={"choice_social_footer"}>
                                {
                                    iconsSocialFooter ? iconsSocialFooter.map((item, index) =>
                                            <SocialIcons
                                                key = {index}
                                                item = {item}
                                                index = {index}
                                                noJson = {footerContent}
                                                setNoJsonContent = {setFooterContent}
                                                setUpdateSocialSaveTriger = {setTriggerFooter}
                                                activeCheck = {footerContent.footerSocial}
                                                nameRecordingObject = {'footerSocial'}
                                            />
                                        )
                                        :
                                        "Заполните менеджер соц.сетей"
                                }
                            </div>

                            {
                                footerContent.footerSocial && footerContent.footerSocial != '' ?
                                    <div className={"subtitle_social_footer"}>Выбранные соц.сети</div>
                                    :
                                    <div className={"subtitle_social_footer"}>Ниже появятся выбранные соц.сети</div>
                            }
                            <div className={"choice_social_footer"}>
                                {
                                    footerContent.footerSocial ? footerContent.footerSocial.map ((item, index) =>
                                            <ActiveSocialIcons
                                                key={index}
                                                item={item}
                                                index={index}
                                                // deleteJsonIcons={e => deleteJsonIcons(e)}
                                                activSocial={footerContent.footerSocial}
                                                setNoJsonContent = {setFooterContent}
                                                noJson={footerContent}
                                                setUpdateSocialSaveTriger = {setTriggerFooter}
                                                nameRecordingObject = {'footerSocial'}
                                            />
                                        )
                                        :
                                        ""
                                }

                            </div>
                        </div>


                        <div className={"feedback_footer_wrapper"}>
                            <div className={"subtitle_block_footer"}>Свяжитесь с нами</div>
                            <div className={"wrap_feedback_footer"}>
                                <div className={"wrapper_social_footer"}>
                                    <div className={"subtitle_social_footer"}>Для добавления и удаления элемента со страницы, необходимо нажать на нужный элемент</div>
                                    <div className={"choice_social_footer"}>
                                        {
                                            iconsSocialFooter ? iconsSocialFooter.map((item, index) =>
                                                    <SocialIcons
                                                        key = {index}
                                                        item = {item}
                                                        index = {index}
                                                        noJson = {footerContent}
                                                        setNoJsonContent = {setFooterContent}
                                                        setUpdateSocialSaveTriger = {setTriggerFooter}
                                                        activeCheck = {footerContent.contactUs}
                                                        nameRecordingObject = {'contactUs'}

                                                    />
                                                )
                                                :
                                                "Заполните менеджер соц.сетей"
                                        }
                                    </div>

                                    {
                                        footerContent.contactUs && footerContent.contactUs != '' ?
                                            <div className={"subtitle_social_footer"}>Выбранные способы связи</div>
                                            :
                                            <div className={"subtitle_social_footer"}>Ниже появятся выбранные способы связи</div>
                                    }
                                    <div className={"choice_social_footer"}>
                                        {
                                            footerContent.contactUs ? footerContent.contactUs.map ((item, index) =>
                                                    <ActiveSocialIcons
                                                        key={index}
                                                        item={item}
                                                        index={index}
                                                        // deleteJsonIcons={e => deleteJsonIcons(e)}
                                                        activSocial={footerContent.contactUs}
                                                        setNoJsonContent = {setFooterContent}
                                                        noJson={footerContent}
                                                        setUpdateSocialSaveTriger = {setTriggerFooter}
                                                        nameRecordingObject = {'contactUs'}
                                                    />
                                                )
                                                :
                                                ""
                                        }

                                    </div>
                                </div>
                                <div className={"custom_fields_feedback"}>
                                    <div
                                        className={"add_new_line_footer"}
                                        onClick={e=> addFieldsFeedback(Object.assign({}, dopFieldsContact ), 'dopNumberContact')}
                                    >Добавить произвольное поле +</div>
                                    {
                                        footerContent.dopNumberContact ? footerContent.dopNumberContact.map((itemFeedback,indexFeedback ) =>

                                            <div className={"wrapper_dop_field_contacts"}>
                                                <div className={"dop_field_footer"}>
                                                    <div key={indexFeedback} className={"one_input_field_content"}>
                                                        <span className="name_input_footer">Текст виден пользователя</span>
                                                        <input
                                                            value={itemFeedback.name ? itemFeedback.name : ""}
                                                            onChange={e => fieldsFooterContacts(e, "name",indexFeedback)}
                                                            className="field_footer" type="text" />
                                                    </div>
                                                    <div className={"one_input_field_content"}>
                                                        <span className="name_input_footer">Выберите тип ссылки</span>
                                                        <select className={"choosing_link"} name="category" value={itemFeedback.website ? itemFeedback.website : 'не выбрано'} onChange={e => fieldsFooterContacts(e,"website",indexFeedback )}>
                                                            {
                                                                linkDopContact ? linkDopContact.map((itemLink, indexLink) =>
                                                                    <option key={indexLink} id={indexLink}>{itemLink.website}</option>
                                                                ) :''
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className={"one_input_field_content"}>
                                                        <span className="name_input_footer">Ссылка (телефон: 79999999999, сайт: /catalog/, сторонний ресурс: https://you-wanna.ru/)</span>
                                                        <input
                                                            value={itemFeedback.link ? itemFeedback.link : ""}
                                                            onChange={e => fieldsFooterContacts(e, "link", indexFeedback)}
                                                            className="field_footer" type="text" />
                                                    </div>
                                                </div>

                                                <div className={"del_one_column"}>
                                                    <div
                                                        onClick={e=> deleteDopFieldContacts(indexFeedback, 'dopNumberContact')}
                                                        className={"del_column_child"}>✖</div>
                                                </div>

                                            </div>



                                        ) : <div className={"subtitle_social_footer"}>Дополнительные поля не добавлены</div>
                                    }

                                </div>
                            </div>
                        </div>


                        <div className={"payment_footer"}>
                            <div className={"subtitle_block_footer"}>Способы оплаты</div>
                            <div
                                className={"add_new_line_footer"}
                                onClick={e=> addFieldsFeedback(Object.assign({}, onePayBlock ), 'paymentMethods')}
                            >Добавить способ оплаты</div>
                            <div className={"pay_footer_wrap"}>
                                {
                                    footerContent.paymentMethods ? footerContent.paymentMethods.map((itemPay, indexPay) =>
                                        <div
                                            key={indexPay}
                                            className={"one_pay_footer"}>
                                            <div className={"del_one_column"}>
                                                <div
                                                    onClick={e=> deleteImgPayFooter(indexPay, 'paymentMethods')}
                                                    className={"del_column_child"}>✖</div>
                                            </div>
                                            <div className={"pay_field_wrap"}>
                                                <OneImageField
                                                    text={"Картинка оплаты"}
                                                    image={itemPay.img ?
                                                        process.env.REACT_APP_API_URL+"uploads/images/footer/payment/"+itemPay.img  : null }
                                                    onChange={ e => uploadPayFooter(e,'paymentMethods','img', indexPay, "payment")}
                                                    deleteImage={ e => deletePayFooter(e,'paymentMethods', 'img', indexPay, "payment")}
                                                />
                                            </div>
                                        </div>
                                    ) : ''
                                }
                            </div>
                        </div>

                        <div className={"logo_and_othen_text"}>
                            <div className={"logo_footer_wrapper"}>
                                <div className={"subtitle_block_footer"}>Логотип</div>
                                <div className={"logo_img_footer"}>
                                    <OneImageHorizontalField
                                        onChange={ e => uploadLogoFooter(e,'logo','logo') }
                                        image={footerContent.logo ?
                                            process.env.REACT_APP_API_URL+"uploads/images/footer/logo/"+footerContent.logo  : null }
                                        deleteImage={e => deleteLogoFooter(footerContent.logo)}

                                    />
                                </div>
                            </div>

                            <div className={"text_footer_wrapper"}>
                                <div className={"footer_copywriting"}>
                                    <div className={"one_input_field_content"}>
                                        <div className={"subtitle_block_footer"}>Текст копирайта</div>
                                        <input
                                            value={footerContent.datesExistence ? footerContent.datesExistence : ""}
                                            onChange={e => fieldsFooterContent(e, "datesExistence")}
                                            className="field_footer" type="text" />
                                    </div>
                                </div>

                                <div className={"footer_link_politic"}>
                                    <div className={"subtitle_block_footer"}>Текст внизу подвала с сылкой</div>
                                    <div className={"text_link_politic"}>
                                        <div className={"one_input_field one_input_field_content"}>
                                            <span className="name_input_footer">Не кликабельный текст</span>
                                            <input
                                                value={footerContent.textToPolicy ? footerContent.textToPolicy : ""}
                                                onChange={e => fieldsFooterContent(e, "textToPolicy")}
                                                className="field_footer" type="text" />
                                        </div>
                                        <div className={"one_input_field_content"}>
                                            <span className="name_input_footer">Кликабельный текст</span>
                                            <input
                                                value={footerContent.textClickable ? footerContent.textClickable : ""}
                                                onChange={e => fieldsFooterContent(e, "textClickable")}
                                                className="field_footer" type="text" />
                                        </div>
                                        <div className={"one_input_field_content"}>
                                            <span className="name_input_footer">Ссылка (только на текущий сайт)</span>
                                            <input
                                                value={footerContent.linkToPolicy ? footerContent.linkToPolicy : ""}
                                                onChange={e => fieldsFooterContent(e, "linkToPolicy")}
                                                className="field_footer" type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                    <div className={"save_footer_content"}>
                        <div
                            onClick={ e => saveFooter(footerMenu, footerContent) }
                            className={ triggerFooter === true ? "save_btn_aft trig" : "save_btn_aft"}
                        >Сохранить</div>
                        <span className="save_runline_notificator">{serverResponseFooter}</span>
                    </div>
                </div>
            </div>




        </div>
    );
};


export default HeaderAndFooterSettings;

