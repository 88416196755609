import React from 'react';
import './CatalogViewLanding.css';
const CatalogViewLanding = () => {
    return (
        <div className={"view_controller"}>
            <div className={"wrapper_control_view_button"}>
                <div className={"style_view active"}>
                    <div className="style_item"></div>
                    <div className="style_item"></div>
                </div>
            </div>

            <div className={"wrapper_control_view_button"}>
                <div className={"default_view"}>
                    <div className="coub_view"> </div>
                    <div className="coub_view"> </div>
                    <div className="coub_view"> </div>
                    <div className="coub_view"> </div>
                </div>
            </div>
            <div className={"wrapper_control_view_button"}>
                <div className={"small_view_btn"}>
                    <div className={"small_view_item"}></div>
                    <div className={"small_view_item"}></div>
                    <div className={"small_view_item"}></div>
                    <div className={"small_center_controller"}>
                        <div className={"small_view_item"}></div>
                        <div className={"small_view_item"}></div>
                        <div className={"small_view_item"}></div>
                    </div>
                    <div className={"small_view_item"}></div>
                    <div className={"small_view_item"}></div>
                    <div className={"small_view_item"}></div>
                </div>
            </div>
        </div>
    );
};

export default CatalogViewLanding;