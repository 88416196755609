import React from 'react';
import './deleteImage.css';

const DeleteImage = (props) => {
    return (
        <div onClick={props.onClick} className={"delete_image"}>
            УДАЛИТЬ
        </div>
    );
};

export default DeleteImage;